
import dataObj from '../utils_old/data-obj'

export default {
  methods: {
    generateParagraph (translate, repeat, fontSize, italic) {
      let array = []
      for (let index = 0; index < repeat; index++) {
        array.push(
          {
            text: this.$t(`${translate + '[' + index + ']'}`),
            alignment: 'justify',
            margin: [0, 0, 16, 12],
            color: '#6d7a7a',
            lineHeight: 1.5,
            fontSize: fontSize,
            italics: italic
          }
        )
      }
      return array
    },
    $generateDmlResults () {
      const higherScores = []
      const lowerScores = []
      let rankingH = []
      let rankingL = []
      rankingH = JSON.parse(JSON.stringify(this.noAutoOthersRankFiltered))
      rankingH.sort((a, b) => b.score - a.score)
      rankingL = JSON.parse(JSON.stringify(this.noAutoOthersRankFiltered))
      rankingL.sort((a, b) => a.score - b.score)
      if (this.othersPopulationFiltered > 0) {
        const noZero = (n) => (n === 0 ? '--' : this.round(n, 2, '--'))

        for (let i = 0; i < 6; i++) {
          let othersScore = noZero(dataObj.propertieOrDefault(rankingH[i], 'score', '--'))
          let autoScore = noZero(dataObj.propertieOrDefault(dataObj.arrayFindObjectByKeyObj(dataObj.arrayFindObjectByKeyObj(this.autoScoresFiltered, 'name', rankingH[i].process).questions, 'question', rankingH[i].question), 'score', '--'))
          let leadersScore = noZero(dataObj.propertieOrDefault(dataObj.arrayFindObjectByKeyObj(this.leadersRankFiltered, 'question', rankingH[i].question), 'score', '--'))
          let pairsScore = noZero(dataObj.propertieOrDefault(dataObj.arrayFindObjectByKeyObj(this.pairsRankFiltered, 'question', rankingH[i].question), 'score', '--'))
          let dependentsScore = noZero(dataObj.propertieOrDefault(dataObj.arrayFindObjectByKeyObj(this.dependentsRankFiltered, 'question', rankingH[i].question), 'score', '--'))

          higherScores.push([
            { text: rankingH[i].abbreviation, color: '#6d7a7a', margin: [0, 0, 0, 0], border: [true, true, true, true] },
            { text: rankingH[i].responsability, color: '#6d7a7a', margin: [0, 0, 0, 0], border: [true, true, true, true] },
            { text: rankingH[i].question, color: '#6d7a7a', margin: [0, 0, 0, 0], border: [true, true, true, true] },
            { text: othersScore, color: '#6d7a7a', margin: [0, 4, 0, 0], border: [true, true, true, true], alignment: 'center' },
            { text: autoScore,
              color: '#6d7a7a',
              margin: [0, 4, 0, 0],
              border: [true, true, true, true],
              alignment: 'center'
            },
            { text: leadersScore, color: '#6d7a7a', margin: [0, 4, 0, 0], border: [true, true, true, true], alignment: 'center' },
            { text: pairsScore, color: '#6d7a7a', margin: [0, 4, 0, 0], border: [true, true, true, true], alignment: 'center' },
            { text: dependentsScore, color: '#6d7a7a', margin: [0, 4, 0, 0], border: [true, true, true, true], alignment: 'center' }
          ])

          othersScore = noZero(dataObj.propertieOrDefault(rankingL[i], 'score', '--'))
          autoScore = noZero(dataObj.propertieOrDefault(dataObj.arrayFindObjectByKeyObj(dataObj.arrayFindObjectByKeyObj(this.autoScoresFiltered, 'name', rankingL[i].process).questions, 'question', rankingL[i].question), 'score', '--'))
          leadersScore = noZero(dataObj.propertieOrDefault(dataObj.arrayFindObjectByKeyObj(this.leadersRankFiltered, 'question', rankingL[i].question), 'score', '--'))
          pairsScore = noZero(dataObj.propertieOrDefault(dataObj.arrayFindObjectByKeyObj(this.pairsRankFiltered, 'question', rankingL[i].question), 'score', '--'))
          dependentsScore = noZero(dataObj.propertieOrDefault(dataObj.arrayFindObjectByKeyObj(this.dependentsRankFiltered, 'question', rankingL[i].question), 'score', '--'))

          lowerScores.push([
            { text: rankingL[i].abbreviation, color: '#6d7a7a', margin: [0, 0, 0, 0], border: [true, true, true, true] },
            { text: rankingL[i].responsability, color: '#6d7a7a', margin: [0, 0, 0, 0], border: [true, true, true, true] },
            { text: rankingL[i].question, color: '#6d7a7a', margin: [0, 0, 0, 0], border: [true, true, true, true] },
            { text: othersScore, color: '#6d7a7a', margin: [0, 4, 0, 0], border: [true, true, true, true], alignment: 'center' },
            { text: autoScore,
              color: '#6d7a7a',
              margin: [0, 4, 0, 0],
              border: [true, true, true, true],
              alignment: 'center'
            },
            { text: leadersScore, color: '#6d7a7a', margin: [0, 4, 0, 0], border: [true, true, true, true], alignment: 'center' },
            { text: pairsScore, color: '#6d7a7a', margin: [0, 4, 0, 0], border: [true, true, true, true], alignment: 'center' },
            { text: dependentsScore, color: '#6d7a7a', margin: [0, 4, 0, 0], border: [true, true, true, true], alignment: 'center' }
          ])
        }
      } else {
        higherScores.push([
          { text: this.$t('individualReport.no_others_evaluators'), color: '#6d7a7a', margin: [0, 0, 0, 0], border: [true, true, true, true], colSpan: 8 }
        ])
        lowerScores.push([
          { text: this.$t('individualReport.no_others_evaluators'), color: '#6d7a7a', margin: [0, 0, 0, 0], border: [true, true, true, true], colSpan: 8 }
        ])
      }
      return [
        {
          table: {
            widths: [479],
            body: [
              [{ text: `${this.$t('individualReport.results_dml')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 6, 0, 6] }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [-40, 0, 0, 20],
          pageBreak: 'before'
        },
        {
          text: `${this.$t('individualReport.results_text')}`,
          alignment: 'justify',
          margin: [0, 0, 22, 0],
          color: '#6d7a7a',
          lineHeight: 1.5
        },
        {
          layout: {
            defaultBorder: '',
            border: [false, false, false, true],
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#BFBFBF' : null
            }
          },
          table: {
            headerRows: 1,
            body: [
              [
                { text: `${this.$t('individualReport.others')}*`, bold: true, color: '#fff', margin: [0, 0, 0, 0], border: [true, true, true, true] },
                { text: `${this.$t('individualReport.autoevaluation')}`, bold: true, color: '#fff', border: [true, true, true, true], margin: [0, 0, 0, 0] },
                { text: `${this.$t('individualReport.leaders')}`, bold: true, color: '#fff', border: [true, true, true, true], margin: [0, 0, 0, 0] },
                { text: `${this.$t('individualReport.pairs')}`, bold: true, color: '#fff', border: [true, true, true, true], margin: [0, 0, 0, 0] },
                { text: `${this.$t('individualReport.dependents')}`, bold: true, color: '#fff', border: [true, true, true, true], margin: [0, 0, 0, 0] }
              ],
              [
                { text: `O`, bold: true, color: '#6d7a7a', border: [true, true, true, true], margin: [0, 0, 0, 0], alignment: 'center' },
                { text: `A`, bold: true, color: '#6d7a7a', border: [true, true, true, true], margin: [0, 0, 0, 0], alignment: 'center' },
                { text: `L`, bold: true, color: '#6d7a7a', border: [true, true, true, true], margin: [0, 0, 0, 0], alignment: 'center' },
                { text: `P`, bold: true, color: '#6d7a7a', border: [true, true, true, true], margin: [0, 0, 0, 0], alignment: 'center' },
                { text: `C`, bold: true, color: '#6d7a7a', border: [true, true, true, true], margin: [0, 0, 0, 0], alignment: 'center' }
              ]
            ]
          },
          margin: [120, 0, 15, 10],
          border: [false, false, false, true],
          alignment: 'center',
          fontSize: 10
        },
        {
          text: `${this.$t('individualReport.results_note')}`,
          alignment: 'center',
          margin: [30, 0, 30, 0],
          color: '#6d7a7a',
          lineHeight: 1.5
        },
        {
          table: {
            widths: [479],
            body: [
              [{ text: `${this.$t('individualReport.higher_scores_title')}`, bold: true, color: '#fff', margin: [20, 6, 0, 6] }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [35, 5, 100, 20]
        },
        {
          text: `${this.$t('individualReport.higher_scores[1]')}`,
          alignment: 'justify',
          margin: [0, 0, 22, 12],
          color: '#6d7a7a',
          lineHeight: 1.5
        },
        {
          table: {
            widths: [479],
            body: [
              [{ text: `${this.$t('individualReport.table')} 1. ${this.$t('individualReport.table1')}`, bold: true, color: '#fff', margin: [20, 6, 0, 6] }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [35, 5, 100, 20]
        },
        {
          layout: {
            defaultBorder: '',
            border: [false, false, false, true],
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex <= 1) ? '#BFBFBF' : null
            }
          },
          table: {
            headerRows: 2,
            widths: ['5%', '30%', '40%', '5%', '5%', '5%', '5%', '5%'],
            body: [
              [
                { text: this.$t('individualReport.process_abbr'), bold: true, color: '#fff', margin: [0, 12, 0, 0], border: [true, true, true, true], fontSize: 12, rowSpan: 2 },
                { text: this.$t('individualReport.responsability'), bold: true, color: '#fff', margin: [0, 12, 0, 0], border: [true, true, true, true], fontSize: 12, rowSpan: 2 },
                { text: this.$t('individualReport.behavior'), bold: true, color: '#fff', margin: [0, 12, 0, 0], border: [true, true, true, true], fontSize: 12, rowSpan: 2 },
                { text: this.$t('individualReport.scores'), bold: true, color: '#fff', margin: [0, 2, 0, 0], border: [true, true, true, true], fontSize: 12, colSpan: 5 },
                {},
                {},
                {},
                {}
              ],
              [
                {},
                {},
                {},
                { text: `O`, bold: true, color: '#fff', margin: [0, 0, 0, 0], border: [true, true, true, true], alignment: 'center' },
                { text: `A`, bold: true, color: '#fff', margin: [0, 0, 0, 0], border: [true, true, true, true], alignment: 'center' },
                { text: `L`, bold: true, color: '#fff', margin: [0, 0, 0, 0], border: [true, true, true, true], alignment: 'center' },
                { text: `P`, bold: true, color: '#fff', margin: [0, 0, 0, 0], border: [true, true, true, true], alignment: 'center' },
                { text: `C`, bold: true, color: '#fff', margin: [0, 0, 0, 0], border: [true, true, true, true], alignment: 'center' }
              ],
              ...higherScores
            ]
          },
          margin: [0, 0, 0, 10],
          border: [false, false, false, true],
          alignment: 'center',
          fontSize: 10
        },
        {
          layout: {
            defaultBorder: '',
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            widths: [300],
            body: [
              [
                { text: `${this.$t('individualReport.notes')}:`, bold: true, color: '#6d7a7a', margin: [0, 0, 0, 0], border: [false, false, false, true] }
              ]
            ]
          },
          margin: [30, 0, 15, 10],
          border: [false, false, false, true],
          alignment: 'left',
          fontSize: 10
        },
        // Lower scores
        {
          table: {
            widths: [479],
            body: [
              [{ text: `${this.$t('individualReport.lower_scores_title')}`, bold: true, color: '#fff', margin: [20, 6, 0, 6] }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [35, 0, 100, 20],
          pageBreak: 'before'
        },
        {
          text: `${this.$t('individualReport.lower_scores[1]')}`,
          alignment: 'justify',
          margin: [0, 0, 22, 12],
          color: '#6d7a7a',
          lineHeight: 1.5
        },
        {
          table: {
            widths: [479],
            body: [
              [{ text: `${this.$t('individualReport.table')} 2. ${this.$t('individualReport.table2')}`, bold: true, color: '#fff', margin: [20, 6, 0, 6] }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [35, 5, 100, 20]
        },
        {
          layout: {
            defaultBorder: '',
            border: [false, false, false, true],
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex <= 1) ? '#BFBFBF' : null
            }
          },
          table: {
            headerRows: 2,
            widths: ['5%', '30%', '40%', '5%', '5%', '5%', '5%', '5%'],
            body: [
              [
                { text: this.$t('individualReport.process_abbr'), bold: true, color: '#fff', margin: [0, 12, 0, 0], border: [true, true, true, true], fontSize: 12, rowSpan: 2 },
                { text: this.$t('individualReport.responsability'), bold: true, color: '#fff', margin: [0, 12, 0, 0], border: [true, true, true, true], fontSize: 12, rowSpan: 2 },
                { text: this.$t('individualReport.behavior'), bold: true, color: '#fff', margin: [0, 12, 0, 0], border: [true, true, true, true], fontSize: 12, rowSpan: 2 },
                { text: this.$t('individualReport.scores'), bold: true, color: '#fff', margin: [0, 2, 0, 0], border: [true, true, true, true], fontSize: 12, colSpan: 5 },
                {},
                {},
                {},
                {}
              ],
              [
                {},
                {},
                {},
                { text: `O`, bold: true, color: '#fff', margin: [0, 0, 0, 0], border: [true, true, true, true], alignment: 'center' },
                { text: `A`, bold: true, color: '#fff', margin: [0, 0, 0, 0], border: [true, true, true, true], alignment: 'center' },
                { text: `L`, bold: true, color: '#fff', margin: [0, 0, 0, 0], border: [true, true, true, true], alignment: 'center' },
                { text: `P`, bold: true, color: '#fff', margin: [0, 0, 0, 0], border: [true, true, true, true], alignment: 'center' },
                { text: `C`, bold: true, color: '#fff', margin: [0, 0, 0, 0], border: [true, true, true, true], alignment: 'center' }
              ],
              ...lowerScores
            ]
          },
          margin: [0, 0, 0, 10],
          border: [false, false, false, true],
          alignment: 'center',
          fontSize: 10
        },
        {
          layout: {
            defaultBorder: '',
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            widths: [300],
            body: [
              [
                { text: `${this.$t('individualReport.notes')}:`, bold: true, color: '#6d7a7a', margin: [0, 0, 0, 0], border: [false, false, false, true] }
              ]
            ]
          },
          margin: [30, 0, 15, 10],
          border: [false, false, false, true],
          alignment: 'left',
          fontSize: 10
        }
      ]
    }
  }
}
