<template>
  <div class="text-center">
    <v-dialog
      style="margin-left: -20px"
      v-model="display"
      width="60em"
      persistent
    >

      <v-card>
        <v-card-title
          class="headline grey lighten-2 text-center"
          style="padding: 1em"
          primary-title
        >
          {{ $t('auth.terms_and_confitions_changed_header') }}
        </v-card-title>

        <v-card-text>
          <div class="ql-editor text-md-left" v-html="termsAndConditions.description"/>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="text-uppercase"
            large
            @click="acceptTerms"
          >
            {{ $t('auth.accept') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import termsAndConditionsService from '../services/terms-and-conditions'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

export default {
  props: {
    user: Object
  },
  data () {
    return {
      termsAndConditions: {
        description: ''
      },
      display: true
    }
  },
  created () {
    if (this.user.role === 'customer') {
      this.fetchLastTerms()
    }
  },
  methods: {
    acceptTerms () {
      this.$emit('acceptNewTerms', this.user.customer.id, this.termsAndConditions.id)
    },
    fetchLastTerms () {
      // this.$store.dispatch('loading/show')
      return termsAndConditionsService.getActiveByType(this.user.customer.type)
        .then((res) => {
          this.termsAndConditions = res
          // this.$store.dispatch('loading/hide')
        }).catch(() => {
          // this.$store.dispatch('loading/hide')
        })
    }
  }
}
</script>
