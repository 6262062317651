
export default {
  add_employee: 'Add employee',
  employee_fields: 'Employee\'s information',
  birthdate: 'Birthdate',
  personal_data: 'Personal data',
  employment_data: 'Employment data',
  created_employee: 'Successfully created employee',
  import_employees: 'Import employees',
  download_template: 'Download template',
  download_docs: 'Download manual',
  upload_data: 'Upload data',
  // tslint:disable-next-line:max-line-length
  upload_description: 'Upload an excel (.xls/.xlsx) or csv (.csv) file with your employees data. This process will update your whole employees data so you should provide the entire employees base.',
  select_file_to_upload: 'Select file to upload',
  select_file: 'Select file',
  summary: 'Process summary',
  fix_errors: 'Errors correction',
  total_updated: '{total} employees will be updated.',
  total_inserted: '{total} employees will be inserted.',
  total_deleted: '{total} employees will be deactivated.',
  total_failed: '{total} records with errors.',
  file_updated: 'File updated',
  save_changes: 'Save changes',
  error_fix: 'Fix errors',
  if_any: 'If any',
  process_cancelled: 'Process cancelled',
  massive_success: 'Massive upload successfully executed.',
  massive_upload: 'Massive upload',
  download_log: 'Download log',
  id: 'Id',
  name: 'Name',
  last_name: 'Last Name',
  city: 'City',
  email: 'Mail',
  action: 'Action',
  employment: 'Employment',
  employees: 'Employees',
  list_employees: 'List of employees',
  look_for: 'Search for an employee',
  filter_by: 'Filter by...',
  no_data: 'There are no employees to show',
  modal_enable: 'Do you want to enable the employee?',
  modal_disable: 'Do you want to disable the employee?',
  modal_action: 'Take action',
  status: 'Estado',
  edit_employee: 'Edit employee',
  enable_title: 'Enable employee',
  disable_title: 'Disable employee',
  // tslint:disable-next-line:max-line-length
  enable_text: 'Do you really want to enable this employee? After that the employee will have access to the system.',
  // tslint:disable-next-line:max-line-length
  disable_text: 'Do you really want to disable this employee? After that the employee will not be able to access the system.',
  employment_at: '{title} at',
  updated_employee: 'Successfully updated employee'
}
