<template>
  <v-dialog v-model="modalWarnings" width="70em">
    <v-card>
      <v-card-title
        class="headline grey lighten-2"
        style="padding: 1em"
        primary-title
      >
        {{ $t('evaluations.warnings_list') }}
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" v-if="errors.evaluatedDuplicated.length">
            <v-alert v-for="evaluatedDuplicated in errors.evaluatedDuplicated" :key="evaluatedDuplicated.id"
              type="warning"
              dense
              text
            >
              {{ $t('evaluations.the_evaluated') }} <strong>{{ evaluatedDuplicated.firstName }} {{ evaluatedDuplicated.lastName }} </strong>
              {{ $t('evaluations.multiple_appears_warning') }}
            </v-alert>
          </v-col>
          <v-col cols="12" v-if="errors.evaluatedEvaluator.length">
            <v-alert v-for="evaluatedEvaluator in errors.evaluatedEvaluator" :key="evaluatedEvaluator.id"
              type="warning"
              dense
              text
            >
              {{ $t('evaluations.the_evaluated') }} <strong>{{ evaluatedEvaluator.employee.firstName }} {{ evaluatedEvaluator.employee.lastName }}</strong>
              {{ $t('evaluations.evaluated_as_evaluator_warning') }}
            </v-alert>
          </v-col>
          <v-col cols="12" v-if="errors.evaluatedNotFound.length">
            <v-alert
              type="warning"
              dense
              text
            >
              {{ $t('evaluations.evaluated_not_found_warning') }}
              <ul>
                <li v-for="evaluatedNotFound in errors.evaluatedNotFound" :key="evaluatedNotFound.id">
                  {{evaluatedNotFound}}
                </li>
              </ul>
            </v-alert>
          </v-col>
          <v-col cols="12" v-if="errors.evaluatorDuplicated.length">
            <v-alert v-for="evaluatorDuplicated in errors.evaluatorDuplicated" :key="evaluatorDuplicated.id"
              type="warning"
              dense
              text
            >
              {{ $t('evaluations.the_evaluator') }} <strong>{{ evaluatorDuplicated.employee.firstName }} {{ evaluatorDuplicated.employee.lastName }}</strong>
              {{ $t('evaluations.duplicated_as_evaluator_of') }} <strong>{{ evaluatorDuplicated.evaluated.firstName }} {{ evaluatorDuplicated.evaluated.lastName }} </strong>
              {{ $t('evaluations.take_only_first_ap') }}
            </v-alert>
          </v-col>
          <v-col cols="12" v-if="errors.evaluatorNotFound.length">
            <v-alert v-for="evaluatorNotFound in errors.evaluatorNotFound" :key="evaluatorNotFound.id"
              type="warning"
              dense
              text
            >
              {{ $t('evaluations.the_evaluator') }} <strong>{{ evaluatorNotFound.evaluator }}</strong> {{ $t('evaluations.added_as_evaluator_type') }}
              <strong>{{ evaluatorNotFound.type }} </strong> {{ $t('evaluations.on_the_evaluation_of') }} <strong>{{ evaluatorNotFound.evaluated.firstName }} {{ evaluatorNotFound.evaluated.lastName }} </strong>
              {{ $t('evaluations.not_found_as_employee') }}
            </v-alert>
          </v-col>
          <v-col cols="12" v-if="errors.evaluatorTypeError.length">
            <v-alert v-for="evaluatorTypeError in errors.evaluatorTypeError" :key="evaluatorTypeError.id"
              type="warning"
              dense
              text
            >
              {{ $t('evaluations.the_evaluator') }} <strong>{{ evaluatorTypeError.evaluator }}</strong> {{ $t('evaluations.added_as_evaluator_type') }}
              <strong>{{ evaluatorTypeError.type }} </strong> {{ $t('evaluations.on_the_evaluation_of') }} <strong>{{ evaluatorTypeError.evaluated.firstName }} {{ evaluatorTypeError.evaluated.lastName }} </strong>
              {{ $t('evaluations.evaluator_type_incorrect') }}
            </v-alert>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="text-uppercase"
          large
          text
          @click="hideModalWarnings"
        >
          {{ $t('input.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import Vue from 'vue'

export default Vue.extend({
  props: {
    errors: Object
  },
  data () {
    return {
      modalWarnings: true
    }
  },
  methods: {
    hideModalWarnings () {
      this.$emit('hideModalWarnings')
    }
  }
})
</script>
