
export default {
  title: 'Precios y recomendaciones',
  cost: ' Posee un costo de ',
  rate: ' Tokens por ',
  table_ranges: 'Rango de Tokens',
  table_prices: 'Valor por Token',
  medition: 'valoración',
  download: 'descarga',
  person: 'participante',
  recomendation: 'Se recomienda ',
  onetime: ' una única ejecución',
  annual: ' Anual',
  ondemand: ' bajo demanda',
  ormore: 'O Más',
  services: 'SERVICIOS',
  recomendations: 'RECOMENDACIONES'
}
