
class DataObj {
  objOrEmptyObj (obj: any | undefined) {
    return obj || {}
  }

  propertieOrDefault (obj: any | undefined, key: string, df?: string | undefined) {
    return obj ? obj[key] : (df || '')
  }

  arrayFindByName (array: [any] | undefined, value: string, key: string, df?: string | undefined) {
    const obj = array ? array.find(element => element.name === value) : undefined
    return obj ? Number(obj[key]) : (df || '')
  }

  arrayFindObjectByKeyObj (array: [any] | undefined, key: string, value: string) {
    const obj = array ? array.find(element => element[key] === value) : undefined
    return this.objOrEmptyObj(obj)
  }

  objectInArrayFindByName (array: [any] | undefined, key: string, index: number) {
    const obj = array ? array[index] : undefined
    return obj ? Number(obj[key]) : ''
  }

  previousScore (value: any | undefined) {
    if (isNaN(Number(value)) || value === 0) {
      return '--'
    }
    return value
  }
}

export default new DataObj()
