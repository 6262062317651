
export default {
  methods: {
    $generateTrends () {
      const higherPositive = []
      const higherNegative = []
      let ranking = this.othersRankFiltered
      ranking.sort((a, b) => b.trend - a.trend)
      const textColor = '#6d7a7a'
      for (let i = 0; i < 6; i++) {
        const isPositive = this.hasPrevious && ranking[i].trend > 0 && ranking[i].previous > 0
        higherPositive.push([
          // Higher score
          { text: `${isPositive ? ranking[i].abbreviation : '.'}`, margin: [0, 5, 0, 0], fontSize: 10, border: [true, true, true, true], bold: true, style: { color: ranking[i].abbreviation === 'P' ? '#0D47A1' : ranking[i].abbreviation === 'D' ? '#b4cf32' : ranking[i].abbreviation === 'M' ? '#4ec7aa' : '#43ace2' } },
          { text: `${isPositive ? ranking[i].responsability : '.'}`, margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: textColor } },
          { text: `${isPositive ? ranking[i].question : '.'}`, margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: textColor } },
          { text: `${isPositive ? this.round(ranking[i].score, 2) : '.'}`, alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } },
          { text: `${isPositive ? this.round(ranking[i].previous, 2) : '.'}`, alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } },
          { text: `${isPositive ? this.round(ranking[i].trend, 2) : '.'}`, alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } }
        ])
        const isNegative = this.hasPrevious && ranking[ranking.length - (i + 1)].trend < 0 && ranking[ranking.length - (i + 1)].previous > 0
        higherNegative.push([
          { text: `${isNegative ? ranking[ranking.length - (i + 1)].abbreviation : '.'}`, margin: [0, 5, 0, 0], fontSize: 10, border: [true, true, true, true], bold: true, style: { color: ranking[ranking.length - (i + 1)].abbreviation === 'P' ? '#0D47A1' : ranking[ranking.length - (i + 1)].abbreviation === 'D' ? '#b4cf32' : ranking[ranking.length - (i + 1)].abbreviation === 'M' ? '#4ec7aa' : '#43ace2' } },
          { text: `${isNegative ? ranking[ranking.length - (i + 1)].responsability : '.'}`, margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: textColor } },
          { text: `${isNegative ? ranking[ranking.length - (i + 1)].question : '.'}`, margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: textColor } },
          { text: `${isNegative ? this.round(ranking[ranking.length - (i + 1)].score, 2) : '.'}`, alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } },
          { text: `${isNegative ? this.round(ranking[ranking.length - (i + 1)].previous, 2) : '.'}`, alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } },
          { text: `${isNegative ? this.round(ranking[ranking.length - (i + 1)].trend, 2) : '.'}`, alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } }
        ])
      }
      return [
        {
          table: {
            widths: [479],
            body: [
              [{ text: `6.9. ${this.$t('individualReport.trend_ranking')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 6, 0, 6] }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [-40, 0, 0, 20],
          pageBreak: 'before'
        },
        {
          text: this.$t('individualReport.trends_report[1]'),
          alignment: 'justify',
          margin: [0, 0, 16, 12],
          color: textColor,
          lineHeight: 1.5,
          fontSize: 10
        },
        {
          table: {
            widths: [479],
            body: [
              [{ text: [
                `${this.$t('individualReport.table')} 28. ${this.$t('individualReport.higher_positives_trends')}`
              ],
              bold: true,
              color: '#fff',
              margin: [20, 6, 0, 6] }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [35, 5, 100, 20]
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#BFBFBF' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            widths: ['6%', '33%', '40%', '7%', '7%', '7%'],
            body: [
              [
                { text: this.$t('individualReport.process'), margin: [-2, 5, -2, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.responsability'), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.behavior'), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.actual_score'), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.previous_score'), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.trend'), margin: [-3, 5, -3, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } }
              ],
              // Body
              ...higherPositive
            ]
          },
          margin: [0, 0, 0, 10],
          border: [false, false, false, true],
          alignment: 'center',
          fontSize: 8
        },
        {
          table: {
            widths: [479],
            body: [
              [{ text: [
                `${this.$t('individualReport.table')} 29. ${this.$t('individualReport.higher_negatives_trends')}`
              ],
              bold: true,
              color: '#fff',
              margin: [20, 6, 0, 6] }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [35, 5, 100, 20]
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#BFBFBF' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            widths: ['6%', '33%', '40%', '7%', '7%', '7%'],
            body: [
              [
                { text: this.$t('individualReport.process'), margin: [-2, 5, -2, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.responsability'), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.behavior'), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.actual_score'), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.previous_score'), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.trend'), margin: [-3, 5, -3, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } }
              ],
              // Body
              ...higherNegative
            ]
          },
          margin: [0, 0, 0, 10],
          border: [false, false, false, true],
          alignment: 'center',
          fontSize: 8
        }
      ]
    }
  }
}
