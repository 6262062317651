
import evaluationService from '../../../services/evaluations'

export default {
  methods: {
    getScores (data, sumCalcsPolls = false) {
      let result = {
        averageScores: [],
        noAutoScores: [],
        leadersScores: [],
        pairsScores: [],
        dependentsScores: [],
        averageResponsability: [],
        noAutoScoresResponsability: [],
        leadersResponsability: [],
        pairsResponsability: [],
        dependentsResponsability: [],
        noAutoPopulation: 0,
        leadersPopulation: 0,
        pairsPopulation: 0,
        dependentsPopulation: 0
      }
      let count = 0
      let population = 0
      // let scores = []
      // let noAutoScores = []
      // let noAutoPopulation = 0
      // let leadersScores = []
      // let leadersPopulation = 0
      // let pairsScores = []
      // let pairsPopulation = 0
      // let dependentsScores = []
      // let dependentsPopulation = 0
      // let averageResponsability = []
      // let noAutoScoresResponsability = []
      // let leadersResponsability = []
      // let pairsResponsability = []
      // let dependentsResponsability = []
      let headers = ['as_person', 'management', 'mobilization', 'achievement']
      const generalEvaluation = data.questionnaire.evaluations.generalEvaluation
      // Inicializando objetos
      for (let i = 0; i < headers.length; i++) {
        result.averageScores[i] = { ...this.getObjProps_Name_Question_Total_IndividualScores_TenPercent(i, headers), questionsScores: [] }
        result.averageResponsability[i] = this.getObjProps_Name_Responsability(i, headers)
        result.noAutoScores[i] = { ...this.getObjProps_Name_Question_Total_IndividualScores_TenPercent(i, headers) }
        result.noAutoScoresResponsability[i] = this.getObjProps_Name_Responsability(i, headers)
        result.leadersScores[i] = { ...this.getObjProps_Name_Question_Total_IndividualScores_TenPercent(i, headers) }
        result.leadersResponsability[i] = this.getObjProps_Name_Responsability(i, headers)
        result.pairsScores[i] = { ...this.getObjProps_Name_Question_Total_IndividualScores_TenPercent(i, headers) }
        result.pairsResponsability[i] = this.getObjProps_Name_Responsability(i, headers)
        result.dependentsScores[i] = { ...this.getObjProps_Name_Question_Total_IndividualScores_TenPercent(i, headers) }
        result.dependentsResponsability[i] = this.getObjProps_Name_Responsability(i, headers)
        if (i === 0) {
          // as_person
          for (const responsability of generalEvaluation[i].responsability) {
            result = this.initArrayResponsabilityProps(result, i, 0, 1, responsability)
            for (let indexK = 0; indexK < 10; indexK++) {
              result.averageResponsability[i].responsability[0].questions.push(indexK + 1)
              result.noAutoScoresResponsability[i].responsability[0].questions.push(indexK + 1)
              result.leadersResponsability[i].responsability[0].questions.push(indexK + 1)
              result.pairsResponsability[i].responsability[0].questions.push(indexK + 1)
              result.dependentsResponsability[i].responsability[0].questions.push(indexK + 1)
              result.averageScores[i].questions[indexK] = this.getObjPropsQuestion(indexK, indexK, responsability)
              result.noAutoScores[i].questions[indexK] = this.getObjPropsQuestion(indexK, indexK, responsability)
              result.leadersScores[i].questions[indexK] = this.getObjPropsQuestion(indexK, indexK, responsability)
              result.pairsScores[i].questions[indexK] = this.getObjPropsQuestion(indexK, indexK, responsability)
              result.dependentsScores[i].questions[indexK] = this.getObjPropsQuestion(indexK, indexK, responsability)
            }
          }
        } else {
          // management, mobilization, achievement
          let counter = 0
          for (const responsability of generalEvaluation[i].responsability) {
            result = this.initArrayResponsabilityProps(result, i, responsability.index - 1, responsability.index, responsability)
            for (let indexK = 0; indexK < responsability.questions.length; indexK++) {
              result.averageResponsability[i].responsability[responsability.index - 1].questions.push(counter + 1)
              result.noAutoScoresResponsability[i].responsability[responsability.index - 1].questions.push(counter + 1)
              result.leadersResponsability[i].responsability[responsability.index - 1].questions.push(counter + 1)
              result.pairsResponsability[i].responsability[responsability.index - 1].questions.push(counter + 1)
              result.dependentsResponsability[i].responsability[responsability.index - 1].questions.push(counter + 1)
              result.averageScores[i].questions[counter] = this.getObjPropsQuestion(indexK, counter, responsability)
              result.noAutoScores[i].questions[counter] = this.getObjPropsQuestion(indexK, counter, responsability)
              result.leadersScores[i].questions[counter] = this.getObjPropsQuestion(indexK, counter, responsability)
              result.pairsScores[i].questions[counter] = this.getObjPropsQuestion(indexK, counter, responsability)
              result.dependentsScores[i].questions[counter] = this.getObjPropsQuestion(indexK, counter, responsability)
              counter++
            }
          }
        }
      }
      let answersDimention = []
      let arrayEvaluators = []
      let autoevaluationCompleted = false
      const keysName = ['employee', 'leadersEvaluators', 'dependentsEvaluators', 'pairsEvaluators']
      // Recorrer cada evaluado para sacar los promedios
      for (let index = 0; index < data.evaluated.length; index++) {
        for (let key of keysName) {
          if (data.evaluated[index][key].length === undefined) { // Para autoevaluación
            if (sumCalcsPolls) this.expectedPolls.auto++
            if (data.evaluated[index][key].completed) {
              autoevaluationCompleted = true
              if (sumCalcsPolls) this.completedPolls.auto++
              answersDimention = data.evaluated[index][key].answersDimention
              count = 0
              for (const j of answersDimention) {
                let counter = 0
                let respCount = 0
                let dimensionTotal = 0
                for (const k of j.responsability) {
                  let respTotal = 0
                  let nullCount = 0
                  for (let l = 0; l < k.questions.length; l++) {
                    if (k.questions[l].score !== -1) {
                      result.averageScores[count].questions[counter].score += k.questions[l].score
                      respTotal += k.questions[l].score
                      result.averageScores[count].questions[counter].individualScores.push(k.questions[l].score)
                      result.averageResponsability[count].responsability[respCount].questionsScores.push(k.questions[l].score)
                      result.averageScores[count].questionsScores.push(k.questions[l].score)
                    } else {
                      result.averageScores[count].questions[counter].nullCount += 1
                      nullCount++
                    }
                    counter++
                  }
                  if (k.questions.length - nullCount > 0) {
                    result.averageResponsability[count].responsability[respCount].individualScores.push(respTotal / (k.questions.length - nullCount))
                    dimensionTotal += (respTotal / (k.questions.length - nullCount))
                  }
                  respCount++
                }
                result.averageScores[count].individualScores.push(dimensionTotal / j.responsability.length)
                count++
              }
              population++
            }
          } else { // Para el resto (lideres, pares, colaboradores)
            arrayEvaluators = data.evaluated[index][key]
            for (const i of arrayEvaluators) {
              if (key === 'leadersEvaluators') {
                if (sumCalcsPolls) this.expectedPolls.leaders++
                if (i.completed) {
                  if (sumCalcsPolls) this.completedPolls.leaders++
                  answersDimention = i.answersDimention
                  count = 0
                  if (autoevaluationCompleted) {
                    for (const j of answersDimention) {
                      let counter = 0
                      let respCount = 0
                      for (const k of j.responsability) {
                        let respTotal = 0
                        let nullCount = 0
                        for (let l = 0; l < k.questions.length; l++) {
                          if (k.questions[l].score !== -1) {
                            result.leadersScores[count].questions[counter].score += k.questions[l].score
                            respTotal += k.questions[l].score
                            result.leadersScores[count].questions[counter].individualScores.push(k.questions[l].score)
                          } else {
                            result.leadersScores[count].questions[counter].nullCount += 1
                            nullCount++
                          }
                          counter++
                        }
                        if (k.questions.length - nullCount > 0) {
                          result.leadersResponsability[count].responsability[respCount].individualScores.push(respTotal / (k.questions.length - nullCount))
                        }
                        respCount++
                      }
                      count++
                    }
                    result.leadersPopulation++
                  }
                }
              } else if (key === 'pairsEvaluators') {
                if (sumCalcsPolls) this.expectedPolls.pairs++
                if (i.completed) {
                  if (sumCalcsPolls) this.completedPolls.pairs++
                  answersDimention = i.answersDimention
                  count = 0
                  if (autoevaluationCompleted) {
                    for (const j of answersDimention) {
                      let counter = 0
                      let respCount = 0
                      for (const k of j.responsability) {
                        let respTotal = 0
                        let nullCount = 0
                        for (let l = 0; l < k.questions.length; l++) {
                          if (k.questions[l].score !== -1) {
                            result.pairsScores[count].questions[counter].score += k.questions[l].score
                            respTotal += k.questions[l].score
                            result.pairsScores[count].questions[counter].individualScores.push(k.questions[l].score)
                          } else {
                            result.pairsScores[count].questions[counter].nullCount += 1
                            nullCount++
                          }
                          counter++
                        }
                        if (k.questions.length - nullCount > 0) {
                          result.pairsResponsability[count].responsability[respCount].individualScores.push(respTotal / (k.questions.length - nullCount))
                        }
                        respCount++
                      }
                      count++
                    }
                    result.pairsPopulation++
                  }
                }
              } else {
                if (sumCalcsPolls) this.expectedPolls.dependents++
                if (i.completed) {
                  if (sumCalcsPolls) this.completedPolls.dependents++
                  answersDimention = i.answersDimention
                  count = 0
                  if (autoevaluationCompleted) {
                    for (const j of answersDimention) {
                      let counter = 0
                      let respCount = 0
                      for (const k of j.responsability) {
                        let respTotal = 0
                        let nullCount = 0
                        for (let l = 0; l < k.questions.length; l++) {
                          if (k.questions[l].score !== -1) {
                            result.dependentsScores[count].questions[counter].score += k.questions[l].score
                            respTotal += k.questions[l].score
                            result.dependentsScores[count].questions[counter].individualScores.push(k.questions[l].score)
                          } else {
                            result.dependentsScores[count].questions[counter].nullCount += 1
                            nullCount++
                          }
                          counter++
                        }
                        if (k.questions.length - nullCount > 0) {
                          result.dependentsResponsability[count].responsability[respCount].individualScores.push(respTotal / (k.questions.length - nullCount))
                        }
                        respCount++
                      }
                      count++
                    }
                    result.dependentsPopulation++
                  }
                }
              }
              // Total promedio de cada pregunta incluyendo autoevaluación.
              if (i.completed) {
                answersDimention = i.answersDimention
                count = 0
                if (autoevaluationCompleted) {
                  for (const j of answersDimention) {
                    let counter = 0
                    let respCount = 0
                    let dimensionTotal = 0
                    for (const k of j.responsability) {
                      let respTotal = 0
                      let nullCount = 0
                      for (let l = 0; l < k.questions.length; l++) {
                        if (k.questions[l].score !== -1) {
                          result.averageScores[count].questions[counter].score += k.questions[l].score
                          respTotal += k.questions[l].score
                          result.averageScores[count].questions[counter].individualScores.push(k.questions[l].score)
                          result.averageResponsability[count].responsability[respCount].questionsScores.push(k.questions[l].score)
                          result.averageScores[count].questionsScores.push(k.questions[l].score)
                        } else {
                          result.averageScores[count].questions[counter].nullCount += 1
                          nullCount++
                        }
                        counter++
                      }
                      if (k.questions.length - nullCount > 0) {
                        result.averageResponsability[count].responsability[respCount].individualScores.push(respTotal / (k.questions.length - nullCount))
                        dimensionTotal += respTotal / (k.questions.length - nullCount)
                      }
                      respCount++
                    }
                    result.averageScores[count].individualScores.push(dimensionTotal / j.responsability.length)
                    count++
                  }
                  population++
                }
                // Total promedio de cada pregunta sin autoevaluación.
                count = 0
                if (autoevaluationCompleted) {
                  for (const j of answersDimention) {
                    let counter = 0
                    let respCount = 0
                    for (const k of j.responsability) {
                      let respTotal = 0
                      let nullCount = 0
                      for (let l = 0; l < k.questions.length; l++) {
                        if (k.questions[l].score !== -1) {
                          result.noAutoScores[count].questions[counter].score += k.questions[l].score
                          respTotal += k.questions[l].score
                          result.noAutoScores[count].questions[counter].individualScores.push(k.questions[l].score)
                        } else {
                          result.noAutoScores[count].questions[counter].nullCount += 1
                          nullCount++
                        }
                        counter++
                      }
                      if (k.questions.length - nullCount) {
                        result.noAutoScoresResponsability[count].responsability[respCount].individualScores.push(respTotal / (k.questions.length - nullCount))
                      }
                      respCount++
                    }
                    count++
                  }
                  result.noAutoPopulation++
                }
              }
            }
          }
        }
      }

      // this.leadersPopulation = leadersPopulation
      // this.pairsPopulation = pairsPopulation
      // this.dependentsPopulation = dependentsPopulation
      for (let i = 0; i < result.averageScores.length; i++) {
        let acumDimension = 0
        let noAutoAcumDimension = 0
        let leadersAcumDimension = 0
        let pairsAcumDimension = 0
        let dependentsAcumDimension = 0
        let scoresCount = 0
        let noAutoCount = 0
        let leadersCount = 0
        let pairsCount = 0
        let dependentsCount = 0
        for (let j = 0; j < result.averageScores[i].questions.length; j++) {
          // Promedio de todos incluyendo autoevaluación
          if ((population - result.averageScores[i].questions[j].nullCount) === 0) {
            result.averageScores[i].questions[j].score = 0
          } else {
            result.averageScores[i].questions[j].score = result.averageScores[i].questions[j].score / (population - result.averageScores[i].questions[j].nullCount)
            scoresCount++
          }
          acumDimension += result.averageScores[i].questions[j].score
          // Promedio de todos sin autoevaluación
          if ((result.noAutoPopulation - result.noAutoScores[i].questions[j].nullCount) === 0) {
            result.noAutoScores[i].questions[j].score = 0
          } else {
            result.noAutoScores[i].questions[j].score = result.noAutoScores[i].questions[j].score / (result.noAutoPopulation - result.noAutoScores[i].questions[j].nullCount)
            noAutoCount++
          }
          noAutoAcumDimension += result.noAutoScores[i].questions[j].score
          // Lideres
          if ((result.leadersPopulation - result.leadersScores[i].questions[j].nullCount) === 0) {
            result.leadersScores[i].questions[j].score = 0
          } else {
            result.leadersScores[i].questions[j].score = result.leadersScores[i].questions[j].score / (result.leadersPopulation - result.leadersScores[i].questions[j].nullCount)
            leadersCount++
          }
          leadersAcumDimension += result.leadersScores[i].questions[j].score
          // Pares
          if ((result.pairsPopulation - result.pairsScores[i].questions[j].nullCount) === 0) {
            result.pairsScores[i].questions[j].score = 0
          } else {
            result.pairsScores[i].questions[j].score = result.pairsScores[i].questions[j].score / (result.pairsPopulation - result.pairsScores[i].questions[j].nullCount)
            pairsCount++
          }
          pairsAcumDimension += result.pairsScores[i].questions[j].score
          // Colaboradores
          if ((result.dependentsPopulation - result.dependentsScores[i].questions[j].nullCount) === 0) {
            result.dependentsScores[i].questions[j].score = 0
          } else {
            result.dependentsScores[i].questions[j].score = result.dependentsScores[i].questions[j].score / (result.dependentsPopulation - result.dependentsScores[i].questions[j].nullCount)
            dependentsCount++
          }
          dependentsAcumDimension += result.dependentsScores[i].questions[j].score

          result.averageScores[i].questions[j].tenPercent = this.calculateTenPercent(result.averageScores[i].questions[j].individualScores)
          result.noAutoScores[i].questions[j].tenPercent = this.calculateTenPercent(result.noAutoScores[i].questions[j].individualScores)
          result.leadersScores[i].questions[j].tenPercent = this.calculateTenPercent(result.leadersScores[i].questions[j].individualScores)
          result.pairsScores[i].questions[j].tenPercent = this.calculateTenPercent(result.pairsScores[i].questions[j].individualScores)
          result.dependentsScores[i].questions[j].tenPercent = this.calculateTenPercent(result.dependentsScores[i].questions[j].individualScores)
          // ------------------------------ DISPERSIÓN, SUMATORIA -------------------------------------------
          for (let k = 0; k < result.averageScores[i].questions[j].individualScores.length; k++) {
            result.averageScores[i].questions[j].dispersion += Math.pow(result.averageScores[i].questions[j].individualScores[k] - result.averageScores[i].questions[j].score, 2)
          }
        }
        // ---------------------------------- DISPERSIÓN TOTAL ------------------------------------------------
        for (let j = 0; j < result.averageScores[i].questions.length; j++) {
          // Promedio de todos
          if ((population - result.averageScores[i].questions[j].nullCount) === 0) {
            result.averageScores[i].questions[j].dispersion = 0
          } else {
            result.averageScores[i].questions[j].dispersion = Math.sqrt(result.averageScores[i].questions[j].dispersion / (population - result.averageScores[i].questions[j].nullCount))
          }
        }
        // ----------------------------------------------------------------------------------------------------
        for (let resp = 0; resp < result.averageResponsability[i].responsability.length; resp++) {
          for (let sc = 0; sc < result.averageResponsability[i].responsability[resp].questions.length; sc++) {
            if (result.averageScores[i].questions[result.averageResponsability[i].responsability[resp].questions[sc] - 1].score === 0) {
              result.averageResponsability[i].responsability[resp].nullCount++
            }
            result.averageResponsability[i].responsability[resp].score += result.averageScores[i].questions[result.averageResponsability[i].responsability[resp].questions[sc] - 1].score
            if (result.noAutoScores[i].questions[result.noAutoScoresResponsability[i].responsability[resp].questions[sc] - 1].score === 0) {
              result.noAutoScoresResponsability[i].responsability[resp].nullCount++
            }
            result.noAutoScoresResponsability[i].responsability[resp].score += result.noAutoScores[i].questions[result.noAutoScoresResponsability[i].responsability[resp].questions[sc] - 1].score
            if (result.leadersScores[i].questions[result.leadersResponsability[i].responsability[resp].questions[sc] - 1].score === 0) {
              result.leadersResponsability[i].responsability[resp].nullCount++
            }
            result.leadersResponsability[i].responsability[resp].score += result.leadersScores[i].questions[result.leadersResponsability[i].responsability[resp].questions[sc] - 1].score
            if (result.pairsScores[i].questions[result.pairsResponsability[i].responsability[resp].questions[sc] - 1].score === 0) {
              result.pairsResponsability[i].responsability[resp].nullCount++
            }
            result.pairsResponsability[i].responsability[resp].score += result.pairsScores[i].questions[result.pairsResponsability[i].responsability[resp].questions[sc] - 1].score
            if (result.dependentsScores[i].questions[result.dependentsResponsability[i].responsability[resp].questions[sc] - 1].score === 0) {
              result.dependentsResponsability[i].responsability[resp].nullCount++
            }
            result.dependentsResponsability[i].responsability[resp].score += result.dependentsScores[i].questions[result.dependentsResponsability[i].responsability[resp].questions[sc] - 1].score
          }
          result.averageResponsability[i].responsability[resp].score = result.averageResponsability[i].responsability[resp].questions.length - result.averageResponsability[i].responsability[resp].nullCount > 0
            ? result.averageResponsability[i].responsability[resp].score / (result.averageResponsability[i].responsability[resp].questions.length - result.averageResponsability[i].responsability[resp].nullCount) : '--'
          result.noAutoScoresResponsability[i].responsability[resp].score = result.noAutoScoresResponsability[i].responsability[resp].questions.length - result.noAutoScoresResponsability[i].responsability[resp].nullCount > 0
            ? result.noAutoScoresResponsability[i].responsability[resp].score / (result.noAutoScoresResponsability[i].responsability[resp].questions.length - result.noAutoScoresResponsability[i].responsability[resp].nullCount) : '--'
          result.leadersResponsability[i].responsability[resp].score = result.leadersResponsability[i].responsability[resp].questions.length - result.leadersResponsability[i].responsability[resp].nullCount > 0
            ? result.leadersResponsability[i].responsability[resp].score / (result.leadersResponsability[i].responsability[resp].questions.length - result.leadersResponsability[i].responsability[resp].nullCount) : '--'
          result.pairsResponsability[i].responsability[resp].score = result.pairsResponsability[i].responsability[resp].questions.length - result.pairsResponsability[i].responsability[resp].nullCount > 0
            ? result.pairsResponsability[i].responsability[resp].score / (result.pairsResponsability[i].responsability[resp].questions.length - result.pairsResponsability[i].responsability[resp].nullCount) : '--'
          result.dependentsResponsability[i].responsability[resp].score = result.dependentsResponsability[i].responsability[resp].questions.length - result.dependentsResponsability[i].responsability[resp].nullCount > 0
            ? result.dependentsResponsability[i].responsability[resp].score / (result.dependentsResponsability[i].responsability[resp].questions.length - result.dependentsResponsability[i].responsability[resp].nullCount) : '--'
          result.averageResponsability[i].responsability[resp].tenPercent = this.calculateTenPercent(result.averageResponsability[i].responsability[resp].individualScores)
          result.noAutoScoresResponsability[i].responsability[resp].tenPercent = this.calculateTenPercent(result.noAutoScoresResponsability[i].responsability[resp].individualScores)
          result.leadersResponsability[i].responsability[resp].tenPercent = this.calculateTenPercent(result.leadersResponsability[i].responsability[resp].individualScores)
          result.pairsResponsability[i].responsability[resp].tenPercent = this.calculateTenPercent(result.pairsResponsability[i].responsability[resp].individualScores)
          result.dependentsResponsability[i].responsability[resp].tenPercent = this.calculateTenPercent(result.dependentsResponsability[i].responsability[resp].individualScores)
          // DISPERSIÓN POR RESPONSABILIDAD, SUMATORIA
          for (let j = 0; j < result.averageResponsability[i].responsability[resp].individualScores.length; j++) {
            result.averageResponsability[i].responsability[resp].dispersion += Math.pow(result.averageResponsability[i].responsability[resp].individualScores[j] - result.averageResponsability[i].responsability[resp].score, 2)
          }
        }
        // DISPERSIÓN POR RESPONSABILIDAD
        for (let resp = 0; resp < result.averageResponsability[i].responsability.length; resp++) {
          if ((result.averageResponsability[i].responsability[resp].questions.length - result.averageResponsability[i].responsability[resp].nullCount) === 0) {
            result.averageResponsability[i].responsability[resp].dispersion = 0
          } else {
            result.averageResponsability[i].responsability[resp].dispersion = Math.sqrt(result.averageResponsability[i].responsability[resp].dispersion / (result.averageResponsability[i].responsability[resp].individualScores.length - result.averageResponsability[i].responsability[resp].nullCount))
          }
        }
        result.averageScores[i].total = acumDimension / scoresCount
        result.noAutoScores[i].total = noAutoAcumDimension / noAutoCount
        result.leadersScores[i].total = leadersAcumDimension / leadersCount
        result.pairsScores[i].total = pairsAcumDimension / pairsCount
        result.dependentsScores[i].total = dependentsAcumDimension / dependentsCount

        result.averageScores[i].tenPercent = this.calculateTenPercent(result.averageScores[i].individualScores)
      }

      // this.averageScores = scores
      // this.noAutoScores = noAutoScores
      // this.leadersScores = leadersScores
      // this.pairsScores = pairsScores
      // this.dependentsScores = dependentsScores
      // this.averageResponsability = scoresResponsability
      // this.noAutoScoresResponsability = noAutoScoresResponsability
      // this.leadersResponsability = leadersResponsability
      // this.pairsResponsability = pairsResponsability
      // this.dependentsResponsability = dependentsResponsability

      return result
    },
    async $getInitialData () {
      this.completedPolls = {
        auto: 0,
        leaders: 0,
        pairs: 0,
        dependents: 0
      }
      this.expectedPolls = {
        auto: 0,
        leaders: 0,
        pairs: 0,
        dependents: 0
      }
      await evaluationService.getOneReportByThreadId(this.thread._id)
        .then(async (res) => {
          this.hasPrevious = res.data.hasPrevious
          this.reportName = res.data.evaluation.name
          this.cutsSelected = res.data.criteria

          const results = await evaluationService.getDemographicReport(res.data._id, this.cutsSelected)
          return results
        })
        .then((res) => {
          try {
            this.currentEvaluation = this.getScores(res.data.currentEvaluation)
            this.filteredCurrentEvaluation = this.getScores(res.data.currentEvaluationFiltered, true)
            if (res.data.previousEvaluationFiltered) {
              this.filteredPreviousEvaluation = this.getScores(res.data.previousEvaluationFiltered)
              this.$setPreviousScore(res.data.previousEvaluationFiltered)
            }
            this.rankResults = this.getResults()
          } catch (error) {
            console.error('getScores', error)
          }
        })
        .catch((err) => {
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        })
    },
    calculateTenPercent (individualScores) {
      const total = individualScores.sort((a, b) => b - a).length
      if (!total) {
        return '--'
      }
      let tenPercent = Math.ceil(total * 0.1)
      let average = 0
      for (let t = 0; t < tenPercent; t++) {
        average += individualScores[t]
      }
      return Math.round((average / tenPercent) * 100) / 100
    },
    getResults () {
      const result = {
        othersRank: [],
        noAutoOthersRank: [],
        leadersRank: [],
        pairsRank: [],
        dependentsRank: [],
        othersRespRank: [],
        noAutoOthersRespRank: [],
        leadersRespRank: [],
        pairsRespRank: [],
        dependentsRespRank: []
      }
      for (let i = 0; i < this.filteredCurrentEvaluation.averageScores.length; i++) {
        for (let j = 0; j < this.filteredCurrentEvaluation.averageScores[i].questions.length; j++) {
          // Otros
          let obj = {}
          const baseObj = {
            process: '--',
            abbreviation: '--',
            score: '--',
            question: '--',
            dispersion: '--',
            tenPercent: '--',
            previous: '--',
            trend: '--',
            responsability: '--',
            currentScore: '--',
            gap: '--' // Brecha
          }
          if (this.filteredCurrentEvaluation.averageScores[i].questions[j].score !== 0) {
            obj = {
              process: this.filteredCurrentEvaluation.averageScores[i].name,
              abbreviation:
                this.filteredCurrentEvaluation.averageScores[i].name === 'as_person'
                  ? 'P'
                  : this.filteredCurrentEvaluation.averageScores[i].name === 'management'
                    ? 'D'
                    : this.filteredCurrentEvaluation.averageScores[i].name === 'mobilization'
                      ? 'M'
                      : 'L',
              score: this.filteredCurrentEvaluation.averageScores[i].questions[j].score,
              question: this.filteredCurrentEvaluation.averageScores[i].questions[j].question,
              tenPercent: this.filteredCurrentEvaluation.averageScores[i].questions[j].tenPercent,
              dispersion: this.filteredCurrentEvaluation.averageScores[i].questions[j].dispersion,
              previous: 0,
              trend: 0,
              responsability: this.filteredCurrentEvaluation.averageScores[i].questions[j].responsability,
              currentScore: this.currentEvaluation.averageScores[i].questions[j].score,
              gap: this.filteredCurrentEvaluation.averageScores[i].questions[j].score - this.currentEvaluation.averageScores[i].questions[j].score
            }
            if (this.hasPrevious) {
              obj.previous =
                this.filteredPreviousEvaluation.averageScores[i].questions[j].score > 0
                  ? this.filteredPreviousEvaluation.averageScores[i].questions[j].score
                  : '--'
              obj.trend =
                this.filteredPreviousEvaluation.averageScores[i].questions[j].score > 0
                  ? this.filteredCurrentEvaluation.averageScores[i].questions[j].score -
                  this.filteredPreviousEvaluation.averageScores[i].questions[j].score
                  : '--'
            }
          }
          result.othersRank.push(obj)
          if (this.filteredCurrentEvaluation.noAutoScores[i].questions[j].score !== 0) {
            obj = {
              process: this.filteredCurrentEvaluation.noAutoScores[i].name,
              abbreviation:
                this.filteredCurrentEvaluation.noAutoScores[i].name === 'as_person'
                  ? 'P'
                  : this.filteredCurrentEvaluation.noAutoScores[i].name === 'management'
                    ? 'D'
                    : this.filteredCurrentEvaluation.noAutoScores[i].name === 'mobilization'
                      ? 'M'
                      : 'L',
              score: this.filteredCurrentEvaluation.noAutoScores[i].questions[j].score,
              question: this.filteredCurrentEvaluation.noAutoScores[i].questions[j].question,
              tenPercent: this.filteredCurrentEvaluation.noAutoScores[i].questions[j].tenPercent,
              dispersion: this.filteredCurrentEvaluation.noAutoScores[i].questions[j].dispersion,
              previous: 0,
              trend: 0,
              responsability: this.filteredCurrentEvaluation.noAutoScores[i].questions[j].responsability,
              currentScore: this.currentEvaluation.noAutoScores[i].questions[j].score,
              gap: this.filteredCurrentEvaluation.noAutoScores[i].questions[j].score - this.currentEvaluation.noAutoScores[i].questions[j].score
            }
            if (this.hasPrevious) {
              obj.previous =
                this.filteredPreviousEvaluation.noAutoScores[i].questions[j].score > 0
                  ? this.filteredPreviousEvaluation.noAutoScores[i].questions[j].score
                  : '--'
              obj.trend =
                this.filteredPreviousEvaluation.noAutoScores[i].questions[j].score > 0
                  ? this.filteredCurrentEvaluation.noAutoScores[i].questions[j].score -
                  this.filteredPreviousEvaluation.noAutoScores[i].questions[j].score
                  : '--'
            }
            result.noAutoOthersRank.push(obj)
          } else {
            result.noAutoOthersRank.push(baseObj)
          }
          // Por Líder
          if (this.filteredCurrentEvaluation.leadersPopulation === 0) {
            result.leadersRank.push(baseObj)
          } else if (this.filteredCurrentEvaluation.leadersScores[i].questions[j].score !== 0) {
            obj = {
              process: this.filteredCurrentEvaluation.leadersScores[i].name,
              abbreviation:
                this.filteredCurrentEvaluation.leadersScores[i].name === 'as_person'
                  ? 'P'
                  : this.filteredCurrentEvaluation.leadersScores[i].name === 'management'
                    ? 'D'
                    : this.filteredCurrentEvaluation.leadersScores[i].name === 'mobilization'
                      ? 'M'
                      : 'L',
              score: this.filteredCurrentEvaluation.leadersScores[i].questions[j].score,
              question: this.filteredCurrentEvaluation.leadersScores[i].questions[j].question,
              tenPercent: this.filteredCurrentEvaluation.leadersScores[i].questions[j].tenPercent,
              dispersion: this.filteredCurrentEvaluation.leadersScores[i].questions[j].dispersion,
              previous: 0,
              trend: 0,
              responsability: this.filteredCurrentEvaluation.leadersScores[i].questions[j].responsability,
              currentScore: this.currentEvaluation.leadersScores[i].questions[j].score,
              gap: this.filteredCurrentEvaluation.leadersScores[i].questions[j].score - this.currentEvaluation.leadersScores[i].questions[j].score
            }
            if (this.hasPrevious) {
              obj.previous =
                this.filteredPreviousEvaluation.leadersScores[i].questions[j].score > 0
                  ? this.filteredPreviousEvaluation.leadersScores[i].questions[j].score
                  : '--'
              obj.trend =
                this.filteredPreviousEvaluation.leadersScores[i].questions[j].score > 0
                  ? this.filteredCurrentEvaluation.leadersScores[i].questions[j].score -
                  this.filteredPreviousEvaluation.leadersScores[i].questions[j].score
                  : '--'
            }
            result.leadersRank.push(obj)
          }

          // Por Pares
          if (this.filteredCurrentEvaluation.pairsPopulation === 0) {
            result.pairsRank.push(baseObj)
          } else if (this.filteredCurrentEvaluation.pairsScores[i].questions[j].score !== 0) {
            obj = {
              process: this.filteredCurrentEvaluation.pairsScores[i].name,
              abbreviation:
                this.filteredCurrentEvaluation.pairsScores[i].name === 'as_person'
                  ? 'P'
                  : this.filteredCurrentEvaluation.pairsScores[i].name === 'management'
                    ? 'D'
                    : this.filteredCurrentEvaluation.pairsScores[i].name === 'mobilization'
                      ? 'M'
                      : 'L',
              score: this.filteredCurrentEvaluation.pairsScores[i].questions[j].score,
              question: this.filteredCurrentEvaluation.pairsScores[i].questions[j].question,
              tenPercent: this.filteredCurrentEvaluation.pairsScores[i].questions[j].tenPercent,
              dispersion: this.filteredCurrentEvaluation.pairsScores[i].questions[j].dispersion,
              previous: 0,
              trend: 0,
              responsability: this.filteredCurrentEvaluation.pairsScores[i].questions[j].responsability,
              currentScore: this.currentEvaluation.pairsScores[i].questions[j].score,
              gap: this.filteredCurrentEvaluation.pairsScores[i].questions[j].score - this.currentEvaluation.pairsScores[i].questions[j].score
            }
            if (this.hasPrevious) {
              obj.previous =
                this.filteredPreviousEvaluation.pairsScores[i].questions[j].score > 0
                  ? this.filteredPreviousEvaluation.pairsScores[i].questions[j].score
                  : '--'
              obj.trend =
                this.filteredPreviousEvaluation.pairsScores[i].questions[j].score > 0
                  ? this.filteredCurrentEvaluation.pairsScores[i].questions[j].score -
                  this.filteredPreviousEvaluation.pairsScores[i].questions[j].score
                  : '--'
            }
            result.pairsRank.push(obj)
          } else {
            result.pairsRank.push(baseObj)
          }
          if (this.filteredCurrentEvaluation.dependentsPopulation === 0) {
            result.dependentsRank.push(baseObj)
          } else if (this.filteredCurrentEvaluation.dependentsScores[i].questions[j].score !== 0) {
            obj = {
              process: this.filteredCurrentEvaluation.dependentsScores[i].name,
              abbreviation:
                this.filteredCurrentEvaluation.dependentsScores[i].name === 'as_person'
                  ? 'P'
                  : this.filteredCurrentEvaluation.dependentsScores[i].name === 'management'
                    ? 'D'
                    : this.filteredCurrentEvaluation.dependentsScores[i].name === 'mobilization'
                      ? 'M'
                      : 'L',
              score: this.filteredCurrentEvaluation.dependentsScores[i].questions[j].score,
              question: this.filteredCurrentEvaluation.dependentsScores[i].questions[j].question,
              tenPercent: this.filteredCurrentEvaluation.dependentsScores[i].questions[j].tenPercent,
              dispersion: this.filteredCurrentEvaluation.dependentsScores[i].questions[j].dispersion,
              previous: 0,
              trend: 0,
              responsability: this.filteredCurrentEvaluation.dependentsScores[i].questions[j].responsability,
              currentScore: this.currentEvaluation.dependentsScores[i].questions[j].score,
              gap: this.filteredCurrentEvaluation.dependentsScores[i].questions[j].score - this.currentEvaluation.dependentsScores[i].questions[j].score
            }
            if (this.hasPrevious) {
              obj.previous =
                this.filteredPreviousEvaluation.dependentsScores[i].questions[j].score > 0
                  ? this.filteredPreviousEvaluation.dependentsScores[i].questions[j].score
                  : '--'
              obj.trend =
                this.filteredPreviousEvaluation.dependentsScores[i].questions[j].score > 0
                  ? this.filteredCurrentEvaluation.dependentsScores[i].questions[j].score -
                  this.filteredPreviousEvaluation.dependentsScores[i].questions[j].score
                  : '--'
            }
            result.dependentsRank.push(obj)
          }
        }
        // Responsabilidad
      }
      // Limpiando
      /*
            result.noAutoOthersRank
            result.leadersRank
            result.pairsRank
            result.dependentsRank
            */
      let localRankingClean = result.noAutoOthersRank.filter(
        (elem) => elem.score !== '--' && elem.score !== 0
      )
      result.noAutoOthersRank =
        localRankingClean.length > 5
          ? localRankingClean
          : result.noAutoOthersRank
      localRankingClean = result.leadersRank.filter(
        (elem) => elem.score !== '--' && elem.score !== 0
      )
      result.leadersRank =
        localRankingClean.length > 5 ? localRankingClean : result.leadersRank
      localRankingClean = result.pairsRank.filter(
        (elem) => elem.score !== '--' && elem.score !== 0
      )
      result.pairsRank =
        localRankingClean.length > 5 ? localRankingClean : result.pairsRank
      localRankingClean = result.dependentsRank.filter(
        (elem) => elem.score !== '--' && elem.score !== 0
      )
      result.dependentsRank =
        localRankingClean.length > 5 ? localRankingClean : result.dependentsRank
      // Fin limpieza

      for (let i = 0; i < this.filteredCurrentEvaluation.averageResponsability.length; i++) {
        for (
          let j = 0;
          j < this.filteredCurrentEvaluation.averageResponsability[i].responsability.length;
          j++
        ) {
          let obj = {}
          const baseObj = {
            name: '--',
            abbreviation: '--',
            score: '--',
            dispersion: '--',
            tenPercent: '--',
            previous: '--',
            trend: '--',
            currentScore: '--',
            gap: '--' // Brecha
          }
          if (this.filteredCurrentEvaluation.averageResponsability[i].responsability[j].score !== 0) {
            obj = {
              name: this.filteredCurrentEvaluation.averageResponsability[i].responsability[j].name,
              abbreviation:
                this.filteredCurrentEvaluation.averageResponsability[i].name === 'as_person'
                  ? 'P'
                  : this.filteredCurrentEvaluation.averageResponsability[i].name === 'management'
                    ? 'D'
                    : this.filteredCurrentEvaluation.averageResponsability[i].name === 'mobilization'
                      ? 'M'
                      : 'L',
              score: this.filteredCurrentEvaluation.averageResponsability[i].responsability[j].score,
              tenPercent: this.filteredCurrentEvaluation.averageResponsability[i].responsability[j]
                .tenPercent,
              dispersion: this.filteredCurrentEvaluation.averageResponsability[i].responsability[j]
                .dispersion,
              previous: 0,
              trend: 0,
              currentScore: this.currentEvaluation.averageResponsability[i].responsability[j].score,
              gap: this.filteredCurrentEvaluation.averageResponsability[i].responsability[j].score - this.currentEvaluation.averageResponsability[i].responsability[j].score
            }
            if (this.hasPrevious) {
              obj.previous =
                this.filteredPreviousEvaluation.averageResponsability[i].responsability[j].score >
                  0
                  ? this.filteredPreviousEvaluation.averageResponsability[i].responsability[j]
                    .score
                  : '--'
              obj.trend =
                this.filteredPreviousEvaluation.averageResponsability[i].responsability[j].score >
                  0
                  ? this.filteredCurrentEvaluation.averageResponsability[i].responsability[j].score -
                  this.filteredPreviousEvaluation.averageResponsability[i].responsability[j]
                    .score
                  : '--'
            }
          }
          result.othersRespRank.push(obj)
          if (
            this.filteredCurrentEvaluation.noAutoScoresResponsability[i].responsability[j].score !== 0
          ) {
            obj = {
              name: this.filteredCurrentEvaluation.noAutoScoresResponsability[i].responsability[j].name,
              abbreviation:
                this.filteredCurrentEvaluation.noAutoScoresResponsability[i].name === 'as_person'
                  ? 'P'
                  : this.filteredCurrentEvaluation.noAutoScoresResponsability[i].name === 'management'
                    ? 'D'
                    : this.filteredCurrentEvaluation.noAutoScoresResponsability[i].name === 'mobilization'
                      ? 'M'
                      : 'L',
              score: this.filteredCurrentEvaluation.noAutoScoresResponsability[i].responsability[j].score,
              tenPercent: this.filteredCurrentEvaluation.noAutoScoresResponsability[i].responsability[j]
                .tenPercent,
              dispersion: 0,
              previous: 0,
              trend: 0,
              currentScore: this.currentEvaluation.noAutoScoresResponsability[i].responsability[j].score,
              gap: this.filteredCurrentEvaluation.noAutoScoresResponsability[i].responsability[j].score - this.currentEvaluation.noAutoScoresResponsability[i].responsability[j].score
            }
            if (this.hasPrevious) {
              obj.previous =
                this.filteredPreviousEvaluation.noAutoScoresResponsability[i].responsability[j]
                  .score > 0
                  ? this.filteredPreviousEvaluation.noAutoScoresResponsability[i].responsability[j]
                    .score
                  : '--'
              obj.trend =
                this.filteredPreviousEvaluation.noAutoScoresResponsability[i].responsability[j]
                  .score > 0
                  ? this.filteredCurrentEvaluation.noAutoScoresResponsability[i].responsability[j].score -
                  this.filteredPreviousEvaluation.noAutoScoresResponsability[i].responsability[j]
                    .score
                  : '--'
            }
          }
          result.noAutoOthersRespRank.push(obj)
          // Por Líder
          if (this.filteredCurrentEvaluation.leadersPopulation === 0) {
            obj = baseObj
          } else if (
            this.filteredCurrentEvaluation.leadersResponsability[i].responsability[j].score !== 0
          ) {
            obj = {
              name: this.filteredCurrentEvaluation.leadersResponsability[i].responsability[j].name,
              abbreviation:
                this.filteredCurrentEvaluation.leadersResponsability[i].name === 'as_person'
                  ? 'P'
                  : this.filteredCurrentEvaluation.leadersResponsability[i].name === 'management'
                    ? 'D'
                    : this.filteredCurrentEvaluation.leadersResponsability[i].name === 'mobilization'
                      ? 'M'
                      : 'L',
              score: this.filteredCurrentEvaluation.leadersResponsability[i].responsability[j].score,
              tenPercent: this.filteredCurrentEvaluation.leadersResponsability[i].responsability[j]
                .tenPercent,
              dispersion: 0,
              previous: 0,
              trend: 0,
              currentScore: this.currentEvaluation.leadersResponsability[i].responsability[j].score,
              gap: this.filteredCurrentEvaluation.leadersResponsability[i].responsability[j].score - this.currentEvaluation.leadersResponsability[i].responsability[j].score
            }
            if (this.hasPrevious) {
              obj.previous =
                this.filteredPreviousEvaluation.leadersResponsability[i].responsability[j].score >
                  0
                  ? this.filteredPreviousEvaluation.leadersResponsability[i].responsability[j]
                    .score
                  : '--'
              obj.trend =
                this.filteredPreviousEvaluation.leadersResponsability[i].responsability[j].score >
                  0
                  ? this.filteredCurrentEvaluation.leadersResponsability[i].responsability[j].score -
                  this.filteredPreviousEvaluation.leadersResponsability[i].responsability[j]
                    .score
                  : '--'
            }
          }
          result.leadersRespRank.push(obj)
          // Por Pares
          if (this.filteredCurrentEvaluation.pairsPopulation === 0) {
            obj = baseObj
          } else if (
            this.filteredCurrentEvaluation.pairsResponsability[i].responsability[j].score !== 0
          ) {
            obj = {
              name: this.filteredCurrentEvaluation.pairsResponsability[i].responsability[j].name,
              abbreviation:
                this.filteredCurrentEvaluation.pairsResponsability[i].name === 'as_person'
                  ? 'P'
                  : this.filteredCurrentEvaluation.pairsResponsability[i].name === 'management'
                    ? 'D'
                    : this.filteredCurrentEvaluation.pairsResponsability[i].name === 'mobilization'
                      ? 'M'
                      : 'L',
              score: this.filteredCurrentEvaluation.pairsResponsability[i].responsability[j].score,
              tenPercent: this.filteredCurrentEvaluation.pairsResponsability[i].responsability[j]
                .tenPercent,
              dispersion: 0,
              previous: 0,
              trend: 0,
              currentScore: this.currentEvaluation.pairsResponsability[i].responsability[j].score,
              gap: this.filteredCurrentEvaluation.pairsResponsability[i].responsability[j].score - this.currentEvaluation.pairsResponsability[i].responsability[j].score
            }
            if (this.hasPrevious) {
              obj.previous =
                this.filteredPreviousEvaluation.pairsResponsability[i].responsability[j].score > 0
                  ? this.filteredPreviousEvaluation.pairsResponsability[i].responsability[j].score
                  : '--'
              obj.trend =
                this.filteredPreviousEvaluation.pairsResponsability[i].responsability[j].score > 0
                  ? this.filteredCurrentEvaluation.pairsResponsability[i].responsability[j].score -
                  this.filteredPreviousEvaluation.pairsResponsability[i].responsability[j].score
                  : '--'
            }
          }
          result.pairsRespRank.push(obj)
          if (this.filteredCurrentEvaluation.dependentsPopulation === 0) {
            obj = baseObj
          } else if (
            this.filteredCurrentEvaluation.dependentsResponsability[i].responsability[j].score !== 0
          ) {
            obj = {
              name: this.filteredCurrentEvaluation.dependentsResponsability[i].responsability[j].name,
              abbreviation:
                this.filteredCurrentEvaluation.dependentsResponsability[i].name === 'as_person'
                  ? 'P'
                  : this.filteredCurrentEvaluation.dependentsResponsability[i].name === 'management'
                    ? 'D'
                    : this.filteredCurrentEvaluation.dependentsResponsability[i].name === 'mobilization'
                      ? 'M'
                      : 'L',
              score: this.filteredCurrentEvaluation.dependentsResponsability[i].responsability[j].score,
              tenPercent: this.filteredCurrentEvaluation.dependentsResponsability[i].responsability[j]
                .tenPercent,
              dispersion: 0,
              previous: 0,
              trend: 0,
              currentScore: this.currentEvaluation.dependentsResponsability[i].responsability[j].score,
              gap: this.filteredCurrentEvaluation.dependentsResponsability[i].responsability[j].score - this.currentEvaluation.dependentsResponsability[i].responsability[j].score
            }
            if (this.hasPrevious) {
              obj.previous =
                this.filteredPreviousEvaluation.dependentsResponsability[i].responsability[j]
                  .score > 0
                  ? this.filteredPreviousEvaluation.dependentsResponsability[i].responsability[j]
                    .score
                  : '--'
              obj.trend =
                this.filteredPreviousEvaluation.dependentsResponsability[i].responsability[j]
                  .score > 0
                  ? this.filteredCurrentEvaluation.dependentsResponsability[i].responsability[j].score -
                  this.filteredPreviousEvaluation.dependentsResponsability[i].responsability[j]
                    .score
                  : '--'
            }
          }
          result.dependentsRespRank.push(obj)
        }
      }
      // Retorna aca
      return result
    },
    getObjProps_Name_Question_Total_IndividualScores_TenPercent (index, headers) {
      return { name: headers[index], questions: [], total: 0, individualScores: [], tenPercent: 0 }
    },
    getObjProps_Name_Responsability (index, headers) {
      return { name: headers[index], responsability: [] }
    },
    getObjPropsResponsability (index, responsability) {
      return { name: responsability[this.user.lang], score: 0, index: index, questions: [], nullCount: 0, dispersion: 0, tenPercent: 0, individualScores: [] }
    },
    getObjPropsQuestion (index, counter, responsability) {
      return { score: 0, index: counter + 1, question: responsability.questions[index][this.user.lang], tenPercent: 0, nullCount: 0, dispersion: 0, responsability: responsability[this.user.lang], individualScores: [] }
    },
    initArrayResponsabilityProps (result, index, indexK, indexR, responsability) {
      result.averageResponsability[index].responsability[indexK] = { ...this.getObjPropsResponsability(indexR, responsability), questionsScores: [] }
      result.noAutoScoresResponsability[index].responsability[indexK] = { ...this.getObjPropsResponsability(indexR, responsability) }
      result.leadersResponsability[index].responsability[indexK] = { ...this.getObjPropsResponsability(indexR, responsability) }
      result.pairsResponsability[index].responsability[indexK] = { ...this.getObjPropsResponsability(indexR, responsability) }
      result.dependentsResponsability[index].responsability[indexK] = { ...this.getObjPropsResponsability(indexR, responsability) }
      return result
    }
  }
}
