
import Services from './base-services'

const service = new Services('users')

export default {
  checkTokenActivity: (type: string, token: string) => {
    return service.bGet(`auth/${type}/${token}/check-activity`)
  },
  tryConection: () => {
    return service.suiteOperation(() => service.bGet('tryConection'))
  }
}
