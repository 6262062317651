
export default {
  enterprises_list: 'Listado de Empresas',
  add_enterprise: 'Agregar empresa',
  edit_enterprise: 'Editar empresa',
  filter_by: 'Filtrar por...',
  look_for: 'Buscar una empresa',
  no_data: 'No hay empresas para mostrar',
  id: 'Id',
  enterprise_name: 'Nombre empresa',
  group: 'Grupo',
  license: 'Licencia',
  expiration_date: 'Vencimiento',
  status: 'Estado',
  actions: 'Acciones',
  modal_enable: '¿Desea habilitar la empresa?',
  modal_disable: '¿Desea inhabilitar la empresa?',
  modal_enable_des: 'Una vez habilitada, la empresa podrá acceder nuevamente al sistema',
  // tslint:disable-next-line:max-line-length
  modal_disable_des: 'Una vez sea inhabilitada, la empresa no podrá acceder al sistema hasta ser habilitada nuevamente',
  action: 'Realizar acción',
  accept: 'Aceptar',
  close: 'Cerrar',
  enterprise_information: 'Información de la empresa',
  contact_employee: 'Empleado de contacto',
  licensing: 'Licenciamiento',
  demographic_cuts: 'Cortes Demográficos',
  tool_customization: 'Personalización de la herramienta',
  primary_color: 'Color primario',
  secondary_color: 'Color secundario',
  // tslint:disable-next-line:max-line-length
  informative_cuts: 'Los siguientes datos son informativos y no representan acciones en el sistema. Estos datos son obligatorios para identificar a los colaboradores.',
  select_demographic_cuts: 'Por favor, seleccione las categorías de los cortes demográficos que desea medir.',
  obligatories_demographic_cuts: 'Las categorías {obligatories} son obligatorias.',
  created_enterprise: 'La empresa fue creada con éxito.',
  section_edited: 'La sección fue editada exitosamente.',
  enterprise_edited: 'La empresa fue editada exitosamente.',
  profile_title: 'Empresa {size} del sector {sector}.',
  profile_title_addres: 'Ubicada en: {contry} - {address}.',
  extend_plan: 'Extender plan',
  extend_plan_requests: 'Solicitudes de extensión de plan',
  info_extend: 'Después de enviar su solicitud de extensión del plan, recibirá una notificación con su aceptación.',
  extend_request_table: [
    { text: 'Engagement solicitadas', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Pulse solicitadas', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Plan', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Finalización del plan', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Acciones', value: '', sortable: false, class: 'text-xs-center' }
  ],
  no_extend_requests: 'Esta empresa no posee solicitudes de extensión pendientes.',
  extend_request_done: 'Extensión de plan realizada',
  extend_request_sent: 'Solicitud de extensión de plan enviada',
  extend_request_accepted: 'Solicitud aceptada',
  extend_request_rejected: 'Solicitud rechazada',
  extend_request_cancelled: 'Solicitud cancelada',
  active_plan: 'Plan activo',
  plan_expire_date: 'Fecha de vencimiento del plan',
  active_engagements: 'Engagement restantes',
  active_pulses: 'Pulse restantes',
  info_update: 'Advertencia: La actualización de plan es recibida como una solicitud. ' +
    'Esta debe ser aceptada por el administrador del sistema, por lo cual recibirá una notificación con el ' +
    'estatus de su solicitud. Solo se puede enviar una solicitud de actualización a la vez.',
  update_plan_request: 'Solicitud de actualización de plan',
  info_request_extend: 'Advertencia: La extensión de plan es recibida como una solicitud. ' +
    'Esta debe ser aceptada por el administrador del sistema, por lo cual recibirá una notificación con el ' +
    'estatus de su solicitud. Solo se puede enviar una solicitud de extensión a la vez.',
  no_update_requests: 'Esta empresa no posee solicitudes de actualización pendientes.',
  active_license: 'Plan activo',
  license_expire_at: 'Fecha de vencimiento del plan actual',
  active_plan_pulses: 'Pulse activos actualmente',
  active_plan_engagements: 'Engagement activos actualmente',
  new_plan_pulses: 'Pulse de su nuevo plan',
  new_plan_engagements: 'Engagement de su nuevo plan',
  new_plan_requested: 'Nuevo plan solicitado:',
  new_plan_duration: 'Duración del plan solicitado:',
  old_plan_end: 'Fecha de finalización del plan actual:',
  old_plan_duration: 'Duración del plan actual:',
  want_other_start: '¿Desea que su plan inicie en una fecha distinta a la fecha de vencimiento de su plan actual?',
  other_start_warning: 'Advertencia: La selección de una fecha distinta a la fecha de vencimiento de su ' +
  'plan tiene condiciones.',
  before_start_warning: 'Si solicita que su plan se actualice en una fecha menor a la fecha de vencimiento de su' +
  'plan actual y su solicitud es aceptada automaticamente perderá todos los beneficios de su plan actual y se ' +
  'le activará el solicitado. ',
  after_start_warning: 'Si solicita que su plan se actualice en una fecha mayor a la fecha de vencimiento de su ' +
  ' plan actual y su solicitud es aceptada no tendrá permisos para ingresar al sistema en las ' +
  'fechas ubicadas entre la fecha de vencimiento de su plan actual y la fecha de inicio del nuevo.',
  new_plan_start: 'Inicio del plan solicitado',
  update_plan: 'Actualizar plan.',
  update_type: 'Actualización',
  extend_type: 'Extensión',
  enterprise_extend_request_table: [
    { text: 'Tipo de solicitud', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Engagement solicitadas', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Pulse solicitadas', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Plan', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Finalización del plan', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Acciones', value: '', sortable: false, class: 'text-xs-center' }
  ],
  extensions_history_header: [
    { text: 'Tipo de solicitud', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Engagement', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Pulse', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Plan', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Inicio de solicitud', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Duración', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Status', value: '', sortable: false, class: 'text-xs-center' }
  ],
  status_pending: 'Pendiente',
  status_accepted: 'Aceptada',
  status_rejected: 'Rechazada',
  status_cancelled: 'Cancelada',
  no_demo_requests: 'No hay solicitudes de demo pendientes',
  demo_plan_request: 'Solicitudes de plan Demo',
  demo_requests_table: [
    { text: 'Empresa', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Dirección', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Fecha de solicitud', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Acciones', value: '', sortable: false, class: 'text-xs-center' }
  ],
  // tslint:disable-next-line:max-line-length
  created_demo_enterprise: 'Se ha registrado su empresa. A continuación, recibirá un correo con las instrucciones a seguir para la activación de su cuenta.',
  profile_warning: 'Recuerde configurar sus cortes demográficos para utilizar el sistema correctamente.',
  first_time_title: 'Bienvenido a OCC - Pulse',
  // tslint:disable-next-line:max-line-length
  first_time_text: 'Para tener una mejor experiencia al interactuar con la plataforma es recomendable seguir los siguientes pasos:',
  first_time_step: 'Paso',
  // tslint:disable-next-line:max-line-length
  first_time_step_1: 'Verifique la información de la empresa, edite o cargue únicamente los cortes demográficos que utilizará para generar los reportes.',
  // tslint:disable-next-line:max-line-length
  first_time_step_2: 'Ingrese a la sección de colaboradores para la carga de nómina de la empresa antes de realizar cualquier valoración.',
  // tslint:disable-next-line:max-line-length
  first_time_step_3: 'Cree su primera valoración de engagement o pulse según el plan que haya contratado.',
  register_date: 'Fecha de registro',
  balance: 'Saldo actual OCC Tokens',
  resumen: 'Resumen de empresas',
  admin: 'Administrador',
  available_tokens: 'Tokens disponibles',
  company_name: 'Nombre de la compañia',
  profile_picture: 'Foto de perfil',
  customer: 'Cliente',
  requirements_title: 'Requerimientos Técnicos',
  // tslint:disable-next-line:max-line-length
  requirements_subtitle: 'Revisa e implementa con el área encargada estos requerimientos, antes del lanzamiento de la medición. Con esto evitamos que los correos lleguen a Spam o  se bloquee el acceso a la plataforma para los encuestados.',
  // tslint:disable-next-line:max-line-length
  requirements_text: 'La herramienta OCC Suite requiere de varias condiciones a nivel técnico que deben garantizarse en la red y equipos de su compañía para ofrecer un nivel de servicio, funcionamiento y mantenimiento adecuado.',
  requirements_title_1: 'Acceso a las URLs',
  requirements_title_list_1: 'Permitir el acceso en los computadores de la organización a las URL:',
  requirements_title_listitems_1: [
    'https://occ-solutions.com/',
    'http://suite.occ-solutions.com/',
    'https://suite.occ-solutions.com/',
    'https://dml360.occ-solutions.com/',
    'http://dml360.occ-solutions.com/'
  ],
  requirements_title_2: 'Servidores de correo entrante',
  // tslint:disable-next-line:max-line-length
  requirements_title_list_2: 'Habilitar en la entrada al servidor de correos de su compañía de correos enviados masivamente desde las siguientes direcciones:',
  requirements_title_listitems_2: [
    'sendgrid.info',
    'sendgrid.com',
    'sendgrid.net'
  ],
  requirements_title_3: 'Dominios de correo',
  // tslint:disable-next-line:max-line-length
  requirements_title_list_3: 'Habilitar la entrada al servidor de correos de su compañía correos enviados con los dominios y subdominios respectivos:',
  requirements_title_listitems_3: [
    '@occ-solutions.com',
    '@occsolutions.org',
    '@suite.occ-solutions.com',
    '@suite.occsolutions.org'
  ],
  requirements_title_4: 'Navegadores compatibles:',
  requirements_title_list_4: 'La aplicación web funciona adecuadamente en los siguientes navegadores:',
  requirements_title_listitems_4: [
    'Google Chrome (recomendado).',
    'Mozilla Firefox.',
    'Opera.',
    'Microsoft Edge.'
    // 'Internet Explorer (versión 11 en adelante).'
  ]
}
