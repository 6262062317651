<template>
  <v-container>
    <template>
      <v-row>
        <v-col>
          <h4 class="display-1">{{ $t('questionnaires.edit_questions') }}</h4>
        </v-col>
      </v-row>
      <v-card>
        <v-tabs
          v-model="tab"
          background-color="transparent"
          light
          grow
        >
        <v-tab
          v-for="(evaluation, i) in questionnaire.evaluations"
          :key="i"
        >
          {{ $t(`questionnaires.${i}`) }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">

        <v-tab-item
          v-for="(item, i) in questionnaire.evaluations"
          :key="i"
        >
          <v-card flat class="px-4">
            <v-row>
              <v-col>
                <v-card-text>
                  <v-expansion-panels>
                    <v-expansion-panel
                      v-for="(dimention) in item"
                      :key="dimention.name"
                    >
                      <v-expansion-panel-header> {{ $t(`questionnaires.${dimention.name}`) }} </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-list dense shaped v-for="(responsability, i) in dimention.responsability" :key="i">
                          <v-list-item-title>
                            <v-tooltip
                              bottom
                              color="green"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  light outlined
                                  :label="$t('questionnaires.responsability')"
                                  name="responsability"
                                  :input="responsability[user.lang]"
                                  v-model="responsability[user.lang]"
                                  @keypress.enter="sendForm"
                                  @blur="sendForm"
                                  v-on="on"
                                  outline
                                  append-icon="mdi-content-save-edit-outline"
                                  color="green"
                                  class="mt-2"
                                ></v-text-field>
                              </template>
                              <span>
                                {{ $t('questionnaires.tooltip_edit') }}
                              </span>
                            </v-tooltip>
                          </v-list-item-title>
                          <v-list-item-group v-model="list" color="primary">
                            <v-list-item
                              v-for="(question, j) in responsability.questions"
                              :key="j"
                            >
                              <v-tooltip
                                bottom
                                color="blue"
                              >
                                <template v-slot:activator="{ on }" >
                                  <v-text-field
                                    light outlined
                                    :label="$t('questionnaires.question')"
                                    name="question"
                                    :input="question[user.lang]"
                                    v-model="question[user.lang]"
                                    @keypress.enter="sendForm"
                                    @blur="sendForm"
                                    v-on="on"
                                    outline
                                    append-icon="mdi-content-save-edit-outline"
                                    color="blue"
                                  ></v-text-field>
                                </template>
                                <span>
                                  {{ $t('questionnaires.tooltip_edit') }}
                                </span>
                              </v-tooltip>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-card-text>
              </v-col>
            </v-row>
            <v-row>
              <v-col align="end">
                <v-btn
                  to="/questionnaires"
                  text
                >
                  {{ $t('input.back') }}
                </v-btn>
              </v-col>
            </v-row>

          </v-card>
        </v-tab-item>
      </v-tabs-items>
      </v-card>
    </template>
  </v-container>
</template>

<script>

import Vue from 'vue'
import { mapState } from 'vuex'

import questionnairesService from '../../services/questionnaires'

export default Vue.extend({
  data () {
    return {
      list: 1,
      questionnaire: null,
      tab: null
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    })
  },
  created () {
    this.getQuestionnaire()
  },
  methods: {
    getQuestionnaire () {
      this.$store.dispatch('loading/show')
      return questionnairesService.getOne(this.$route.params.slug)
        .then((res) => {
          this.questionnaire = res
          this.$store.dispatch('loading/hide')
        }).catch(() => {
          this.$store.dispatch('loading/hide')
        })
    },
    sendForm () {
      this.$store.dispatch('loading/show')
      return questionnairesService.edit(this.questionnaire.slug, this.questionnaire)
        .then((res) => {
          this.$store.dispatch('loading/hide')
          this.$store.dispatch('alert/success', this.$t('questionnaires.edited_succesfully'))
        }).catch(() => {
          this.$store.dispatch('loading/hide')
          this.$store.dispatch('alert/error', this.$t('questionnaires.error_editing'))
        })
    }
  }
})
</script>
