
import auth from './auth'
import customers from './customers'
import dashboard from './dashboard'
import employees from './employees'
import enterprises from './enterprises'
import errors from './errors'
import evaluations from './evaluations'
import groups from './groups'
import help from './help'
import input from './input'
import instructive from './instructive'
import languages from './languages'
import navigation from './navigation'
import notifications from './notifications'
import operationSummary from './operation-summary'
import outside from './outside'
import pluralization from './pluralization'
import settings from './settings'
import services from './services'
import tokens from './tokens'
import pricing from './pricing'
import calculatorView from './calculator_view'
import questionnaires from './questionnaires'
import organizationalReport from './organizational_report'
import individualReport from './individual_report'
import demographicReport from './demographic_report'
import reports from './reports'

export default {
  /* Gerenal labels */
  title: 'OCC Solutions - Pulse',
  active: 'Active',
  inactive: 'Inactive',
  no_data: 'No records',
  registration_updated_successfully: 'Registration updated successfully',
  record_saved_successfully: 'Record saved successfully',
  error_trying_update: 'There was an error trying to update',
  error_trying_save: 'An error occurred while trying to save',
  copyright: 'All rights reserved 2019',
  go_back: 'Go Back',
  results_per_pages: 'Results per pages',
  manual: 'Help manuals',
  go_to_manual: 'Click here to go to the manuals section',
  page_all: 'All',
  /* Models */
  models: {
    enterprises: 'Enterprises',
    groups: 'Groups',
    pulse: 'Pulse'
  },
  waiting: 'Please wait',
  maintenance: 'Page under construction',
  // tslint:disable-next-line:max-line-length
  maintenance_text: 'We work each day to achieve the objectives<br/>and give you the best results and experiences.',
  /* Labels grouped by modules or type */
  auth,
  customers,
  dashboard,
  employees,
  enterprises,
  errors,
  evaluations,
  help,
  input,
  instructive,
  navigation,
  notifications,
  operationSummary,
  outside,
  pluralization,
  languages,
  pricing,
  calculatorView,
  settings,
  services,
  tokens,
  groups,
  questionnaires,
  organizationalReport,
  individualReport,
  demographicReport,
  reports,
  page_text: 'of'
}
