
export default {
  title: 'Cofigurando Servicio',
  name: 'Name of Service',
  modality: 'Modality',
  periocity: 'Periocity',
  periocities: {
    Annual: 'Annual',
    Biannual: 'Biannual',
    Quarterly: 'Quarterly',
    Monthly: 'Monthly',
    Biweekly: 'Biweekly',
    Weekly: 'Weekly',
    Diary: 'Diary',
    OneTime: 'Onetime',
    ByDemand: 'Bydemand'
  },
  recomendation: 'Recomendation',
  price: 'Price in Tokens',
  modalities: {
    Download: 'Download',
    Person: 'Person',
    Medition: 'Medition'
  }
}
