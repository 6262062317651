
<template>
  <v-container fluid>
    <div>
      <v-alert
        type="info"
        :value="conectionModal || !isOnline"
        style="position: fixed; top: 0; width: 98%; z-index: 3;"
      >{{ $t('evaluations.conection_modal_msg') }}</v-alert>
      <v-card
        style="position: fixed; top: 0; z-index: 2; width: 100%;"
        v-if="showFixed"
        elevation="9"
      >
        <v-row justify="center">
          <v-col cols="12">
            <p class="headline mb-0 pt-2 px-3">
              {{ $t('evaluations.evaluate') }}: {{ evaluated }} | {{ $t(`evaluations.iam_${evaluatedRole}`) }}
              <v-btn class="mx-2 hidden-sm-and-up" fab dark small color="info" @click="showExample = !showExample">
                <v-icon dark v-if="showExample">mdi-minus</v-icon>
                <v-icon dark v-else>mdi-plus</v-icon>
              </v-btn>
            </p>
          </v-col>
        </v-row>
        <v-row justify="space-around" class="my-0 px-2 text-center" v-show="showExample || $vuetify.breakpoint.name !== 'xs'">
          <v-col cols="6" sm="4" md="4" :lg="evaluatedRole === 'pair' ? 1 : 2"
            v-for="i in (evaluatedRole !== 'pair' ? 6 : 7)" :key="i"
            class="pa-2"
          >
            <v-avatar :color="legendColors[i - 1]" size="25" style="margin: 3px 0;">
              <span v-if="i !== 7" class="white--text headline">{{i}}</span>
            </v-avatar>
            <br/>
            <span style="margin: 5px 0 0 5px; font-weight: bold;">{{ $t(`evaluations.score_label${i}`) }}</span>
          </v-col>
        </v-row>
      </v-card>
      <v-card v-if="!completed" style="margin-bottom:10px; overflow-y-auto" id="scrolling-evaluations">
        <!-- Header (evaluado - rol) -->
        <v-row wrap row align="center" class="pl-2 pr-2">
          <v-col cols="12" sm="6">
            <v-card style="border-radius:10px; border-top:10px solid #309DE0;">
              <div class="d-flex flex-no-wrap justify-space-around align-center">
                <p class="headline pt-2">DML 360°</p>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6">
            <v-card style="border-radius:10px; border-top:10px solid #4ACAAE;">
              <div class="d-flex flex-no-wrap justify-space-around align-center">
                <p class="headline pt-2">
                  {{ $t('evaluations.evaluate') }}: {{ evaluated }} | {{ $t(`evaluations.iam_${evaluatedRole}`) }}
                </p>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <!-- Explicación de la Encuesta -->
        <v-row justify="center" class="example">
          <v-col cols="11">
            <v-card>
              <p style="padding: 30px; font-weight: bold;">
                {{ $t('evaluations.information_2') }}
              </p>
              <v-row justify="space-around" class="pa-2 text-center">
                <v-col cols="6" sm="4" md="4" :lg="evaluatedRole === 'pair' ? 1 : 2"
                  v-for="i in (evaluatedRole !== 'pair' ? 6 : 7)" :key="i"
                  class="pa-2"
                >
                  <v-avatar :color="legendColors[i - 1]" size="36" style="margin: 15px 0;">
                    <span v-if="i === 7" class="white--text headline"></span>
                    <span v-else class="white--text headline">{{i}}</span>
                  </v-avatar>
                  <br/>
                  <span style="margin: 5px 0 0 5px; font-weight: bold;">{{ $t(`evaluations.score_label${i}`) }}</span>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <!-- Encuesta -->
        <v-row v-for="(evaluation, i) in generalEvaluation" :key="`${i}-content`" justify="center">
          <v-row justify="center">
            <v-col cols="11">
              <v-card class="mt-4 text-center">
                <v-col>
                  <p style="font-size: 25px; font-weigth: bold; color: rgb(94,175,169);">{{ $t(`evaluations.${evaluation.name}`) }}</p>
                </v-col>
              </v-card>
            </v-col>
          </v-row>
          <v-row justify="center" v-for="(responsability, j) in evaluation.responsability" :key="j">
            <v-col cols="11" v-for="(item, k) in responsability.questions" :key="k">
              <v-card class="my-2" style="border-radius: 5px;">
                <v-row wrap row justify="space-between">
                  <v-col class="ml-3 mt-4">
                    <span style="font-size: 20px; font-weight:bold;" class="mr-2">{{questionCount[i] + item.index}}</span>{{item[lang]}}
                  </v-col>
                  <v-col class="pb-2">
                    <v-radio-group
                      v-model="radioObject[`${evaluation.name}`][item.index - 1]"
                      @change="setRadioValues(radioObject[`${evaluation.name}`][item.index - 1], i, j, k, evaluation.name)"
                      :mandatory="false" row>
                      <v-radio style="margin: 0px 20px" label="1" value="1" :color="legendColors[0]"></v-radio>
                      <v-radio style="margin: 0px 20px" label="2" value="2" :color="legendColors[1]"></v-radio>
                      <v-radio style="margin: 0px 20px" label="3" value="3" :color="legendColors[2]"></v-radio>
                      <v-radio style="margin: 0px 20px" label="4" value="4" :color="legendColors[3]"></v-radio>
                      <v-radio style="margin: 0px 20px" label="5" value="5" :color="legendColors[4]"></v-radio>
                      <v-radio style="margin: 0px 20px" label="6" value="6" :color="legendColors[5]"></v-radio>
                      <v-radio
                        v-if="evaluatedRole === 'pair'"
                        :style="{margin: $vuetify.breakpoint.width > 1350 ? '0 0 0 30px' : '0px 20px' }"
                        :label="$t('evaluations.dont_know')"
                        value="-1" :color="legendColors[6]">
                      </v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-row>
        <v-row justify="center" class="mt-8 pl-4 pr-4">
          <v-col cols="12" class="text-center">
            <p style="font-size: 25px; font-weigth: bold; color: rgb(94,175,169);">{{ $t('evaluations.open_questions') }}</p>
          </v-col>
          <v-col cols="12" class="mb-2 py-0" v-for="(n, i) in openQuestions" :key="i">
            <v-row wrap row class="mt-5" justify="center">
              <v-col cols="12" class="pb-0 text-center">
                <h4 v-if="evaluatedRole === 'autoevaluation'" style="font-size: 20px;">{{ n.auto[lang] }}</h4>
                <h4 v-else style="font-size: 20px;">{{ n.general[lang] }}</h4>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-row justify="space-between">
                  <v-col cols="12" v-for="(item, j) in n.answer" :key="j">
                    <v-text-field
                      v-model="openQuestions[i].answer[j]"
                      :rules="rules"
                      :label="$t('input.answer')"
                      @change="answerOpenQuestions"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
      <v-row wrap row class="mt-5" justify="center">
        <v-col cols="12" sm="6" class="text-center mt-4">
          <v-btn :disabled="progress !== 100" width="100%" class="mt-4" @click="showConfirmation = true" large color="primary">{{ $t('input.finish_send') }}</v-btn>
        </v-col>
      </v-row>
      <v-col>
        <v-fab-transition style="bottom: 10px;">
          <v-btn
            :color="progress === 100 ? 'green darken-3' : 'red darken-1'"
            dark fixed bottom right fab x-large
          >
            <span>{{(progress.toFixed(0))}}%</span>
          </v-btn>
        </v-fab-transition>
      </v-col>
    </div>

    <x-wellcome-dialog
      :evaluation="evaluation"
      :evaluatedRole="evaluatedRole"
      :start-dialog="startDialog"
      @close="($event) => this.startDialog = false"
    ></x-wellcome-dialog>
    <x-middle-dialog
      :middle-dialog="middleDialog"
      @close="($event) => this.middleDialog = false"
    ></x-middle-dialog>
    <x-finish-dialog
      :finish-dialog="finishDialog"
      @close="($event) => this.finishDialog = false"
    />
    <x-confirmation-modal
      :show.sync="showConfirmation"
      :title="$t('evaluations.confirmation_modal_title')"
      :action="saveDml"
      color="success"
      @close="showConfirmation = false"
    >
      <template v-slot:question>{{ $t('evaluations.confirmation_modal_des') }}</template>
    </x-confirmation-modal>
  </v-container>
</template>

<script>

import { mapState } from 'vuex'
import evaluationsService from '../../services/evaluations'
import userService from '../../services/users'
// import authService from '../../services/auth'

import XWellcomeDialog from './components/wellcome-dialog.vue'
import XMiddleDialog from './components/middle-dialog.vue'
import XFinishDialog from './components/finish-dialog.vue'

let interval
const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms))

export default {
  components: {
    XWellcomeDialog,
    XMiddleDialog,
    XFinishDialog
  },
  data () {
    return {
      outIntervalDialog: false,
      dialogIcon: '',
      dialogText: '',
      evaluation: {
        timeZone: '',
        enterprise: {}
      },
      generalEvaluation: [],
      autoEvaluation: [],
      radios: null,
      evaluated: '',
      evaluatedRole: '',
      evaluator: '',
      evaluatorIndex: '',
      answersDimention: [],
      radioObject: {
        as_person: [],
        management: [],
        mobilization: [],
        achievement: []
      },
      evaluatedIndex: 0,
      rules: [
        value => !!value || this.$i18n.t('input.required_field')
      ],
      lang: 0,
      questionCount: [0, 10, 25, 40],
      openQuestions: [],
      completed: false,
      startDialog: false,
      middleDialog: false,
      finishDialog: false,
      displayedMiddleDialog: false,
      progress: 0,
      legendColors: ['#BB3E3E', '#B8663D', '#C2B147', '#B6C144', '#44C156', '#1B5E20', '#BDBDBD'],
      showFixed: false,
      showExample: true,
      showConfirmation: false,
      employeeData: null,
      conectionAttempt: 0,
      timeConectionAttempt: 0,
      conectionCallBack: null,
      conectionTry: false,
      conectionModal: false,
      isOnline: true
    }
  },
  watch: {
    startDialog (val) {
      document.querySelector('html').style.overflow = val ? 'hidden' : null
    },
    middleDialog (val) {
      document.querySelector('html').style.overflow = val ? 'hidden' : null
    },
    outIntervalDialog (val) {
      document.querySelector('html').style.overflow = val ? 'hidden' : null
    },
    finishDialog (val) {
      document.querySelector('html').style.overflow = val ? 'hidden' : null
    },
    progress () {
      if (this.progress >= 50 && !this.displayedMiddleDialog) {
        this.middleDialog = this.displayedMiddleDialog = true
      }
    }
  },
  computed: {
    ...mapState({
      user: state => state.session.user
    })
  },
  created () {
    this.lang = this.user ? (this.user.lang || 'es') : 'es'
    this.getEvaluation()
    window.addEventListener('scroll', this.handleScroll)
    interval = setInterval(() => {
      this.checkInternet()
    }, 3000)
  },
  destroyed () {
    clearInterval(interval)
  },
  methods: {
    checkInternet () {
      return userService.tryConection()
        .then(() => { this.isOnline = true })
        .catch(() => { this.isOnline = false })
    },
    retryConection () {
      if (this.intervalConection) {
        clearInterval(this.intervalConection)
      }
      this.conectionAttempt = 0
      this.tryConection()
    },
    async tryConection (callBack) {
      this.conectionAttempt++
      if (callBack) {
        this.conectionCallBack = callBack
      }
      console.log(this.conectionCallBack, callBack)
      return this.conectionCallBack()
        .then((res) => {
          console.log('aaaaaaaaaaaaaaaaaaaaaaaaaa', res)
          this.conectionModal = false
          this.conectionTry = false
          this.conectionAttempt = 0
          this.timeConectionAttempt = 0
          return res
        })
        .catch(async (err) => {
          this.conectionTry = true
          this.conectionModal = this.conectionAttempt > 6
          if (this.conectionAttempt < 10) {
            const time = this.timeConectionAttempt = this.conectionAttempt <= 3 ? 2 : (this.conectionAttempt <= 6 ? 5 : 10)
            this.intervalConection = setInterval(() => {
              this.timeConectionAttempt--
              if (this.timeConectionAttempt <= 0) {
                clearInterval(this.intervalConection)
              }
            }, 1000)
            await sleep(time * 1000)
            const res = await this.tryConection()
            return res
          }
          throw err
        })
    },
    handleScroll ($event) {
      const example = document.querySelector('.example')
      if (example) {
        this.showFixed = example.getBoundingClientRect().bottom <= 0 && !this.startDialog && !this.middleDialog
      } else {
        this.showFixed = false
      }
    },
    setProgress () {
      let progress = 0
      let questionCount = 0
      let counterAnsweredQuestions = 0
      for (const type in this.radioObject) {
        questionCount += this.radioObject[type].length
        counterAnsweredQuestions += this.radioObject[type].filter(q => +q === -1 || (q >= 1 && q <= 6)).length
      }
      for (const open in this.openQuestions) {
        questionCount += this.openQuestions[open].answer.length
        counterAnsweredQuestions += this.openQuestions[open].answer.filter(a => typeof a === 'string' && a).length
      }

      this.progress = (counterAnsweredQuestions / questionCount) * 100
    },
    getEvaluation () {
      this.$store.dispatch('loading/show')
      return evaluationsService.findByTokenId(this.$route.params.tokenId)
        .then((res) => {
          if (res.executed) {
            if (res.data.status === 'completed') {
              this.$store.dispatch('loading/hide')
              this.showDialog('/img/expiracion.png', this.$i18n.t('evaluations.expiration_date'))
            } else {
              this.evaluation = res.data
              let releasedAtParsed = Date.parse(res.data.deliveredAt.split('Z')[0]) / 1000
              let deadLineAtParsed = Date.parse(res.data.validUntil.split('Z')[0]) / 1000
              if (releasedAtParsed > parseInt(Date.now() / 1000)) {
                this.showDialog('/img/reloj.png', this.$i18n.t('evaluations.before_date'))
              } else if (deadLineAtParsed < parseInt(Date.now() / 1000)) {
                this.showDialog('/img/expiracion.png', this.$i18n.t('evaluations.expiration_date'))
              } else if (res.data.status === 'pending') {
                this.showDialog('/img/reloj.png', this.$i18n.t('evaluations.not_available'))
              } else {
                this.getRelation(this.$route.params.tokenId)
                if (this.completed) {
                  this.finishDialog = true
                } else {
                  this.startDialog = true
                  let openQuestions = res.data.openQuestions
                  if (this.openQuestions === undefined || this.openQuestions.length === 0) {
                    this.openQuestions = res.data.openQuestions
                    for (let i = 0; i < openQuestions.length; i++) {
                      this.openQuestions[i].answer = ['', '', '']
                    }
                  }
                  this.getTemporal()
                  if (this.progress >= 50) {
                    this.displayedMiddleDialog = true
                  }
                }
              }
            }
          } else {
            this.$store.dispatch('loading/hide')
            this.showDialog('/img/alerta.png', this.$i18n.t('evaluations.invalid_token'))
          }
        }).catch((err) => {
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
          this.$store.dispatch('loading/hide')
        })
    },
    getRelation (tokenId) {
      const employee = this.evaluation.evaluated.find(evaluated => evaluated.employee.token === tokenId)
      if (employee !== undefined) {
        this.evaluated = employee.employee.employee.text
        this.evaluatedRole = 'autoevaluation'
        this.evaluator = 'autoevaluation'
        this.generalEvaluation = this.evaluation.questionnaire.evaluations.autoEvaluation
        this.answersDimention = employee.employee.answersDimention
        this.openQuestions = employee.employee.openQuestions
        this.completed = employee.employee.completed
        this.employeeData = employee.employee.employee
      } else {
        const getEvaluator = (key, keyTranslate) => {
          let arrIndex = 0
          let evaluatorIndex = 0
          const employee = this.evaluation.evaluated.find((evaluated, index) => {
            arrIndex = index
            return evaluated[key].find((o, i) => {
              evaluatorIndex = i
              return o.token === tokenId
            })
          })
          const resp = employee !== undefined
          if (resp) {
            this.evaluatedIndex = arrIndex
            this.evaluated = employee.employee.employee.text
            this.evaluatedRole = keyTranslate
            this.evaluator = key
            this.generalEvaluation = this.evaluation.questionnaire.evaluations.generalEvaluation
            this.evaluatorIndex = evaluatorIndex
            this.answersDimention = employee[key][evaluatorIndex].answersDimention
            this.openQuestions = employee[key][evaluatorIndex].openQuestions
            this.completed = employee[key][evaluatorIndex].completed
            this.employeeData = employee[key][evaluatorIndex].employee
          }
          return resp
        }
        getEvaluator('leadersEvaluators', 'leader') || getEvaluator('dependentsEvaluators', 'dependent') || getEvaluator('pairsEvaluators', 'pair')
      }
      // this.checkActivity()
      this.$store.dispatch('loading/hide')
    },
    // checkActivity () {
    //   if (this.employeeData) {
    //     authService.checkActivity(this.employeeData.id)
    //   }
    // },
    getTemporal () {
      for (let i = 0; i < this.answersDimention.length; i++) {
        for (let j = 0; j < this.answersDimention[i].responsability.length; j++) {
          for (let k = 0; k < this.answersDimention[i].responsability[j].questions.length; k++) {
            if (this.answersDimention[i].responsability[j].questions[k].score === 0) {
              this.radioObject[this.answersDimention[i].name].push(null)
            } else {
              this.radioObject[this.answersDimention[i].name].push((this.answersDimention[i].responsability[j].questions[k].score).toString())
            }
          }
        }
      }
      this.setProgress()
    },
    setRadioValues (value, evaluation, responsability, question, type) {
      this.answersDimention[evaluation].responsability[responsability].questions[question].score = parseInt(value)
      this.runSetRadioValues()
      this.setProgress()
    },
    runSetRadioValues () {
      return (this.evaluatedRole === 'autoevaluation')
        ? evaluationsService.updateAutoEvaluation(this.$route.params.tokenId, this.answersDimention)
        : evaluationsService.updateEvaluator(this.$route.params.tokenId, this.answersDimention)
    },
    showDialog (icon, text) {
      this.outIntervalDialog = true
      this.dialogIcon = icon
      this.dialogText = text
    },
    saveDml () {
      this.$store.dispatch('loading/show')
      return this.progress === 100 ? this.runSaveDML() : Promise.resolve(false)
    },
    runSaveDML () {
      const endMedition = () => {
        return evaluationsService.updateEvaluationStatus(this.$route.params.tokenId, this.evaluator)
          .then(() => {
            this.$store.dispatch('loading/hide')
            this.finishDialog = true
          }).catch((err) => {
            this.$store.dispatch('loading/hide')
            this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
          })
      }

      return this.tryConection(this.runSetRadioValues)
        .then(() => this.tryConection(this.runAnswerOpenQuestions))
        .then(() => this.tryConection(endMedition))
    },
    answerOpenQuestions () {
      this.runAnswerOpenQuestions()
      this.setProgress()
    },
    runAnswerOpenQuestions () {
      return (this.evaluatedRole === 'autoevaluation')
        ? evaluationsService.updateAutoOpenQuestion(this.$route.params.tokenId, this.openQuestions)
        : evaluationsService.updateEvaluatorOpenQuestion(this.$route.params.tokenId, this.openQuestions)
    }

  }
}
</script>
<style scoped>
  .v-slider__tick--filled {
    background-color: none;
  }
</style>
