
export default {
  create: 'Create assessment',
  edit: 'Edit assessment',
  no_data: 'No assessments to show',
  eval_no_data: 'No evaluated to show',
  settings: 'Assessment settings',
  overview: 'Poll name',
  date: 'date',
  questions: 'Questions',
  revition: 'Revition and customization',
  poll_name: 'Assessment name',
  want_external_name: 'Would you like to put an internal name for the evaluators?',
  tooltip_name: 'Assessment internal name',
  evaluated_selection: 'Evaluated and evaluators',
  evaluated_selection_desc: 'In this section, you will be able to choose and assign the leaders who will be part of the assessment and their evaluators with their relationships. Remember that all the participants of the assessment must be previously loaded.',
  evaluated_selection_warning: 'After the start date of the assessment, the evaluators cannot be modified, nor the relationships with the evaluators.',
  hours: 'Send hour',
  hours_valid_until: 'Close hour',
  date_delivery: 'Delivery date',
  poll_valid_until: 'Assessment valid until',
  time_zone: 'Timezone',
  want_send_reminders: 'Would you like to send reminders?',
  reminders: 'Send reminders',
  scheduled_reminders: 'Scheduled reminders',
  external_name: 'Show name',
  send_reminders: 'Reminders send',
  no_reminders: 'No scheduled reminders',
  questionnaire: 'Questionnaire',
  personalization: 'Email customization',
  poll_invitation: 'Assessment invitation',
  reminder_mail: 'Reminder mail',
  tk_message: 'Thanks message',
  message_subject: 'Email subject',
  body_message: 'Email body',
  message_from_the_company: 'Enterprise message',
  in_progress: 'In progress',
  pending: 'Pending',
  completed: 'Completed',
  questionnaire_e: 'DML 360° Questionnaire',
  pdf_explained: 'This file contains the questions of the "{name}" questionnaire grouped by dimension.',
  copyright: 'Copyright by OCC - All rights reserved',
  title: 'OCC - DML 360° Report',
  details: 'Assessment details',
  name: 'Name',
  status: 'Status',
  vigency: 'Vigency',
  actions: 'Actions',
  evaluation: 'Assessment DML 360°',
  total: 'Total',
  total_receptors: 'Total respondents: {n}',
  pending_evaluations: 'Pending',
  finished_evaluations: 'Completed',
  of_polls: 'of polls',
  leader: 'Leader',
  leaders: 'Leaders',
  dependent: 'Dependent',
  dependents: 'Dependents',
  pair: 'Pair',
  pairs: 'Pairs',
  iam_autoevaluation: 'self-assessment',
  iam_leader: 'I am their leader',
  iam_pair: 'We are peers',
  iam_dependent: 'He is my leader',
  tooltip_display_name: 'Display name',
  want_massive: 'Do you want to add the leaders to be evaluated and their evaluators via bulk upload?',
  massive_upload_recomendations: 'The purpose of this section is to mass load the leaders to be evaluated and their evaluators. To prevent bulk upload errors, please note:',
  massive_upload_recomendations_items: [
    'Before performing the bulk upload you must add all collaborators to the suite.',
    'Click on the download template button to download the file where you are going to define the relationships of each leader to be evaluated.',
    'Only the following relationships are allowed: boss, peer, collaborator. The payload is case sensitive. Write the relationships in lowercase.',
    'If any of the measurement participants does not have an email, you can add them manually with the add evaluator or edit button.',
    'The evaluated with all its different evaluators should only be written once.',
    'The same evaluator cannot have two relationships for the same leader to be evaluated.'
  ],
  add_evaluated: 'Add evaluated',
  edit_evaluators: 'Edit evaluators',
  download_instructive: 'Download instructive',
  download_template: 'Download template',
  warning_text: 'There are faild on your file data.',
  error_text: 'One of the evaluated does not have evaluator assigned.',
  // Llenado
  as_person: 'As a person',
  management: 'Direction',
  mobilization: 'Mobilization',
  achievement: 'Achievement',
  open_questions: 'Open questions',
  autoevaluation: 'Self-assessment',
  dont_know: 'I dont know',
  information: 'The assessment is made up of a series of questions, which correspond to the leader\'s own personal attributes (the Leader as a person) and to the Processes of the DML 360° model (Management, Mobilization and Achievement).',
  information_2: 'The assessment is made up of a series of behaviors and behaviors related to the competencies and values of the DML 360° Leadership Model, on which each evaluator rates the frequency between 1 and 6 according to the following scale:',
  score_label1: 'Never',
  score_label2: 'Almost never',
  score_label3: 'Rarely',
  score_label4: 'Sometimes',
  score_label5: 'Usually',
  score_label6: 'Always',
  score_label7: 'I dont know',
  // Dialog messages
  wellcome_title: 'Welcome to the DML 360º assessment.',
  wellcome_instructions: 'Below you will find 55 questions that serve to describe the way you perceive the behavior of the leader evaluated within your Organization in the present.<br/><br/> Remember, for true-to-life results make sure you are responding honestly. Your assessment is neither good nor bad, it is about finding the value that most accurately reflects the behaviors in the Organization. We trust your judgment and sincerity.<br/><br/> Be sure to properly read the information.<br/><br/>The maximum term to complete the survey is: {deadline}',
  end: 'Thank you very much for your participation. You have successfully completed the survey',
  middle: 'Very well! You\'ve already completed half the process, stay tuned for every question!',
  invalid_token: 'There is no survey for this access, please place a valid one',
  before_date: 'The survey is not available for the current date',
  not_available: 'The survey is not available at the moment',
  expiration_date: 'The survey you are trying to enter has ended',
  evaluation_completed: 'You have already completed this survey',
  custom_msg: '<p>Welcome to the DML 360º Leadership Assessment which collects the perceptions about leadership skills of those who work with a leader to complement them with their self-perception. This information provides a complete overview of the leader\'s performance and the impact it is generating in their work environment.</p>' +
    '<p>This is a perception survey; no question has correct or incorrect answer. Your <b>sincerity and frankness</b> are essential for the results to be useful in the development of the Leader. <b>Your responses are completely confidential.</b></p>' +
    '<p>Key information about this survey::</p>' +
    '<p>The information provided will be handled <b>confidentially</b> and will be used for statistical purposes.</p>' +
    '<p>For a better experience use Google Chrome, remember that you must be connected to the internet.</p>' +
    '<p>If you have any doubts or evidence of a problem with the survey, please do not hesitate to contact us at: <a href="mailto:info@occsolutions.org">info@occsolutions.org</a>.</p>' +
    '<p>Remember that <b>this link is personal</b> and should not be shared.</p>' +
    '<p>We thank you in advance for your time in this important process.</p>',
  custom_reminder: '<p>We remind you that the DML 360° leadership assessment is pending. Your opinion is very important in this process. We want to remind you that:</p>' +
    '<p>This is a perception survey; no question has correct or incorrect answer. Your <b>sincerity and frankness</b> are essential for the results to be useful in the development of the Leader. <b>Your responses are completely confidential.</b></p>' +
    '<p>Key information about this survey::</p>' +
    '<p>The information provided will be handled <b>confidentially</b> and will be used for statistical purposes.</p>' +
    '<p>For a better experience use <b>Google Chrome,</b> remember that you must be connected to the internet.</p>' +
    '<p>If you have any doubts or evidence of a problem with the survey, please do not hesitate to contact us at: <b><a href="mailto:info@occsolutions.org">info@occsolutions.org</a>.</b></p>' +
    '<p>Remember that <b>this link is personal</b> and should not be shared.</p>' +
    '<p><b>We thank you in advance for your time in this important process.</b></p>',
  evaluated: 'Evaluated',
  evaluate: 'Evaluated',
  start_at: 'Start at',
  end_at: 'End at',
  your_balance: 'Your balance:',
  token_unit: 'OCC Tokens',
  balance_after: 'Balance after creating the measurement:',
  non_active_employees: 'You must have at least one active employee for this survey.',
  acquire_tokens: 'Acquire more OCC Tokens',
  confirm_create_title: 'Confirm the creation of the assessment',
  workshop_cost: 'Assessment cost',
  invalid_reminder_date: 'Reminder Date must be within the release period',
  all_right: 'All rights reserved 2020. ©',
  automatic_message: 'This message was sent automatically from',
  show_list: 'Show list',
  evaluators: 'Evaluators',
  modal_del_title: 'Confirm Delete',
  modal_del_question: 'Do you want to delete this evaluated?',
  confirm_edit_title: 'Confirm the creation of the assessment<br/>New evaluated',
  diff_cost: 'Difference cost',
  created_evaluation: 'Assessment created successfully! Soon you will be redirected to the operation summary',
  updated_evaluation: 'Measurement successfully updated',
  delete_evaluator_min_error: 'You cannot remove all evaluators from a relationship',
  list_of_evaluators_for: 'List of evaluators for',
  add_evaluator: 'Add evaluator',
  the_evaluated: 'The evaluated',
  the_evaluator: 'The evaluator',
  no_evaluated_type: 'do not has evaluators of the type',
  fix_document_or_try_again: 'please fix the document and try again.',
  warnings_list: 'Warnings list',
  multiple_appears_warning: 'has been repeated as evaluated, so the system has taken the first appearance and ignored the rest',
  evaluated_as_evaluator_warning: 'has been added as evaluator of his own assessment, so the system ignored that appearence and added the rest of the evaluators.',
  evaluated_not_found_warning: 'The evaluators shown below could not be found as employees of your enterprise on the system:',
  duplicated_as_evaluator_of: 'has been repeated as evaluator on the assessment of',
  take_only_first_ap: 'so the system has taken his first appearence and ignored the rest',
  added_as_evaluator_type: 'has been added as evaluator of the type',
  on_the_evaluation_of: 'on the assessment of',
  not_found_as_employee: 'but was not found as employee on the system pero, so the system only added the others evaluators',
  evaluator_type_incorrect: 'but this type of evaluator is wrong, so the system added the rest',
  are_you_sure_continue_warnings: 'Do you want to continue with de warnings?',
  remember_warnings_msg: 'Remember if you continue the data will be keep as the warnings said, we recomend to repeat the proccess',
  incorrect_file: 'Please upload a valid file',
  operation_failed: 'Unsuccessful operation, it is recommended:',
  paid_measuring: 'Measuring has been paid already.',
  link: 'Copy link',
  copy_invitation_url: 'Copy the link and send it to your employees without email to complete the assessment.',
  link_copied: 'Link copied.',
  custom_questionnaire_info: 'If you wish to modify any of the statements in the questionnaire, you can do so, as long as the OCC DML 360º model is maintained. Please contact us through our email info@occsolutions.org',
  options: 'Options',
  generate_report: 'Generate reports',
  sending_reminders: 'Send reminders',
  close_evaluation: 'Close assessment',
  close_evaluation_q: 'Are you sure to close assessment?',
  send_reminders_q: 'Are you sure to send reminders?',
  reminders_sent_succesfully: 'Reminders sent succesfully',
  evaluation_closed_succesfully: 'Assessment closed succesfully',
  fileExist: 'Attached video',
  deleteFile: 'Delete file',
  fileExistNote: 'To replace the attached file it is not necessary to delete the previous one.',
  evaluatedExists: 'There are evaluated in the file that are already in the list',
  confirmation_modal_title: 'Save responses',
  confirmation_modal_des: 'Be sure to verify your answers, since once saved they cannot be modified',
  no_leaders: 'No leaders',
  no_pairs: 'No pairs',
  no_dependents: 'No dependents'
}
