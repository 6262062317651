
<template>
  <v-dialog
    v-model="show"
    persistent
    max-width="600px">
    <v-card>
      <v-toolbar light flat>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon light @click="hideModal">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <div class="text-xs-center">
          <slot name="question"></slot>
        </div>
        <div v-if="!reversible">
          <br>
          <div class="text-xs-center"> {{ $t('input.no_reversible_msg')}} </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="hideModal">
          <v-icon v-if="icon && $vuetify.breakpoint.smAndUp">close</v-icon>&nbsp;{{ $t('input.cancel') }}
        </v-btn>
        <x-async-btn
          :color="color"
          class="white--text"
          :action="confirm"
        ><v-icon v-if="icon && $vuetify.breakpoint.smAndUp">{{ icon }}</v-icon>&nbsp;{{ btnSave || $t('input.save') }}
        </x-async-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import Vue from 'vue'

export default Vue.extend({
  props: {
    show: Boolean,
    reversible: Boolean,
    title: String,
    icon: String,
    action: Function,
    color: {
      type: String,
      default: 'primary'
    },
    btnSave: String
  },
  methods: {
    confirm () {
      return this.action()
        .then(() => this.hideModal())
    },
    hideModal () {
      this.$emit('close')
    }
  }
})
</script>
