
export default {
  tokens_list: 'OCC Tokens',
  acquire: 'Acquire',
  total_amount: 'Total Amount',
  tool: 'Tool',
  calculator: 'Calculator',
  operations: 'Operations',
  operation_detail: 'Operation detail',
  operation_details: 'Operation details',
  transaction_number: 'N° Transaction',
  success_transaction: 'Transaction completed successfully.',
  operations_group: 'Group operations',
  created_movement: 'The adquisition was realized successfully.',
  created_transfer: 'The transfer was realized successfully.',
  created_authorization: 'Authorization was created successfully.',
  eliminated_authorization: 'Authorization was removed successfully.',
  authorization_message: 'authorize',
  authorization_message_p1: 'By enabling this option',
  authorization_message_p2: 'of using your OCC Tokens if required when making a consumption and not having enough balance.',
  invalid_quantity: 'Invalid quantity.',
  balance: 'Balance',
  add_new: 'Agregar Nueva',
  date: 'Date',
  token_qty: 'OCC Tokens quantity',
  actions: 'Actions',
  no_data: 'There are none operations to display',
  purchase: 'OCC Token Acquisition',
  create: 'Account creation',
  transfer: 'Transfer',
  receiving: 'Receiving',
  sending: 'Sending',
  received: 'received',
  received_female: 'received',
  sended: 'sended',
  sended_female: 'sended',
  spend: 'Spend',
  consumption: 'Consumption',
  by: ' by ',
  of: ' of ',
  authorization: 'Authorization',
  authorized: 'authorized',
  authorized_female: 'authorized',
  medition: 'Medition',
  download: 'Download',
  downloads: 'Downloads',
  person: 'Person',
  persons: 'Persons',
  poblation: 'Poblation',
  annual: 'Annual',
  biannual: 'Biannual',
  quarterly: 'Quarterly',
  monthly: 'Monthly',
  biweekly: 'Biweekly',
  weekly: 'Weekly',
  diary: 'Diary',
  one_time: 'One time',
  products: 'Products',
  creation: 'creation',
  collaborators_lowercase: 'collaborators',
  filter_by: 'Filter by...',
  look_for: 'Look for a transaction number',
  consumer_transactions: 'Consumer transactions',
  authorization_transactions: 'Consumer transactions',
  movements_between_accounts: 'Movements between accounts',
  customer_balance: 'Customer Balace',
  enterprise_balance: 'Enterprise Balance',
  group_balance: 'Group Balance',
  administrator: 'Administrator',
  since: 'since',
  to: 'to',
  for: 'for',
  see_all_companies: 'See all companies',
  see_all_members: 'See all members',
  no_operations: 'There are no operations to show.',
  cost: 'Cost',
  calculator_closing_message: 'Are you sure you want to cancel the operation?',
  return: 'Return',
  confirm_close_calculator: "Yes, I'm sure",
  required: 'Required',
  times: 'times',
  leaders: 'leaders',
  evaluation: 'Evaluation',
  total: 'Total',
  services: 'Services',
  modality: 'Modality',
  recomendation: 'Recomendation',
  unit: 'OCC Tokens',
  money_unit: 'USD'
}
