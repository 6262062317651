
import pdfUtil from '../utils_old/pdf'
import circle64 from '../base64Files_old/circle64'
import diamond64 from '../base64Files_old/diamond64'

export default {
  methods: {
    generateParagraph (translate, repeat, fontSize, italic) {
      let array = []
      for (let index = 0; index < repeat; index++) {
        array.push(
          {
            text: this.$t(`${translate + '[' + index + ']'}`),
            alignment: 'justify',
            margin: [0, 0, 16, 12],
            color: '#6d7a7a',
            lineHeight: 1.5,
            fontSize: fontSize,
            italics: italic
          }
        )
      }
      return array
    },
    generateParagraphSlim (translate, repeat, fontSize, italic, mb, lineHeight) {
      let array = []
      for (let index = 0; index < repeat; index++) {
        array.push(
          {
            text: this.$t(`${translate + '[' + index + ']'}`),
            alignment: 'justify',
            margin: [0, 0, 16, mb],
            color: '#6d7a7a',
            lineHeight: lineHeight,
            fontSize: fontSize,
            italics: italic
          }
        )
      }
      return array
    },
    generateBoldList (title, text, fontSize) {
      let array = []
      array.push(
        {
          text: [
            { text: title, bold: true },
            text
          ],
          alignment: 'justify',
          margin: [30, 0, 16, 5],
          color: '#6d7a7a',
          lineHeight: 1.1,
          fontSize: fontSize
        }
      )
      return array
    },
    generateTitleText (translate, repeat, fontSize, pageBreak, margin) {
      let array = []
      array.push(
        {
          text: this.$t(`${translate + '[' + 0 + ']'}`),
          alignment: 'justify',
          margin: margin !== undefined ? margin : [0, 0, 16, 5],
          color: '#6d7a7a',
          lineHeight: 1.3,
          fontSize: fontSize,
          bold: true,
          pageBreak: pageBreak
        }
      )
      for (let index = 1; index < repeat + 1; index++) {
        array.push(
          {
            text: this.$t(`${translate + '[' + index + ']'}`),
            alignment: 'justify',
            margin: [0, repeat === 4 && index === 2 ? 0 : 0, 16, repeat < 4 && repeat === index ? 15 : 5],
            color: '#6d7a7a',
            lineHeight: 1.3,
            fontSize: fontSize,
            pageBreak: repeat === 4 && index === 2 ? 'before' : ''
          }
        )
      }
      return array
    },
    $generateInformationContent () {
      const scoresMeaning = []
      const relationsMeaning = []
      const tablesMeaning = []
      const relations = [
        this.$t(`individualReport.me`),
        this.$t(`individualReport.leaders`),
        this.$t(`individualReport.pairs`),
        this.$t(`individualReport.dependents`),
        this.$t(`individualReport.others`)
      ]
      const graphText = [
        { title: this.$t('individualReport.bars'), text: this.$t('individualReport.bars_text') },
        { title: this.$t('individualReport.score'), text: this.$t('individualReport.score_text') },
        { title: this.$t('individualReport.ten_percent'), text: this.$t('individualReport.ten_percent_text') },
        { title: this.$t('individualReport.leaders_average'), text: this.$t('individualReport.leaders_average_text') },
        { title: this.$t('individualReport.trend'), text: this.$t('individualReport.trend_text') },
        { title: this.$t('individualReport.plus_minus_symbols'), text: this.$t('individualReport.plus_minus_symbols_text') },
        { title: this.$t('individualReport.others_symbols'), text: this.$t('individualReport.others_symbols_text') },
        { title: this.$t('individualReport.others_symbols'), text: this.$t('individualReport.bold_text') }
      ]
      for (let i = 0; i < 6; i++) {
        scoresMeaning.push([
          { text: `(${i + 1})`, margin: [10, 0, 10, 0], border: [true, true, true, true], bold: false, style: { color: '#6d7a7a' }, alignment: 'center' },
          { text: this.$t(`individualReport.scores_meaning[${i}]`), margin: [15, 0, 15, 0], border: [true, true, true, true], bold: false, style: { color: '#6d7a7a' }, alignment: 'left' }
        ])
      }
      for (let i = 0; i < 5; i++) {
        relationsMeaning.push([
          { text: relations[i], margin: [10, -3, 10, -3], border: [true, true, true, true], bold: true, style: { color: '#6d7a7a' }, alignment: 'center' },
          { text: this.$t(`individualReport.relations_texts[${i}]`), margin: [15, -3, 15, -3], border: [true, true, true, true], bold: false, style: { color: '#6d7a7a' }, alignment: 'left' }
        ])
      }
      for (let i = 0; i < 8; i++) {
        if (i === 2 || i === 3) {
          const left = i === 2 ? 190 : 110
          tablesMeaning.push([
            { text: graphText[i].title.toUpperCase(), margin: [5, 0, 5, 0], border: [true, true, true, true], bold: true, style: { color: '#6d7a7a' }, alignment: 'center' },
            {
              table: {
                body: [
                  [
                    { text: graphText[i].text, border: [false, false, false, false] }
                  ],
                  [
                    { image: i === 2 ? diamond64 : circle64, width: 10, height: 10, margin: [left, -18, -20, 0], border: [false, false, false, false] }
                  ]
                ]
              },
              margin: [0, -2, 0, -2],
              border: [true, true, true, true],
              bold: false,
              style: { color: '#6d7a7a' },
              alignment: 'left'
            }
          ])
        } else {
          tablesMeaning.push([
            i === 7
              ? {
                table: {
                  body: [
                    [
                      { text: this.$t('individualReport.higher_bold'), margin: [-15, 0, -15, 0], border: [false, false, false, false], bold: true, style: { color: '#6d7a7a' }, fontSize: 6 }
                    ],
                    [
                      { text: this.$t('individualReport.lower_sub'), margin: [-15, -5, -15, 0], border: [false, false, false, false], decoration: 'underline', style: { color: '#6d7a7a' }, fontSize: 6 }
                    ]
                  ]
                },
                border: [true, true, true, true],
                margin: [5, 0, 5, 0]
              }
              : { text: graphText[i].title.toUpperCase(), margin: [10, 0, 10, 0], border: [true, true, true, true], bold: true, style: { color: '#6d7a7a' }, alignment: 'center' },
            { text: graphText[i].text, margin: [5, -2, 5, -2], border: [true, true, true, true], bold: false, style: { color: '#6d7a7a' }, alignment: 'left' }
          ])
        }
      }
      const legend = [
        this.$t('individualReport.no_answer'),
        this.$t('individualReport.data_range'),
        this.$t('individualReport.score'),
        this.$t('individualReport.ten_percent'),
        this.$t('individualReport.leaders_average'),
        this.$t('individualReport.higher_bold'),
        this.$t('individualReport.lower_sub')
      ]
      const evaluator = [
        this.$t('individualReport.me'),
        this.$t('individualReport.leaders'),
        this.$t('individualReport.pairs'),
        this.$t('individualReport.dependents'),
        this.$t('individualReport.others')
      ]
      const asPersonResponsability = []
      const dataFiltereds = [
        [{ total: 4.00, tenPercent: 4.88, averageLeaders: 3.99, previous: 1.00, individualScores: [2, 5] }],
        [{ total: 4.25, tenPercent: 4.69, averageLeaders: 4.50, previous: -0.69, individualScores: [1, 6] }],
        [{ total: 5.00, tenPercent: 5.00, averageLeaders: 5.00, previous: 1.16, individualScores: [1, 5] }],
        [{ total: 3.90, tenPercent: 5.23, averageLeaders: 4.20, previous: -0.23, individualScores: [3, 6] }],
        [{ total: 4.10, tenPercent: 5.00, averageLeaders: 4.88, previous: 1.09, individualScores: [2, 5] }]
      ]
      const dataTotalFiltereds = [
        { total: 4.00 },
        { total: 4.25 },
        { total: 5.00 },
        { total: 3.90 },
        { total: 4.10 }
      ]

      for (let j = 0; j < 5; j++) {
        const dataFiltered = JSON.parse(JSON.stringify(dataFiltereds[j]))
        asPersonResponsability.push([
          { text: this.$t('individualReport.as_person'), rowSpan: 5, color: '#6d7a7a', margin: [0, 50, 0, 0], border: [true, true, true, true] },
          { text: evaluator[j], bold: true, color: '#6d7a7a', margin: [0, 0, 0, 0], border: [true, true, true, true] },
          pdfUtil.generateXDCChartCover(dataFiltered, 0, dataFiltered[0].averageLeaders, 'detail'),
          pdfUtil.higherLowerRow(dataTotalFiltereds, dataFiltered[0].total, '#6d7a7a', [0, 4, 0, 0], 'center', [true, true, true, true]),
          { text: this.round(dataFiltered[0].tenPercent, 2), bold: false, color: '#6d7a7a', margin: [0, 4, 0, 0], border: [true, true, true, true], alignment: 'center' },
          { text: this.round(dataFiltered[0].averageLeaders, 2), bold: false, color: '#6d7a7a', margin: [0, 4, 0, 0], border: [true, true, true, true], alignment: 'center' },
          { text: `${this.hasPrevious ? this.round(dataFiltered[0].previous, 2) : '--'}`, bold: false, color: '#6d7a7a', margin: [0, 4, 0, 0], border: [true, true, true, true], alignment: 'center' }
        ])
      }
      return [
        {
          table: {
            widths: [479],
            body: [
              [{ text: `${this.$t('individualReport.for_what_leadership_important')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 6, 0, 6] }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [-40, 0, 0, 20],
          pageBreak: 'before'
        },
        ...this.generateParagraphSlim('individualReport.for_what_leadership_important_texts', 5, 11, false, 30, 1.2),
        {
          table: {
            widths: [479],
            body: [
              [{ text: `${this.$t('individualReport.for_what_360_leadership_measure_title')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 6, 0, 6] }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [-40, 0, 0, 10],
          pageBreak: 'before'
        },
        ...this.generateParagraphSlim('individualReport.for_what_360_leadership_measure', 2, 11, false, 5, 1.1),
        ...this.generateBoldList(this.$t('individualReport.preparation[0]'), this.$t('individualReport.preparation[1]'), 11),
        ...this.generateBoldList(this.$t('individualReport.commitment[0]'), this.$t('individualReport.commitment[1]'), 11),
        ...this.generateBoldList(this.$t('individualReport.feedback[0]'), this.$t('individualReport.feedback[1]'), 11),
        ...this.generateBoldList(this.$t('individualReport.follow_up[0]'), this.$t('individualReport.follow_up[1]'), 11),
        {
          text: this.$t('individualReport.for_what_360_leadership_measure2'),
          alignment: 'justify',
          margin: [0, 0, 16, 5],
          color: '#6d7a7a',
          lineHeight: 1.1,
          fontSize: 11,
          italics: true
        },
        {
          table: {
            widths: [479],
            body: [
              [{ text: `${this.$t('individualReport.what_is_leadership_model')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 6, 0, 6] }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [-40, 10, 0, 10]
        },
        {
          text: `${this.$t('individualReport.what_is_leadership_model_text')}`,
          alignment: 'justify',
          margin: [0, 0, 22, 0],
          color: '#6d7a7a',
          lineHeight: 1.1
        },
        {
          image: this.stampBase64,
          width: 258,
          height: 258,
          alignment: 'center',
          margin: [30, -4, 0, -20]
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: '#97b526'
          },
          table: {
            widths: [479],
            body: [
              [{ text: `${this.$t('individualReport.direction')}`, margin: [0, 5, 0, 5], bold: true, color: '#fff' }]
            ]
          },
          margin: [100, 15, 20, 20],
          pageBreak: 'before'
        },
        ...this.generateParagraph('individualReport.direction_texts', 5, 11, false),
        {
          layout: {
            defaultBorder: '',
            fillColor: '#45c0a3'
          },
          table: {
            widths: [479],
            body: [
              [{ text: `${this.$t('individualReport.mobilization')}`, margin: [0, 5, 0, 5], bold: true, color: '#fff' }]
            ]
          },
          margin: [100, 15, 20, 20],
          pageBreak: 'before'
        },
        ...this.generateParagraph('individualReport.mobilization_texts', 5, 11, false),
        {
          layout: {
            defaultBorder: '',
            fillColor: '#3397d9'
          },
          table: {
            widths: [479],
            body: [
              [{ text: `${this.$t('individualReport.achievement')}`, margin: [0, 5, 0, 5], bold: true, color: '#fff' }]
            ]
          },
          margin: [100, 15, 20, 20],
          pageBreak: 'before'
        },
        ...this.generateParagraph('individualReport.achievement_texts', 6, 11, false),
        {
          table: {
            widths: [479],
            body: [
              [{ text: `${this.$t('individualReport.how_is_the_tool_title')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 6, 0, 6] }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [-40, 0, 0, 20],
          pageBreak: 'before'
        },
        ...this.generateParagraph('individualReport.how_is_the_tool', 1, 11, false),
        {
          text: `${this.$t('individualReport.what_is_the_utility')}`,
          alignment: 'justify',
          margin: [0, 0, 22, 10],
          color: '#6d7a7a',
          fontSize: 12,
          bold: true
        },
        ...this.generateParagraph('individualReport.what_is_the_utility_text', 3, 11, false),
        {
          text: `${this.$t('individualReport.how_it_works')}`,
          alignment: 'justify',
          margin: [0, 0, 22, 10],
          color: '#6d7a7a',
          fontSize: 12,
          bold: true
        },
        ...this.generateParagraph('individualReport.how_it_works_text', 2, 11, false),
        {
          layout: {
            defaultBorder: '',
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            body: [
              ...scoresMeaning
            ]
          },
          margin: [150, 0, 15, 10],
          border: [false, false, false, true],
          alignment: 'center',
          fontSize: 10
        },
        {
          table: {
            widths: [479],
            body: [
              [{ text: `${this.$t('individualReport.how_to_interpret_the_report_title')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 6, 0, 6] }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [-40, 0, 0, 20],
          pageBreak: 'before'
        },
        ...this.generateParagraph('individualReport.how_to_interpret_the_report', 2, 11, false),
        {
          layout: {
            defaultBorder: '',
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            body: [
              ...relationsMeaning
            ]
          },
          margin: [0, 0, 15, 10],
          border: [false, false, false, true],
          alignment: 'center',
          fontSize: 9
        },
        // example table
        pdfUtil.generateDmlLegendIndividualExample(legend),
        {
          layout: {
            defaultBorder: '',
            border: [false, false, false, true],
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#BFBFBF' : null
            }
          },
          table: {
            headerRows: 1,
            widths: ['15%', '14%', '39%', '8%', '8%', '8%', '8%'],
            body: [
              [
                { text: `${this.$t('individualReport.responsability')}`, bold: true, color: '#fff', margin: [0, 0, 0, -5], border: [true, true, true, true], fontSize: 9 },
                { text: `${this.$t('individualReport.evaluator')}`, bold: true, color: '#fff', border: [true, true, true, true], margin: [0, 0, 0, -5], fontSize: 9 },
                { layout: { defaultBorder: '', fillColor: '#BFBFBF' },
                  table: {
                    headerRows: 1,
                    body: [[
                      { text: '1\n|', margin: [10, -5, 15, -5] },
                      { text: '2\n|', margin: [0, -5, 15, -5] },
                      { text: '3\n|', margin: [0, -5, 15, -5] },
                      { text: '4\n|', margin: [0, -5, 15, -5] },
                      { text: '5\n|', margin: [0, -5, 15, -5] },
                      { text: '6\n|', margin: [0, -5, 15, -5] }
                    ]]
                  },
                  bold: true,
                  style: { color: '#fff' },
                  border: [true, true, true, true],
                  margin: [0, 0, 0, -5]
                },
                { text: this.$t('individualReport.score'), margin: [0, 0, 0, -5], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 },
                { text: this.$t('individualReport.ten_percent'), margin: [0, 0, 0, -5], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 },
                { text: this.$t('individualReport.leaders_average'), margin: [0, 0, 0, -5], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 },
                { text: this.$t('individualReport.trend'), margin: [-3, 0, -3, -5], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 }
              ],
              ...asPersonResponsability
            ]
          },
          margin: [-10, 0, 0, 10],
          border: [false, false, false, true],
          alignment: 'center',
          fontSize: 9
        },
        {
          layout: {
            defaultBorder: '',
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            body: [
              [
                { text: this.$t('individualReport.references_text'), margin: [10, 0, 10, 0], colSpan: 2, border: [true, true, true, true], style: { color: '#6d7a7a' }, alignment: 'center' },
                {}
              ],
              ...tablesMeaning
            ],
            widths: [73, 'auto']
          },
          margin: [-15, 0, 0, 10],
          border: [true, true, true, true],
          alignment: 'center',
          fontSize: 8
        },
        {
          text: `${this.$t('individualReport.how_to_interpret_the_report2[0]')}`,
          alignment: 'justify',
          margin: [0, 0, 16, 15],
          color: '#6d7a7a',
          lineHeight: 1.2,
          pageBreak: 'before'
        },
        {
          text: [
            { text: `${this.$t('individualReport.how_to_interpret_the_report2[1]')}`, bold: true },
            `${this.$t('individualReport.how_to_interpret_the_report2[2]')}`
          ],
          alignment: 'justify',
          margin: [0, 0, 16, 15],
          color: '#6d7a7a',
          lineHeight: 1.2
        },
        {
          text: `${this.$t('individualReport.how_to_interpret_the_report2[3]')}`,
          alignment: 'justify',
          margin: [0, 0, 16, 15],
          color: '#6d7a7a',
          lineHeight: 1.2
        },
        ...this.generateTitleText('individualReport.higher_scores', 1, 11),
        ...this.generateTitleText('individualReport.lower_scores', 1, 11),
        ...this.generateTitleText('individualReport.process_results', 1, 11),
        ...this.generateTitleText('individualReport.responsability_results', 1, 11),
        ...this.generateTitleText('individualReport.behavior_results', 1, 11),
        ...this.generateTitleText('individualReport.confirmed_fo', 4, 11),
        {
          text: `${this.$t('individualReport.gaps_analysis_title')}`,
          alignment: 'justify',
          margin: [0, 20, 22, 15],
          color: '#6d7a7a',
          fontSize: 12,
          bold: true
        },
        ...this.generateTitleText('individualReport.gaps_analysis', 2, 11),
        ...this.generateTitleText('individualReport.gaps_analysis2', 1, 11),
        ...this.generateTitleText('individualReport.dispersion_analysis', 1, 11),
        ...this.generateTitleText('individualReport.trends_report', 1, 11),
        ...this.generateTitleText('individualReport.open_questions', 1, 11),
        {
          text: `${this.$t('individualReport.thanks_message')}`,
          alignment: 'center',
          margin: [30, 20, 30, 15],
          color: '#2e8fd0',
          fontSize: 12,
          bold: true
        }
      ]
    }
  }
}
