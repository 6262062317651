
<template>
  <div style="display:inline;">
    <v-btn large
      color="primary"
      :loading="lockPdfButton"
      :disabled="lockPdfButton || !disableButton"
      @click="openDialog()"
    >
      <v-icon>mdi-file-pdf</v-icon>
      {{ $t('reports.general_report') }}
    </v-btn>

    <x-confirm-spend-dialog
      :confirmText="$t('organizationalReport.confirm_report_title')"
      :costText="$t('organizationalReport.report_cost')"
      :showModalConfirm="showModalConfirm"
      :balance="balance"
      :price="price"
      :noBalanceResponse="noBalanceResponse"
      :disableButtonModal="disableButton"
      @result="verifySpend"
      @update="checkBalance">
    </x-confirm-spend-dialog>

    <!-- Dialog already Generated Report -->
    <x-dialog-already-generated-report
      :show.sync="alreadyGeneratedModal"
      @close="alreadyGeneratedModal = false"
    ></x-dialog-already-generated-report>
  </div>
</template>

<script>
import Vue from 'vue'

import evaluationService from '../../services/evaluations'
import XDialogAlreadyGeneratedReport from './components/dialog-already-generated-report'

export default Vue.extend({
  name: 'thread-organizational-report',
  components: {
    XDialogAlreadyGeneratedReport
  },
  props: {
    alreadyGenerated: Boolean,
    pollId: String,
    disableButton: Boolean
  },
  data () {
    return {
      lockPdfButton: false,
      alreadyGeneratedModal: false,
      showModalConfirm: false,
      disableButtonModal: true,
      noBalanceResponse: false,
      operationsLink: '',
      balance: 0,
      price: 0
    }
  },
  watch: {
    disableButton () {
      this.disableButtonModal = this.disableButton
    }
  },
  methods: {
    openDialog () {
      if (this.alreadyGenerated) {
        this.alreadyGeneratedModal = true
      } else {
        this.openReportOneById()
      }
    },
    openReportOneById () {
      this.$store.dispatch('loading/show')
      this.lockPdfButton = true
      return this.checkBalance()
    },
    runOpenReport () {
      this.$store.dispatch('loading/show')
      return evaluationService.openReportOneById(this.pollId)
        .then(() => {
          this.$store.dispatch('alert/success', this.$t('organizationalReport.opetaion_init'))
          this.$store.dispatch('loading/hide')
          this.showModalConfirm = false
          setTimeout(this.refreshPage, 2000)
        })
        .catch((err) => {
          if (err.code === 'suite-fail/evaluation/spend-fail') {
            this.noBalanceResponse = true
            this.disableButtonModal = true
            this.$store.dispatch('alert/error', this.$t('errors.no_balance'))
          } else {
            this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
          }
          this.$store.dispatch('loading/hide')
          this.lockPdfButton = false
        })
    },
    checkBalance () {
      return evaluationService.checkBalance('rood')
        .then((res) => {
          this.balance = res.balance
          this.price = res.productService
          this.showModalConfirm = true
          this.$store.dispatch('loading/hide')
        })
    },
    verifySpend ($event) {
      return $event ? this.runOpenReport() : (this.showModalConfirm = false)
    },
    refreshPage () {
      window.location.reload()
    }
  }
})
</script>
