
<template>
  <v-expansion-panels tile>
    <v-expansion-panel
      v-for="(thread, $index) in threads"
      :key="$index"
    >
      <v-expansion-panel-header disable-icon-rotate>
        <span class="expansion-title text-uppercase grey--text text--darken-3">
          <span v-if="thread.data.type === 'organizational'">
            {{ $t('reports.required_general_title') }}
          </span>
          <span v-else-if="thread.data.type === 'individual'">
            {{ $t('reports.individual_title') }}
          </span>
          <span v-else>
            {{ $t('reports.demographic_title') }}
          </span>
        </span>
        <template v-slot:actions>
          <span
            v-if="thread.data.progress !== 100"
            class="caption d-inline-block mt-1 mr-2 grey--text text--darken-1 font-weight-bold"
          >
            {{ thread.data.progress }}%
          </span>
          <v-icon color="primary" v-if="thread.status === 'pending'">mdi-progress-clock</v-icon>
          <v-icon color="primary" v-else-if="thread.status === 'in_progress' || thread.status === 'in_action'">mdi-progress-alert</v-icon>
          <v-icon color="red" v-else-if="thread.status === 'failed'">mdi-alert-circle</v-icon>
          <v-icon color="primary" v-else>mdi-check-circle</v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row v-if="thread.data.type === 'by_demographic'">
          <v-col class="text-center pt-0">
            <v-chip
              class="ma-1 grey--text text--darken-3"
              v-for="(demographic, $i) in Object.keys(getDemographicChips(thread.data.criteria))"
              :key="$i"
            >
              <span class="font-weight-bold body-2">
                {{ demographic }}
              </span>
              <p
                v-for="(child, $j) in getDemographicChips(thread.data.criteria)[demographic].childs"
                :key="$j"
                class="mb-0 ml-1 caption"
              >
                ({{ child }})
              </p>
            </v-chip>
          </v-col>
        </v-row>
        <v-row v-if="thread.status !== 'completed'">
          <v-col cols="12">
            <v-progress-linear
              color="light-blue"
              height="10"
              :value="thread.data.progress"
              striped
            ></v-progress-linear>
          </v-col>
        </v-row>
        <v-row v-if="thread.data.type === 'individual'">
          <v-col class="text-center pt-0">
            <v-chip large class="ma-1 px-7 grey--text text--darken-3">
              <span class="font-weight-bold body-2">
                {{ threadEvaluatedName(thread.data.individualReference) }}
              </span>
            </v-chip>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="text-center pt-0">
            <x-old-organizational-report-exec
              v-if="thread.data.type === 'organizational'"
              :thread="thread"
            ></x-old-organizational-report-exec>
            <x-old-individual-report-exec
              v-else-if="thread.data.type === 'individual'"
              :thread="thread"
              :evaluated-id="thread.data.individualReference"
            ></x-old-individual-report-exec>
            <x-thread-demographic-report-exec
              v-else
              :demographic-cuts="demographics"
              :thread="thread"
            ></x-thread-demographic-report-exec>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<style lang="scss">
  .expansion-title > span {
    font-size: 14px !important;
  }
</style>

<script>

import { mapState } from 'vuex'

import XOldOrganizationalReportExec from './organizational_report_old'
// import XThreadOrganizationalReportExec from './thread_organizational_report_exec'

import XOldIndividualReportExec from './individual_report_old'
// import XThreadIndividualReportExec from './thread_individual_report_exec'

import XThreadDemographicReportExec from './thread_demographic_report_exec'

export default {
  name: 'report-threads',
  components: {
    XOldOrganizationalReportExec,
    XOldIndividualReportExec,
    XThreadDemographicReportExec
  },
  props: {
    individuals: Array,
    threads: Array,
    demographics: Object
  },
  data () {
    return {
      //
    }
  },
  watch: {
    //
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    })
  },
  methods: {
    threadEvaluatedName (evaluatedId) {
      const foundEvaluated = this.individuals.find(ind => ind.employee.employee.id === evaluatedId)
      return foundEvaluated ? foundEvaluated.employee.employee.text : 'N/A'
    },
    getTranslation (tag) {
      return this.$t(`reports.demographicCuts.${tag}`)
    },
    getDemographicChips (criteria) {
      const labels = []
      const formattedCriteria = { filters: criteria }
      if (formattedCriteria && formattedCriteria.filters) {
        const filters = formattedCriteria.filters
        for (const filter of Object.keys(filters)) {
          if (filters[filter].length) {
            if (filter === 'antiquity' || filter === 'age') {
              const name = this.getTranslation(filter)
              labels[name] = {
                childs: []
              }
              let tmp = []
              for (const flt of filters[filter]) {
                const match = this.demographics[filter][this.user.lang].find(fl =>
                  fl.range.min === flt.min && fl.range.max === flt.max
                )
                if (match) {
                  tmp.push(match.name)
                }
              }
              labels[name].childs.push(tmp.join(', '))
            } else if (this.demographics[filter]) {
              const demographicToFilter = this.demographics[filter]
              const name = this.getTranslation(filter)
              labels[name] = {
                childs: []
              }
              const match = demographicToFilter.filter(fl => {
                return filters[filter].includes(fl.id)
              }).map(x => {
                return filter === 'headquarter' ? x.name : x.translate.label
              }).join(', ')
              if (match.length) {
                labels[name].childs.push(match)
              }
            }
          }
        }
      }
      return labels
    }
  }
}
</script>
