// TODO
/*
  Gráfica por rango
  10% superior por dimensión. listo
  Dispersión por responsabilidad. listo
  Probar puntaje anterior y tendencia
  Ranking tendencia
  Tabla de respuestas. listo
*/
import evaluationService from '../../../services/evaluations'
export default {
  methods: {
    setScores (res) {
      let count = 0
      let scores = []
      let scoresFiltered = []
      let autoScoresFiltered = []
      let autoPopulationFiltered = 0
      let noAutoScores = []
      let noAutoScoresFiltered = []
      let noAutoPopulation = 0
      let noAutoPopulationFiltered = 0
      let leadersScores = []
      let leadersScoresFiltered = []
      let pairsScores = []
      let pairsScoresFiltered = []
      let dependentsScores = []
      let dependentsScoresFiltered = []
      let scoresResponsability = []
      let scoresResponsabilityFiltered = []
      let autoScoresResponsabilityFiltered = []
      let noAutoScoresResponsability = []
      let noAutoScoresResponsabilityFiltered = []
      let leadersResponsability = []
      let leadersResponsabilityFiltered = []
      let pairsResponsability = []
      let pairsResponsabilityFiltered = []
      let dependentsResponsability = []
      let dependentsResponsabilityFiltered = []
      const generalEvaluation = res.questionnaire.evaluations.generalEvaluation

      // Orden en el que se encuentran las responsabilidades dentro res.questionnaire.evaluations.(autoEvaluation || generalEvaluation)
      const headers = ['as_person', 'management', 'mobilization', 'achievement']

      // crea constantes para obtenes los objetos básicos de estructura para los contadores y acumuladores
      const getBasicScore = (name, questionsScores) => {
        const basic = { name, questions: [], total: 0, individualScores: [], tenPercent: 0, nullCount: 0 }
        if (questionsScores) {
          basic.questionsScores = []
        }
        return basic
      }
      const getBasicResponsability = (name) => ({ name, responsability: [] })
      const getBasicResponsabilityScore = (name, index, questionsScores) => {
        const basic = { name, score: 0, index, questions: [], nullCount: 0, dispersion: 0, tenPercent: 0, individualScores: [] }
        if (questionsScores) {
          basic.questionsScores = []
        }
        return basic
      }
      const getBasicResponsabilityScoreQuestion = (responsability, question, index) => {
        return { score: 0, index, question, tenPercent: 0, nullCount: 0, dispersion: 0, responsability, individualScores: [] }
      }

      // Establecer las estructuras generales y filtradas
      for (let i = 0; i < headers.length; i++) {
        // General Score
        scores.push(getBasicScore(headers[i], true))
        scoresResponsability.push(getBasicResponsability(headers[i]))
        noAutoScores.push(getBasicScore(headers[i]))
        noAutoScoresResponsability.push(getBasicResponsability(headers[i]))
        leadersScores.push(getBasicScore(headers[i]))
        leadersResponsability.push(getBasicResponsability(headers[i]))
        pairsScores.push(getBasicScore(headers[i]))
        pairsResponsability.push(getBasicResponsability(headers[i]))
        dependentsScores.push(getBasicScore(headers[i]))
        dependentsResponsability.push(getBasicResponsability(headers[i]))

        // Filtered score
        scoresFiltered.push(getBasicScore(headers[i], true))
        scoresResponsabilityFiltered.push(getBasicResponsability(headers[i]))
        autoScoresFiltered.push(getBasicScore(headers[i], true))
        autoScoresResponsabilityFiltered.push(getBasicResponsability(headers[i]))
        noAutoScoresFiltered.push(getBasicScore(headers[i]))
        noAutoScoresResponsabilityFiltered.push(getBasicResponsability(headers[i]))
        leadersScoresFiltered.push(getBasicScore(headers[i]))
        leadersResponsabilityFiltered.push(getBasicResponsability(headers[i]))
        pairsScoresFiltered.push(getBasicScore(headers[i]))
        pairsResponsabilityFiltered.push(getBasicResponsability(headers[i]))
        dependentsScoresFiltered.push(getBasicScore(headers[i]))
        dependentsResponsabilityFiltered.push(getBasicResponsability(headers[i]))

        // set responsability structure
        let counter = 0
        for (const j of generalEvaluation[i].responsability) {
          const idx = j.index - 1
          // General responsability score
          scoresResponsability[i].responsability[idx] = getBasicResponsabilityScore(j[this.user.lang], j.index, true)
          noAutoScoresResponsability[i].responsability[idx] = getBasicResponsabilityScore(j[this.user.lang], j.index)
          leadersResponsability[i].responsability[idx] = getBasicResponsabilityScore(j[this.user.lang], j.index)
          pairsResponsability[i].responsability[idx] = getBasicResponsabilityScore(j[this.user.lang], j.index)
          dependentsResponsability[i].responsability[idx] = getBasicResponsabilityScore(j[this.user.lang], j.index)

          // Filtered responsability score
          scoresResponsabilityFiltered[i].responsability[idx] = getBasicResponsabilityScore(j[this.user.lang], j.index, true)
          autoScoresResponsabilityFiltered[i].responsability[idx] = getBasicResponsabilityScore(j[this.user.lang], j.index, true)
          noAutoScoresResponsabilityFiltered[i].responsability[idx] = getBasicResponsabilityScore(j[this.user.lang], j.index, true)
          leadersResponsabilityFiltered[i].responsability[idx] = getBasicResponsabilityScore(j[this.user.lang], j.index, true)
          pairsResponsabilityFiltered[i].responsability[idx] = getBasicResponsabilityScore(j[this.user.lang], j.index, true)
          dependentsResponsabilityFiltered[i].responsability[idx] = getBasicResponsabilityScore(j[this.user.lang], j.index, true)

          for (let k = 0; k < j.questions.length; k++) {
            const qIdx = counter + 1
            // General
            scoresResponsability[i].responsability[idx].questions.push(qIdx)
            noAutoScoresResponsability[i].responsability[idx].questions.push(qIdx)
            leadersResponsability[i].responsability[idx].questions.push(qIdx)
            pairsResponsability[i].responsability[idx].questions.push(qIdx)
            dependentsResponsability[i].responsability[idx].questions.push(qIdx)
            // Filtered
            scoresResponsabilityFiltered[i].responsability[idx].questions.push(qIdx)
            autoScoresResponsabilityFiltered[i].responsability[idx].questions.push(qIdx)
            noAutoScoresResponsabilityFiltered[i].responsability[idx].questions.push(qIdx)
            leadersResponsabilityFiltered[i].responsability[idx].questions.push(qIdx)
            pairsResponsabilityFiltered[i].responsability[idx].questions.push(qIdx)
            dependentsResponsabilityFiltered[i].responsability[idx].questions.push(qIdx)
            // General
            scores[i].questions[counter] = getBasicResponsabilityScoreQuestion(j[this.user.lang], j.questions[k][this.user.lang], qIdx)
            noAutoScores[i].questions[counter] = getBasicResponsabilityScoreQuestion(j[this.user.lang], j.questions[k][this.user.lang], qIdx)
            leadersScores[i].questions[counter] = getBasicResponsabilityScoreQuestion(j[this.user.lang], j.questions[k][this.user.lang], qIdx)
            pairsScores[i].questions[counter] = getBasicResponsabilityScoreQuestion(j[this.user.lang], j.questions[k][this.user.lang], qIdx)
            dependentsScores[i].questions[counter] = getBasicResponsabilityScoreQuestion(j[this.user.lang], j.questions[k][this.user.lang], qIdx)
            // Filtered
            scoresFiltered[i].questions[counter] = getBasicResponsabilityScoreQuestion(j[this.user.lang], j.questions[k][this.user.lang], qIdx)
            autoScoresFiltered[i].questions[counter] = getBasicResponsabilityScoreQuestion(j[this.user.lang], j.questions[k][this.user.lang], qIdx)
            noAutoScoresFiltered[i].questions[counter] = getBasicResponsabilityScoreQuestion(j[this.user.lang], j.questions[k][this.user.lang], qIdx)
            leadersScoresFiltered[i].questions[counter] = getBasicResponsabilityScoreQuestion(j[this.user.lang], j.questions[k][this.user.lang], qIdx)
            pairsScoresFiltered[i].questions[counter] = getBasicResponsabilityScoreQuestion(j[this.user.lang], j.questions[k][this.user.lang], qIdx)
            dependentsScoresFiltered[i].questions[counter] = getBasicResponsabilityScoreQuestion(j[this.user.lang], j.questions[k][this.user.lang], qIdx)

            counter++
          }
        }
      }

      let population = 0
      let populationFiltered = 0
      const baseEvaluatorsAux = (key, score, responsability, scoreFiltered, responsabilityFiltered) => ({
        key,
        score,
        scoreFiltered,
        responsability,
        responsabilityFiltered,
        population: 0,
        populationFiltered: 0
      })
      const evaluatorsAux = {
        leadersEvaluators: baseEvaluatorsAux('leaders',
          leadersScores,
          leadersResponsability,
          leadersScoresFiltered,
          leadersResponsabilityFiltered),
        pairsEvaluators: baseEvaluatorsAux('pairs',
          pairsScores,
          pairsResponsability,
          pairsScoresFiltered,
          pairsResponsabilityFiltered),
        dependentsEvaluators: baseEvaluatorsAux('dependents',
          dependentsScores,
          dependentsResponsability,
          dependentsScoresFiltered,
          dependentsResponsabilityFiltered)
      }

      for (let index = 0; index < res.evaluated.length; index++) {
        const evaluated = res.evaluated[index]
        this.expectedPolls.auto++
        if (evaluated.employee.completed) {
          this.completedPolls.auto++
          count = 0
          const itThis = res.evaluated[index].employee.employee.id === this.evaluatedId
          if (itThis) {
            this.openQuestions = [
              [], [], []
            ]
          }
          for (const j of evaluated.employee.answersDimention) {
            let counter = 0
            let respCount = 0
            let dimensionTotal = 0
            for (const k of j.responsability) {
              let respTotal = 0
              let nullCount = 0
              for (let l = 0; l < k.questions.length; l++) {
                if (k.questions[l] && k.questions[l].score && k.questions[l].score !== -1) {
                  respTotal += k.questions[l].score
                  // Score general con autoevaluación
                  scores[count].questions[counter].score += k.questions[l].score
                  scores[count].questions[counter].individualScores.push(k.questions[l].score)
                  scores[count].individualScores.push(k.questions[l].score)
                  scoresResponsability[count].responsability[respCount].questionsScores.push(k.questions[l].score)
                  // Responsabilidad general con autoevaluación
                  scoresResponsability[count].responsability[respCount].individualScores.push(k.questions[l].score)
                  scores[count].questionsScores.push(k.questions[l].score)

                  if (itThis) {
                    // Score general con autoevaluación del evaluado
                    scoresFiltered[count].questions[counter].score += k.questions[l].score
                    scoresFiltered[count].questions[counter].individualScores.push(k.questions[l].score)
                    scoresResponsabilityFiltered[count].responsability[respCount].questionsScores.push(k.questions[l].score)
                    scoresFiltered[count].questionsScores.push(k.questions[l].score)

                    // Score autoevaluación
                    autoScoresFiltered[count].questions[counter].score += k.questions[l].score
                    autoScoresFiltered[count].questions[counter].individualScores.push(k.questions[l].score)
                    autoScoresFiltered[count].individualScores.push(k.questions[l].score)
                    autoScoresResponsabilityFiltered[count].responsability[respCount].questionsScores.push(k.questions[l].score)
                    autoScoresFiltered[count].questionsScores.push(k.questions[l].score)
                  }
                } else {
                  // Respuestas no sabe general con auntoevaluación
                  scores[count].questions[counter].nullCount += 1
                  scores[count].nullCount += 1

                  if (itThis) {
                    // Respuestas no sabe general con autoevaluación del evaluado
                    scoresFiltered[count].questions[counter].nullCount += 1
                    scoresFiltered[count].nullCount += 1
                    // Respuestas no sabe de autoevaluación
                    autoScoresFiltered[count].questions[counter].nullCount += 1
                    autoScoresFiltered[count].nullCount += 1
                  }
                  nullCount++
                }
                counter++
              }
              if (respTotal) {
                if (itThis) {
                  // Responsabilidad general con autoevaluación del evaluado
                  scoresResponsabilityFiltered[count].responsability[respCount].individualScores.push(respTotal / (k.questions.length - nullCount))
                  // Responsabilidad autoevaluación
                  autoScoresResponsabilityFiltered[count].responsability[respCount].individualScores.push(respTotal / (k.questions.length - nullCount))
                }

                dimensionTotal += respTotal / (k.questions.length - nullCount)
              }
              respCount++
            }
            if (dimensionTotal) {
              if (itThis) {
                // IndividualScores general con autoevaluación del evaluado
                scoresFiltered[count].individualScores.push(dimensionTotal / j.responsability.length)
              }
            }
            count++
          }
          // Aunmenta contador population general con autoevaluación
          population++
          if (itThis) {
            // Aunmenta contador population general con autoevaluación del evaluado
            populationFiltered++
            // Aunmenta contador population autoevaluación
            autoPopulationFiltered++
          }

          for (const keyEvaluator in evaluatorsAux) {
            const evaluator = evaluatorsAux[keyEvaluator]
            for (const i of evaluated[keyEvaluator]) {
              this.expectedPolls[evaluator.key]++
              if (itThis) {
                this.expectedPollsFiltered[evaluator.key]++
              }
              if (i.completed) {
                this.completedPolls[evaluator.key]++
                if (itThis) {
                  this.completedPollsFiltered[evaluator.key]++
                }
                count = 0
                for (const j of i.answersDimention) {
                  let counter = 0
                  let respCount = 0
                  let dimensionTotal = 0
                  for (const k of j.responsability) {
                    let respTotal = 0
                    let nullCount = 0
                    for (let l = 0; l < k.questions.length; l++) {
                      if (k.questions[l] && k.questions[l].score && k.questions[l].score !== -1) {
                        respTotal += k.questions[l].score

                        // Promedio para evaluadores
                        evaluator.score[count].questions[counter].score += k.questions[l].score
                        evaluator.score[count].questions[counter].individualScores.push(k.questions[l].score)
                        evaluator.score[count].individualScores.push(k.questions[l].score)
                        // Responsabilidad evaluadores
                        evaluator.responsability[count].responsability[respCount].individualScores.push(k.questions[l].score)

                        // Promedio general con autoevaluación
                        scoresResponsability[count].responsability[respCount].questionsScores.push(k.questions[l].score)
                        scores[count].questionsScores.push(k.questions[l].score)

                        // Promedio general sin autoevaluación
                        noAutoScores[count].questions[counter].score += k.questions[l].score
                        noAutoScores[count].questions[counter].individualScores.push(k.questions[l].score)
                        noAutoScores[count].individualScores.push(k.questions[l].score)
                        // Responsabilidad general sin autoevaluación
                        noAutoScoresResponsability[count].responsability[respCount].individualScores.push(k.questions[l].score)

                        if (itThis) {
                          // Promedio para evaluadores del evaluado
                          evaluator.scoreFiltered[count].questions[counter].score += k.questions[l].score
                          evaluator.scoreFiltered[count].questions[counter].individualScores.push(k.questions[l].score)
                          evaluator.responsabilityFiltered[count].responsability[respCount].questionsScores.push(k.questions[l].score)

                          // Promedio general con autoevaluación del evaluado
                          scoresFiltered[count].questions[counter].score += k.questions[l].score
                          scoresFiltered[count].questions[counter].individualScores.push(k.questions[l].score)
                          scoresResponsabilityFiltered[count].responsability[respCount].questionsScores.push(k.questions[l].score)
                          scoresFiltered[count].questionsScores.push(k.questions[l].score)

                          // Promedio general sin autoevaluación del evaluado
                          noAutoScoresFiltered[count].questions[counter].score += k.questions[l].score
                          noAutoScoresFiltered[count].questions[counter].individualScores.push(k.questions[l].score)
                          noAutoScoresFiltered[count].individualScores.push(k.questions[l].score)
                          noAutoScoresResponsabilityFiltered[count].responsability[respCount].questionsScores.push(k.questions[l].score)
                        }
                      } else {
                        // Respuestas no sabe Evaluadores
                        evaluator.score[count].questions[counter].nullCount += 1
                        evaluator.score[count].nullCount += 1
                        // Respuestas no sabe general con auntoevaluación
                        scores[count].questions[counter].nullCount += 1
                        scores[count].nullCount += 1
                        // Respuestas no sabe general sin autoevaluación
                        noAutoScores[count].questions[counter].nullCount += 1
                        noAutoScores[count].nullCount += 1

                        if (itThis) {
                          // Respuestas no sabe Evaluadores del evaluado
                          evaluator.scoreFiltered[count].questions[counter].nullCount += 1
                          evaluator.scoreFiltered[count].nullCount += 1
                          // Respuestas no sabe general con auntoevaluación del evaluado
                          scoresFiltered[count].questions[counter].nullCount += 1
                          scoresFiltered[count].nullCount += 1
                          // Respuestas no sabe general sin autoevaluación del evaluado
                          noAutoScoresFiltered[count].questions[counter].nullCount += 1
                          noAutoScoresFiltered[count].nullCount += 1
                        }
                        nullCount++
                      }
                      counter++
                    }
                    if (respTotal) {
                      if (itThis) {
                        // Responsabilidad evaluadores del evaluado
                        evaluator.responsabilityFiltered[count].responsability[respCount].individualScores.push(respTotal / (k.questions.length - nullCount))
                        // Responsabilidad general con autoevaluación del evaluado
                        scoresResponsabilityFiltered[count].responsability[respCount].individualScores.push(respTotal / (k.questions.length - nullCount))
                        // Responsabilidad general sin autoevaluación del evaluado
                        noAutoScoresResponsabilityFiltered[count].responsability[respCount].individualScores.push(respTotal / (k.questions.length - nullCount))
                      }
                      dimensionTotal += respTotal / (k.questions.length - nullCount)
                    }
                    respCount++
                  }
                  if (dimensionTotal) {
                    if (itThis) {
                      // IndividualScores evaluadores del evaluado
                      evaluator.scoreFiltered[count].individualScores.push(dimensionTotal / j.responsability.length)
                      // IndividualScores general con autoevaluación del evaluado
                      scoresFiltered[count].individualScores.push(dimensionTotal / j.responsability.length)
                    }
                  }
                  count++
                }
                // Aunmenta contador population evaluadores
                evaluator.population++
                // Aunmenta contador population general con autoevaluación
                population++
                // Aunmenta contador population general sin autoevaluación
                noAutoPopulation++

                if (itThis) {
                  // Aunmenta contador population evaluadores del evaluador
                  evaluator.populationFiltered++
                  // Aunmenta contador population general con autoevaluación del evaluado
                  populationFiltered++
                  // Aunmenta contador population general sin autoevaluación del evaluado
                  noAutoPopulationFiltered++

                  // OPEN QUESTIONS
                  let openCount = 1
                  for (var k = 0; k < 3; k++) {
                    const oq = i.openQuestions.find(x => x.name === `openQuestion${openCount}`)
                    this.openQuestions[openCount - 1].push(...oq.answer)
                    openCount++
                  }
                }
              }
            }
          }
        }
      }

      this.leadersPopulation = evaluatorsAux.leadersEvaluators.population
      this.leadersPopulationFiltered = evaluatorsAux.leadersEvaluators.populationFiltered
      this.pairsPopulation = evaluatorsAux.pairsEvaluators.population
      this.pairsPopulationFiltered = evaluatorsAux.pairsEvaluators.populationFiltered
      this.dependentsPopulation = evaluatorsAux.dependentsEvaluators.population
      this.dependentsPopulationFiltered = evaluatorsAux.dependentsEvaluators.populationFiltered
      this.othersPopulation = this.leadersPopulation + this.pairsPopulation + this.dependentsPopulation
      this.othersPopulationFiltered = this.leadersPopulationFiltered + this.pairsPopulationFiltered + this.dependentsPopulationFiltered

      leadersScores = evaluatorsAux.leadersEvaluators.score
      leadersResponsability = evaluatorsAux.leadersEvaluators.responsability
      pairsScores = evaluatorsAux.pairsEvaluators.score
      pairsResponsability = evaluatorsAux.pairsEvaluators.responsability
      dependentsScores = evaluatorsAux.dependentsEvaluators.score
      dependentsResponsability = evaluatorsAux.dependentsEvaluators.responsability

      leadersScoresFiltered = evaluatorsAux.leadersEvaluators.scoreFiltered
      leadersResponsabilityFiltered = evaluatorsAux.leadersEvaluators.responsabilityFiltered
      pairsScoresFiltered = evaluatorsAux.pairsEvaluators.scoreFiltered
      pairsResponsabilityFiltered = evaluatorsAux.pairsEvaluators.responsabilityFiltered
      dependentsScoresFiltered = evaluatorsAux.dependentsEvaluators.scoreFiltered
      dependentsResponsabilityFiltered = evaluatorsAux.dependentsEvaluators.responsabilityFiltered

      for (let i = 0; i < scores.length; i++) {
        let noAutoAcumDimension = 0
        let leadersAcumDimension = 0
        let pairsAcumDimension = 0
        let dependentsAcumDimension = 0
        let noAutoCount = 0
        let leadersCount = 0
        let pairsCount = 0
        let dependentsCount = 0
        for (let j = 0; j < scores[i].questions.length; j++) {
          // Promedio de todos incluyendo autoevaluación
          if ((population - scores[i].questions[j].nullCount) === 0) {
            scores[i].questions[j].score = 0
          }
          // Promedio de todos sin autoevaluación
          if ((noAutoPopulation - noAutoScores[i].questions[j].nullCount) === 0) {
            noAutoScores[i].questions[j].score = 0
          } else {
            noAutoScores[i].questions[j].score = noAutoScores[i].questions[j].score / (noAutoPopulation - noAutoScores[i].questions[j].nullCount)
            noAutoCount++
          }
          noAutoAcumDimension += noAutoScores[i].questions[j].score
          // Lideres
          if ((this.leadersPopulation - leadersScores[i].questions[j].nullCount) === 0) {
            leadersScores[i].questions[j].score = 0
          } else {
            leadersScores[i].questions[j].score = leadersScores[i].questions[j].score / (this.leadersPopulation - leadersScores[i].questions[j].nullCount)
            leadersCount++
          }
          leadersAcumDimension += leadersScores[i].questions[j].score
          // Pares
          if ((this.pairsPopulation - pairsScores[i].questions[j].nullCount) === 0) {
            pairsScores[i].questions[j].score = 0
          } else {
            pairsScores[i].questions[j].score = pairsScores[i].questions[j].score / (this.pairsPopulation - pairsScores[i].questions[j].nullCount)
            pairsCount++
          }
          pairsAcumDimension += pairsScores[i].questions[j].score
          // Colaboradores
          if ((this.dependentsPopulation - dependentsScores[i].questions[j].nullCount) === 0) {
            dependentsScores[i].questions[j].score = 0
          } else {
            dependentsScores[i].questions[j].score = dependentsScores[i].questions[j].score / (this.dependentsPopulation - dependentsScores[i].questions[j].nullCount)
            dependentsCount++
          }
          dependentsAcumDimension += dependentsScores[i].questions[j].score

          scores[i].questions[j].leadersProm = this.getProm(scores[i].questions[j].individualScores)
          noAutoScores[i].questions[j].leadersProm = this.getProm(noAutoScores[i].questions[j].individualScores)
          leadersScores[i].questions[j].leadersProm = this.getProm(leadersScores[i].questions[j].individualScores)
          pairsScores[i].questions[j].leadersProm = this.getProm(pairsScores[i].questions[j].individualScores)
          dependentsScores[i].questions[j].leadersProm = this.getProm(dependentsScores[i].questions[j].individualScores)

          scores[i].questions[j].tenPercent = this.calculateTenPercent(scores[i].questions[j].individualScores)
          noAutoScores[i].questions[j].tenPercent = this.calculateTenPercent(noAutoScores[i].questions[j].individualScores)
          leadersScores[i].questions[j].tenPercent = this.calculateTenPercent(leadersScores[i].questions[j].individualScores)
          pairsScores[i].questions[j].tenPercent = this.calculateTenPercent(pairsScores[i].questions[j].individualScores)
          dependentsScores[i].questions[j].tenPercent = this.calculateTenPercent(dependentsScores[i].questions[j].individualScores)
          // ------------------------------ DISPERSIÓN, SUMATORIA -------------------------------------------
          for (let k = 0; k < scores[i].questions[j].individualScores.length; k++) {
            scores[i].questions[j].dispersion += Math.pow(scores[i].questions[j].individualScores[k] - scores[i].questions[j].score, 2)
          }
        }
        // ---------------------------------- DISPERSIÓN TOTAL ------------------------------------------------
        for (let j = 0; j < scores[i].questions.length; j++) {
          // Promedio de todos
          if ((population - scores[i].questions[j].nullCount) === 0) {
            scores[i].questions[j].dispersion = 0
          } else {
            scores[i].questions[j].dispersion = Math.sqrt(scores[i].questions[j].dispersion / (population - scores[i].questions[j].nullCount))
          }
        }
        // ----------------------------------------------------------------------------------------------------
        for (let resp = 0; resp < scoresResponsability[i].responsability.length; resp++) {
          for (let sc = 0; sc < scoresResponsability[i].responsability[resp].questions.length; sc++) {
            if (scores[i].questions[scoresResponsability[i].responsability[resp].questions[sc] - 1].score === 0) {
              scoresResponsability[i].responsability[resp].nullCount++
              scoresResponsability[i].nullCount++
            }
            scoresResponsability[i].responsability[resp].score += scores[i].questions[scoresResponsability[i].responsability[resp].questions[sc] - 1].score
            if (noAutoScores[i].questions[noAutoScoresResponsability[i].responsability[resp].questions[sc] - 1].score === 0) {
              noAutoScoresResponsability[i].responsability[resp].nullCount++
              noAutoScoresResponsability[i].nullCount++
            }
            noAutoScoresResponsability[i].responsability[resp].score += noAutoScores[i].questions[noAutoScoresResponsability[i].responsability[resp].questions[sc] - 1].score
            if (leadersScores[i].questions[leadersResponsability[i].responsability[resp].questions[sc] - 1].score === 0) {
              leadersResponsability[i].responsability[resp].nullCount++
              leadersResponsability[i].nullCount++
            }
            leadersResponsability[i].responsability[resp].score += leadersScores[i].questions[leadersResponsability[i].responsability[resp].questions[sc] - 1].score
            if (pairsScores[i].questions[pairsResponsability[i].responsability[resp].questions[sc] - 1].score === 0) {
              pairsResponsability[i].responsability[resp].nullCount++
              pairsResponsability[i].nullCount++
            }
            pairsResponsability[i].responsability[resp].score += pairsScores[i].questions[pairsResponsability[i].responsability[resp].questions[sc] - 1].score
            if (dependentsScores[i].questions[dependentsResponsability[i].responsability[resp].questions[sc] - 1].score === 0) {
              dependentsResponsability[i].responsability[resp].nullCount++
              dependentsResponsability[i].nullCount++
            }
            dependentsResponsability[i].responsability[resp].score += dependentsScores[i].questions[dependentsResponsability[i].responsability[resp].questions[sc] - 1].score
          }
          scoresResponsability[i].responsability[resp].score = this.getProm(scoresResponsability[i].responsability[resp].individualScores)
          noAutoScoresResponsability[i].responsability[resp].score = noAutoScoresResponsability[i].responsability[resp].score / (noAutoScoresResponsability[i].responsability[resp].questions.length - noAutoScoresResponsability[i].responsability[resp].nullCount)
          leadersResponsability[i].responsability[resp].score = leadersResponsability[i].responsability[resp].score / (leadersResponsability[i].responsability[resp].questions.length - leadersResponsability[i].responsability[resp].nullCount)
          pairsResponsability[i].responsability[resp].score = pairsResponsability[i].responsability[resp].score / (pairsResponsability[i].responsability[resp].questions.length - pairsResponsability[i].responsability[resp].nullCount)
          dependentsResponsability[i].responsability[resp].score = dependentsResponsability[i].responsability[resp].score / (dependentsResponsability[i].responsability[resp].questions.length - dependentsResponsability[i].responsability[resp].nullCount)

          scoresResponsability[i].responsability[resp].tenPercent = this.calculateTenPercent(scoresResponsability[i].responsability[resp].individualScores)
          noAutoScoresResponsability[i].responsability[resp].tenPercent = this.calculateTenPercent(noAutoScoresResponsability[i].responsability[resp].individualScores)
          leadersResponsability[i].responsability[resp].tenPercent = this.calculateTenPercent(leadersResponsability[i].responsability[resp].individualScores)
          pairsResponsability[i].responsability[resp].tenPercent = this.calculateTenPercent(pairsResponsability[i].responsability[resp].individualScores)
          dependentsResponsability[i].responsability[resp].tenPercent = this.calculateTenPercent(dependentsResponsability[i].responsability[resp].individualScores)
          // DISPERSIÓN POR RESPONSABILIDAD, SUMATORIA
          for (let j = 0; j < scoresResponsability[i].responsability[resp].individualScores.length; j++) {
            scoresResponsability[i].responsability[resp].dispersion += Math.pow(scoresResponsability[i].responsability[resp].individualScores[j] - scoresResponsability[i].responsability[resp].score, 2)
          }
        }
        // DISPERSIÓN POR RESPONSABILIDAD
        for (let resp = 0; resp < scoresResponsability[i].responsability.length; resp++) {
          if ((scoresResponsability[i].responsability[resp].questions.length - scoresResponsability[i].responsability[resp].nullCount) === 0) {
            scoresResponsability[i].responsability[resp].dispersion = 0
          } else {
            scoresResponsability[i].responsability[resp].dispersion = Math.sqrt(scoresResponsability[i].responsability[resp].dispersion / (scoresResponsability[i].responsability[resp].individualScores.length - scoresResponsability[i].responsability[resp].nullCount))
          }
        }

        scores[i].total = this.getProm(scores[i].individualScores)
        noAutoScores[i].total = noAutoAcumDimension / noAutoCount
        leadersScores[i].total = leadersAcumDimension / leadersCount
        pairsScores[i].total = pairsAcumDimension / pairsCount
        dependentsScores[i].total = dependentsAcumDimension / dependentsCount

        scores[i].tenPercent = this.calculateTenPercent(scores[i].individualScores)
        noAutoScores[i].tenPercent = this.calculateTenPercent(noAutoScores[i].individualScores)
        leadersScores[i].tenPercent = this.calculateTenPercent(leadersScores[i].individualScores)
        pairsScores[i].tenPercent = this.calculateTenPercent(pairsScores[i].individualScores)
        dependentsScores[i].tenPercent = this.calculateTenPercent(dependentsScores[i].individualScores)
      }

      /** ********************************************************************************************************************************************* */
      // if (res.evaluated[index].employee.employee.id === this.evaluatedId) {
      // Recorrer el evaluado para sacar los promedios
      for (let i = 0; i < scoresFiltered.length; i++) {
        let acumDimension = 0
        let autoDimension = 0
        let noAutoAcumDimension = 0
        let leadersAcumDimension = 0
        let pairsAcumDimension = 0
        let dependentsAcumDimension = 0
        let scoresCount = 0
        let autoCount = 0
        let noAutoCount = 0
        let leadersCount = 0
        let pairsCount = 0
        let dependentsCount = 0
        for (let j = 0; j < scoresFiltered[i].questions.length; j++) {
          // Promedio de todos incluyendo autoevaluación
          if ((populationFiltered - scoresFiltered[i].questions[j].nullCount) > 0) {
            scoresFiltered[i].questions[j].score = scoresFiltered[i].questions[j].score / (populationFiltered - scoresFiltered[i].questions[j].nullCount)
            scoresCount++
          }
          acumDimension += scoresFiltered[i].questions[j].score

          // Autoevaluación
          if ((autoPopulationFiltered - autoScoresFiltered[i].questions[j].nullCount) > 0) {
            autoScoresFiltered[i].questions[j].score = autoScoresFiltered[i].questions[j].score / (autoPopulationFiltered - autoScoresFiltered[i].questions[j].nullCount)
            autoCount++
          }
          autoDimension += autoScoresFiltered[i].questions[j].score

          // Promedio de todos sin autoevaluación
          if ((noAutoPopulationFiltered - noAutoScoresFiltered[i].questions[j].nullCount) > 0) {
            noAutoScoresFiltered[i].questions[j].score = this.getProm(noAutoScoresFiltered[i].questions[j].individualScores)
            noAutoCount++
          }
          noAutoAcumDimension += noAutoScoresFiltered[i].questions[j].score

          // Lideres
          if ((this.leadersPopulationFiltered - leadersScoresFiltered[i].questions[j].nullCount) > 0) {
            leadersScoresFiltered[i].questions[j].score = leadersScoresFiltered[i].questions[j].score / (this.leadersPopulationFiltered - leadersScoresFiltered[i].questions[j].nullCount)
            leadersCount++
          }
          leadersAcumDimension += leadersScoresFiltered[i].questions[j].score

          // Pares
          if ((this.pairsPopulationFiltered - pairsScoresFiltered[i].questions[j].nullCount) > 0) {
            pairsScoresFiltered[i].questions[j].score = pairsScoresFiltered[i].questions[j].score / (this.pairsPopulationFiltered - pairsScoresFiltered[i].questions[j].nullCount)
            pairsCount++
          }
          pairsAcumDimension += pairsScoresFiltered[i].questions[j].score
          // Colaboradores
          if ((this.dependentsPopulationFiltered - dependentsScoresFiltered[i].questions[j].nullCount) > 0) {
            dependentsScoresFiltered[i].questions[j].score = dependentsScoresFiltered[i].questions[j].score / (this.dependentsPopulationFiltered - dependentsScoresFiltered[i].questions[j].nullCount)
            dependentsCount++
          }
          dependentsAcumDimension += dependentsScoresFiltered[i].questions[j].score

          // ------------------------------ 10% SUPERIOR -------------------------------------------
          scoresFiltered[i].questions[j].tenPercent = this.calculateTenPercent(scoresFiltered[i].questions[j].individualScores)
          autoScoresFiltered[i].questions[j].tenPercent = this.calculateTenPercent(autoScoresFiltered[i].questions[j].individualScores)
          noAutoScoresFiltered[i].questions[j].tenPercent = this.calculateTenPercent(noAutoScoresFiltered[i].questions[j].individualScores)
          leadersScoresFiltered[i].questions[j].tenPercent = this.calculateTenPercent(leadersScoresFiltered[i].questions[j].individualScores)
          pairsScoresFiltered[i].questions[j].tenPercent = this.calculateTenPercent(pairsScoresFiltered[i].questions[j].individualScores)
          dependentsScoresFiltered[i].questions[j].tenPercent = this.calculateTenPercent(dependentsScoresFiltered[i].questions[j].individualScores)

          // ------------------------------ DISPERSIÓN, SUMATORIA -------------------------------------------
          for (let k = 0; k < noAutoScoresFiltered[i].questions[j].individualScores.length; k++) {
            noAutoScoresFiltered[i].questions[j].dispersion += Math.pow(noAutoScoresFiltered[i].questions[j].individualScores[k] - noAutoScoresFiltered[i].questions[j].score, 2)
          }
        }

        // ---------------------------------- DISPERSIÓN TOTAL ------------------------------------------------
        for (let j = 0; j < noAutoScoresFiltered[i].questions.length; j++) {
          // Promedio de todos
          if ((noAutoPopulationFiltered - noAutoScoresFiltered[i].questions[j].nullCount) === 0) {
            noAutoScoresFiltered[i].questions[j].dispersion = 0
          } else {
            noAutoScoresFiltered[i].questions[j].dispersion = Math.sqrt(noAutoScoresFiltered[i].questions[j].dispersion / (noAutoPopulationFiltered - noAutoScoresFiltered[i].questions[j].nullCount))
          }
        }

        // ----------------------------------------------------------------------------------------------------
        for (let resp = 0; resp < scoresResponsabilityFiltered[i].responsability.length; resp++) {
          for (let sc = 0; sc < scoresResponsabilityFiltered[i].responsability[resp].questions.length; sc++) {
            if (scoresFiltered[i].questions[scoresResponsabilityFiltered[i].responsability[resp].questions[sc] - 1].score === 0) {
              scoresResponsabilityFiltered[i].responsability[resp].nullCount++
              scoresResponsabilityFiltered[i].nullCount++
            }
            scoresResponsabilityFiltered[i].responsability[resp].score += scoresFiltered[i].questions[scoresResponsabilityFiltered[i].responsability[resp].questions[sc] - 1].score
            if (autoScoresFiltered[i].questions[autoScoresResponsabilityFiltered[i].responsability[resp].questions[sc] - 1].score === 0) {
              autoScoresResponsabilityFiltered[i].responsability[resp].nullCount++
              autoScoresResponsabilityFiltered[i].nullCount++
            }
            autoScoresResponsabilityFiltered[i].responsability[resp].score += autoScoresFiltered[i].questions[autoScoresResponsabilityFiltered[i].responsability[resp].questions[sc] - 1].score
            if (noAutoScoresFiltered[i].questions[noAutoScoresResponsabilityFiltered[i].responsability[resp].questions[sc] - 1].score === 0) {
              noAutoScoresResponsabilityFiltered[i].responsability[resp].nullCount++
              noAutoScoresResponsabilityFiltered[i].nullCount++
            }
            noAutoScoresResponsabilityFiltered[i].responsability[resp].score += noAutoScoresFiltered[i].questions[noAutoScoresResponsabilityFiltered[i].responsability[resp].questions[sc] - 1].score
            if (leadersScoresFiltered[i].questions[leadersResponsabilityFiltered[i].responsability[resp].questions[sc] - 1].score === 0) {
              leadersResponsabilityFiltered[i].responsability[resp].nullCount++
              leadersResponsabilityFiltered[i].nullCount++
            }
            leadersResponsabilityFiltered[i].responsability[resp].score += leadersScoresFiltered[i].questions[leadersResponsabilityFiltered[i].responsability[resp].questions[sc] - 1].score
            if (pairsScoresFiltered[i].questions[pairsResponsabilityFiltered[i].responsability[resp].questions[sc] - 1].score === 0) {
              pairsResponsabilityFiltered[i].responsability[resp].nullCount++
              pairsResponsabilityFiltered[i].nullCount++
            }
            pairsResponsabilityFiltered[i].responsability[resp].score += pairsScoresFiltered[i].questions[pairsResponsabilityFiltered[i].responsability[resp].questions[sc] - 1].score
            if (dependentsScoresFiltered[i].questions[dependentsResponsabilityFiltered[i].responsability[resp].questions[sc] - 1].score === 0) {
              dependentsResponsabilityFiltered[i].responsability[resp].nullCount++
              dependentsResponsabilityFiltered[i].nullCount++
            }
            dependentsResponsabilityFiltered[i].responsability[resp].score += dependentsScoresFiltered[i].questions[dependentsResponsabilityFiltered[i].responsability[resp].questions[sc] - 1].score
          }
          scoresResponsabilityFiltered[i].responsability[resp].score = scoresResponsabilityFiltered[i].responsability[resp].score / (scoresResponsabilityFiltered[i].responsability[resp].questions.length - scoresResponsabilityFiltered[i].responsability[resp].nullCount)
          autoScoresResponsabilityFiltered[i].responsability[resp].score = autoScoresResponsabilityFiltered[i].responsability[resp].score / (autoScoresResponsabilityFiltered[i].responsability[resp].questions.length - autoScoresResponsabilityFiltered[i].responsability[resp].nullCount)
          noAutoScoresResponsabilityFiltered[i].responsability[resp].score = noAutoScoresResponsabilityFiltered[i].responsability[resp].score / (noAutoScoresResponsabilityFiltered[i].responsability[resp].questions.length - noAutoScoresResponsabilityFiltered[i].responsability[resp].nullCount)
          leadersResponsabilityFiltered[i].responsability[resp].score = leadersResponsabilityFiltered[i].responsability[resp].score / (leadersResponsabilityFiltered[i].responsability[resp].questions.length - leadersResponsabilityFiltered[i].responsability[resp].nullCount)
          pairsResponsabilityFiltered[i].responsability[resp].score = pairsResponsabilityFiltered[i].responsability[resp].score / (pairsResponsabilityFiltered[i].responsability[resp].questions.length - pairsResponsabilityFiltered[i].responsability[resp].nullCount)
          dependentsResponsabilityFiltered[i].responsability[resp].score = dependentsResponsabilityFiltered[i].responsability[resp].score / (dependentsResponsabilityFiltered[i].responsability[resp].questions.length - dependentsResponsabilityFiltered[i].responsability[resp].nullCount)

          scoresResponsabilityFiltered[i].responsability[resp].tenPercent = this.calculateTenPercent(scoresResponsabilityFiltered[i].responsability[resp].individualScores)
          autoScoresResponsabilityFiltered[i].responsability[resp].tenPercent = this.calculateTenPercent(autoScoresResponsabilityFiltered[i].responsability[resp].questionsScores)
          noAutoScoresResponsabilityFiltered[i].responsability[resp].tenPercent = this.calculateTenPercent(noAutoScoresResponsabilityFiltered[i].responsability[resp].individualScores)
          leadersResponsabilityFiltered[i].responsability[resp].tenPercent = this.calculateTenPercent(leadersResponsabilityFiltered[i].responsability[resp].individualScores)
          pairsResponsabilityFiltered[i].responsability[resp].tenPercent = this.calculateTenPercent(pairsResponsabilityFiltered[i].responsability[resp].individualScores)
          dependentsResponsabilityFiltered[i].responsability[resp].tenPercent = this.calculateTenPercent(dependentsResponsabilityFiltered[i].responsability[resp].individualScores)
          // DISPERSIÓN POR RESPONSABILIDAD, SUMATORIA
          for (let j = 0; j < scoresResponsabilityFiltered[i].responsability[resp].individualScores.length; j++) {
            scoresResponsabilityFiltered[i].responsability[resp].dispersion += Math.pow(scoresResponsabilityFiltered[i].responsability[resp].individualScores[j] - scoresResponsabilityFiltered[i].responsability[resp].score, 2)
          }
        }
        // DISPERSIÓN POR RESPONSABILIDAD
        for (let resp = 0; resp < scoresResponsabilityFiltered[i].responsability.length; resp++) {
          if ((scoresResponsabilityFiltered[i].responsability[resp].questions.length - scoresResponsabilityFiltered[i].responsability[resp].nullCount) === 0) {
            scoresResponsabilityFiltered[i].responsability[resp].dispersion = 0
          } else {
            scoresResponsabilityFiltered[i].responsability[resp].dispersion = Math.sqrt(scoresResponsabilityFiltered[i].responsability[resp].dispersion / (scoresResponsabilityFiltered[i].responsability[resp].individualScores.length - scoresResponsabilityFiltered[i].responsability[resp].nullCount))
          }
        }
        scoresFiltered[i].total = acumDimension / scoresCount
        autoScoresFiltered[i].total = autoDimension / autoCount
        noAutoScoresFiltered[i].total = noAutoAcumDimension / noAutoCount
        leadersScoresFiltered[i].total = leadersAcumDimension / leadersCount
        pairsScoresFiltered[i].total = pairsAcumDimension / pairsCount
        dependentsScoresFiltered[i].total = dependentsAcumDimension / dependentsCount

        scoresFiltered[i].tenPercent = this.calculateTenPercent(scoresFiltered[i].individualScores)
        autoScoresFiltered[i].tenPercent = this.calculateTenPercent(autoScoresFiltered[i].individualScores)
        noAutoScoresFiltered[i].tenPercent = this.calculateTenPercent(noAutoScoresFiltered[i].individualScores)
        leadersScoresFiltered[i].tenPercent = this.calculateTenPercent(leadersScoresFiltered[i].individualScores)
        pairsScoresFiltered[i].tenPercent = this.calculateTenPercent(pairsScoresFiltered[i].individualScores)
        dependentsScoresFiltered[i].tenPercent = this.calculateTenPercent(dependentsScoresFiltered[i].individualScores)
      }
      /** ********************************************************************************************************************************************* */
      this.averageScores = scores
      this.averageScoresFiltered = scoresFiltered
      this.autoScoresFiltered = autoScoresFiltered
      this.noAutoScores = noAutoScores
      this.noAutoScoresFiltered = noAutoScoresFiltered
      this.leadersScores = leadersScores
      this.leadersScoresFiltered = leadersScoresFiltered
      this.pairsScores = pairsScores
      this.pairsScoresFiltered = pairsScoresFiltered
      this.dependentsScores = dependentsScores
      this.dependentsScoresFiltered = dependentsScoresFiltered
      this.averageResponsability = scoresResponsability
      this.averageResponsabilityFiltered = scoresResponsabilityFiltered
      this.autoResponsabilityFiltered = autoScoresResponsabilityFiltered
      this.noAutoScoresResponsability = noAutoScoresResponsability
      this.noAutoScoresResponsabilityFiltered = noAutoScoresResponsabilityFiltered
      this.leadersResponsability = leadersResponsability
      this.leadersResponsabilityFiltered = leadersResponsabilityFiltered
      this.pairsResponsability = pairsResponsability
      this.pairsResponsabilityFiltered = pairsResponsabilityFiltered
      this.dependentsResponsability = dependentsResponsability
      this.dependentsResponsabilityFiltered = dependentsResponsabilityFiltered
    },
    async $getInitialData (id) {
      const basePolss = {
        auto: 0,
        leaders: 0,
        pairs: 0,
        dependents: 0
      }
      this.completedPolls = JSON.parse(JSON.stringify(basePolss))
      this.expectedPolls = JSON.parse(JSON.stringify(basePolss))
      delete basePolss.auto
      this.completedPollsFiltered = JSON.parse(JSON.stringify(basePolss))
      this.expectedPollsFiltered = JSON.parse(JSON.stringify(basePolss))
      return evaluationService.findById(id)
        .then((res) => {
          this.currentEvaluation = res
          this.evaluatedName = res.evaluated.find(evaluated => evaluated.employee.employee.id === this.evaluatedId).employee.employee.text
          this.setScores(this.currentEvaluation)
        })
        .catch((err) => {
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        })
    },
    async $getPreviousData (id) {
      let previousEvaluation = await evaluationService.getPrevious(id)
      if (!previousEvaluation || previousEvaluation.status || previousEvaluation.length === 0) {
        this.hasPrevious = false
      } else {
        this.hasPrevious = true
        this.$setPreviousScore(previousEvaluation[0])
      }
      this.getResults()
    },
    calculateTenPercent (individualScores) {
      const total = individualScores.sort((a, b) => b - a).length
      if (!total) {
        return 0
      }
      let tenPercent = Math.ceil(total * 0.10)
      let average = 0
      for (let t = 0; t < tenPercent; t++) {
        average += individualScores[t]
      }

      return (average / tenPercent)
    },
    getProm (ar) {
      if (ar.length === 0) return 0
      let ac = 0
      ar.forEach((v) => { ac += v })
      return ac / ar.length
    },
    getResults () {
      this.othersRank = []
      this.othersRankFiltered = []
      this.autoRankFiltered = []
      this.noAutoOthersRank = []
      this.noAutoOthersRankFiltered = []
      this.leadersRank = []
      this.leadersRankFiltered = []
      this.pairsRank = []
      this.pairsRankFiltered = []
      this.dependentsRank = []
      this.dependentsRankFiltered = []
      this.othersRespRank = []
      this.othersRespRankFiltered = []
      this.autoRespRankFiltered = []
      this.noAutoOthersRespRank = []
      this.noAutoOthersRespRankFiltered = []
      this.leadersRespRank = []
      this.leadersRespRankFiltered = []
      this.pairsRespRank = []
      this.pairsRespRankFiltered = []
      this.dependentsRespRank = []
      this.dependentsRespRankFiltered = []
      for (let i = 0; i < this.averageScores.length; i++) {
        for (let j = 0; j < this.averageScores[i].questions.length; j++) {
          // Otros
          let obj = {}
          const baseObj = {
            process: '--',
            abbreviation: '--',
            score: '--',
            question: '--',
            dispersion: '--',
            tenPercent: '--',
            previous: '--',
            trend: '--',
            responsability: '--'
          }
          if (this.averageScores[i].questions[j].scores !== 0) {
            obj = {
              process: this.averageScores[i].name,
              abbreviation: this.averageScores[i].name === 'as_person' ? 'P' : this.averageScores[i].name === 'management' ? 'D' : this.averageScores[i].name === 'mobilization' ? 'M' : 'L',
              score: this.averageScores[i].questions[j].score,
              question: this.averageScores[i].questions[j].question,
              tenPercent: this.averageScores[i].questions[j].tenPercent,
              dispersion: this.averageScores[i].questions[j].dispersion,
              previous: 0,
              trend: 0,
              responsability: this.averageScores[i].questions[j].responsability
            }
            if (this.hasPrevious) {
              obj.previous = this.previousAverageScores[i].questions[j].score
              obj.trend = this.averageScores[i].questions[j].score - this.previousAverageScores[i].questions[j].score
            }
          }
          this.othersRank.push(obj)
          if (this.noAutoScores[i].questions[j].scores !== 0) {
            obj = {
              process: this.noAutoScores[i].name,
              abbreviation: this.noAutoScores[i].name === 'as_person' ? 'P' : this.noAutoScores[i].name === 'management' ? 'D' : this.noAutoScores[i].name === 'mobilization' ? 'M' : 'L',
              score: this.noAutoScores[i].questions[j].score,
              question: this.noAutoScores[i].questions[j].question,
              tenPercent: this.noAutoScores[i].questions[j].tenPercent,
              dispersion: this.noAutoScores[i].questions[j].dispersion,
              previous: 0,
              trend: 0,
              responsability: this.noAutoScores[i].questions[j].responsability
            }
            if (this.hasPrevious) {
              obj.previous = this.previousNoAutoScores[i].questions[j].score
              obj.trend = this.noAutoScores[i].questions[j].score - this.previousNoAutoScores[i].questions[j].score
            }
          }
          this.noAutoOthersRank.push(obj)
          // Por Líder
          if (this.leadersPopulation === 0) {
            obj = baseObj
          } else if (this.leadersScores[i].questions[j].score !== 0) {
            obj = {
              process: this.leadersScores[i].name,
              abbreviation: this.leadersScores[i].name === 'as_person' ? 'P' : this.leadersScores[i].name === 'management' ? 'D' : this.leadersScores[i].name === 'mobilization' ? 'M' : 'L',
              score: this.leadersScores[i].questions[j].score,
              question: this.leadersScores[i].questions[j].question,
              tenPercent: this.leadersScores[i].questions[j].tenPercent,
              dispersion: this.leadersScores[i].questions[j].dispersion,
              previous: 0,
              trend: 0,
              responsability: this.leadersScores[i].questions[j].responsability
            }
            if (this.hasPrevious) {
              obj.previous = this.previousLeadersScores[i].questions[j].score
              obj.trend = this.leadersScores[i].questions[j].score - this.previousLeadersScores[i].questions[j].score
            }
          }
          this.leadersRank.push(obj)
          // Por Pares
          if (this.pairsPopulation === 0) {
            obj = baseObj
          } else if (this.pairsScores[i].questions[j].score !== 0) {
            obj = {
              process: this.pairsScores[i].name,
              abbreviation: this.pairsScores[i].name === 'as_person' ? 'P' : this.pairsScores[i].name === 'management' ? 'D' : this.pairsScores[i].name === 'mobilization' ? 'M' : 'L',
              score: this.pairsScores[i].questions[j].score,
              question: this.pairsScores[i].questions[j].question,
              tenPercent: this.pairsScores[i].questions[j].tenPercent,
              dispersion: this.pairsScores[i].questions[j].dispersion,
              previous: 0,
              trend: 0,
              responsability: this.pairsScores[i].questions[j].responsability
            }
            if (this.hasPrevious) {
              obj.previous = this.previousPairsScores[i].questions[j].score
              obj.trend = this.pairsScores[i].questions[j].score - this.previousPairsScores[i].questions[j].score
            }
          }
          this.pairsRank.push(obj)
          if (this.dependentsPopulation === 0) {
            obj = baseObj
          } else if (this.dependentsScores[i].questions[j].score !== 0) {
            obj = {
              process: this.dependentsScores[i].name,
              abbreviation: this.dependentsScores[i].name === 'as_person' ? 'P' : this.dependentsScores[i].name === 'management' ? 'D' : this.dependentsScores[i].name === 'mobilization' ? 'M' : 'L',
              score: this.dependentsScores[i].questions[j].score,
              question: this.dependentsScores[i].questions[j].question,
              tenPercent: this.dependentsScores[i].questions[j].tenPercent,
              dispersion: this.dependentsScores[i].questions[j].dispersion,
              previous: 0,
              trend: 0,
              responsability: this.dependentsScores[i].questions[j].responsability
            }
            if (this.hasPrevious) {
              obj.previous = this.previousDependentsScores[i].questions[j].score
              obj.trend = this.dependentsScores[i].questions[j].score - this.previousDependentsScores[i].questions[j].score
            }
          }
          this.dependentsRank.push(obj)
        }
        // Responsabilidad
      }
      /** ********************************************************************************************************************************************* */
      // if (res.evaluated[index].employee.employee.id === this.evaluatedId) {
      // Recorrer el evaluado para sacar los promedios
      this.generalGap = []
      this.confirmedStrengths = []
      this.unknownStrengths = []
      this.confirmedOpportunities = []
      this.unknownOpportunities = []
      for (let i = 0; i < this.averageScoresFiltered.length; i++) {
        for (let j = 0; j < this.averageScoresFiltered[i].questions.length; j++) {
          // Otros
          let obj = {}
          const baseObj = {
            process: '--',
            abbreviation: '--',
            score: '--',
            question: '--',
            dispersion: '--',
            tenPercent: '--',
            previous: '--',
            trend: '--',
            responsability: '--'
          }
          if (this.averageScoresFiltered[i].questions[j].score !== 0) {
            obj = {
              process: this.averageScoresFiltered[i].name,
              abbreviation: this.averageScoresFiltered[i].name === 'as_person' ? 'P' : this.averageScoresFiltered[i].name === 'management' ? 'D' : this.averageScoresFiltered[i].name === 'mobilization' ? 'M' : 'L',
              score: this.averageScoresFiltered[i].questions[j].score,
              question: this.averageScoresFiltered[i].questions[j].question,
              tenPercent: this.averageScoresFiltered[i].questions[j].tenPercent,
              dispersion: this.averageScoresFiltered[i].questions[j].dispersion,
              previous: 0,
              trend: 0,
              responsability: this.averageScoresFiltered[i].questions[j].responsability
            }
            if (this.hasPrevious) {
              obj.previous = this.previousAverageScoresFiltered[i].questions[j].score
              obj.trend = this.averageScoresFiltered[i].questions[j].score - this.previousAverageScoresFiltered[i].questions[j].score
            }
            this.othersRankFiltered.push(obj)
          }
          // noAutoScores
          if (this.noAutoScoresFiltered[i].questions[j].score !== 0) {
            obj = {
              process: this.noAutoScoresFiltered[i].name,
              abbreviation: this.noAutoScoresFiltered[i].name === 'as_person' ? 'P' : this.noAutoScoresFiltered[i].name === 'management' ? 'D' : this.noAutoScoresFiltered[i].name === 'mobilization' ? 'M' : 'L',
              score: this.noAutoScoresFiltered[i].questions[j].score,
              question: this.noAutoScoresFiltered[i].questions[j].question,
              tenPercent: this.noAutoScoresFiltered[i].questions[j].tenPercent,
              dispersion: this.noAutoScoresFiltered[i].questions[j].dispersion,
              previous: 0,
              trend: 0,
              responsability: this.noAutoScoresFiltered[i].questions[j].responsability,
              auto: this.autoScoresFiltered[i].questions[j].score,
              gap: this.autoScoresFiltered[i].questions[j].score - this.noAutoScoresFiltered[i].questions[j].score
            }
            if (this.hasPrevious) {
              obj.previous = this.previousNoAutoScoresFiltered[i].questions[j].score
              obj.trend = this.noAutoScoresFiltered[i].questions[j].score - this.previousNoAutoScoresFiltered[i].questions[j].score
            }
            this.noAutoOthersRankFiltered.push(obj)
          }
          // autoScores
          if (this.autoScoresFiltered[i].questions[j].score !== 0) {
            obj = {
              process: this.autoScoresFiltered[i].name,
              abbreviation: this.autoScoresFiltered[i].name === 'as_person' ? 'P' : this.autoScoresFiltered[i].name === 'management' ? 'D' : this.autoScoresFiltered[i].name === 'mobilization' ? 'M' : 'L',
              score: this.autoScoresFiltered[i].questions[j].score,
              question: this.autoScoresFiltered[i].questions[j].question,
              tenPercent: this.autoScoresFiltered[i].questions[j].tenPercent,
              dispersion: this.autoScoresFiltered[i].questions[j].dispersion,
              previous: 0,
              trend: 0,
              responsability: this.autoScoresFiltered[i].questions[j].responsability,
              others: this.noAutoScoresFiltered[i].questions[j].score,
              gap: this.autoScoresFiltered[i].questions[j].score - this.noAutoScoresFiltered[i].questions[j].score
            }
            if (this.hasPrevious) {
              obj.previous = this.previousAutoScoresFiltered[i].questions[j].score
              obj.trend = this.autoScoresFiltered[i].questions[j].score - this.previousAutoScoresFiltered[i].questions[j].score
            }
            if (this.noAutoScoresFiltered[i].questions[j].score > 0 && this.autoScoresFiltered[i].questions[j].score > 0) {
              if (this.noAutoScoresFiltered[i].questions[j].score >= 5) {
                if (this.autoScoresFiltered[i].questions[j].score >= 5) {
                  this.confirmedStrengths.push({
                    question: this.autoScoresFiltered[i].questions[j].question,
                    score: this.autoScoresFiltered[i].questions[j].score,
                    others: this.noAutoScoresFiltered[i].questions[j].score
                  })
                } else {
                  this.unknownStrengths.push({
                    question: this.autoScoresFiltered[i].questions[j].question,
                    score: this.autoScoresFiltered[i].questions[j].score,
                    others: this.noAutoScoresFiltered[i].questions[j].score
                  })
                }
              } else {
                if (this.autoScoresFiltered[i].questions[j].score <= 4) {
                  this.confirmedOpportunities.push({
                    question: this.autoScoresFiltered[i].questions[j].question,
                    score: this.autoScoresFiltered[i].questions[j].score,
                    others: this.noAutoScoresFiltered[i].questions[j].score
                  })
                } else {
                  this.unknownOpportunities.push({
                    question: this.autoScoresFiltered[i].questions[j].question,
                    score: this.autoScoresFiltered[i].questions[j].score,
                    others: this.noAutoScoresFiltered[i].questions[j].score
                  })
                }
              }
            }
            if (obj.score > 0 && obj.others > 0) {
              this.autoRankFiltered.push(obj)
            }
          }
          // Por Líder
          if (this.leadersPopulationFiltered === 0) {
            obj = baseObj
          } else if (this.leadersScoresFiltered[i].questions[j].score !== 0) {
            obj = {
              process: this.leadersScoresFiltered[i].name,
              abbreviation: this.leadersScoresFiltered[i].name === 'as_person' ? 'P' : this.leadersScoresFiltered[i].name === 'management' ? 'D' : this.leadersScoresFiltered[i].name === 'mobilization' ? 'M' : 'L',
              score: this.leadersScoresFiltered[i].questions[j].score,
              question: this.leadersScoresFiltered[i].questions[j].question,
              tenPercent: this.leadersScoresFiltered[i].questions[j].tenPercent,
              dispersion: this.leadersScoresFiltered[i].questions[j].dispersion,
              previous: 0,
              trend: 0,
              responsability: this.leadersScoresFiltered[i].questions[j].responsability
            }
            if (this.pairsScoresFiltered[i].questions[j].score > 0) {
              this.generalGap.push({
                abbreviation: this.leadersScoresFiltered[i].name === 'as_person' ? 'P' : this.leadersScoresFiltered[i].name === 'management' ? 'D' : this.leadersScoresFiltered[i].name === 'mobilization' ? 'M' : 'L',
                score: this.leadersScoresFiltered[i].questions[j].score,
                evaluator: 'leaders',
                question: this.leadersScoresFiltered[i].questions[j].question,
                other: this.pairsScoresFiltered[i].questions[j].score,
                comparedEvaluator: 'pairs',
                gap: this.leadersScoresFiltered[i].questions[j].score - this.pairsScoresFiltered[i].questions[j].score
              })
            }
            if (this.dependentsScoresFiltered[i].questions[j].score > 0) {
              this.generalGap.push({
                abbreviation: this.leadersScoresFiltered[i].name === 'as_person' ? 'P' : this.leadersScoresFiltered[i].name === 'management' ? 'D' : this.leadersScoresFiltered[i].name === 'mobilization' ? 'M' : 'L',
                score: this.leadersScoresFiltered[i].questions[j].score,
                evaluator: 'leaders',
                question: this.leadersScoresFiltered[i].questions[j].question,
                other: this.dependentsScoresFiltered[i].questions[j].score,
                comparedEvaluator: 'dependents',
                gap: this.leadersScoresFiltered[i].questions[j].score - this.dependentsScoresFiltered[i].questions[j].score
              })
            }
            if (this.hasPrevious) {
              obj.previous = this.previousLeadersScoresFiltered[i].questions[j].score
              obj.trend = this.leadersScoresFiltered[i].questions[j].score - this.previousLeadersScoresFiltered[i].questions[j].score
            }
            this.leadersRankFiltered.push(obj)
          }
          // Por Pares
          if (this.pairsPopulationFiltered === 0 || this.pairsScoresFiltered[i].questions[j].score === 0) {
            obj = baseObj
          } else if (this.pairsScoresFiltered[i].questions[j].score !== 0) {
            obj = {
              process: this.pairsScoresFiltered[i].name,
              abbreviation: this.pairsScoresFiltered[i].name === 'as_person' ? 'P' : this.pairsScoresFiltered[i].name === 'management' ? 'D' : this.pairsScoresFiltered[i].name === 'mobilization' ? 'M' : 'L',
              score: this.pairsScoresFiltered[i].questions[j].score,
              question: this.pairsScoresFiltered[i].questions[j].question,
              tenPercent: this.pairsScoresFiltered[i].questions[j].tenPercent,
              dispersion: this.pairsScoresFiltered[i].questions[j].dispersion,
              previous: 0,
              trend: 0,
              responsability: this.pairsScoresFiltered[i].questions[j].responsability
            }
            if (this.leadersScoresFiltered[i].questions[j].score > 0) {
              this.generalGap.push({
                abbreviation: this.pairsScoresFiltered[i].name === 'as_person' ? 'P' : this.pairsScoresFiltered[i].name === 'management' ? 'D' : this.pairsScoresFiltered[i].name === 'mobilization' ? 'M' : 'L',
                score: this.pairsScoresFiltered[i].questions[j].score,
                evaluator: 'pairs',
                question: this.pairsScoresFiltered[i].questions[j].question,
                other: this.leadersScoresFiltered[i].questions[j].score,
                comparedEvaluator: 'leaders',
                gap: this.pairsScoresFiltered[i].questions[j].score - this.leadersScoresFiltered[i].questions[j].score
              })
            }
            if (this.dependentsScoresFiltered[i].questions[j].score > 0) {
              this.generalGap.push({
                abbreviation: this.pairsScoresFiltered[i].name === 'as_person' ? 'P' : this.pairsScoresFiltered[i].name === 'management' ? 'D' : this.pairsScoresFiltered[i].name === 'mobilization' ? 'M' : 'L',
                score: this.pairsScoresFiltered[i].questions[j].score,
                evaluator: 'pairs',
                question: this.pairsScoresFiltered[i].questions[j].question,
                other: this.dependentsScoresFiltered[i].questions[j].score,
                comparedEvaluator: 'dependents',
                gap: this.pairsScoresFiltered[i].questions[j].score - this.dependentsScoresFiltered[i].questions[j].score
              })
            }
            if (this.hasPrevious) {
              obj.previous = this.previousPairsScoresFiltered[i].questions[j].score
              obj.trend = this.pairsScoresFiltered[i].questions[j].score - this.previousPairsScoresFiltered[i].questions[j].score
            }
            this.pairsRankFiltered.push(obj)
          }
          if (this.dependentsPopulationFiltered === 0) {
            obj = baseObj
          } else if (this.dependentsScoresFiltered[i].questions[j].score !== 0) {
            obj = {
              process: this.dependentsScoresFiltered[i].name,
              abbreviation: this.dependentsScoresFiltered[i].name === 'as_person' ? 'P' : this.dependentsScoresFiltered[i].name === 'management' ? 'D' : this.dependentsScoresFiltered[i].name === 'mobilization' ? 'M' : 'L',
              score: this.dependentsScoresFiltered[i].questions[j].score,
              question: this.dependentsScoresFiltered[i].questions[j].question,
              tenPercent: this.dependentsScoresFiltered[i].questions[j].tenPercent,
              dispersion: this.dependentsScoresFiltered[i].questions[j].dispersion,
              previous: 0,
              trend: 0,
              responsability: this.dependentsScoresFiltered[i].questions[j].responsability
            }
            if (this.pairsScoresFiltered[i].questions[j].score > 0) {
              this.generalGap.push({
                abbreviation: this.dependentsScoresFiltered[i].name === 'as_person' ? 'P' : this.dependentsScoresFiltered[i].name === 'management' ? 'D' : this.dependentsScoresFiltered[i].name === 'mobilization' ? 'M' : 'L',
                score: this.dependentsScoresFiltered[i].questions[j].score,
                evaluator: 'dependents',
                question: this.dependentsScoresFiltered[i].questions[j].question,
                other: this.pairsScoresFiltered[i].questions[j].score,
                comparedEvaluator: 'pairs',
                gap: this.dependentsScoresFiltered[i].questions[j].score - this.pairsScoresFiltered[i].questions[j].score
              })
            }
            if (this.leadersScoresFiltered[i].questions[j].score > 0) {
              this.generalGap.push({
                abbreviation: this.dependentsScoresFiltered[i].name === 'as_person' ? 'P' : this.dependentsScoresFiltered[i].name === 'management' ? 'D' : this.dependentsScoresFiltered[i].name === 'mobilization' ? 'M' : 'L',
                score: this.dependentsScoresFiltered[i].questions[j].score,
                evaluator: 'dependents',
                question: this.dependentsScoresFiltered[i].questions[j].question,
                other: this.leadersScoresFiltered[i].questions[j].score,
                comparedEvaluator: 'leaders',
                gap: this.dependentsScoresFiltered[i].questions[j].score - this.leadersScoresFiltered[i].questions[j].score
              })
            }
            if (this.hasPrevious) {
              obj.previous = this.previousDependentsScoresFiltered[i].questions[j].score
              obj.trend = this.dependentsScoresFiltered[i].questions[j].score - this.previousDependentsScoresFiltered[i].questions[j].score
            }
            this.dependentsRankFiltered.push(obj)
          }
        }
        // Responsabilidad
      }
      /** ********************************************************************************************************************************************* */

      for (let i = 0; i < this.averageResponsability.length; i++) {
        for (let j = 0; j < this.averageResponsability[i].responsability.length; j++) {
          let obj = {}
          const baseObj = {
            name: '--',
            abbreviation: '--',
            score: '--',
            dispersion: '--',
            tenPercent: '--',
            previous: '--',
            trend: '--'
          }
          if (this.averageResponsability[i].responsability[j].scores !== 0) {
            obj = {
              name: this.averageResponsability[i].responsability[j].name,
              abbreviation: this.averageResponsability[i].name === 'as_person' ? 'P' : this.averageResponsability[i].name === 'management' ? 'D' : this.averageResponsability[i].name === 'mobilization' ? 'M' : 'L',
              score: this.averageResponsability[i].responsability[j].score,
              tenPercent: this.averageResponsability[i].responsability[j].tenPercent,
              dispersion: this.averageResponsability[i].responsability[j].dispersion,
              previous: 0,
              trend: 0
            }
            if (this.hasPrevious) {
              obj.previous = this.previousAverageResponsability[i].responsability[j].score
              obj.trend = this.averageResponsability[i].responsability[j].score - this.previousAverageResponsability[i].responsability[j].score
            }
          }
          this.othersRespRank.push(obj)
          if (this.noAutoScoresResponsability[i].responsability[j].scores !== 0) {
            obj = {
              name: this.noAutoScoresResponsability[i].responsability[j].name,
              abbreviation: this.noAutoScoresResponsability[i].name === 'as_person' ? 'P' : this.noAutoScoresResponsability[i].name === 'management' ? 'D' : this.noAutoScoresResponsability[i].name === 'mobilization' ? 'M' : 'L',
              score: this.noAutoScoresResponsability[i].responsability[j].score,
              tenPercent: this.noAutoScoresResponsability[i].responsability[j].tenPercent,
              dispersion: 0,
              previous: 0,
              trend: 0
            }
            if (this.hasPrevious) {
              obj.previous = this.previousNoAutoScoresResponsability[i].responsability[j].score
              obj.trend = this.noAutoScoresResponsability[i].responsability[j].score - this.previousNoAutoScoresResponsability[i].responsability[j].score
            }
          }
          this.noAutoOthersRespRank.push(obj)
          // Por Líder
          if (this.leadersPopulation === 0) {
            obj = baseObj
          } else if (this.leadersResponsability[i].responsability[j].score !== 0) {
            obj = {
              name: this.leadersResponsability[i].responsability[j].name,
              abbreviation: this.leadersResponsability[i].name === 'as_person' ? 'P' : this.leadersResponsability[i].name === 'management' ? 'D' : this.leadersResponsability[i].name === 'mobilization' ? 'M' : 'L',
              score: this.leadersResponsability[i].responsability[j].score,
              tenPercent: this.leadersResponsability[i].responsability[j].tenPercent,
              dispersion: 0,
              previous: 0,
              trend: 0
            }
            if (this.hasPrevious) {
              obj.previous = this.previousLeadersResponsability[i].responsability[j].score
              obj.trend = this.leadersResponsability[i].responsability[j].score - this.previousLeadersResponsability[i].responsability[j].score
            }
          }
          this.leadersRespRank.push(obj)
          // Por Pares
          if (this.pairsPopulation === 0) {
            obj = baseObj
          } else if (this.pairsResponsability[i].responsability[j].score !== 0) {
            obj = {
              name: this.pairsResponsability[i].responsability[j].name,
              abbreviation: this.pairsResponsability[i].name === 'as_person' ? 'P' : this.pairsResponsability[i].name === 'management' ? 'D' : this.pairsResponsability[i].name === 'mobilization' ? 'M' : 'L',
              score: this.pairsResponsability[i].responsability[j].score,
              tenPercent: this.pairsResponsability[i].responsability[j].tenPercent,
              dispersion: 0,
              previous: 0,
              trend: 0
            }
            if (this.hasPrevious) {
              obj.previous = this.previousPairsResponsability[i].responsability[j].score
              obj.trend = this.pairsResponsability[i].responsability[j].score - this.previousPairsResponsability[i].responsability[j].score
            }
          }
          this.pairsRespRank.push(obj)
          if (this.dependentsPopulation === 0) {
            obj = baseObj
          } else if (this.dependentsResponsability[i].responsability[j].score !== 0) {
            obj = {
              name: this.dependentsResponsability[i].responsability[j].name,
              abbreviation: this.dependentsResponsability[i].name === 'as_person' ? 'P' : this.dependentsResponsability[i].name === 'management' ? 'D' : this.dependentsResponsability[i].name === 'mobilization' ? 'M' : 'L',
              score: this.dependentsResponsability[i].responsability[j].score,
              tenPercent: this.dependentsResponsability[i].responsability[j].tenPercent,
              dispersion: 0,
              previous: 0,
              trend: 0
            }
            if (this.hasPrevious) {
              obj.previous = this.previousDependentsResponsability[i].responsability[j].score
              obj.trend = this.dependentsResponsability[i].responsability[j].score - this.previousDependentsResponsability[i].responsability[j].score
            }
          }
          this.dependentsRespRank.push(obj)
        }
      }
      /** ********************************************************************************************************************************************* */
      // if (res.evaluated[index].employee.employee.id === this.evaluatedId) {
      // Recorrer el evaluado para sacar los promedios
      for (let i = 0; i < this.averageResponsabilityFiltered.length; i++) {
        for (let j = 0; j < this.averageResponsabilityFiltered[i].responsability.length; j++) {
          let obj = {}
          const baseObj = {
            name: '--',
            abbreviation: '--',
            score: '--',
            dispersion: '--',
            tenPercent: '--',
            previous: '--',
            trend: '--'
          }
          if (this.averageResponsabilityFiltered[i].responsability[j].scores !== 0) {
            obj = {
              name: this.averageResponsabilityFiltered[i].responsability[j].name,
              abbreviation: this.averageResponsabilityFiltered[i].name === 'as_person' ? 'P' : this.averageResponsabilityFiltered[i].name === 'management' ? 'D' : this.averageResponsabilityFiltered[i].name === 'mobilization' ? 'M' : 'L',
              score: this.averageResponsabilityFiltered[i].responsability[j].score,
              tenPercent: this.averageResponsabilityFiltered[i].responsability[j].tenPercent,
              dispersion: this.averageResponsabilityFiltered[i].responsability[j].dispersion,
              previous: 0,
              trend: 0
            }
            if (this.hasPrevious) {
              obj.previous = this.previousAverageResponsabilityFiltered[i].responsability[j].score
              obj.trend = this.averageResponsabilityFiltered[i].responsability[j].score - this.previousAverageResponsabilityFiltered[i].responsability[j].score
            }
          }
          this.othersRespRankFiltered.push(obj)
          if (this.noAutoScoresResponsabilityFiltered[i].responsability[j].scores !== 0) {
            obj = {
              name: this.noAutoScoresResponsabilityFiltered[i].responsability[j].name,
              abbreviation: this.noAutoScoresResponsabilityFiltered[i].name === 'as_person' ? 'P' : this.noAutoScoresResponsabilityFiltered[i].name === 'management' ? 'D' : this.noAutoScoresResponsabilityFiltered[i].name === 'mobilization' ? 'M' : 'L',
              score: this.noAutoScoresResponsabilityFiltered[i].responsability[j].score,
              tenPercent: this.noAutoScoresResponsabilityFiltered[i].responsability[j].tenPercent,
              dispersion: 0,
              previous: 0,
              trend: 0
            }
            if (this.hasPrevious) {
              obj.previous = this.previousNoAutoScoresResponsabilityFiltered[i].responsability[j].score
              obj.trend = this.noAutoScoresResponsabilityFiltered[i].responsability[j].score - this.previousNoAutoScoresResponsabilityFiltered[i].responsability[j].score
            }
          }
          this.noAutoOthersRespRankFiltered.push(obj)
          // Por Líder
          if (this.leadersPopulationFiltered === 0) {
            obj = baseObj
          } else if (this.leadersResponsabilityFiltered[i].responsability[j].score !== 0) {
            obj = {
              name: this.leadersResponsabilityFiltered[i].responsability[j].name,
              abbreviation: this.leadersResponsabilityFiltered[i].name === 'as_person' ? 'P' : this.leadersResponsabilityFiltered[i].name === 'management' ? 'D' : this.leadersResponsabilityFiltered[i].name === 'mobilization' ? 'M' : 'L',
              score: this.leadersResponsabilityFiltered[i].responsability[j].score,
              tenPercent: this.leadersResponsabilityFiltered[i].responsability[j].tenPercent,
              dispersion: 0,
              previous: 0,
              trend: 0
            }
            if (this.hasPrevious) {
              obj.previous = this.previousLeadersResponsabilityFiltered[i].responsability[j].score
              obj.trend = this.leadersResponsabilityFiltered[i].responsability[j].score - this.previousLeadersResponsabilityFiltered[i].responsability[j].score
            }
          }
          this.leadersRespRankFiltered.push(obj)
          // Por Pares
          if (this.pairsPopulationFiltered === 0) {
            obj = baseObj
          } else if (this.pairsResponsabilityFiltered[i].responsability[j].score !== 0) {
            obj = {
              name: this.pairsResponsabilityFiltered[i].responsability[j].name,
              abbreviation: this.pairsResponsabilityFiltered[i].name === 'as_person' ? 'P' : this.pairsResponsabilityFiltered[i].name === 'management' ? 'D' : this.pairsResponsabilityFiltered[i].name === 'mobilization' ? 'M' : 'L',
              score: this.pairsResponsabilityFiltered[i].responsability[j].score,
              tenPercent: this.pairsResponsabilityFiltered[i].responsability[j].tenPercent,
              dispersion: 0,
              previous: 0,
              trend: 0
            }
            if (this.hasPrevious) {
              obj.previous = this.previousPairsResponsabilityFiltered[i].responsability[j].score
              obj.trend = this.pairsResponsabilityFiltered[i].responsability[j].score - this.previousPairsResponsabilityFiltered[i].responsability[j].score
            }
          }
          this.pairsRespRankFiltered.push(obj)
          if (this.dependentsPopulationFiltered === 0) {
            obj = baseObj
          } else if (this.dependentsResponsabilityFiltered[i].responsability[j].score !== 0) {
            obj = {
              name: this.dependentsResponsabilityFiltered[i].responsability[j].name,
              abbreviation: this.dependentsResponsabilityFiltered[i].name === 'as_person' ? 'P' : this.dependentsResponsabilityFiltered[i].name === 'management' ? 'D' : this.dependentsResponsabilityFiltered[i].name === 'mobilization' ? 'M' : 'L',
              score: this.dependentsResponsabilityFiltered[i].responsability[j].score,
              tenPercent: this.dependentsResponsabilityFiltered[i].responsability[j].tenPercent,
              dispersion: 0,
              previous: 0,
              trend: 0
            }
            if (this.hasPrevious) {
              obj.previous = this.previousDependentsResponsabilityFiltered[i].responsability[j].score
              obj.trend = this.dependentsResponsabilityFiltered[i].responsability[j].score - this.previousDependentsResponsabilityFiltered[i].responsability[j].score
            }
          }
          this.dependentsRespRankFiltered.push(obj)
        }
      }
      /** ********************************************************************************************************************************************* */
    }
  }
}
