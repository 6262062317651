
<template>
  <x-async-btn
    class="white--text"
    :action="downloadTemplate"
    :loading="loadingBtn"
  >
    <v-icon left>mdi-download</v-icon>
    {{ $t('evaluations.download_template') }}
  </x-async-btn>
</template>

<script>
import Vue from 'vue'

import fileDownload from '../../../utils/file-download'

import evaluationsService from '../../../services/evaluations'

export default Vue.extend({
  data: () => ({
    loadingBtn: false
  }),
  methods: {
    downloadTemplate () {
      this.loadingBtn = true
      return evaluationsService
        .generateTemplate()
        .then((res) => {
          const blob = new Blob([res.template])
          fileDownload(blob, 'plantilla.csv')
        })
        .catch(err =>
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        )
        .finally(() => {
          this.loadingBtn = false
        })
    }
  }
})
</script>
