
export default {
  title: 'Instructive OCC Solutions - DML 360°: Massive upload module.',
  title_body: 'This instructive is intended to guide users to the right way of upload the right format of the ' +
    'massive upload to prevent errors on users charge.',
  demographic_items: 'Demographics items: ',
  identifyType: 'Identify document types.',
  matchCaseValue: 'Must be especified as any of the values already setted on the system for this demographic item, ' +
    'respecting upper and lower case.',
  example: 'Example:',
  identifyDocument: 'Identify document',
  matchCaseNumber: 'Must be especified as only digits.',
  firstName: 'First name',
  matchCaseText: 'Must be only letters and spaces, without numbers and special characters',
  lastName: 'Last name',
  email: 'Email',
  matchCaseEmail: 'Must be especified as email address valid, it cannot be repeated. ' +
    'This field is not required.',
  gender: 'Gender',
  age: 'Age range (Birthd Date)',
  antiquity: 'Antiquity range (Date of Admission)',
  matchCaseDate: 'Must be an valid date on the format yyyy/mm/dd or yyyy-mm-dd, withouth spaces or letters',
  departments: 'Department/Área',
  charge: 'Charge',
  charge_dependent_info: 'Charges with departments/Area identified in brackets "()" can only be\n assigned to employees belonging to that department/Area.',
  jobTypes: 'Job type',
  academicDegree: 'Academic degree',
  country: 'Country',
  headquarter: 'Headquarter',
  headquarter_dependent_info: 'Headquarters with countries identified in brackets "()" can only be\n assigned to employees belonging to that country.',
  importDataToExcelTitle: 'Import data from file CSV to Excel document.',
  step1: 'Step 1',
  step1_desc: 'Open Excel.',
  step2: 'Step 2',
  step2_desc: 'Select white sheet option',
  step3: 'Step 3',
  step3_desc: 'Go to data tab and click on obtain extern data, then select the option From text file.',
  step4: 'Step 4',
  step4_desc: 'Select the CSV file, then click on the Import button.',
  step5: 'Step 5',
  step5_desc: 'This section specifies the settings that will be used to import the data file. It should be noted ​' +
  'that the values are Delimited, and that the Unicode uft-8 encoding will be used, as shown in the following image. ' +
  'This for the correct conversion of the accented words. Then you must press the Next button.',
  step6: 'Step 6',
  step6_desc: 'At this point it should be indicated which character will be used for the separation of values. ' +
  'The comma must be indicated as shown in the following image, then the Finish button must be pressed.',
  step7: 'Step 7',
  step7_desc: 'You will then be asked if this data will be imported in the existing sheet or in a new sheet. ' +
    'By default, import will be selected on the existing sheet, if not, select this option and press OK.',
  step8_desc: 'Finally the data appears correctly separated and the accented words will be imported correctly.',
  availableItems: 'Available values:'
}
