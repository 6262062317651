
import pdfUtil from '../utils_old/pdf'

export default {
  methods: {
    $generateResultsPerResponsability () {
      // 309DE0 EB493C 48BBA1
      const descriptions = [
        this.$t('organizationalReport.table14')
      ]
      const legend = [
        this.$t('organizationalReport.data_range'),
        this.$t('organizationalReport.score'),
        this.$t('organizationalReport.ten_percent'),
        this.$t('organizationalReport.higher_bold'),
        this.$t('organizationalReport.lower_sub')
      ]
      const process = [
        { name: this.$t('organizationalReport.management'), color: '#bcd835' },
        { name: this.$t('organizationalReport.mobilization'), color: '#93dbca' },
        { name: this.$t('organizationalReport.achievement'), color: '#309DE0' }
      ]
      let tables = []
      for (let i = 0; i < 3; i++) {
        let scores = []
        let title = {}
        for (let j = 0; j < this.averageResponsability[i + 1].responsability.length; j++) {
          scores.push([
            { text: this.averageResponsability[i + 1].responsability[j].name, margin: [0, 5, 0, 0], border: [true, true, true, true], style: { color: '#6d7a7a' } },
            pdfUtil.generateXDChart(this.averageResponsability, i + 1, j, 'responsability'),
            pdfUtil.higherLowerResponsability(this.averageResponsability[i + 1].responsability, this.averageResponsability[i + 1].responsability[j].score, '#2e2d2d', [0, 5, 0, 0], 'center', [true, true, true, true]),
            { text: this.round((this.averageResponsability[i + 1].responsability[j].tenPercent), 2), alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: '#6d7a7a' } },
            { text: `${this.hasPrevious ? this.round(this.previousAverageResponsability[i + 1].responsability[j].score, 2) : ''}`, alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: '#6d7a7a' } },
            { text: `${this.hasPrevious ? this.round(this.averageResponsability[i + 1].responsability[j].score - this.previousAverageResponsability[i + 1].responsability[j].score, 2) : ''}`, alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: '#6d7a7a' } }
          ])
        }
        if (i <= 1) {
          title = {
            table: {
              widths: [479],
              body: [
                [{ text: this.textToUpper('organizationalReport.index[3]'), bold: true, color: '#fff', margin: [50, 8, 0, 5] }]
              ]
            },
            layout: {
              fillColor: '#aaaaaa',
              defaultBorder: ''
            },
            alignment: 'left',
            margin: i === 1 ? [-40, 25, 0, 0] : [-40, 15, 0, 0],
            pageBreak: i === 1 ? 'before' : ''
          }
        }
        tables.push(
          title,
          {
            layout: {
              defaultBorder: '',
              fillColor: '#aaaaaa'
            },
            table: {
              widths: [330, 140],
              body: [
                [
                  { text: `${this.$t('organizationalReport.table')} ${i + 14}. ${descriptions[0]}`, margin: [30, 5, 0, 5], bold: true, color: '#fff' },
                  { text: `${process[i].name}`.toUpperCase(), margin: [0, 5, 0, 5], bold: true, color: process[i].color }
                ]
              ]
            },
            margin: [35, 10, 20, 10]
          },
          i === 1 ? {
            layout: {
              defaultBorder: ''
            },
            table: {
              widths: [479],
              body: [
                [{ text: this.$t('organizationalReport.legend_description'), margin: [30, 0, 0, 0], color: '#6d7a7a' }]
              ]
            },
            margin: [35, 0, 20, 0]
          } : {},
          pdfUtil.generateDmlLegend(legend),
          {
            layout: {
              defaultBorder: '',
              fillColor: function (rowIndex, node, columnIndex) {
                return (rowIndex === 0) ? '#BFBFBF' : null
              },
              border: [false, false, false, true]
            },
            table: {
              headerRows: 1,
              widths: ['20%', '41%', '10%', '10%', '10%', '10%'],
              body: [
                // Header
                [
                  { text: this.$t('organizationalReport.responsability'), margin: [0, 6, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 },
                  { layout: { defaultBorder: '', fillColor: '#BFBFBF' },
                    table: {
                      headerRows: 1,
                      body: [[
                        { text: '1\n|', margin: [10, 2, 15, 0] },
                        { text: '2\n|', margin: [0, 2, 15, 0] },
                        { text: '3\n|', margin: [0, 2, 15, 0] },
                        { text: '4\n|', margin: [0, 2, 15, 0] },
                        { text: '5\n|', margin: [0, 2, 15, 0] },
                        { text: '6\n|', margin: [0, 2, 15, 0] }
                      ]]
                    },
                    bold: true,
                    style: { color: '#fff' },
                    border: [true, true, true, true]
                  },
                  { text: this.$t('organizationalReport.score'), margin: [0, 6, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 },
                  { text: this.$t('organizationalReport.ten_percent'), margin: [0, 2, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 },
                  { text: this.$t('organizationalReport.previous_score'), margin: [0, 2, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 },
                  { text: this.$t('organizationalReport.trend'), margin: [0, 6, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 }
                ],
                // Body
                ...scores
              ]
            },
            margin: [10, 0, 20, 10],
            border: [false, false, false, true],
            alignment: 'center',
            fontSize: 8
          }
        )
      }
      return tables
    }
  }
}
