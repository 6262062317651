
<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-toolbar color="primary" class="white--text" dense>
            <v-toolbar-title>
              {{ $t('dashboard.last_polls') }}
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center" v-for="(header, k) in headers" :key="k"> {{ $t(`dashboard.${header}`) }} </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="evaluation in info" :key="evaluation.slug">
                    <td class="text-center">
                      {{ evaluation.displayName || evaluation.name }}
                    </td>
                    <td class="text-center">
                      {{ evaluation.deliveredAt | date }} {{ $t('dashboard.to') }} {{ evaluation.validUntil | date }}
                    </td>
                    <td class="text-center">
                      {{ $t(`evaluations.${evaluation.status}`) }}
                    </td>
                    <td class="text-center">
                      {{ evaluation.enterprise ? evaluation.enterprise.name : '' }}
                    </td>
                    <td class="text-center">
                      {{ evaluation.enterprise ? evaluation.enterprise.customer.name : '' }}
                    </td>
                    <td class="text-center">
                      <v-btn
                        icon
                        :to="`evaluations/${evaluation.slug}/details`"
                      >
                        <v-icon small>fa-eye</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import Vue from 'vue'

export default Vue.extend({
  props: {
    info: [Object, Array]
  },
  data () {
    return {
      headers: [
        'name',
        'dates',
        'status',
        'enterprise',
        'customer',
        'actions'
      ]
    }
  }
})
</script>

<style lang="css" scoped>
.text-center{
  text-align: center !important;
}
</style>
