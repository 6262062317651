
export default {
  create: 'Crear valoración',
  edit: 'Editar valoración',
  no_data: 'No hay valoraciones para mostrar',
  eval_no_data: 'No hay evaluados para mostrar',
  settings: 'Configuración de cuestionario',
  overview: 'Nombre de la encuesta',
  date: 'Fecha',
  questions: 'Preguntas',
  revition: 'Revisión y personalización',
  poll_name: 'Nombre de la valoración',
  want_external_name: '¿Desea colocar un nombre externo para los evaluados?',
  tooltip_name: 'Nombre interno de la valoración',
  evaluated_selection: 'Evaluados y evaluadores',
  evaluated_selection_desc: 'En esta sección, podrás elegir y asignar los líderes que van a hacer parte de la medición y sus evaluadores con sus relaciones. Recuerda que todos los participantes de la medición, deben estar previamente cargados.',
  evaluated_selection_warning: 'Después de la fecha de inicio de la medición no se podrán modificar los evaluados, ni las relaciones con los evaluadores.',
  hours: 'Hora de envío',
  hours_valid_until: 'Hora de cierre',
  date_delivery: 'Fecha de lanzamiento',
  poll_valid_until: 'Valoración válida hasta',
  time_zone: 'Zona horaria',
  want_send_reminders: '¿Desea enviar recordatorios?',
  reminders: 'Enviar recordatorio',
  scheduled_reminders: 'Recordatorios programados',
  external_name: 'Nombre de muestra',
  send_reminders: 'Envio de recordatorios',
  no_reminders: 'Sin recordatorios programados',
  questionnaire: 'Cuestionario',
  personalization: 'Personalización de correos electrónicos',
  poll_invitation: 'Invitación a la valoración',
  reminder_mail: 'Correo de recordatorio',
  tk_message: 'Mensaje de agradecimiento',
  message_subject: 'Asunto del mensaje',
  body_message: 'Cuerpo del mensaje',
  message_from_the_company: 'Mensaje por la empresa',
  in_progress: 'En progreso',
  pending: 'Pendiente',
  creating: 'Creando',
  editing: 'Editando',
  completed: 'Completada',
  questionnaire_e: 'Cuestionario DML 360°',
  pdf_explained: 'Este archivo contiene las preguntas del cuestionario "{name}" agrupadas por dimensión.',
  copyright: 'Copyright por OCC - Todos los derechos reservados',
  title: 'OCC - Reporte DML 360°',
  details: 'Detalles de valoración',
  name: 'Nombre',
  status: 'Estado',
  vigency: 'Vigencia',
  actions: 'Acciones',
  evaluation: 'Valoración DML 360°',
  total: 'Total',
  total_receptors: 'Total de encuestados: {n}',
  pending_evaluations: 'Pendientes',
  finished_evaluations: 'Completadas',
  of_polls: 'de encuestas',
  leader: 'Jefe',
  leaders: 'Jefes',
  dependent: 'Colaborador',
  dependents: 'Colaboradores',
  pair: 'Par',
  pairs: 'Pares',
  conection_modal_msg: 'Problema de conexión, Su progreso será almacenado al reconectar',
  iam_autoevaluation: 'Autovaloración',
  iam_leader: 'Soy su líder',
  iam_pair: 'Somos pares',
  iam_dependent: 'Mi líder',
  tooltip_display_name: 'Nombre de muestra',
  want_massive: '¿Desea agregar los líderes a evaluar y sus evaluadores vía carga masiva?',
  massive_upload_recomendations: 'Esta sección tiene como finalidad realizar la carga masiva de los líderes a evaluar y sus evaluadores. Para prevenir errores en la carga masiva, por favor tener en cuenta:',
  massive_upload_recomendations_items: [
    'Antes de realizar la carga masiva debes agregar todos los colaboradores a la suite.',
    'Da click en el botón descargar plantilla, para descargar el archivo donde vas a definir las relaciones de cada líder a evaluar.',
    'Solamente se permiten las siguientes relaciones: jefe, par, colaborador. La carga es sensible a mayúsculas. Escribir las relaciones en minúscula.',
    'Si alguno de los participantes de la medición no tiene correo electrónico, lo podrás agregar manualmente con el botón de agregar evaluador o editar.',
    'Solo se debe escribir una sola vez el evaluado con todos sus diferentes evaluadores',
    'El mismo evaluador no puede tener dos relaciones para el mismo líder a evaluar.'
  ],
  add_evaluated: 'Agregar evaluado',
  edit_evaluators: 'Editar evaluadores',
  download_instructive: 'Descargar instructivo',
  download_template: 'Descargar plantilla',
  warning_text: 'Se han encontrado fallas en los datos del archivo.',
  error_text: 'Uno de los evaluados no posee un tipo de evaluador asignado.',
  // Llenado
  as_person: 'Como persona',
  management: 'Dirección',
  mobilization: 'Movilización',
  achievement: 'Logro',
  open_questions: 'Preguntas abiertas',
  autoevaluation: 'Autoevaluación',
  dont_know: 'No se',
  information: 'La valoración está compuesta por una serie preguntas, las cuales corresponden a atributos personales propios del líder (el Líder como persona) y a los Procesos del modelo DML 360° (Dirección, Movilización y Logro).',
  information_2: 'La valoración está compuesta por una serie comportamientos y conductas relacionadas con las competencias y valores del Modelo de liderazgo DML 360°, sobre las cuales cada evaluador califica la frecuencia entre 1 y 6 de acuerdo a la siguiente escala:',
  score_label1: 'Nunca',
  score_label2: 'Casi nunca',
  score_label3: 'Rara vez',
  score_label4: 'Algunas veces',
  score_label5: 'Casi siempre',
  score_label6: 'Siempre',
  score_label7: 'No se',
  // Dialog messages
  wellcome_title: 'Bienvenido a la valoración DML 360º.',
  wellcome_instructions: 'La valoración está compuesta por una serie preguntas, las cuales corresponden a atributos personales propios del líder (el Líder como persona) y a los Procesos del modelo DML 360° (Dirección, Movilización y Logro).<br/><br/> Recuerde, para obtener resultados fieles a la realidad asegúrese de estar respondiendo de forma sincera. Su valoración no es ni buena ni mala, se trata de encontrar el valor que refleje con mayor fidelidad a la realidad. Confiamos en su criterio y sinceridad.<br/><br/> Asegúrese de leer adecuadamente la información.<br/><br/>El plazo máximo para completar la encuesta es: {deadline}',
  end: 'Muchas gracias por tu participación. Has completado la encuesta satisfactoriamente.',
  middle: '¡Muy Bien! ¡Ya has completado la mitad del proceso, mantente atento a cada pregunta!',
  invalid_token: 'No existe encuesta para este acceso, por favor coloque uno válido',
  before_date: 'La encuesta no se encuentra disponible para la fecha actual',
  not_available: 'La encuesta no se encuentra disponible en estos momentos',
  expiration_date: 'La encuesta a la que se está intentando ingresar ha terminado',
  evaluation_completed: 'Ya has completado esta encuesta.',
  custom_msg: '<p>Bienvenido a la valoración de liderazgo DML 360º la cual recoge las percepciones sobre las habilidades de liderazgo de quienes trabajan con un líder para complementarlas con su auto-percepción. Esta información permite tener un panorama completo sobre el desempeño del líder y el impacto que está generando en su entorno laboral.</p>' +
    '<p>Ésta es una encuesta de percepción; ninguna pregunta tiene respuesta correcta o incorrecta. Su <b>sinceridad y franqueza</b> son fundamentales para que los resultados sean útiles en el desarrollo del líder. <b>Sus respuestas son totalmente confidenciales.</b></p>' +
    '<p>Información clave sobre esta encuesta:</p>' +
    '<p>La información entregada será manejada de manera <b>confidencial</b> y será utilizada con fines estadísticos.</p>' +
    '<p>Para una mejor experiencia use Google Chrome, recuerde que debe estar conectado a internet.</p>' +
    '<p>Si tiene alguna duda o evidencia algún problema con la encuesta por favor no dude en contactarnos en el correo: <a href="mailto:info@occsolutions.org">info@occsolutions.org</a>.</p>' +
    '<p>Recuerde que <b>este link es personal</b> y no se debe compartir.</p>' +
    '<p>De antemano le agradecemos por su tiempo en este importante proceso.</p>',
  custom_reminder: '<p>Le recordamos que tiene pendiente la valoración de liderazgo DML 360°. Su opinión es muy importante en este proceso. Queremos recordarle que:</p>' +
    '<p>Ésta es una encuesta de percepción; ninguna pregunta tiene respuesta correcta o incorrecta. Su <b>sinceridad y franqueza</b> son fundamentales para que los resultados sean útiles en el desarrollo del líder. <b>Sus respuestas son totalmente confidenciales.</b></p>' +
    '<p>Información clave sobre esta encuesta:</p>' +
    '<p>La información entregada será manejada de manera <b>confidencial</b> y será utilizada con fines estadísticos.</p>' +
    '<p>Para una mejor experiencia use <b>Google Chrome,</b> recuerde que debe estar conectado a internet.</p>' +
    '<p>Si tiene alguna duda o evidencia algún problema con la encuesta por favor no dude en contactarnos en el correo: <b><a href="mailto:info@occsolutions.org">info@occsolutions.org</a>.</b></p>' +
    '<p>Recuerde que <b>este link es personal</b> y no se debe compartir.</p>' +
    '<p><b>De antemano le agradecemos por su tiempo en este importante proceso.</b></p>',
  evaluated: 'Evaluados',
  evaluate: 'Evaluado',
  start_at: 'Fecha Inicio',
  end_at: 'Fecha Cierre',
  your_balance: 'Su saldo:',
  token_unit: 'OCC Tokens',
  balance_after: 'Saldo luego de crear la valoración:',
  non_active_employees: 'Debe tener al menos un colaborador activo para esta encuesta.',
  acquire_tokens: 'Adquirir más OCC Tokens',
  confirm_create_title: 'Confirme la creación de la valoración',
  workshop_cost: 'Costo de la valoración',
  invalid_reminder_date: 'La Fecha del Recordatorio debe estar dentro del período de lanzamiento',
  all_right: 'Todos los derechos reservados 2020. ©',
  automatic_message: 'Este mensaje fue automáticamente enviado desde',
  show_list: 'Ver listado',
  evaluators: 'Evaluadores',
  modal_del_title: 'Confirmar Borrado',
  modal_del_question: '¿Desea borrar este evaluado?',
  confirm_edit_title: 'Confirme la edición de la valoración<br/>Nuevos evaluados',
  diff_cost: 'Costo de la diferencia',
  created_evaluation: '¡Valoración creada exitosamente! En breve será redireccionado al resumen de la operación',
  updated_evaluation: 'Valoración actualizada exitosamente',
  delete_evaluator_min_error: 'No puede eliminar todos los evaluadores de una relación',
  list_of_evaluators_for: 'Listado de evaluadores para',
  add_evaluator: 'Agregar evaluador',
  the_evaluated: 'El evaluado',
  the_evaluator: 'El evaluador',
  no_evaluated_type: 'no posee evaluadores de tipo',
  fix_document_or_try_again: 'por favor corrija el documento e intente nuevamente.',
  warnings_list: 'Listado de advertencias',
  multiple_appears_warning: 'se ha repetido como evaluado, por lo cual se tomó en cuenta en su primera aparición y se ignoró en la o las siguientes',
  evaluated_as_evaluator_warning: 'se ha ingresado como evaluador de su propia valoración, por lo cual se ignoró está aparición y se agregaron el resto de los evaluadores.',
  evaluated_not_found_warning: 'Los evaluados mostrados a continuación no fueron encontrados como colaboradores de su empresa:',
  duplicated_as_evaluator_of: 'se ha repetido como evaluador en la valoración de',
  take_only_first_ap: 'por lo cual se tomó en cuenta su primera aparición y se ignoraron el resto',
  added_as_evaluator_type: 'se ha ingresado como evaluador de tipo',
  on_the_evaluation_of: 'en la valoración de',
  not_found_as_employee: 'pero no fue conseguido como colaborador dentro del sistema, por lo cual no fue tomado en cuenta y se agregaron el resto',
  evaluator_type_incorrect: 'pero este tipo de evaluador es incorrecto, por lo cual no fue tomado en cuenta y se agregaron el resto',
  are_you_sure_continue_warnings: '¿Desea continuar aún con las fallas en la carga?',
  remember_warnings_msg: 'Recuerde que al continuar los datos permanecerán como fue explicado en las advertencias, se recomienda realizar la carga nuevamente',
  incorrect_file: 'Por favor cargue un archivo valido',
  operation_failed: 'Operación no exitosa, se recomienda:',
  paid_measuring: 'La valoración ya ha sido pagada.',
  link: 'Copiar enlace',
  copy_invitation_url: 'Copie el enlace y envíelo a sus colaboradores sin correo electrónico para que completen la valoración.',
  link_copied: 'Enlace copiado.',
  custom_questionnaire_info: 'Si usted desea modificar alguno de los enunciados de la encuesta podrá hacerlo, siempre y cuando se mantenga el modelo OCC DML 360º. Por favor comuníquese a través de nuestro correo info@occsolutions.org',
  options: 'Opciones',
  generate_report: 'Generar reportes',
  sending_reminders: 'Enviar recordatorios',
  close_evaluation: 'Cerrar valoración',
  close_evaluation_q: '¿Desea cerrar la valoración?',
  send_reminders_q: '¿Desea enviar recordatorios?',
  reminders_sent_succesfully: 'Recordatorios enviados correctamente',
  evaluation_closed_succesfully: 'Valoración cerrada correctamente',
  fileExist: 'Video adjunto',
  deleteFile: 'Eliminar archivo',
  fileExistNote: 'Para sustituir el archivo adjunto no es necesario eliminar el anterior.',
  evaluatedExists: 'Hay evaluados en el archivo que ya se encuentran en el listado',
  confirmation_modal_title: 'Guardar respuestas',
  confirmation_modal_des: 'Asegúrese de verificar sus respuestas, ya que una vez guardadas no se pueden modificar',
  no_leaders: 'No hay jefes',
  no_pairs: 'No hay pares',
  no_dependents: 'No hay colaboradores'
}
