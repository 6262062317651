
import pdfUtil from '../utils_old/pdf'

export default {
  methods: {
    calculateWidthsByScore (score) {
      const result = (((score - 1) * 135) / 5)
      return result
    },
    $generateResultsPerProcess () {
      const descriptions = [
        this.$t('demographicReport.table13')
      ]
      const process = [
        { name: this.$t('demographicReport.leader_as_person'), color: '#0D47A1' },
        { name: this.$t('demographicReport.management'), color: '#b4cf32' },
        { name: this.$t('demographicReport.mobilization'), color: '#4ec7aa' },
        { name: this.$t('demographicReport.achievement'), color: '#43ace2' }
      ]
      const legend = [
        this.$t('demographicReport.data_range'),
        this.$t('demographicReport.cutScore'),
        this.$t('demographicReport.ten_percent'),
        this.$t('demographicReport.higher_bold'),
        this.$t('demographicReport.lower_sub')
      ]
      let scores = []
      for (let i = 0; i < this.filteredCurrentEvaluation.averageScores.length; i++) {
        let gap = this.filteredCurrentEvaluation.averageScores[i].total - this.currentEvaluation.averageScores[i].total
        scores.push([
          { text: process[i].name.toUpperCase(), margin: [0, 6, 0, 6], border: [true, true, true, true], bold: true, fontSize: 10, style: { color: process[i].color } },
          pdfUtil.generateXDChart(this.filteredCurrentEvaluation.averageScores, i, undefined, '', 10, 23, 24, 22, 22),
          pdfUtil.higherLowerProcess(this.filteredCurrentEvaluation.averageScores, this.filteredCurrentEvaluation.averageScores[i].total, '#2e2d2d', [0, 5, 0, 0], 'center', [true, true, true, true]),
          { text: this.round(this.currentEvaluation.averageScores[i].total, 2), alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: '#2e2d2d' } },
          { text: this.round(gap, 2), alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: '#2e2d2d' } },
          { text: this.round(this.filteredCurrentEvaluation.averageScores[i].tenPercent, 2), alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: '#2e2d2d' } },
          { text: `${this.hasPrevious ? this.round(this.filteredPreviousEvaluation.averageScores[i].total, 2) : '--'}`, alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: '#2e2d2d' } },
          { text: `${this.hasPrevious ? this.round(this.filteredCurrentEvaluation.averageScores[i].total - this.filteredPreviousEvaluation.averageScores[i].total, 2) : '--'}`, alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: '#2e2d2d' } }
        ])
      }
      return [
        {
          table: {
            widths: [479],
            body: [
              [{ text: this.textToUpper('demographicReport.index[2]'), bold: true, color: '#fff', margin: [50, 8, 0, 5] }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [-40, 40, 0, 0],
          pageBreak: 'before'
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: '#aaaaaa'
          },
          table: {
            widths: [479],
            body: [
              [{ text: `${this.$t('demographicReport.table')} 13. ${descriptions[0]}`, margin: [30, 5, 0, 5], bold: true, color: '#fff' }]
            ]
          },
          margin: [35, 10, 20, 20]
        },
        {
          layout: {
            defaultBorder: ''
          },
          table: {
            widths: [479],
            body: [
              [{ text: this.$t('demographicReport.legend_description'), margin: [30, 0, 0, 0], color: '#6d7a7a' }]
            ]
          },
          margin: [35, 0, 20, 0]
        },
        pdfUtil.generateDmlLegend(legend),
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#BFBFBF' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            widths: ['27%', '31%', '7%', '7%', '7%', '7%', '7%', '7%'],
            body: [
              // Header
              [
                { text: this.$t('demographicReport.process'), margin: [0, 6, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9, lineHeight: 1 },
                { layout: { defaultBorder: '', fillColor: '#BFBFBF' },
                  table: {
                    headerRows: 1,
                    body: [[
                      { text: '1\n|', margin: [0, -1, 10, 0] },
                      { text: '2\n|', margin: [0, -1, 10, 0] },
                      { text: '3\n|', margin: [0, -1, 10, 0] },
                      { text: '4\n|', margin: [0, -1, 10, 0] },
                      { text: '5\n|', margin: [0, -1, 10, 0] },
                      { text: '6\n|', margin: [0, -1, 10, 0] }
                    ]]
                  },
                  bold: true,
                  style: { color: '#fff' },
                  border: [true, true, true, true]
                },
                { text: this.$t('demographicReport.cutScore_abbr'), margin: [0, 2, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 },
                { text: this.$t('demographicReport.organizational_score_abbr'), margin: [0, 2, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 },
                { text: this.$t('demographicReport.gap'), margin: [0, 4, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 },
                { text: this.$t('demographicReport.ten_percent_abbr'), margin: [0, 4, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 },
                { text: this.$t('demographicReport.previous_score_abbr'), margin: [0, 2, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 },
                { text: this.$t('demographicReport.trend_abbr'), margin: [0, 6, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 }
              ],
              // Body
              ...scores
            ]
          },
          margin: [5, 0, 6, 10],
          border: [false, false, false, true],
          alignment: 'center',
          fontSize: 8,
          lineHeight: 1
        }
      ]
    }
  }
}
