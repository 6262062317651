
import mapParameters from '../utils/map-parameters'
import Services from './base-services'

const service = new Services('enterprises')

interface IOptionsList {
  page?: number;
  itemsPerPage?: number;
  filter?: string;
  search?: string;
}

export default {
  list: (options: IOptionsList) => {
    return true
    // const params = mapParameters({
    //   page: options && options.page ? options.page : null,
    //   rowsPerPage: options && options.itemsPerPage ? options.itemsPerPage : null,
    //   filter: options && options.filter ? options.filter : null,
    //   search: options && options.search ? options.search : null
    // })
    // return service.get('list', params)
  },
  listWithBalance: (options: IOptionsList) => {
    const params = mapParameters({
      page: options && options.page ? options.page : null,
      rowsPerPage: options && options.itemsPerPage ? options.itemsPerPage : null,
      filter: options && options.filter ? options.filter : null,
      search: options && options.search ? options.search : null
    })
    return service.get('list-with-balance', params)
  },
  getDemographicsItems: () => {
    return service.get('get-demographics-items')
  },
  disable: (id: number) => {
    return service.put('disable', { id })
  },
  enable: (id: number) => {
    return service.put('enable', { id })
  },
  create: (data: object) => {
    return service.form('', data, {
      contact: 'object',
      license: 'object',
      demographicItems: 'array'
    })
  },
  getOne (id: number) {
    return service.get(`${id}`)
  },
  edit: (id: number, data: object) => {
    return service.form(`${id}/edit`, data, {
      contact: 'object',
      license: 'object',
      demographicItems: 'array'
    })
  },
  getProfile () {
    return service.get('profile')
  },
  editProfile () {
    return service.get('edit-profile')
  },
  selfEdit: (data: object) => {
    return service.form(`self-edit`, data, {
      headquarter: 'object',
      demographicItems: 'array'
    })
  },
  createOutside: (data: object) => {
    return service.bForm('auth/create-enterprise', data, {
      contact: 'object',
      license: 'object',
      demographicItems: 'array'
    })
  }
}
