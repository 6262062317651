
import Services from './base-services'

const service = new Services('charges')

export default {
  list: (department?: number) => {
    return service.get(`list?department=${department}`)
  },
  fetchByEnterprise: () => {
    return service.get('list-by-enterprise')
  },
  delete: (id: number) => {
    return service.get(`delete/${id}`)
  },
  toggleActive: (id: number, active: boolean) => {
    return service.post(`toggle-active/${id}`, { active })
  },
  create: (data: any) => {
    return service.post('', data)
  },
  update: (id: number, data: any) => {
    return service.put(`${id}`, data)
  },
  listFromSuite: () => {
    return service.suiteOperation(() => service.get('list-by-enterprise'))
  },
  listByDepartmentsFromSuite: (department:Array<number>) => {
    return service.suiteOperation(() => service.get(`list-by-multiple-departments?department=${department}`))
  }
}
