
export default {
  methods: {
    $generateDispersion () {
      // 309DE0 EB493C 48BBA1
      const descriptions = [
        this.$t('organizationalReport.table21'),
        this.$t('organizationalReport.table22')
      ]
      let table21 = {}
      let dispersion21 = []
      let higherDispersion = []
      let lowerDispersion = []
      let respRanking = this.othersRespRank
      respRanking.sort((a, b) => b.dispersion - a.dispersion)
      for (let i = 0; i < 3; i++) {
        dispersion21.push([
          // Higher score
          { text: respRanking[i].abbreviation, margin: [0, 4, 0, 0], border: [true, true, true, true], fontSize: 10, bold: true, style: { color: respRanking[i].abbreviation === 'P' ? '#0D47A1' : respRanking[i].abbreviation === 'D' ? '#b4cf32' : respRanking[i].abbreviation === 'M' ? '#4ec7aa' : '#43ace2' } },
          { text: respRanking[i].name, margin: [0, 4, 0, 0], border: [true, true, true, true], style: { color: '#6d7a7a' } },
          { text: this.round(respRanking[i].dispersion, 2), alignment: 'center', margin: [0, 4, 0, 0], border: [true, true, true, true], style: { color: '#6d7a7a' } },
          // Lower score
          { text: respRanking[respRanking.length - (i + 1)].abbreviation, margin: [0, 4, 0, 0], border: [true, true, true, true], fontSize: 10, bold: true, style: { color: respRanking[respRanking.length - (i + 1)].abbreviation === 'P' ? '#0D47A1' : respRanking[respRanking.length - (i + 1)].abbreviation === 'D' ? '#b4cf32' : respRanking[respRanking.length - (i + 1)].abbreviation === 'M' ? '#4ec7aa' : '#43ace2' } },
          { text: respRanking[respRanking.length - (i + 1)].name, margin: [0, 4, 0, 0], border: [true, true, true, true], style: { color: '#6d7a7a' } },
          { text: this.round(respRanking[respRanking.length - (i + 1)].dispersion, 2), alignment: 'center', margin: [0, 4, 0, 0], border: [true, true, true, true], style: { color: '#6d7a7a' } }
        ])
      }
      table21 = {
        layout: {
          defaultBorder: '',
          fillColor: function (rowIndex, node, columnIndex) {
            return (rowIndex <= 1) ? '#BFBFBF' : null
          },
          border: [false, false, false, true]
        },
        table: {
          headerRows: 1,
          body: [
            [
              { text: this.$t('organizationalReport.higher_dispersion').toUpperCase(), colSpan: 3, margin: [0, 3, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
              {},
              {},
              { text: this.$t('organizationalReport.lower_dispersion').toUpperCase(), colSpan: 3, margin: [0, 3, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
              {},
              {}
            ],
            // Header
            [
              { text: this.$t('organizationalReport.process'), margin: [0, 0, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
              { text: this.$t('organizationalReport.responsability'), margin: [45, 0, 45, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
              { text: this.$t('organizationalReport.dispersion'), margin: [0, 0, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
              { text: this.$t('organizationalReport.process'), margin: [0, 0, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
              { text: this.$t('organizationalReport.responsability'), margin: [45, 0, 45, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
              { text: this.$t('organizationalReport.dispersion'), margin: [0, 0, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } }
            ],
            // Body
            ...dispersion21
          ]
        },
        margin: [10, 0, 40, 0],
        border: [false, false, false, true],
        alignment: 'center',
        fontSize: 8
      }
      let ranking = this.othersRank
      ranking.sort((a, b) => b.dispersion - a.dispersion)
      for (let i = 0; i < 6; i++) {
        higherDispersion.push([
          // Higher score
          { text: ranking[i].abbreviation, margin: [0, 5, 0, 0], fontSize: 10, border: [true, true, true, true], bold: true, style: { color: ranking[i].abbreviation === 'P' ? '#0D47A1' : ranking[i].abbreviation === 'D' ? '#b4cf32' : ranking[i].abbreviation === 'M' ? '#4ec7aa' : '#43ace2' } },
          { text: ranking[i].responsability, margin: [0, 5, 0, 0], border: [true, true, true, true], style: { color: '#6d7a7a' } },
          { text: ranking[i].question, margin: [0, 5, 0, 0], border: [true, true, true, true], style: { color: '#6d7a7a' } },
          { text: this.round(ranking[i].dispersion, 2), alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], style: { color: '#6d7a7a' } }
        ])
        lowerDispersion.push([
          // Higher score
          { text: ranking[ranking.length - (i + 1)].abbreviation, margin: [0, 5, 0, 0], fontSize: 10, border: [true, true, true, true], bold: true, style: { color: ranking[ranking.length - (i + 1)].abbreviation === 'P' ? '#0D47A1' : ranking[ranking.length - (i + 1)].abbreviation === 'D' ? '#b4cf32' : ranking[ranking.length - (i + 1)].abbreviation === 'M' ? '#4ec7aa' : '#43ace2' } },
          { text: ranking[ranking.length - (i + 1)].responsability, margin: [0, 5, 0, 0], border: [true, true, true, true], style: { color: '#6d7a7a' } },
          { text: ranking[ranking.length - (i + 1)].question, margin: [0, 5, 0, 0], border: [true, true, true, true], style: { color: '#6d7a7a' } },
          { text: ranking[ranking.length - (i + 1)].dispersion === 0 ? 0 : this.round(ranking[ranking.length - (i + 1)].dispersion, 2), alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], style: { color: '#6d7a7a' } }
        ])
      }
      return [
        {
          table: {
            widths: [479],
            body: [
              [{ text: this.textToUpper('organizationalReport.index[5]'), bold: true, color: '#fff', margin: [50, 8, 0, 5] }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [-40, 25, 0, 0],
          pageBreak: 'before'
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: '#aaaaaa'
          },
          table: {
            widths: [479],
            body: [
              [{ text: `${this.$t('organizationalReport.table')} 21. ${descriptions[0]}`, margin: [30, 5, 0, 5], bold: true, color: '#fff' }]
            ]
          },
          margin: [35, 5, 20, 5]
        },
        {
          layout: {
            defaultBorder: ''
          },
          table: {
            widths: [479],
            body: [
              [{ text: this.$t('organizationalReport.dispersion_description'), margin: [30, 0, 0, 0], color: '#6d7a7a' }]
            ]
          },
          margin: [35, 0, 20, 0]
        },
        table21,
        {
          layout: {
            defaultBorder: '',
            fillColor: '#aaaaaa'
          },
          table: {
            widths: [479],
            body: [
              [{ text: `${this.$t('organizationalReport.table')} 22. ${descriptions[1]}`, margin: [30, 5, 0, 5], bold: true, color: '#fff' }]
            ]
          },
          margin: [35, 10, 20, 5]
        },
        {
          layout: {
            defaultBorder: ''
          },
          table: {
            widths: [479],
            body: [
              [{ text: this.$t('organizationalReport.dispersion_description'), margin: [30, 0, 0, 0], color: '#6d7a7a' }]
            ]
          },
          margin: [35, 0, 20, 5]
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex <= 1) ? '#BFBFBF' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            body: [
              [
                { text: this.$t('organizationalReport.higher_dispersion').toUpperCase(), colSpan: 4, margin: [0, 3, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                {},
                {},
                {}
              ],
              // Header
              [
                { text: this.$t('organizationalReport.process'), margin: [0, 0, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('organizationalReport.responsability'), margin: [50, 0, 50, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('organizationalReport.behavior'), margin: [90, 0, 90, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('organizationalReport.dispersion'), margin: [0, 0, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } }
              ],
              // Body
              ...higherDispersion
            ]
          },
          margin: [10, 0, 15, 5],
          border: [false, false, false, true],
          alignment: 'center',
          fontSize: 8
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex <= 1) ? '#BFBFBF' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            body: [
              [
                { text: this.$t('organizationalReport.lower_dispersion').toUpperCase(), colSpan: 4, margin: [0, 3, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                {},
                {},
                {}
              ],
              // Header
              [
                { text: this.$t('organizationalReport.process'), margin: [0, 0, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('organizationalReport.responsability'), margin: [50, 0, 50, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('organizationalReport.behavior'), margin: [90, 0, 90, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('organizationalReport.dispersion'), margin: [0, 0, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } }
              ],
              // Body
              ...lowerDispersion
            ]
          },
          margin: [10, 0, 15, 10],
          border: [false, false, false, true],
          alignment: 'center',
          fontSize: 8
        }
      ]
    }
  }
}
