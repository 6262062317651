
import pdfUtil from '../utils_old/pdf'

export default {
  methods: {
    calculateWidthsByScore (score) {
      const result = (((score - 1) * 135) / 5)
      return result
    },
    $generateResultsPerProcess () {
      // 309DE0 EB493C 48BBA1
      const descriptions = [
        this.$t('organizationalReport.table13')
      ]
      const process = [
        { name: this.$t('organizationalReport.leader_as_person'), color: '#0D47A1' },
        { name: this.$t('organizationalReport.management'), color: '#b4cf32' },
        { name: this.$t('organizationalReport.mobilization'), color: '#4ec7aa' },
        { name: this.$t('organizationalReport.achievement'), color: '#43ace2' }
      ]
      const legend = [
        this.$t('organizationalReport.data_range'),
        this.$t('organizationalReport.score'),
        this.$t('organizationalReport.ten_percent'),
        this.$t('organizationalReport.higher_bold'),
        this.$t('organizationalReport.lower_sub')
      ]
      let scores = []
      const averageScores = this.averageScores.map(averageScores => averageScores.total)
      for (let i = 0; i < 4; i++) {
        scores.push([
          { text: process[i].name.toUpperCase(), margin: i === 0 ? [0, 0, 0, 0] : [0, 6, 0, 6], border: [true, true, true, true], bold: true, fontSize: 10, style: { color: process[i].color } },
          pdfUtil.generateXDChart(this.averageScores, i),
          pdfUtil.higherLowerRow(averageScores, this.averageScores[i].total, '#2e2d2d', [0, 5, 0, 0], 'center', [true, true, true, true]),
          { text: this.round(this.averageScores[i].tenPercent, 2), alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: '#2e2d2d' } },
          { text: `${this.hasPrevious ? this.round(this.previousAverageScores[i].total, 2) : ''}`, alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: '#2e2d2d' } },
          { text: `${this.hasPrevious ? this.round(this.averageScores[i].total - this.previousAverageScores[i].total, 2) : ''}`, alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: '#2e2d2d' } }
        ])
      }
      return [
        {
          table: {
            widths: [479],
            body: [
              [{ text: this.textToUpper('organizationalReport.index[2]'), bold: true, color: '#fff', margin: [50, 8, 0, 5] }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [-40, 40, 0, 0],
          pageBreak: 'before'
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: '#aaaaaa'
          },
          table: {
            widths: [479],
            body: [
              [{ text: `${this.$t('organizationalReport.table')} 13. ${descriptions[0]}`, margin: [30, 5, 0, 5], bold: true, color: '#fff' }]
            ]
          },
          margin: [35, 10, 20, 20]
        },
        {
          layout: {
            defaultBorder: ''
          },
          table: {
            widths: [479],
            body: [
              [{ text: this.$t('organizationalReport.legend_description'), margin: [30, 0, 0, 0], color: '#6d7a7a' }]
            ]
          },
          margin: [35, 0, 20, 0]
        },
        pdfUtil.generateDmlLegend(legend),
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#BFBFBF' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            widths: ['20%', '41%', '10%', '10%', '10%', '10%'],
            body: [
              // Header
              [
                { text: this.$t('organizationalReport.process'), margin: [0, 6, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9, lineHeight: 1 },
                { layout: { defaultBorder: '', fillColor: '#BFBFBF' },
                  table: {
                    headerRows: 1,
                    body: [[
                      { text: '1\n|', margin: [10, 2, 15, 0] },
                      { text: '2\n|', margin: [0, 2, 15, 0] },
                      { text: '3\n|', margin: [0, 2, 15, 0] },
                      { text: '4\n|', margin: [0, 2, 15, 0] },
                      { text: '5\n|', margin: [0, 2, 15, 0] },
                      { text: '6\n|', margin: [0, 2, 15, 0] }
                    ]]
                  },
                  bold: true,
                  style: { color: '#fff' },
                  border: [true, true, true, true]
                },
                { text: this.$t('organizationalReport.score'), margin: [0, 6, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 },
                { text: this.$t('organizationalReport.ten_percent'), margin: [0, 2, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 },
                { text: this.$t('organizationalReport.previous_score'), margin: [0, 2, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 },
                { text: this.$t('organizationalReport.trend'), margin: [0, 6, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 }
              ],
              // Body
              ...scores
            ]
          },
          margin: [10, 0, 20, 10],
          border: [false, false, false, true],
          alignment: 'center',
          fontSize: 8,
          lineHeight: 1
        }
      ]
    }
  }
}
