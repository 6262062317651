
export default {
  methods: {
    $generateBehavior () {
      let tables = []
      // 309DE0 EB493C 48BBA1
      const descriptions = [
        this.$t('demographicReport.table5'),
        this.$t('demographicReport.table6'),
        this.$t('demographicReport.table7'),
        this.$t('demographicReport.table8'),
        this.$t('demographicReport.table9'),
        this.$t('demographicReport.table10'),
        this.$t('demographicReport.table11'),
        this.$t('demographicReport.table12')
      ]
      for (let i = 0; i < descriptions.length; i++) {
        let title = {}
        let scores = []
        if (i === 0 || i % 2 === 0) {
          title = {
            table: {
              widths: [479],
              body: [
                [{ text: this.textToUpper('demographicReport.index[1]'), bold: true, color: '#fff', margin: [50, 8, 0, 5] }]
              ]
            },
            layout: {
              fillColor: '#aaaaaa',
              defaultBorder: ''
            },
            alignment: 'left',
            margin: [-40, 25, 0, 0],
            pageBreak: i === 0 || i % 2 === 0 ? 'before' : ''
          }
        }
        let ranking = []
        if (i === 0) {
          ranking = this.rankResults.noAutoOthersRank
          ranking.sort((a, b) => b.score - a.score)
        } else if (i === 1) {
          ranking = this.rankResults.noAutoOthersRank
          ranking.sort((a, b) => a.score - b.score)
        } else if (i === 2) {
          ranking = this.rankResults.leadersRank
          ranking.sort((a, b) => b.score - a.score)
        } else if (i === 3) {
          ranking = this.rankResults.leadersRank
          ranking.sort((a, b) => a.score - b.score)
        } else if (i === 4) {
          ranking = this.rankResults.pairsRank
          ranking.sort((a, b) => b.score - a.score)
        } else if (i === 5) {
          ranking = this.rankResults.pairsRank
          ranking.sort((a, b) => a.score - b.score)
        } else if (i === 6) {
          ranking = this.rankResults.dependentsRank
          ranking.sort((a, b) => b.score - a.score)
        } else {
          ranking = this.rankResults.dependentsRank
          ranking.sort((a, b) => a.score - b.score)
        }
        for (let j = 0; j < 6; j++) {
          scores.push([
            // Higher score
            { text: ranking[j].abbreviation ? ranking[j].abbreviation : '--', margin: [0, 5, 0, 0], border: [true, true, true, true], fontSize: 10, bold: true, style: { color: ranking[j].abbreviation === 'P' ? '#0D47A1' : ranking[j].abbreviation === 'D' ? '#b4cf32' : ranking[j].abbreviation === 'M' ? '#4ec7aa' : '#43ace2' } },
            { text: ranking[j].responsability ? ranking[j].responsability : '--', margin: [0, 0, 0, 0], border: [true, true, true, true], style: { color: '#6d7a7a' } },
            { text: ranking[j].question ? ranking[j].question : '--', margin: [0, 0, 0, 0], border: [true, true, true, true], style: { color: '#6d7a7a' } },
            { text: this.round(ranking[j].score, 2), alignment: 'center', margin: [0, 8, 0, 0], border: [true, true, true, true], style: { color: '#6d7a7a' } },
            { text: this.round(ranking[j].currentScore, 2), alignment: 'center', margin: [0, 8, 0, 0], border: [true, true, true, true], style: { color: '#6d7a7a' } },
            { text: this.round(ranking[j].gap, 2), alignment: 'center', margin: [0, 8, 0, 0], border: [true, true, true, true], style: { color: '#6d7a7a' } },
            { text: this.round(ranking[j].tenPercent, 2), alignment: 'center', margin: [0, 8, 0, 0], border: [true, true, true, true], style: { color: '#6d7a7a' } },
            { text: `${this.hasPrevious ? this.round(ranking[j].previous, 2) : '--'}`, alignment: 'center', margin: [0, 8, 0, 0], border: [true, true, true, true], style: { color: '#6d7a7a' } },
            { text: `${this.hasPrevious ? this.round(ranking[j].trend, 2) : '--'}`, alignment: 'center', margin: [0, 8, 0, 0], border: [true, true, true, true], style: { color: '#6d7a7a' } }
          ])
        }
        tables.push(
          title,
          {
            layout: {
              defaultBorder: '',
              fillColor: '#aaaaaa'
            },
            table: {
              widths: [479],
              body: [
                [{ text: `${this.$t('demographicReport.table')} ${i + 5}. ${descriptions[i]}`, margin: [30, 5, 0, 5], bold: true, color: '#fff' }]
              ]
            },
            margin: [35, 10, 20, 10]
          },
          {
            layout: {
              defaultBorder: '',
              fillColor: function (rowIndex, node, columnIndex) {
                return (rowIndex === 0) ? '#BFBFBF' : null
              },
              border: [false, false, false, true]
            },
            table: {
              headerRows: 1,
              widths: ['2%', '18%', '29%', '8%', '8%', '8%', '8%', '9%', '9%'],
              body: [
                // Header
                [
                  { text: this.$t('demographicReport.process_abbr'), margin: [0, 4, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                  { text: this.$t('demographicReport.responsability'), margin: [0, 4, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                  { text: this.$t('demographicReport.behavior'), margin: [0, 4, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                  { text: this.$t('demographicReport.cutScore'), margin: [0, 4, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                  { text: this.$t('demographicReport.score'), margin: [0, 4, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                  { text: this.$t('demographicReport.gap'), margin: [0, 4, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                  { text: this.$t('demographicReport.ten_percent'), margin: [0, 4, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                  { text: this.$t('demographicReport.previous_score'), margin: [0, 4, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                  { text: this.$t('demographicReport.trend'), margin: [0, 4, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } }
                ],
                // Body
                ...scores
              ]
            },
            margin: [5, 0, 5, 5],
            border: [false, false, false, true],
            alignment: 'center',
            fontSize: 8
          }
        )
      }
      return tables
    }
  }
}
