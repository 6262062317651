
export default {
  title: 'Prices and recommendations',
  cost: '     It has a cost of ',
  rate: ' Tokens per ',
  table_ranges: 'Range of Tokens',
  table_prices: 'Price per Token',
  medition: 'medition',
  download: 'download',
  person: 'person',
  recomendation: 'It is recommended',
  onetime: ' a only excetuion',
  annual: ' Annual',
  ondemand: ' On Demand',
  ormore: 'Or More',
  services: 'SERVICES',
  recomendations: 'RECOMENDATIONS'
}
