
import dataObj from '../utils_old/data-obj'

export default {
  methods: {
    generateTitleTextG (translate, repeat, fontSize, pageBreak, margin) {
      let array = []
      array.push(
        {
          text: this.$t(`${translate + '[' + 0 + ']'}`),
          alignment: 'justify',
          margin: margin !== undefined ? margin : [0, 0, 16, 12],
          color: '#6d7a7a',
          lineHeight: 1.5,
          fontSize: fontSize,
          bold: true,
          pageBreak: pageBreak
        }
      )
      for (let index = 1; index < repeat + 1; index++) {
        array.push(
          {
            text: this.$t(`${translate + '[' + index + ']'}`),
            alignment: 'justify',
            margin: [0, 0, 16, 12],
            color: '#6d7a7a',
            lineHeight: 1.5,
            fontSize: fontSize
          }
        )
      }
      return array
    },
    $generateGapsAnalysis () {
      const table25 = []
      const gaps = []
      let ranking = this.autoRankFiltered
      let generalRanking = this.generalGap
      ranking.sort((a, b) => b.gap - a.gap)
      generalRanking.sort((a, b) => b.gap - a.gap)
      this.generalGap = []
      const textColor = '#6d7a7a'
      if (this.othersPopulationFiltered > 0) {
        for (let i = 0; i < 6; i++) {
          table25.push([
            // Higher score
            { text: ranking[i].abbreviation, margin: [0, 5, 0, 0], fontSize: 10, border: [true, true, true, true], bold: true, style: { color: ranking[i].abbreviation === 'P' ? '#0D47A1' : ranking[i].abbreviation === 'D' ? '#b4cf32' : ranking[i].abbreviation === 'M' ? '#4ec7aa' : '#43ace2' } },
            { text: ranking[i].question, margin: [0, 5, 0, 0], border: [true, true, true, true], style: { color: textColor } },
            { text: this.round(ranking[i].score, 2), alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } },
            { text: this.round(ranking[i].others, 2), alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } },
            { text: this.round(ranking[i].gap, 2), alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } },
            // Lower score
            { text: ranking[ranking.length - (i + 1)].abbreviation, margin: [0, 5, 0, 0], fontSize: 10, border: [true, true, true, true], bold: true, style: { color: ranking[ranking.length - (i + 1)].abbreviation === 'P' ? '#0D47A1' : ranking[ranking.length - (i + 1)].abbreviation === 'D' ? '#b4cf32' : ranking[ranking.length - (i + 1)].abbreviation === 'M' ? '#4ec7aa' : '#43ace2' } },
            { text: ranking[ranking.length - (i + 1)].question, margin: [0, 5, 0, 0], border: [true, true, true, true], style: { color: textColor } },
            { text: this.round(ranking[ranking.length - (i + 1)].score, 2), alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } },
            { text: this.round(ranking[ranking.length - (i + 1)].others, 2), alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } },
            { text: this.round(ranking[ranking.length - (i + 1)].gap, 2), alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } }
          ])
          const abbreviation = dataObj.propertieOrDefault(generalRanking[i], 'abbreviation', '--')
          const question = dataObj.propertieOrDefault(generalRanking[i], 'question', '--')
          const score = dataObj.propertieOrDefault(generalRanking[i], 'score', '--')
          const evaluator = generalRanking[i] ? `${this.$t(`individualReport.${generalRanking[i].evaluator}`)}` : '--'
          const other = dataObj.propertieOrDefault(generalRanking[i], 'other', '--')
          const comparedEvaluator = generalRanking[i] ? `${this.$t(`individualReport.${generalRanking[i].comparedEvaluator}`)}` : '--'
          const gap = dataObj.propertieOrDefault(generalRanking[i], 'gap', '--')
          gaps.push([
            // Higher score
            { text: abbreviation, margin: [0, 5, 0, 0], fontSize: 10, border: [true, true, true, true], bold: true, style: { color: abbreviation === 'P' ? '#0D47A1' : abbreviation === 'D' ? '#b4cf32' : abbreviation === 'M' ? '#4ec7aa' : '#43ace2' } },
            { text: question, margin: [0, 5, 0, 0], border: [true, true, true, true], style: { color: textColor } },
            { text: this.round(score, 2), alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } },
            { text: evaluator, alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: textColor } },
            { text: this.round(other, 2), alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } },
            { text: comparedEvaluator, margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: textColor } },
            { text: this.round(gap, 2), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } }
          ])
        }
      } else {
        table25.push([
          { text: this.$t('individualReport.no_others_evaluators'), margin: [0, 5, 0, 0], fontSize: 10, border: [true, true, true, true], bold: false, colSpan: 10 }
        ])
        gaps.push([
          { text: this.$t('individualReport.no_others_evaluators'), margin: [0, 5, 0, 0], fontSize: 10, border: [true, true, true, true], bold: false, colSpan: 7 }
        ])
      }
      return [
        {
          table: {
            widths: [479],
            body: [
              [{ text: `6.7. ${this.$t('individualReport.gaps_analysis_title')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 6, 0, 6] }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [-40, 0, 0, 20],
          pageBreak: 'before'
        },
        ...this.generateTitleTextG('individualReport.gaps_analysis', 2, 10),
        {
          table: {
            widths: [479],
            body: [
              [{ text: [
                `${this.$t('individualReport.table')} 25. ${this.$t('individualReport.table25')}`
              ],
              bold: true,
              color: '#fff',
              margin: [20, 6, 0, 6] }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [35, 5, 100, 20]
        },
        {
          layout: {
            defaultBorder: '--',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex <= 1) ? '#BFBFBF' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            widths: ['3%', '32%', '5%', '5%', '5%', '3%', '32%', '5%', '5%', '5%'],
            body: [
              [
                { text: this.$t('individualReport.higher_gap').toUpperCase(), colSpan: 5, margin: [0, 3, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                {},
                {},
                {},
                {},
                { text: this.$t('individualReport.lower_gap').toUpperCase(), colSpan: 5, margin: [0, 3, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                {},
                {},
                {},
                {}
              ],
              // Header
              [
                // Positive
                { text: 'P', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.behavior'), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.auto'), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.others'), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.gap'), margin: [-2, 5, -2, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                // Negative
                { text: 'P', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.behavior'), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.auto'), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.others'), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.gap'), margin: [-2, 5, -2, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } }
              ],
              // Body
              ...table25
            ]
          },
          margin: [0, 0, 0, 10],
          border: [false, false, false, true],
          alignment: 'center',
          fontSize: 8
        },
        {
          layout: {
            defaultBorder: '',
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            widths: [300],
            body: [
              [
                { text: `${this.$t('individualReport.notes')}:`, bold: true, color: textColor, margin: [0, 0, 0, 0], border: [false, false, false, true] }
              ]
            ]
          },
          margin: [30, 0, 15, 10],
          border: [false, false, false, true],
          alignment: 'left',
          fontSize: 10
        },
        {
          table: {
            widths: [479],
            body: [
              [{ text: `6.7. ${this.$t('individualReport.gaps_analysis_title')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 6, 0, 6] }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [-40, 0, 0, 20],
          pageBreak: 'before'
        },
        ...this.generateTitleTextG('individualReport.gaps_analysis2', 1, 10),
        {
          table: {
            widths: [479],
            body: [
              [{ text: [
                `${this.$t('individualReport.table')} 26. ${this.$t('individualReport.table26')}`
              ],
              bold: true,
              color: '#fff',
              margin: [20, 6, 0, 6] }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [35, 5, 100, 20],
          pageBreak: ''
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#BFBFBF' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            body: [
              // Header
              [
                { text: 'P', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.behavior'), margin: [25, 5, 25, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.higher_score'), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.evaluator'), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.lower_score'), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.evaluator'), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.gap'), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } }
              ],
              // Body
              ...gaps
            ]
          },
          margin: [0, 0, 0, 10],
          border: [false, false, false, true],
          alignment: 'center',
          fontSize: 8
        },
        {
          layout: {
            defaultBorder: '',
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            widths: [300],
            body: [
              [
                { text: `${this.$t('individualReport.notes')}:`, bold: true, color: textColor, margin: [0, 0, 0, 0], border: [false, false, false, true] }
              ]
            ]
          },
          margin: [30, 0, 15, 10],
          border: [false, false, false, true],
          alignment: 'left',
          fontSize: 10
        }
      ]
    }
  }
}
