
export default {
  cover_table_header_1: 'ESPERADAS',
  cover_table_header_2: 'RECIBIDAS',
  cover_table_header_3: 'PORCENTAJE DE RESPUESTAS',
  cover_table_row_1: 'AUTOVALORACIONES',
  cover_table_row_2: 'LÍDER(ES)',
  cover_table_row_3: 'PARES',
  cover_table_row_4: 'COLABORADORES',
  cover_table_row_5: 'TOTAL',
  index_title: 'Contenido del informe',
  index: [
    '1. Responsabilidad con Puntaje Mayor y Menor según Relación',
    '2. Conductas con Puntaje Mayor y Menor según Relación',
    '3. Resultados por Proceso',
    '4. Resultados por Responsabilidad',
    '5. Resultados por Conducta',
    '6. Análisis de Dispersión por Responsabilidad y Conducta',
    '7. Análisis de Tendencia por Responsabilidad y Conducta'
  ],
  title: 'Informe de Resultados',
  subtitle: 'Reporte Organizacional de Liderazgo',
  poll_answers: 'Respuestas a la encuesta',
  autoevaluations: 'Autovaloraciones',
  leaders: 'Líder(es)',
  pairs: 'Pares',
  dependents: 'Colaboradores',
  total: 'Total',
  received_evaluations: 'Valoraciones Recibidas',
  requested_evaluations: 'Valoraciones Solicitadas',
  answers_rate: 'Porcentaje de Respuestas',
  table: 'Tabla',
  table1: 'Tres Puntajes más altos y bajos según Otros; donde Otros es el promedio de todas las relaciones (Líderes, Pares y Colaboradores)',
  table2: 'Tres Puntajes más altos y bajos según Líder(es)',
  table3: 'Tres Puntajes más altos y bajos según Pares',
  table4: 'Tres Puntajes más altos y bajos según Colaboradores',
  table5: 'Seis Puntajes más altos según Otros; donde Otros es el promedio de todas las relaciones (Líderes, Pares y Colaboradores)',
  table6: 'Seis Puntajes más bajos según Otros; donde Otros es el promedio de todas las relaciones (Líderes, Pares y Colaboradores)',
  table7: 'Seis Puntajes más altos según Líder(es)',
  table8: 'Seis Puntajes más bajos según Líder(es)',
  table9: 'Seis Puntajes más altos según Pares',
  table10: 'Seis Puntajes más bajos según Pares',
  table11: 'Seis Puntajes más altos según Colaboradores',
  table12: 'Seis Puntajes más bajos según Colaboradores',
  table13: 'Resultados por proceso',
  table14: 'Resultados por Responsabilidad para el proceso de ',
  table17: 'Resultados por Conducta para el proceso de ',
  table21: 'Dispersión por Responsabilidad',
  table22: 'Dispersión por Conducta',
  table23: 'Tendencia por Responsabilidad',
  table24: 'Tendencia por Conducta',
  legend_description: 'En las barras de la siguiente tabla, el color gris, el rombo y la cruz representan:',
  dispersion_description: 'La dispersión se calcula como la desviación estándar de los datos',
  table_description: 'En las barras de las tablas, el color gris, la cruz y el rombo representan: ',
  higher_scores: 'Puntajes más altos',
  lower_scores: 'Puntajes más bajos',
  process: 'Proceso',
  process_abbr: 'P',
  responsability: 'Responsabilidad',
  behavior: 'Conducta',
  score: 'Puntaje',
  previous_score: 'Puntaje Anterior',
  previous_score_abbr: 'Punt. Ant.',
  current_score: 'Puntaje Actual',
  cut_score: 'Puntaje Corte',
  previous: 'Anterior',
  current: 'Actual',
  ten_percent: '10% Superior',
  dispersion: 'Dispersión',
  trend: 'Tendencia',
  higher_trends: 'Tendencias más altas positivas',
  lower_trends: 'Tendencias más altas negativas',
  trend_abbr: 'Tend.',
  data_range: 'Rango de datos (máx-min)',
  higher_bold: 'Calificaciones más altas en negrilla',
  lower_sub: 'Calificaciones más bajas subrayadas',
  management: 'Dirección',
  mobilization: 'Movilización',
  achievement: 'Logro',
  as_person: 'Como persona',
  leader_as_person: 'Líder como persona',
  results: 'Resultados',
  copyright: 'OCC - Todos los derechos reservados.',
  higher_dispersion: 'Mayor dispersión',
  lower_dispersion: 'Menor dispersión',
  higher_gap: 'Brechas más altas positivas',
  lower_gap: 'Brechas más altas negativas',
  gap: 'Brecha',
  months: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
  ],
  lead_by_example: 'Lidera la cultura con el ejemplo',
  ensures_learning: 'Asegura aprendizaje continuo y desarrollo integral',
  confirm_report_title: 'Confirme la generación del reporte',
  report_cost: 'Costo del reporte',
  balance_after: 'Saldo luego de generar el reporte:',
  confirm_btn: 'Confirmar y Generar',
  opetaion_init: 'Se ha dado inicio a un proceso de descarga "Reporte organizacional", este proceso puede tardar varios minutos, puede ver su progreso al refrescar la página',
  cover_months: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
  ],
  background_1: 'Contenido',
  background_2: 'Introducción',
  background_3: 'RESULTADOS POR DIMENSIONES',
  background_4: 'RESULTADOS',
  background_5: 'Comunicación y retroalimentación',
  background_6: 'Desarrollo personal y profesional',
  background_7: 'Planeación e implementación',
  background_8: 'Dispersión',
  background_9: 'Seguimiento',
  introduction_title_1: '01. INTRODUCCIÓN',
  introduction_title_1_paragraph_1: 'No cabe duda que las competencias de Coaching y Retroalimentación (Feedback), son esenciales para un liderazgo efectivo en el mundo de hoy. Los líderes logran los resultados a través de la gente y son en consecuencia y prioritariamente, responsables por el desarrollo integral de las personas y los equipos.\n\n',
  introduction_title_1_paragraph_2: 'El Coaching organizacional para el desempeño, como estilo de liderazgo, puede ser entendido como el proceso de acompañar a las personas para que se conviertan en lo que quieren ser. Diagnosticar competencias y ayudar a definir planes de desarrollo, desarrollar el talento y potencial de la gente, despertar la grandeza en las personas para que fijen metas retadoras, acompañar en el terreno y ofrecer apoyo cuando se requiera, crear confianza y empoderamiento, ayudar a eliminar interferencias y, en general, facilitar para que cada ser humano logre sus metas personales y profesionales. Todo lo anterior funciona igualmente para equipos.\n\n',
  introduction_title_1_paragraph_3: 'El Coaching opera desde la consciencia. Los seres humanos tomamos consciencia por reflexión o sufrimiento, pero más que nos miremos a nosotros en el espejo, hay muchas facetas y características de nosotros que no alcanzamos a observar. No es posible no impactar a otros y por eso debemos conocer el impacto que generamos en los demás.\n\n',
  introduction_title_1_paragraph_4: 'Es por esto anterior que todos necesitamos dar y recibir retroalimentación. La retroalimentación es un regalo que recibimos, es una forma fundamental de elevar consciencia y desarrollar competencias. En el mundo organizacional, un proceso formal e informal, positivo y correctivo, en todas las direcciones y bien intencionado, es fundamental para desarrollar personas y equipos de alto desempeño.\n\n',
  introduction_title_1_paragraph_5: 'Por estas razones, desarrollamos esta herramienta de auto-valoración, que representa tres Dimensiones: Comunicación y Retroalimentación, Desarrollo Personal y Profesional y Planeación e Implementación. Cada una de estas dimensiones incluye tres competencias y, a su vez, cada competencia está definida por cinco conductas. Creemos que las definiciones son suficientemente claras y permiten tener una visión de nuestras fortalezas y áreas de oportunidad.\n\n',
  introduction_title_1_paragraph_6: 'Lo anterior claro está depende de su nivel de consciencia a la hora de responder y su deseo de mejorar continuamente como líder. Con la información obtenida acerca de Usted mismo, creemos que es posible establecer un plan de desarrollo concreto y de alto impacto, adelante, desde la consciencia, la intención y la responsabilidad; todos los días podemos ser mejores líderes.\n\n',
  introduction_title_1_msg: '¡Mucho éxito!',
  results_dimention_title: '02. RESULTADOS POR DIMENSIONES',
  results_dimention_table_info_header_1: 'INFORMACIÓN DE DIMENSIONES',
  results_dimention_table_info_sub_header_1: 'DIMENSIÓN',
  results_dimention_table_info_sub_header_2: 'ABREVIATURA',
  results_dimention_table_info_sub_header_3: 'AUTO',
  results_dimention_table_info_sub_header_4: 'EQUIPO',
  results_dimention_table_header_1: 'RESULTADO',
  results_dimention_table_header_2: 'NIVEL',
  results_dimention_table_header_3: 'INTERPRETACIÓN',
  results_dimention_table_row_1_col_1: 'Entre 5 y 6',
  results_dimention_table_row_1_col_2: 'Alto',
  results_dimention_table_row_1_col_3: 'Competencia clara',
  results_dimention_table_row_2_col_1: 'Entre 3 y 4',
  results_dimention_table_row_2_col_2: 'Aceptable',
  results_dimention_table_row_2_col_3: 'Desempeño aceptable',
  results_dimention_table_row_3_col_1: 'De 2 y menor a 3',
  results_dimention_table_row_3_col_2: 'Medio',
  results_dimention_table_row_3_col_3: 'Requiere atención',
  results_dimention_table_row_4_col_1: 'Menor a 2',
  results_dimention_table_row_4_col_2: 'Bajo',
  results_dimention_table_row_4_col_3: 'Requiere desarrollo prioritario',
  rank_title_1: '1. RESPONSABILIDAD CON PUNTAJE MAYOR Y MENOR SEGÚN RELACIÓN',
  rank_title_1_sub_1: 'Tabla 1. Tres Puntajes más altos y bajos según Otros; donde Otros es el promedio de todas las relaciones (Líderes, Pares y Colaboradores)',
  rank_title_1_sub_2: 'Tabla 2. Tres Puntajes más altos y bajos según Líder(es)',
  rank_title_1_sub_3: 'Tabla 3. Tres Puntajes más altos y bajos según Pares',
  rank_title_1_sub_4: 'Tabla 4. Tres Puntajes más altos y bajos según Colaboradores',
  rank_title_1_info: 'Estas son las competencias con los 3 puntajes más altos (Fortalezas) y los 3 más bajos (Oportunidades de Mejora) según el promedio de la autovaloración de los líderes.',
  rank_title_2: '2. CONDUCTAS CON PUNTAJE MAYOR Y MENOR SEGÚN RELACIÓN',
  rank_title_2_sub_1: 'Tabla 5. Seis Puntajes más altos según Otros; donde Otros es el promedio de todas las relaciones (Líderes, Pares y Colaboradores)',
  rank_title_2_sub_2: 'Tabla 6. Seis Puntajes más bajos según Otros; donde Otros es el promedio de todas las relaciones (Líderes, Pares y Colaboradores)',
  rank_title_2_sub_3: 'Tabla 7. Seis Puntajes más altos según Líder(es)',
  rank_title_2_sub_4: 'Tabla 8. Seis Puntajes más bajos según Líder(es)',
  rank_title_2_sub_5: 'Tabla 9. Seis Puntajes más altos según Pares',
  rank_title_2_sub_6: 'Tabla 10. Seis Puntajes más bajos según Pares',
  rank_title_2_sub_7: 'Tabla 11. Seis Puntajes más altos según Colaboradores',
  rank_title_2_sub_8: 'Tabla 12. Seis Puntajes más bajos según Colaboradores',
  rank_table_1_header_1: 'PUNTAJES MÁS ALTOS',
  rank_table_1_header_2: 'PUNTAJES MÁS BAJOS',
  rank_table_1_sub_header_1: 'PROCESO',
  rank_table_1_sub_header_2: 'RESPONSABILIDAD',
  rank_table_1_sub_header_3: 'PUNTAJE',
  rank_table_1_sub_header_4: '10% SUPERIOR',
  rank_table_1_sub_header_5: 'TENDENCIA',
  rank_table_2_header_1: 'PUNTAJES MÁS ALTOS',
  rank_table_2_header_2: 'PUNTAJES MÁS BAJOS',
  rank_table_2_sub_header_1: 'D',
  rank_table_2_sub_header_2: 'COMPETENCIA',
  rank_table_2_sub_header_3: 'CONDUCTA',
  rank_table_2_sub_header_4: 'PUNTAJE',
  rank_table_2_sub_header_5: 'TENDENCIA',

  results_title_1: '07. Resultado por Dimensión',
  result_legend_1_table_1: 'Rango de datos (min-max)',
  result_legend_1_table_2: 'Puntaje',
  result_legend_1_table_3: 'Calificaciones más altas en negrita',
  result_legend_1_table_4: 'Calificaciones más bajas en subrayadas',
  results_title_1_table_header_1: 'DIMENSIÓN',
  results_title_1_table_header_2: 'VALORACIÓN',
  results_title_1_table_header_3: 'PUNTAJE',
  results_title_1_table_header_4: 'PUNTAJE ANTERIOR',
  results_title_1_table_header_5: 'TENDENCIA',
  results_title_2: '08. Resultado por Competencia',
  results_title_2_info: 'Resultados por competencia para la dimensión ',
  results_title_2_table_header_1: 'COMPETENCIA',
  results_title_2_table_header_2: 'VALORACIÓN',
  results_title_2_table_header_3: 'PUNTAJE',
  results_title_2_table_header_4: 'PUNTAJE ANTERIOR',
  results_title_2_table_header_5: 'TENDENCIA',
  results_title_3: '  09. Resultado por Conducta',
  results_title_3_info: 'Resultados por Conducta para la dimensión ',
  result_legend_2_table_1: 'Rango de datos (min-max)',
  result_legend_2_table_2: 'Puntaje',
  result_legend_2_table_3: '10%\nSuperior',
  result_legend_2_table_4: 'Calificaciones más altas en negrita',
  result_legend_2_table_5: 'Calificaciones más bajas en subrayadas',
  results_title_3_table_1_header_1: 'DIMENSIÓN',
  results_title_3_table_1_header_2: 'VALORACIÓN',
  results_title_3_table_1_header_3: 'PUNTAJE',
  results_title_3_table_1_header_4: '10% SUPERIOR',
  results_title_3_table_1_header_5: 'PUNTAJE ANTERIOR',
  results_title_3_table_1_header_6: 'TENDENCIA',
  results_title_3_table_2_header_1: 'CONDUCTA',
  results_title_3_table_2_header_2: 'VALORACIÓN',
  results_title_3_table_2_header_3: 'PUNTAJE',
  results_title_3_table_2_header_4: '10% SUPERIOR',
  results_title_3_table_2_header_5: 'PUNTAJE ANTERIOR',
  results_title_3_table_2_header_6: 'TENDENCIA',
  scatter_title: '10. Análisis de dispersión por competencia y conducta',
  scatter_table_1_title: 'Dispersión por competencia',
  scatter_table_1_info: 'La dispersión se calcula como la desviación estándar de los datos',
  scatter_table_1__header_1: 'MAYOR DISPERSIÓN',
  scatter_table_1__header_2: 'MENOR DISPERSIÓN',
  scatter_table_1__sub_header_1: 'DIMENSIÓN',
  scatter_table_1__sub_header_2: 'COMPETENCIA',
  scatter_table_1__sub_header_3: 'DISPERSIÓN',
  scatter_table_2_title: 'Dispersión por conducta',
  scatter_table_2_info: 'La dispersión se calcula como la desviación estándar de los datos',
  scatter_table_2__header_1: 'MAYOR DISPERSIÓN',
  scatter_table_2__header_2: 'MENOR DISPERSIÓN',
  scatter_table_2__sub_header_1: 'DIMENSIÓN',
  scatter_table_2__sub_header_2: 'COMPETENCIA',
  scatter_table_2__sub_header_3: 'CONDUCTA',
  scatter_table_2__sub_header_4: 'DISPERSIÓN',
  trend_title: '11. Análisis de tendencias',
  trend_info: 'Las siguientes tablas solo aplica cuando se hace una medición de seguimiento. En esta se presentan los 6 mayores cambios o saltos Positivos del Equipo (donde el promedio actual es mayor al anterior) y Negativos (donde el promedio actual disminuye con respecto al anterior).',
  trend_msg: 'Disponible a partir de la segunda medición',
  trend_table_1_title: 'TENDENCIAS POSITIVAS',
  trend_table_1_header_1: 'DIMENSIÓN',
  trend_table_1_header_2: 'COMPETENCIA',
  trend_table_1_header_3: 'CONDUCTA',
  trend_table_1_header_4: 'ACTUAL',
  trend_table_1_header_5: 'ANTERIOR',
  trend_table_1_header_6: 'TENDENCIA',
  trend_table_2_title: 'TENDENCIAS NEGATIVAS',
  trend_table_2_header_1: 'DIMENSIÓN',
  trend_table_2_header_2: 'COMPETENCIA',
  trend_table_2_header_3: 'CONDUCTA',
  trend_table_2_header_4: 'ACTUAL',
  trend_table_2_header_5: 'ANTERIOR',
  trend_table_2_header_6: 'TENDENCIA'
}
