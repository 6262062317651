
export default {
  add_group: 'Crear Grupo',
  groups_list: 'Listado de Grupos',
  watch_requests: 'Ver Solicitudes',
  no_data: 'No hay grupos para mostrar',
  // Headers
  id: 'Id',
  group_name: 'Nombre grupo',
  enterprises_no: 'N° Empresas',
  main_customer: 'Líder',
  main_customer_tokens: 'Saldo Tokens Líder',
  group_tokens: 'Saldo Tokens Grupo',
  change_leader: 'Cambiar Líder',
  delete_from_group: 'Eliminar Cliente de Grupo',
  customer_no_group: 'Este Cliente no se encuentra asignado a ningun Grupo',
  login_as_customer: 'Acceder como este Cliente',
  add_member: 'Agregar Miembro',
  actual_tokens: 'Saldo Actual Tokens',
  delete_member: 'Eliminar Miembro',
  add_member_success: 'Member agregado exitosament',
  delete_member_message: 'Miembro eliminado exitosamente',
  leader_changed_success: 'Líder cambiado exitosamente',
  delete_member_question: '¿Desea eliminar el miembro del grupo?',
  status: 'Estado',
  actions: 'Acciones',
  modal_enable: '¿Desea habilitar el grupo?',
  modal_disable: '¿Desea Inhabilitar el grupo?',
  // tslint:disable-next-line:max-line-length
  modal_enable_des: 'Una vez habilitado el grupo, el administrador del grupo podrá gestionar el licenciamiento de las empresas del grupo.',
  // tslint:disable-next-line:max-line-length
  modal_disable_des: 'Una vez sea inhabilitado el grupo, el administrador del grupo no podrá gestionar el licenciamiento de las empresas del grupo.',
  modal_save_btn: 'Aceptar',
  created_group: 'Grupo creado',
  updated_group: 'Grupo modificado exitosamente!',
  edit_group: 'Editar Grupo',
  main_enterprise: 'Cliente líder',
  current_plan: 'Saldo',
  associated_enterprises: 'Empresa',
  want_to_select_enterprises: '¿Desea seleccionar las empresas del grupo a las cuales aplicar este cambio?',
  want_other_start: '¿Desea especificar una fecha de inicio?',
  must_select_enterprises: 'Debe seleccionar las empresas para actualizar del listado',
  must_select_plan: 'Debe seleccionar el nuevo plan',
  no_update_requests: 'Este grupo no posee solicitudes de actualización pendientes.',
  plan_updated: 'Esta solicitud ha sido aplicada exitosamente.',
  plan_rejected: 'Esta solicitud ha sido rechazada exitosamente.'
}
