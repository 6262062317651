
import Services from './base-services'

const service = new Services('demographic-items')

export default {
  list: () => {
    return service.get('list')
  },
  getDemographicsItems: () => {
    return service.suiteOperation(() => service.get('list'))
  }
}
