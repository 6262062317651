
<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-row justify="space-between" class="px-8">
          <h4 class="display-1 left">{{ evaluation.name }}</h4>
          <v-chip
            class="mb-3 white--text right"
            color="primary"
            v-if="evaluation.displayName"
          >{{ evaluation.displayName }}</v-chip>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-row class="ma-3" justify="center">
            <v-col cols="12">
              <v-select
                v-model="typeReport"
                :items="items"
                :label="$t('reports.select_report_type')"
                item-value="value"
                item-text="name"
              />
            </v-col>
          </v-row>

          <!-- General -->
          <v-card-text v-if="typeReport === 'organizational'">
            <h5 class="headline mb-2">{{ $t('reports.required_general_title') }}</h5>
            <p class="mt-5 mb-12">{{ $t('reports.required_general_desc') }}</p>
            <v-row justify="center">
              <span v-if="displayReport">
                  <x-thread-organizational-report
                    v-if="validEvaluation"
                    :poll-id="this.$route.params.id"
                    :already-generated="computedAlreadyGeneratedOrg"
                    :disable-button="disableButton"
                  ></x-thread-organizational-report>
                  <div v-else class="headline red--text">{{ $t('reports.no_autocompleteds') }}</div>
              </span>
            </v-row>
          </v-card-text>

          <!-- Individual -->
          <v-card-text v-if="typeReport === 'individual'">
            <h5 class="headline mb-2">{{ $t('reports.individual_title') }}</h5>
            <p class="mt-5 mb-12">{{ $t('reports.individual_desc') }}</p>
            <v-row justify="center">
              <v-col cols="12">
                <v-autocomplete
                  v-model="evaluated"
                  :items="evaluation.evaluated"
                  :label="$t('reports.evaluated')"
                  item-value="employee.employee.id"
                  item-text="employee.employee.text"
                  @change="changeIndividual"
                />
              </v-col>
              <span v-if="displayReport">
                <x-thread-individual-report
                  v-if="validEvaluated"
                  :poll-id="this.$route.params.id"
                  :evaluatedId="Number.parseInt(evaluated)"
                  :evaluatedName="evaluatedName"
                  :disable-button="!!evaluated"
                  :poll-completed="evaluated && validEvaluated"
                  :already-generated="computedAlreadyGeneratedInd.includes(evaluated)"
                ></x-thread-individual-report>
                <div v-else class="headline red--text">{{ $t('reports.no_autocompleted') }}</div>
              </span>
            </v-row>
          </v-card-text>

          <!-- Demographic -->
          <v-card-text v-if="typeReport === 'demographic'" class="px-8">
            <h5 class="headline mb-2">{{ $t('reports.demographic_title') }}</h5>
            <p class="mt-5 mb-2">{{ $t('reports.demographic_desc') }}</p>
            <h6 class="headline mb-2 text-right mt-10">{{ $t('reports.number_leaders_evaluated') }}: {{ showCountedEvaluated }}/{{ evaluatedLeaders.length }}</h6>
            <v-row justify="center" align="center">
              <v-col cols="12">
                <v-row>
                  <v-col xs="12" sm="6">
                    <v-select
                      v-model="cutsSelected['country']"
                      :label="$t(`reports.demographicCuts.country`)"
                      :items="demographicCuts['country']"
                      :item-value="'id'"
                      :item-text="'translate.label'"
                      :menu-props="{ offsetY: true }"
                      outlined
                      multiple
                      chips
                      deletable-chips
                      @change="updateHeadquarters($event)"
                    />
                  </v-col>
                  <v-col xs="12" sm="6">
                    <v-select
                      v-model="cutsSelected['headquarter']"
                      :label="$t(`reports.demographicCuts.headquarter`)"
                      :items="demographicCuts['headquarter']"
                      :item-value="'id'"
                      :item-text="'name'"
                      :menu-props="{ offsetY: true }"
                      outlined
                      multiple
                      chips
                      deletable-chips
                      :loading="loadingHeadquarters"
                    />
                  </v-col>
                  <v-col xs="12" sm="6">
                    <v-select
                      v-model="cutsSelected['departments']"
                      :label="$t(`reports.demographicCuts.departments`)"
                      :items="demographicCuts['departments']"
                      :item-value="'id'"
                      :item-text="'translate.label'"
                      :menu-props="{ offsetY: true }"
                      outlined
                      multiple
                      chips
                      deletable-chips
                      @change="updateCharges($event)"
                    />
                  </v-col>
                  <v-col xs="12" sm="6">
                    <v-select
                      v-model="cutsSelected['charge']"
                      :label="$t(`reports.demographicCuts.charge`)"
                      :items="demographicCuts['charge']"
                      :item-value="'id'"
                      :item-text="'translate.label'"
                      :menu-props="{ offsetY: true }"
                      outlined
                      multiple
                      chips
                      deletable-chips
                      :loading="loadingCharges"
                    />
                  </v-col>
                  <v-col xs="12" sm="6">
                    <v-select
                      v-model="cutsSelected['jobTypes']"
                      :label="$t(`reports.demographicCuts.jobTypes`)"
                      :items="demographicCuts['jobTypes']"
                      :item-value="'id'"
                      :item-text="'translate.label'"
                      :menu-props="{ offsetY: true }"
                      outlined
                      multiple
                      chips
                      deletable-chips
                    />
                  </v-col>
                  <v-col xs="12" sm="6">
                    <v-select
                      v-model="cutsSelected['academicDegree']"
                      :label="$t(`reports.demographicCuts.academicDegree`)"
                      :items="demographicCuts['academicDegree']"
                      :item-value="'id'"
                      :item-text="'translate.label'"
                      :menu-props="{ offsetY: true }"
                      outlined
                      multiple
                      chips
                      deletable-chips
                    />
                  </v-col>
                  <v-col xs="12" sm="6">
                    <v-select
                      v-model="cutsSelected['age']"
                      :label="$t(`reports.demographicCuts.age`)"
                      :items="demographicCuts['age'].es"
                      :item-value="'range'"
                      :item-text="'name'"
                      :menu-props="{ offsetY: true }"
                      outlined
                      multiple
                      chips
                      deletable-chips
                    />
                  </v-col>
                  <v-col xs="12" sm="6">
                    <v-select
                      v-model="cutsSelected['antiquity']"
                      :label="$t(`reports.demographicCuts.antiquity`)"
                      :items="demographicCuts['antiquity'].es"
                      :item-value="'range'"
                      :item-text="'name'"
                      :menu-props="{ offsetY: true }"
                      outlined
                      multiple
                      chips
                      deletable-chips
                    />
                  </v-col>
                  <v-col xs="12" sm="6">
                    <v-select
                      v-model="cutsSelected['gender']"
                      :label="$t(`reports.demographicCuts.gender`)"
                      :items="demographicCuts['gender']"
                      :item-value="'id'"
                      :item-text="'translate.label'"
                      :menu-props="{ offsetY: true }"
                      outlined
                      multiple
                      chips
                      deletable-chips
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col xs="12" class="pt-0">
                <span class="d-flex justify-center">
                  <x-thread-demographic-report
                    v-show="hasCutSelected && showCountedEvaluated > 0"
                    :poll-id="this.$route.params.id"
                    :cutsSelected="cutsSelected"
                  ></x-thread-demographic-report>
                </span>
                <span
                  v-if="!showCountedEvaluated > 0"
                  class="headline red--text d-flex justify-center"
                >{{ $t('reports.message_number_leaders_evaluated') }}</span>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider class="mt-4"></v-divider>

          <v-card-text v-if="reportThreads.length" class="pa-0">
            <v-row class="mt-1 pr-8">
              <v-col cols="12" sm="8" class="pl-7">
                <h5 class="headline">{{ $t('reports.generated_reports') }}</h5>
              </v-col>
            <!-- Refresh Page Button -->
              <v-col cols="12" sm="4" class="pr-8 text-right">
                <template v-if="showUpdateProgressBtn">
                  <span
                    class="caption d-inline-block mt-2 grey--text text--darken-1 cursor-pointer"
                    @click="getThreads"
                  >
                    {{ $t('individualReport.input_update_progress') }}
                  </span>
                  <v-btn fab dark x-small
                    absolute
                    right
                    color="primary"
                    class="refresh-fab mr-n1"
                    :loading="loadingThreads"
                    @click="getThreads"
                  >
                    <v-icon>mdi-reload</v-icon>
                  </v-btn>
                </template>
              </v-col>
              <v-col cols="12" class="pt-0 pl-8">
                <p class="mb-0 text-justify" v-html="$t('reports.generated_reports_desc')"></p>
              </v-col>
            </v-row>
            <!-- Report Requests -->
            <v-row>
              <v-col cols="12" class="my-0 pt-4 py-0">
                <x-report-threads
                  :individuals="evaluation.evaluated"
                  :threads="reportThreads"
                  :demographics="demographicCuts"
                ></x-report-threads>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-row justify="center">
              <v-col cols="10">
                <v-btn class="ml-5 my-3" @click="goBack()" large block>{{ $t('input.back') }}</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="showModal" width="600px">
      <v-card>
        <v-card-text style="padding: 24px 20px;">{{ $t('reports.no_answers_modal_msg') }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="white--text"
            large
            block
            color="#3898d9"
            @click="showModal = !showModal"
          >{{ $t('input.accept') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<style lang="scss">
  .refresh-fab.v-btn--fab.v-btn--absolute {
    z-index: 3;
  }
</style>

<script>
import { mapState } from 'vuex'

import XThreadOrganizationalReport from '../reports/thread_organizational_report'
import XThreadIndividualReport from '../reports/thread_individual_report'
import XThreadDemographicReport from '../reports/thread_demographic_report'

import XReportThreads from '../reports/report-threads'

import resolver from '../../utils/resolver'
import evaluationService from '../../services/evaluations'
import demographicService from '../../services/demographic-items'
import academicDegreeService from '../../services/academic-degrees'
import chargeService from '../../services/charges'
import countryService from '../../services/countries'
import departmentService from '../../services/departments'
import genderService from '../../services/genders'
import headquarterService from '../../services/headquarters'
import jobTypeService from '../../services/job-types'
import { type } from 'os'

export default {
  components: {
    XThreadOrganizationalReport,
    XThreadIndividualReport,
    XThreadDemographicReport,
    XReportThreads
  },
  data () {
    return {
      loadingThreads: false,
      cutsSelected: {},
      isAnyCutSelected: false,
      demographicCuts: {},
      demographicItemsData2: {},
      displayCuts: false,
      disableButton: false,
      disableIndividualButton: false,
      evaluation: {},
      evaluatedLeaders: [],
      displayReport: true,
      headquarterTemp: [],
      chargesTemp: [],
      showModal: false,
      loadingHeadquarters: false,
      loadingCharges: false,
      typeReport: 'organizational',
      evaluated: '',
      countedEvaluated: 0,
      validEvaluation: true,
      items: [
        { name: this.$i18n.t('reports.required_general_title'), value: 'organizational' },
        { name: this.$i18n.t('reports.individual_title'), value: 'individual' },
        { name: this.$i18n.t('reports.demographic_title'), value: 'demographic' }
      ],
      reportThreads: [],
      evaluatedName: ''
    }
  },
  computed: {
    computedAlreadyGeneratedOrg () {
      let isGenerated = false
      if (this.reportThreads.length) {
        const org = this.reportThreads.find((t) => {
          return t.data.type === 'organizational'
        })
        if (org) {
          isGenerated = true
        }
      }
      return isGenerated
    },
    computedAlreadyGeneratedInd () {
      let generated = []
      if (this.reportThreads.length) {
        generated = this.reportThreads.filter((t) => {
          return t.data.type === 'individual'
        }).map(x => x.data.individualReference)
      }
      return generated
    },
    showUpdateProgressBtn () {
      let shouldShow = false
      if (this.reportThreads.length) {
        const inProgress = this.reportThreads.find((t) => {
          return t.data.progress !== 100
        })
        if (inProgress) {
          shouldShow = true
        }
      }
      return shouldShow
    },
    validEvaluated () {
      const evaluated = this.evaluation.evaluated.find((ev) => ev.employee.employee.id === this.evaluated)
      return !this.evaluated || (evaluated && evaluated.employee && evaluated.employee.completed)
    },
    showCountedEvaluated () {
      return this.countedEvaluated
    },
    hasCutSelected () {
      return this.isAnyCutSelected
    }
  },
  created () {
    this.getEvaluation()
    this.getDemographicsItems()
  },
  methods: {
    getThreads () {
      this.loadingThreads = true
      evaluationService.currentThreadsById(this.$route.params.id)
        .then((res) => {
          this.reportThreads = res
        })
        .finally(() => {
          this.loadingThreads = false
        })
    },
    changeIndividual () {
      const employee = this.evaluation.evaluated.find(employee => employee.employee.employee.id === this.evaluated)
      if (employee) {
        this.evaluatedName = employee.employee.employee.text
      }
    },
    getDemographicsItemsByEntreprise () {
      let demographicItemsData = {
        jobTypes: [],
        headquarter: [],
        gender: [],
        departments: [],
        country: [],
        charge: [{}],
        antiquity: [],
        age: [],
        academicDegree: []
      }
      let demographicItem = []

      return resolver.all({
        academicDegrees: academicDegreeService.listFromSuite(),
        charges: chargeService.listFromSuite(),
        countries: countryService.listFromSuite(),
        demographicItem: demographicService.getDemographicsItems(),
        departments: departmentService.listFromSuite(),
        genders: genderService.listFromSuite(),
        headquarters: headquarterService.listFromSuite(),
        jobTypes: jobTypeService.listFromSuite()
      })
        .then((res) => {
          demographicItem.push(...res.demographicItem.items)
          demographicItemsData.academicDegree = res.academicDegrees.items
          demographicItemsData.departments = res.departments.items
          demographicItemsData.charge = []
          for (let index = 0; index < res.charges.items.length; index++) {
            let object = { label: res.charges.items[index].translations[0].label, id: res.charges.items[index].id }
            demographicItemsData.charge.push(res.charges.items[index])
            demographicItemsData.charge[index].translate = object
          }
          demographicItemsData.country = res.countries.items
          demographicItemsData.gender = res.genders.items
          demographicItemsData.headquarter = res.headquarters
          demographicItemsData.jobTypes = res.jobTypes.items

          return { demographicItem: demographicItem, data: demographicItemsData }
        })
    },
    goBack () {
      this.$router.go(-1)
    },
    getEvaluation () {
      this.$store.dispatch('loading/show')
      evaluationService.findById(this.$route.params.id)
        .then((res) => {
          if (res.status !== 'completed') {
            this.goBack()
            return
          }
          this.evaluation = res
          this.validateEvaluation(res.evaluated)
          this.evaluatedLeaders = res.evaluated
          this.disableButton = true
          this.disableIndividualButton = false
          this.$store.dispatch('loading/hide')
          this.getThreads()
        })
        .catch(err => {
          console.log(err)
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        })
    },
    getDemographicsItems () {
      this.getDemographicsItemsByEntreprise()
        .then(res => {
          this.demographicCuts = res.data
          let antiquity = {
            es: [
              { range: { min: 0, max: 0.5 }, name: 'Menos de 6 meses' },
              { range: { min: 0.6, max: 1 }, name: 'De 6 meses a 1 año' },
              { range: { min: 1, max: 3 }, name: 'De 1 año a 3 años' },
              { range: { min: 3, max: 5 }, name: 'De 3 años a 5 años' },
              { range: { min: 5, max: 10 }, name: 'De 5 años a 10 años' },
              { range: { min: 10, max: 20 }, name: 'De 10 años a 20 años' },
              { range: { min: 20, max: 0 }, name: 'Más de 20 años' }
            ],
            en: [
              { range: { min: 0, max: 0.5 }, name: 'Less than 6 months' },
              { range: { min: 0.6, max: 1 }, name: 'From 6 months to 1 year' },
              { range: { min: 1, max: 3 }, name: 'From 1 year to 3 years' },
              { range: { min: 3, max: 5 }, name: 'From 3 years to 5 years' },
              { range: { min: 5, max: 10 }, name: 'From 5 years to 10 years' },
              { range: { min: 10, max: 20 }, name: 'From 10 years to 20 years' },
              { range: { min: 20, max: 0 }, name: 'More than 20 years' }
            ]
          }

          let age = {
            es: [
              { range: { min: 0, max: 25 }, name: 'Menos de 25 años' },
              { range: { min: 25, max: 35 }, name: 'De 25 a 35 años' },
              { range: { min: 35, max: 45 }, name: 'De 35 a 45 años' },
              { range: { min: 45, max: 55 }, name: 'De 45 a 55 años' },
              { range: { min: 55, max: 0 }, name: 'Más de 55 años' }
            ],
            en: [
              { range: { min: 0, max: 25 }, name: 'Less than 25 years' },
              { range: { min: 25, max: 35 }, name: 'From 25 to 35 years' },
              { range: { min: 35, max: 45 }, name: 'From 35 to 45 years' },
              { range: { min: 45, max: 55 }, name: 'From 45 to 55 years' },
              { range: { min: 55, max: 0 }, name: 'More than 55 years' }
            ]
          }

          this.demographicCuts.age = age
          this.demographicCuts.antiquity = antiquity
          this.headquarterTemp = this.demographicCuts.headquarter
          this.chargesTemp = this.demographicCuts.charge
        })
        .catch(err => {
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        })
    },
    reloadReport () {
      this.disableButton = false
      this.$nextTick().then(() => { this.disableButton = true })
    },
    validateEvaluation (evaluated) {
      const validEvaluated = evaluated.filter((ev) => ev.employee.completed === true)
      this.validEvaluation = validEvaluated.length > 0
    },
    updateHeadquarters (countries) {
      this.loadingHeadquarters = true
      if (countries.length > 0) {
        headquarterService.listByCountriesFromSuite(countries).then(res => {
          this.demographicCuts.headquarter = res.items
          this.loadingHeadquarters = false
        })
      } else {
        this.demographicCuts.headquarter = this.headquarterTemp
        this.loadingHeadquarters = false
      }
    },
    updateCharges (departments) {
      this.loadingCharges = true
      if (departments.length > 0) {
        chargeService.listByDepartmentsFromSuite(departments).then(res => {
          this.demographicCuts.charge = res.items
          this.loadingCharges = false
        })
      } else {
        this.demographicCuts.charge = this.chargesTemp
        this.loadingCharges = false
      }
    },
    countEvaluated (newValue) {
      let cutSelected = {
        country: newValue.country || [],
        headquarter: newValue.headquarter || [],
        departments: newValue.departments || [],
        charge: newValue.charge || [],
        jobTypes: newValue.jobTypes || [],
        academicDegree: newValue.academicDegree || [],
        gender: newValue.gender || [],
        age: newValue.age || [],
        antiquity: newValue.antiquity || []
      }
      if (
        cutSelected.country.length === 0 &&
        cutSelected.headquarter.length === 0 &&
        cutSelected.departments.length === 0 &&
        cutSelected.charge.length === 0 &&
        cutSelected.jobTypes.length === 0 &&
        cutSelected.academicDegree.length === 0 &&
        cutSelected.gender.length === 0 &&
        cutSelected.age.length === 0 &&
        cutSelected.antiquity.length === 0
      ) {
        this.countedEvaluated = 0
        this.isAnyCutSelected = false
      } else {
        const leadersFiltered = this.evaluatedLeaders.filter((ev) => {
          return !(
            (cutSelected.country.indexOf(ev.employee.employee.countryId) === -1 && cutSelected.country.length > 0) ||
            (cutSelected.headquarter.indexOf(ev.employee.employee.headquarterId) === -1 && cutSelected.headquarter.length > 0) ||
            (cutSelected.departments.indexOf(ev.employee.employee.departmentId) === -1 && cutSelected.departments.length > 0) ||
            (cutSelected.charge.indexOf(ev.employee.employee.chargeId) === -1 && cutSelected.charge.length > 0) ||
            (cutSelected.jobTypes.indexOf(ev.employee.employee.jobTypeId) === -1 && cutSelected.jobTypes.length > 0) ||
            (cutSelected.academicDegree.indexOf(ev.employee.employee.academicDegreeId) === -1 && cutSelected.academicDegree.length > 0) ||
            (cutSelected.gender.indexOf(ev.employee.employee.genderId) === -1 && cutSelected.gender.length > 0) ||
            (cutSelected.age.length > 0 && !this.calculateTime(cutSelected.age, ev.employee.employee.birthdate)) ||
            (cutSelected.antiquity.length > 0 && !this.calculateTime(cutSelected.antiquity, ev.employee.employee.admission))
          ) || false
        })
        this.countedEvaluated = leadersFiltered.length
        this.isAnyCutSelected = true
      }
    },
    calculateTime (range = [], date) {
      let currentDate = new Date()
      let oldDate = new Date(date)
      let milliseconds = 12 * 30.416666666666668 * 24 * 60 * 60 * 1000
      let millisecondsElapsed = currentDate - oldDate
      let yearsElapsed = Math.floor(millisecondsElapsed / milliseconds)
      let evaluatedLeaderRange = range.filter((item) => {
        return (yearsElapsed >= item.min && (yearsElapsed < item.max || item.max === 0)) || false
      })
      return evaluatedLeaderRange.length > 0 || false
    }
  },
  watch: {
    cutsSelected: {
      deep: true,
      handler: function (newValue, oldValue) {
        this.countEvaluated(newValue)
      }
    }
  }
}
</script>
