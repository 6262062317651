
export default {
  index_title: 'Contenido del informe',
  index1: [
    '1. ¿Para qué es importante el Liderazgo?',
    '2. ¿Para qué una valoración de Liderazgo 360°?',
    '3. ¿En qué consiste el Modelo Liderazgo DML 360°?'
  ],
  index2: [
    { name: 'Dirección', index: 1 },
    { name: 'Movilización', index: 2 },
    { name: 'Logro', index: 3 }
  ],
  index3: [
    '4. ¿Cómo es la herramienta de valoración?',
    '5. ¿Cómo interpretar el reporte?',
    '6. Resultados Valoración DML 360°',
    '6.1. Puntajes más altos',
    '6.2. Puntajes más bajos',
    '6.3. Resultados por Proceso',
    '6.4. Resultados por Responsabilidad',
    '6.5. Resultados por Conductas',
    '6.6. Fortalezas y oportunidad de mejora confirmadas y desconocidas',
    '6.7. Análisis de brechas',
    '6.8. Análisis de dispersión',
    '6.9. Ranking de tendencias',
    '6.10. Consolidado de preguntas abiertas'
  ],
  for_what_leadership_important: '1. ¿Para qué es importante el Liderazgo?',
  for_what_leadership_important_texts: [
    'En el mundo de hoy, donde el cambio es permanente y su velocidad vertiginosa, el rol de las organizaciones, entendidas como sistemas dinámicos, es anticiparse al cambio. De no ser posible tal previsión, al menos deberán adaptarse continuamente a las nuevas realidades. Sus líderes, deberán ser agentes activos, responsables de manejar tales cambios, adaptarse, monitorear y ejecutar permanentemente estrategias, que garanticen la competitividad de la organización de una manera sostenible.',
    'Ricardo Matamala Señor, socio consultor y fundador de las firmas OCC Solutions y Liderazgo & Transformación, define el Liderazgo como el arte de inspirar, movilizar y dirigir toda la inteligencia, emociones y energía de un equipo, para asegurar el logro de propósitos y objetivos comunes. En el mismo sentido, Ronald A. Heifetz, profesor titular de Liderazgo de la Universidad de Harvard, lo define como un “Proceso de movilización en el cual todos nos ayudamos entre sí, para afrontar una realidad incierta y desarrollar nuevas capacidades que permitan enfrentar con éxito los retos adaptativos”.',
    'A la luz de estas definiciones, el Liderazgo se debe comprender como un proceso dinámico y de continuo cambio, que lejos de depender de una sola persona o líder, involucra a un conjunto de personas interrelacionadas que, tras coordinar y alinear sus respectivos trabajos, logran alcanzar resultados sobresalientes. En esta medida, podríamos afirmar que Liderazgo es un proceso de colaboración para lograr sueños, propósitos y metas comunes. De ahí que este proceso conlleve la capacidad de las personas de tomar iniciativa, crear y consolidar equipos, promover, incentivar e inspirar a otros seres humanos.',
    'Así mismo, atraer, desarrollar y mantener el mejor talento, se convierten en una de las funciones claves de un líder, quien será el responsable de facilitar el desarrollo de las personas y direccionarlas a ser su mejor versión para el mundo. De lo anterior, se hace evidente la gran importancia del Liderazgo dentro del desarrollo de los equipos y las organizaciones. Son los líderes quienes, conjuntamente con su equipo de trabajo, transforman una determinada visión de la realidad, movilizando y orientando toda la inteligencia, emociones y energía colectiva, para alcanzar logros sostenibles y sobresalientes.',
    'No obstante, cada una de las competencias propias de los líderes pueden desarrollarse y adoptarse, siempre y cuando exista un auténtico proceso de toma de consciencia y una real y legítima voluntad de cambio y desarrollo. Es decir, el Liderazgo puede desarrollarse, una vez se cuente con la información suficiente acerca del impacto que tienen nuestras acciones en otras personas y de nuestra propia autovaloración. Así, identificadas nuestras fortalezas y áreas de mejoramiento, es posible estructurar un plan de acción focalizado y pertinente. El modelo DML 360° (Dirección, Movilización y Logro) partió de las anteriores premisas para su desarrollo conceptual, por lo que incluye las competencias que debe tener un líder de alto desempeño en el mundo de hoy.'
  ],
  for_what_360_leadership_measure_title: '2. ¿Para qué una valoración de liderazgo 360°?',
  for_what_360_leadership_measure: [
    'Una valoración 360° refleja las percepciones de las personas y sirve como base para un trabajo profundo de desarrollo de competencias de los líderes. Por su naturaleza no se puede afirmar que es un reflejo exacto de la realidad, pero si ayuda para entender la forma en que los líderes se comportan en la organización y el impacto que producen en otros.',
    'El presente reporte muestra los resultados de su valoración de Liderazgo DML 360°. Su apertura y actitud durante la lectura de este reporte serán fundamentales para el análisis de los resultados y la efectividad del proceso. Las recomendaciones más importantes son:'
  ],
  preparation: [
    '1. PREPARACIÓN: ',
    'Disponga de un tiempo prudente para analizar los resultados, asegúrese de revisar todo el reporte con consciencia y profundidad, antes de empezar a priorizar soluciones y definir acciones concretas.'
  ],
  commitment: [
    '2. COMPROMISO: ',
    'Defina una primera versión del plan de desarrollo utilizando los formatos sugeridos.'
  ],
  feedback: [
    '3. RETROALIMENTACIÓN: ',
    'Una vez definido el plan de acción y terminada la versión final, se sugiere presentarlo en tres conversaciones, con el líder directo, con los pares y con colaboradores, en la que se agradece por el tiempo y la sinceridad y se muestra el plan de desarrollo.'
  ],
  follow_up: [
    '4. SEGUIMIENTO: ',
    'La clave para el éxito está en el seguimiento a las acciones. También recomendamos hacer esta valoración en ciclos de seis meses para dinamizar el proceso de desarrollo y observar tendencias. En caso de hacer la valoración frecuentemente, es muy importante analizar el reporte de tendencias.'
  ],
  for_what_360_leadership_measure2: 'La retroalimentación es un regalo. El líder que no conoce su impacto está condenado al fracaso. Esta información es de alto valor y un pretexto para abrir espacios de conversación transparentes y constructivos.',
  what_is_leadership_model: '3. ¿En qué consiste el Modelo Liderazgo DML 360°?',
  what_is_leadership_model_text: 'El modelo de Liderazgo DML 360° está compuesto por tres Procesos: Dirección, Movilización y Logro, las cuales, a su vez, conllevan cinco responsabilidades respectivamente.',
  title: 'Informe de Resultados',
  subtitle: 'Reporte Individual de Liderazgo',
  direction: 'Dirección',
  direction_texts: [
    'Un líder es una persona auténtica e íntegra que, a través de su actuación coherente, muestra al equipo lo que es correcto y adecuado, tanto con sus principios personales como con los de la organización.',
    'Es él, quien con su ejemplo, guía la cultura organizacional y las conductas de los demás miembros del equipo de trabajo a través de la vivencia observable de un conjunto de valores. El líder crea un escenario de acompañamiento para asegurar el desarrollo exitoso de la cultura que requiere la organización, a la hora de ejecutar sus estrategias y alcanzar los objetivos.',
    'Los líderes, de manera permanente, ajustan tales estrategias a las nuevas realidades del entorno y aseguran la comprensión y compromiso de todos con los objetivos y planes; movilizan el pensamiento estratégico del equipo por medio de conversaciones periódicas y la creación de espacios que promuevan la inteligencia colectiva. Finalmente, contribuyen para que cada quien interiorice su propósito personal frente al trabajo asignado y lidere su vida personal y profesional de forma equilibrada. Por ello, los líderes no agotan su impacto en la esfera profesional, orientan su esfuerzo al crecimiento integral del ser humano en todos los aspectos de la vida.',
    'En consecuencia, el liderazgo empresarial debe partir de la identificación de un propósito que, para el líder y el equipo, pueda traducirse en una visión inspiradora, una misión trascendente y unos objetivos retadores. De ahí que sus responsabilidades principales sean las siguientes:',
    '√ Acordar con el equipo visión, misión y objetivos.\n√ Vivir los valores y liderar la cultura.\n√ Construir colectiva y permanentemente estrategias con el equipo.\n√ Inspirar y liderar con el ejemplo.\n√ Promover el liderazgo personal.'
  ],
  mobilization: 'Movilización',
  mobilization_texts: [
    'Los líderes tienen la responsabilidad de inspirar a otros, de crear sentido de pertenencia, orgullo y ganas de comprometerse y dar lo mejor por un propósito superior. Movilizar hace referencia, principalmente, a la gestión efectiva del cambio, la innovación y la potenciación del compromiso y las competencias de cada uno de los miembros del equipo, como un aspecto vital de la competitividad y sostenibilidad de la organización.',
    'Por ello, el líder reconoce la necesidad de crear y mantener relaciones de confianza, basadas en la comunicación efectiva de doble vía: aquella en la que escuchar, preguntar y compartir información, son elementos esenciales para liderar la motivación de las personas y asegurar una actuación exitosa. La movilización del talento requiere empatía, acompañamiento, cercanía y en general buenas relaciones.',
    'De igual forma, los líderes reconocen la importancia de confiar en su equipo. Delegar y empoderar a las personas, asignando y equilibrando responsabilidades tanto a nivel individual como colectivo, hace parte de la dinámica diaria de su equipo de trabajo; así asegura personas y equipos autónomos y comprometidos.',
    'Finalmente, deben crear y mantener condiciones donde la motivación intrínseca de cada persona pueda emerger y contribuir con el logro de los objetivos comunes. Es importante saber qué motiva a cada ser humano y direccionar su talento de manera consecuente con esas motivaciones. De lo anterior, se tiene que las responsabilidades asociadas al proceso de Movilización son las siguientes:',
    '√ Asegurar una comunicación efectiva.\n√ Reconocer el empoderamiento y la responsabilidad.\n√ Gestionar el cambio y promover la innovación.\n√ Mantener relaciones de confianza.\n√ Liderar la motivación de las personas.'
  ],
  achievement: 'Logro',
  achievement_texts: [
    'El logro se consigue gracias al desarrollo de equipos de alto desempeño, en donde las personas comparten objetivos, valores y reglas de juego. Estos equipos se caracterizan porque las personas perciben que el problema de uno es un problema de todos, por ello, tanto las responsabilidades como los logros y los retos que se presentan son compartidos.',
    'El logro se relaciona con una visión de largo plazo y una acción disciplinada. Por eso, el líder promueve el desarrollo integral de cada individuo, asegurando su aprendizaje continuo, a través de acciones de seguimiento que implementa recurrentemente. Con esta disciplina, el líder tiene altas posibilidades de anticipar contingencias y llevar a cabo los planes necesarios de manera efectiva.',
    'Finalmente, todo lo anterior ocurre siempre que exista en un ambiente laboral sano, un alto nivel de Engagement, donde las relaciones, el disfrute y la forma en que se sienten las personas trabajando, son factores determinantes para la ejecución exitosa, competitiva y satisfactoria en términos de resultados.',
    'Contar con líderes promotores de la sostenibilidad de negocio, bajo una perspectiva consiente de entregar resultados que generen valor y equilibrio para todos los grupos de interés, es trascendental e imperativo en el mundo de hoy.',
    'Por ello, el proceso de Logro implica las siguientes responsabilidades:',
    '√ Desarrollar equipos de alto desempeño.\n√ Asegurar aprendizaje continuo y desarrollo integral.\n√ Mantener disciplina de seguimiento.\n√ Liderar el clima laboral y crear ambientes sanos.\n√ Promover la sostenibilidad del negocio'
  ],
  how_is_the_tool_title: '4. ¿Cómo es la herramienta de valoración?',
  how_is_the_tool: [
    'Liderazgo DML 360° es una herramienta de valoración de competencias de liderazgo, que de manera integral y sistematizada, genera una valoración del comportamiento de los líderes al interior de una organización. La herramienta consolida las percepciones de diferentes personas (Líder(es), Pares y Colaboradores), quienes desempeñan cargos variados y se relacionan con ellos de diferentes maneras, para medir su desempeño y competencias y contrastarlas con su auto-percepción.'
  ],
  what_is_the_utility: '¿Para qué sirve?',
  what_is_the_utility_text: [
    'El propósito de la herramienta de valoración de Liderazgo DML 360°, es darle al líder la retroalimentación necesaria para definir un plan de desarrollo de competencias que le permita mejorar su desempeño y crecer integralmente como ser humano.',
    'Los alcances de la valoración de Liderazgo DML 360° son los siguientes:',
    '√ Elevar consciencia del impacto que se genera en otros y contrastarlo con la valoración de sí mismo.\n√ Identificar las fortalezas para reforzarlas y las debilidades para convertirlas en oportunidades de crecimiento y desarrollo.\n√ Motivar a las personas a mejorar su desempeño e involucrarlas con los objetivos de la organización.\n√ Propiciar conversaciones y espacios de retroalimentación en todas las direcciones.\n√ Definir planes de desarrollo de competencias individuales y colectivas.'
  ],
  how_it_works: '¿Cómo funciona?',
  how_it_works_text: [
    'La valoración está compuesta por una serie de preguntas, las cuales corresponden a atributos personales propios del líder (el Líder como persona) y a los procesos del modelo DML 360° (Dirección, Movilización y Logro).',
    'La valoración está compuesta por una serie de comportamientos y conductas relacionadas con las competencias y valores del Modelo de Liderazgo DML 360°, sobre las cuales cada valorador califica la frecuencia entre 1 y 6 de acuerdo a la siguiente escala:'
  ],
  scores_meaning: [
    'Nunca',
    'Casi nunca',
    'Rara vez',
    'Algunas veces',
    'Casi siempre',
    'Siempre'
  ],
  how_to_interpret_the_report_title: '5. ¿Cómo interpretar el reporte?',
  how_to_interpret_the_report: [
    'Del buen entendimiento e interpretación de los resultados, depende la efectividad de la valoración. El reporte resalta las fortalezas y las oportunidades de mejora de la persona evaluada y permite hacer análisis de tendencias, brechas y dispersión de los resultados.',
    'El reporte debe entenderse de acuerdo al actor que valora, es decir:'
  ],
  me: 'Yo',
  relations_texts: [
    'Corresponde a la autovaloración que usted asignó',
    'Corresponde a la apreciación de su líder(es) o superior(es).',
    'Corresponde al promedio de las percepciones de las personas con quienes usted tiene una misma posición o rango dentro de la estructura organizacional',
    'Corresponde al promedio de las valoraciones que hicieron quienes le reportan a usted',
    'Corresponde al promedio de las valoraciones dadas por su líder, pares y colaboradores'
  ],
  references_text: 'Para cada una de las categorías anteriormente señaladas Usted encontrará las siguientes referencias',
  bars: 'BARRAS SOMBREADAS',
  bars_text: 'Muestran la dispersión de las respuestas, es decir el rango en el que estuvieron las mismas. Tenga en cuenta que sobre la barra aparecen los puntajes anteriormente mencionados.',
  score: 'Puntaje',
  scores: 'Puntajes',
  notes: 'Notas',
  score_text: 'Presenta el puntaje obtenido según cada valorador o grupo de valoradores y está representado como el símbolo X.',
  ten_percent: '10% Superior',
  ten_percent_text: 'Corresponde a la valoración promedio del 10% de los Líderes de la organización que obtuvieron el puntaje más alto y está representado con el símbolo de un rombo verde:',
  leaders_average: 'Promedio líderes',
  leaders_average_text: 'Refleja el promedio en que se encuentra el total del colectivo valorado en cada una de las competencias y valores. Con esta referencia Usted podrá saber si se encuentra ubicado en la media del colectivo valorado, por encima o por debajo. Se representa con un circulo:',
  trend: 'Tendencia',
  trend_text: 'Esta columna se aplica para casos donde la prueba se realiza más de una vez, exponiendo los cambios que han ocurrido. Aparecerá el símbolo de la tendencia si la valoración en cada caso aumenta o disminuye en 0.4. Esta columna es clave para dar seguimiento al proceso y verificar si el plan de acción ha dado o no los resultados esperados.',
  plus_minus_symbols: 'Símbolos (+) y (-)',
  plus_minus_symbols_text: 'Si después del texto de una Responsabilidad o Conducta aparece al final un símbolo positivo (+), significa que la autovaloración es 1 punto mayor que el promedio de Otros, lo que implica que su autopercepción está sobrevalorada; por el contrario, aparecerá un símbolo negativo (-) cuando su autopercepción es menor que 1 con relación al promedio de Otros, lo que significa que su autopercepción está subvalorada.',
  others_symbols: 'Símbolos (#;%)',
  others_symbols_text: 'Para la relación de pares, la encuesta cuenta con la opción de No sabe/No responde y por esto puede aparecer un número seguido de un porcentaje en algunas de las conductas. Esto significa la cantidad y el porcentaje de personas que calificaron esta opción. A nivel de Procesos y Responsabilidades solo se muestra el porcentaje de dicha agrupación.',
  bold_text: 'En cada tabla saldrán los puntajes más altos en negrilla y los más bajos subrayados. No se tendrán en cuenta los puntajes de la autovaloración.',
  how_to_interpret_the_report2: [
    'Esta forma de presentar la información facilita la comprensión y a la vez permite realizar un análisis profundo frente a cada elemento valorado.',
    'El Modelo DML 360° ',
    'tiene 10 preguntas de atributos personales de liderazgo (el Líder como persona). Adicionalmente, cada uno de los 3 Procesos del modelo de Liderazgo (Dirección, Movilización y Logro) tiene 5 Responsabilidades y cada responsabilidad a su vez 3 Conductas.',
    'A continuación se presenta una breve descripción del contenido del reporte:'
  ],
  higher_scores: [
    'Puntajes más altos',
    'Identificar los puntajes más altos permite observar las fortalezas de la persona de tal forma que estas sean reconocidas, afianzadas y profundizadas. La tabla presenta las 6 conductas más altas de acuerdo con el puntaje de Otros y el valor correspondiente para cada categoría de valorador. Se recomienda apalancarse en las fortalezas para trabajar en las áreas de mejoramiento.'
  ],
  lower_scores: [
    'Puntajes más bajos',
    'Los puntajes más bajos se refieren a las debilidades o áreas de mejoramiento. Es de gran importancia reconocer que como seres humanos tenemos algunas competencias donde existen oportunidades de mejora. La tabla presenta las 6 conductas con puntajes más bajos de acuerdo con el promedio de Otros valoradores. Para la lectura de estos resultados se requiere un alto compromiso y humildad para mirarse a sí mismo y tomar consciencia de los aspectos donde se requiere hacer un mayor esfuerzo para el desarrollo.'
  ],
  process_results: [
    'Resultados por Proceso',
    'Esta sección muestra el resultado de los promedios para cada una de los procesos del Modelo de Liderazgo DML 360° (El Líder como Persona, Dirección, Movilización y Logro). Estos resultados sirven para evaluar el estado de las competencias de forma general y tener una primera imagen de las percepciones y oportunidades. La información se presenta de forma gráfica y numérica para facilitar el análisis. En los casos que aplique, se puede observar el puntaje de la valoración anterior y la tendencia.'
  ],
  responsability_results: [
    'Resultados por Responsabilidad',
    'En esta parte del informe, se presenta la información agrupada para el Líder como Persona y para cada una de las 15 responsabilidades del Modelo de Liderazgo DML 360°, agrupadas en cada uno de los 3 Procesos.'
  ],
  behavior_results: [
    'Resultados por Conductas',
    'Las tablas de las preguntas detalladas muestran los resultados de forma precisa para cada una de las preguntas que fueron respondidas (Conductas), agrupadas por cada una de los Procesos y Responsabilidades.'
  ],
  confirmed_fo: [
    'Fortalezas y Oportunidades de Mejora Confirmadas y Desconocidas',
    'Esta sección categoriza los comportamientos de su valoración según los siguientes criterios:',
    '√ Si el promedio de Otros fue mayor o igual a 5 y su Autovaloración fue 5 o 6, esta es una Fortaleza confirmada; si su Autovaloración fue de 4 o menor es una Fortaleza desconocida.',
    '√ Si el promedio de Otros fue menor o igual a 4 y su Autovaloración fue 4 o menos, esta es una Oportunidad de mejora confirmada; si su Autovaloración fue de 5 o superior es una Oportunidad de Mejora desconocida.',
    'En todos los casos aparecerán hasta 6 conductas, las de mayor y menor puntaje según sea el caso.'
  ],
  gaps_analysis_title: 'Análisis de Brechas',
  gaps_analysis: [
    'Brechas entre Autovaloración y el promedio de Otros:',
    'Las brechas permiten observar las diferencias que existen entre la autovaloración y las percepciones de las diferentes personas que responden la encuesta, de tal forma que sean visibles las diferencias entre las mismas. Esta información es importante para analizar los niveles de consciencia del líder y el conocimiento que tiene sobre su impacto en otros.',
    'En estas tablas aparecen las brechas más altas a nivel de Conductas. Primero se muestran las mayores brechas cuando la Autovaloración es superior a la de Otros y luego se muestran las brechas más altas cuando la valoración de Otros es superior a la Autovaloración.'
  ],
  gaps_analysis2: [
    'Brechas entre valoradores',
    'En esta tabla se presentan las brechas más altas encontradas entre los promedios de los diferentes valoradores. Esta información es importante en la medida que se muestran las conductas en las cuales el líder está siendo percibido de manera diferente según las diferentes poblaciones de valoradores: Líder(es), Pares y Colaboradores.'
  ],
  dispersion_analysis: [
    'Análisis de Dispersión',
    'En esta parte del informe se muestran las conductas donde hubo mayor dispersión (opiniones diversas) y donde hubo menor dispersión (mayor consenso). Esta valoración se obtiene a través de la desviación estándar de las respuestas de Otros.'
  ],
  trends_report: [
    'Reporte de Tendencias',
    'En esta parte del reporte se muestran las tendencias donde hubo mayores cambios positivos y negativos con respecto a la valoración anterior.'
  ],
  open_questions: [
    'Preguntas Abiertas',
    'Esta sección contiene el compilado de respuestas dadas por quienes lo valoraron y están relacionadas con las oportunidades y fortalezas que sus valoradores encuentran en Usted y los consejos que le darían para ser más efectivo en su rol como Líder.'
  ],
  thanks_message: 'GRACIAS POR SU INTERÉS Y ESPERAMOS QUE ESTA INFORMACIÓN SEA DE GRAN UTILIDAD PARA SU DESARROLLO COMO LÍDER Y COMO PERSONA',
  results_dml: '6. Resultados Valoración DML 360°',
  results_text: 'En las tablas 1 y 2, cada columna corresponde a la valoración promedio de:',
  results_note: '* A lo largo del informe, la categoría Otros se refiere al promedio de Líder(es), Pares y Colaborades',
  higher_scores_title: '6.1. Puntajes más altos',
  lower_scores_title: '6.2. Puntajes más bajos',
  process_results_title: '6.3. Resultados por Proceso',
  responsability_results_title: '6.4. Resultados por Responsabilidad',
  behavior_results_title: '6.5. Resultados por Conductas',
  confirmed_fo_title: '6.6. Fortalezas y Oportunidades de Mejora Confirmadas y Desconocidas',
  trend_ranking: 'Ranking de Tendencias',
  open_questions_title: 'Consolidado de Preguntas Abiertas',
  open_questions_t: 'Preguntas Abiertas',
  higher_positives_trends: 'Mayores Tendencias Positivas',
  higher_negatives_trends: 'Mayores Tendencias Negativas',
  process_results_text: 'Resultado por Proceso',
  table: 'Tabla',
  table1: 'Seis Puntajes más Altos (según Otros)',
  table2: 'Seis Puntajes más Bajos (según Otros)',
  table4: 'Resultados por Responsabilidad para el Proceso de ',
  table7: 'Resultados por Responsabilidad y Conducta para el Proceso de ',
  table24: 'Fortalezas y Oportunidades de Mejora Confirmadas y Desconocidas',
  table25: 'Análisis de Brechas entre Autovaloración y Promedio de Otros',
  table26: 'Análisis de Brechas entre Valoradores',
  chart1: 'En esta gráfica se compara el promedio de su Autovaloración en cada Responsabilidad (franja de color) frente al promedio obtenido por la calificación de otros valoradores (línea gris).',
  responsability_results_text: 'En esta parte del informe se presentan los resultados por responsabilidad. En total son 15 responsabilidades, 5 de Dirección, 5 de Movilización y 5 de Logro.',
  higher_dispersion: 'Mayor dispersión',
  lower_dispersion: 'Menor dispersión',
  higher_bold: 'Calificaciones más altas en negrilla',
  lower_sub: 'Calificaciones más bajas subrayadas',
  // FO
  confirmed_strengths: 'Fortalezas Confirmadas',
  confirmed_strengths_text: 'Promedio de Otros mayor o igual a 5 y Autovaloración 5 o 6.',
  unknown_strengths: 'Fortalezas Desconocidas',
  unknown_strengths_text: 'Promedio de Otros mayor o igual a 5 y Autovaloración 4 o menor.',
  confirmed_improvement_opportunities: 'Oportunidades de Mejora Confirmadas',
  confirmed_improvement_opportunities_text: 'Promedio de Otros menor a 5 y Autovaloración 4 o menos.',
  unknown_improvement_opportunities: 'Oportunidades de Mejora Desconocidas',
  unknown_improvement_opportunities_text: 'Promedio de Otros menor a 5 y Autovaloración 5 o superior.',
  greater_auto: 'Autovaloración mayor que el promedio de Otros',
  lower_auto: 'Autovaloración menor que el promedio de Otros',
  higher_score: 'Puntaje más alto',
  lower_score: 'Puntaje más bajo',
  // Resultados Generales
  general_results: '9. Resultados Generales',
  general_results_text: 'En resumen, se presenta para cada Proceso el promedio obtenido por los líderes (barra blanca) y su Autovaloración Individual (barra de color). El color oscuro en cada Proceso corresponde a la valoración actual y el color claro a la valoración anterior (en una primera aplicación de la Encuesta sólo encontrará una barra por Proceso).',
  // Brechas
  positive_negative_gaps: '10. Brechas positivas y negativas',
  positive_negative_gaps_text: 'Tomando el resultado de toda la encuesta, éstas son las mayores brechas entre su Autovaloración Individual y el Promedio de los Líderes. Al lado derecho están las positivas (donde Usted, se Autovalora mejor que el promedio de Líderes) y al lado izquierdo las negativas (donde Usted se Autovalora por debajo del promedio).',
  // Fortalezas y oportunidades de mejora
  strengths_opportunities: '11. Fortalezas y Oportunidades de Mejora',
  strengths_opportunities_text: 'El siguiente es el resumen de los 5 puntajes más altos (Fortalezas) y los 5 más bajos (Oportunidades de Mejora) de los líderes, con su respectiva dispersión y Autovaloración Individual.',
  // Seguimiento
  strengths: 'Fortalezas',
  leader_strengths: 'Las fortalezas de este líder son: ',
  leader_opportunities: 'Las oportunidades de mejora de este líder son:',
  leader_tips: 'Los retos para ser un líder más inspirador y efectivo son:',
  information: 'Información',
  ranking: 'Ranking',
  improvement_opportunities: 'Oportunidades de Mejora',
  actual_evaluation: 'Valoración actual',
  average_team: 'Promedio del Equipo',
  individual: 'Individual',
  dimension: 'Dimensión',
  attribute: 'Atributo',
  average_leaders: 'Promedio Líderes',
  average: 'Promedio',
  poll_answers: 'Respuestas a la encuesta',
  autoevaluation: 'Autovaloración',
  autoevaluations: 'Autovaloraciones',
  leaders: 'Líder(es)',
  pairs: 'Pares',
  dependents: 'Colaboradores',
  others: 'Otros',
  total: 'Total',
  auto: 'Auto',
  evaluator: 'Valorador',
  received_evaluations: 'Valoraciones Recibidas',
  received: 'Recibidas',
  requested_evaluations: 'Valoraciones Solicitadas',
  requested: 'Esperadas',
  answers_rate: 'Porcentaje de Respuestas',
  process_abbr: 'P',
  responsability: 'Responsabilidad',
  behavior: 'Conducta',
  process: 'Proceso',
  actual_dispersion: 'Dispersión Actual',
  previous_average: 'Promedio Anterior',
  actual_average: 'Promedio Actual',
  previous_score: 'Puntaje Anterior',
  actual_score: 'Puntaje Actual',
  previous: 'Anterior',
  percent_break: 'Salto %',
  current: 'Actual',
  dispersion: 'Dispersión',
  as_person: 'Como persona',
  average_as_person: 'Promedio como persona',
  direction_average: 'Promedio dirección',
  mobilization_average: 'Promedio de movilización',
  achievement_average: 'Promedio de movilización',
  leader_as_person: 'Líder como persona',
  results: 'Resultados',
  copyright: 'OCC - Todos los derechos reservados.',
  higher_gap: 'Brechas más altas positivas',
  lower_gap: 'Brechas más altas negativas',
  gap: 'Brecha',
  gaps: 'Brechas',
  chart: 'Gráfica',
  strengths_and_opportunities: 'Fortalezas y Oportunidades',
  months: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
  ],
  data_range: 'Rango de datos(Máx-Min)',
  no_answer: '(#;%) No sabe/No responde',
  leadership: 'Liderazgo',
  concepts: 'Conceptos',
  no_evaluators: 'No hay valoradores',
  no_others_evaluators: 'No hay otros valoradores',
  there_is_no: 'No hay ',
  input_generating_report: 'Generando reporte',
  input_failed_generation: 'Generación fallida',
  input_download_report: 'Descargar reporte',
  input_update_progress: 'Actualizar progreso',
  input_no_participation: 'Sin participación',
  input_generate_report: 'Generar reporte',
  opetaion_init: 'Se ha dado inicio a un proceso de descarga "Reporte Individual", este proceso puede tardar varios minutos, puede ver su progreso al refrescar la página',
  cover_table_header_1: 'Evaluaciones esperadas',
  cover_table_header_2: 'Evaluaciones obtenidas',
  cover_table_header_3: 'Porcentaje de respuestas',
  cover_table_row_1: 'COLABORADORES',
  cover_table_row_2: 'TOTAL',
  cover_months: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
  ],
  background_1: 'CONTENIDO',
  background_2: 'INTRODUCCIÓN',
  background_3: 'RESULTADOS POR DIMENSIONES',
  background_4: 'RESULTADOS POR COMPETENCIAS',
  background_5: 'CONDUCTAS MÁS ALTAS Y MÁS BAJAS',
  background_6: 'Comunicación y retroalimentación.',
  background_7: 'Desarrollo personal y profesional',
  background_8: 'Planeación e implementación',
  background_9: 'ANÁLISIS DE BRECHAS',
  background_10: 'ANÁLISIS DE DISPERSIÓN',
  background_11: 'FORTALEZAS CONFIRMADAS Y DESCONOCIDAS',
  background_12: 'ANÁLISIS DE TENDENCIAS',
  index: [
    '01. Introducción',
    '02. Resultados por dimensiones',
    '03. Resultados por competencias',
    '04. Conductas más altas y más bajas',
    '05. Resultado por Conducta',
    '06. Análisis de brechas',
    '07. Análisis de dispersión',
    '08. Fortalezas confirmadas y desconocidas',
    '09. Análisis de tendencias',
    '10. Plan de trabajo'
  ],
  results_dimention_title_1: '02. RESULTADOS POR DIMENSIONES',
  results_dimention_table_info_header_1: 'INFORMACIÓN DE DIMENSIONES',
  results_dimention_table_info_sub_header_1: 'DIMENSIÓN',
  results_dimention_table_info_sub_header_2: 'ABREVIATURA',
  results_dimention_table_info_sub_header_3: 'AUTO',
  results_dimention_table_info_sub_header_4: 'EQUIPO',
  results_dimention_table_header_1: 'RESULTADO',
  results_dimention_table_header_2: 'NIVEL',
  results_dimention_table_header_3: 'INTERPRETACIÓN',
  results_dimention_table_row_1_col_1: 'Entre 5 y 6',
  results_dimention_table_row_1_col_2: 'Alto',
  results_dimention_table_row_1_col_3: 'Competencia clara',
  results_dimention_table_row_2_col_1: 'Entre 3 y 4',
  results_dimention_table_row_2_col_2: 'Aceptable',
  results_dimention_table_row_2_col_3: 'Desempeño aceptable',
  results_dimention_table_row_3_col_1: 'De 2 y menor a 3',
  results_dimention_table_row_3_col_2: 'Medio',
  results_dimention_table_row_3_col_3: 'Requiere atención',
  results_dimention_table_row_4_col_1: 'Menor a 2',
  results_dimention_table_row_4_col_2: 'Bajo',
  results_dimention_table_row_4_col_3: 'Requiere desarrollo prioritario',
  results_dimention_title_2: '03. RESULTADOS POR COMPETENCIAS',
  results_dimention_title_2_table_header_1: 'Individual',
  results_dimention_title_2_table_header_2: 'Equipo',
  results_dimention_title_2_table_header_3: 'Tendencia',
  results_dimention_info_1: 'La evaluación individual es la forma en la que usted se auto valora y el equipo es el promedio de como cada uno de tus colaboradores te valora.',
  results_dimention_info_2: 'Esta gráfica muestra los resultados generales de acuerdo con los promedios de tu autovaloración y las valoraciones del equipo.',
  rank_title: '04. CONDUCTAS MÁS ALTAS Y MÁS BAJAS',
  rank_title_info: 'Las siguientes tablas presentan las 6 conductas más altas y más bajas de acuerdo al puntaje dado por el Equipo.',
  rank_sub_title_1: 'SEIS PUNTAJES MÁS ALTOS (SEGÚN VALORACIÓN DEL EQUIPO).',
  rank_table_1_header_1: 'DIMENSIÓN',
  rank_table_1_header_2: 'COMPETENCIA',
  rank_table_1_header_3: 'CONDUCTA',
  rank_table_1_header_4: 'EQUIPO',
  rank_table_1_header_5: 'AUTO',
  rank_sub_title_2: 'SEIS PUNTAJES MÁS BAJOS (SEGÚN VALORACIÓN DEL EQUIPO).',
  rank_table_2_header_1: 'DIMENSIÓN',
  rank_table_2_header_2: 'COMPETENCIA',
  rank_table_2_header_3: 'CONDUCTA',
  rank_table_2_header_4: 'EQUIPO',
  rank_table_2_header_5: 'AUTO',
  results_title: '05. RESULTADO POR CONDUCTA',
  results_info: 'A continuación se presenta cada conducta con su autovaloración y la valoración de su equipo. La columna tendencia muestra si hubo cambio positivo o negativo de acuerdo con la medición anterior. Aparecerá una flecha indicando la tendencia si la valoración aumenta o disminuye más de 0.4.',
  result_legend_1: 'Rango de datos (min-max)',
  result_legend_2: 'Autovaloración',
  result_legend_3: 'Equipo',
  results_table_header_1: 'D',
  results_table_header_2: 'COMPETENCIA',
  results_table_header_3: 'CONDUCTA',
  results_table_header_4: 'VALORACIÓN',
  results_table_header_5: 'AUTO',
  results_table_header_6: 'EQUIPO',
  results_table_header_7: 'TENDENCIA',
  gaps_title: '06. ANÁLISIS DE BRECHAS',
  gaps_title_info: 'En estas tablas aparecen las brechas más altas en cuanto a conductas. Primero se muestran las mayores brechas cuando la autovaloración es superior a la del equipo y luego las brechas más altas cuando la valoración del Equipo es superior a la Autovaloración.',
  gaps_sub_title_1: 'Brechas más altas positivas',
  gaps_table_1_header_1: 'DIMENSIÓN',
  gaps_table_1_header_2: 'COMPETENCIA',
  gaps_table_1_header_3: 'CONDUCTA',
  gaps_table_1_header_4: 'AUTO',
  gaps_table_1_header_5: 'EQUIPO',
  gaps_table_1_header_6: 'BRECHA',
  gaps_sub_title_2: 'Brechas más altas negativas',
  gaps_table_2_header_1: 'DIMENSIÓN',
  gaps_table_2_header_2: 'COMPETENCIA',
  gaps_table_2_header_3: 'CONDUCTA',
  gaps_table_2_header_4: 'AUTO',
  gaps_table_2_header_5: 'EQUIPO',
  gaps_table_2_header_6: 'BRECHA',
  scatter_title: '07. Análisis de dispersión',
  scatter_info: 'En esta parte del informe se muestran las conductas donde hubo mayor dispersión (opiniones diversas) y donde hubo menor dispersión (mayor consenso). Esta medición se obtiene a través de la desviación estándar de las respuestas del Equipo.',
  scatter_header_1: 'MAYOR DISPERSIÓN',
  scatter_header_2: 'MENOR DISPERSIÓN',
  scatter_sub_header_1: 'DIMENSIÓN',
  scatter_sub_header_2: 'COMPETENCIA',
  scatter_sub_header_3: 'CONDUCTA',
  scatter_sub_header_4: 'AUTO',
  scatter_sub_header_5: 'EQUIPO',
  scatter_sub_header_6: 'DISPERSIÓN',
  strengths_title: '08. Fortalezas confirmadas y desconocidas',
  strengths_info_title_1: 'Fortalezas Confirmadas: ',
  strengths_info_text_1: 'Promedio del Equipo mayor o igual a 5 y Autovaloración mayor o igual a 5.',
  strengths_info_title_2: 'Fortalezas Desconocidas: ',
  strengths_info_text_2: 'Promedio del Equipo mayor o igual a 5 y Autovaloración 4 o menor.',
  strengths_info_title_3: 'Oportunidades de Mejora Confirmadas: ',
  strengths_info_text_3: 'Promedio del Equipo menor o igual a 4 y Autovaloración 4 o menos.',
  strengths_info_title_4: 'Oportunidades de Mejora Desconocidas: ',
  strengths_info_text_4: 'Promedio del Equipo menor o igual a 4 y Autovaloración 5 o superior.',
  strengths_table_header_1: 'CONDUCTA',
  strengths_table_header_2: 'AUTO',
  strengths_table_header_3: 'EQUIPO',
  trend_title: '09. Análisis de tendencias',
  trend_info: 'En esta parte del informe, se muestran las conductas donde hubo mayor cambio positivo y negativo en la calificación versus la valoración anterior en el Equipo.',
  trend_msg: 'Disponible a partir de la segunda medición',
  trend_table_1_title: 'TENDENCIAS POSITIVAS',
  trend_table_1_header_1: 'DIMENSIÓN',
  trend_table_1_header_2: 'COMPETENCIA',
  trend_table_1_header_3: 'CONDUCTA',
  trend_table_1_header_4: 'ACTUAL',
  trend_table_1_header_5: 'ANTERIOR',
  trend_table_1_header_6: 'TENDENCIA',
  trend_table_2_title: 'TENDENCIAS NEGATIVAS',
  trend_table_2_header_1: 'DIMENSIÓN',
  trend_table_2_header_2: 'COMPETENCIA',
  trend_table_2_header_3: 'CONDUCTA',
  trend_table_2_header_4: 'ACTUAL',
  trend_table_2_header_5: 'ANTERIOR',
  trend_table_2_header_6: 'TENDENCIA',
  plan_title: '10. Plan de trabajo',
  plan_info: 'Teniendo en cuenta sus resultados, recomendamos crear un plan de trabajo para las categorías cuyas brechas son las mayores. Para cada categoría a trabajar, defina como máximo tres acciones y una estrategia que permita dar seguimiento al cumplimiento de las mismas.',
  plan_table_header_1: 'DIMENSIÓN',
  plan_table_header_2: 'AUTO',
  plan_table_header_3: 'EQUIPO',
  plan_table_header_4: 'BRECHA',
  plan_table_header_5: 'FORTALEZAS',
  plan_table_header_6: 'ÁREAS DE OPORTUNIDAD',
  plan_table_header_7: 'RESULTADO ESPERADO',
  plan_table_header_8: 'PLAN DE ACCIÓN',
  plan_table_header_9: 'ESTRATEGIA DE SEGUIMIENTO'
}
