<template>
  <v-dialog
    v-model="show"
    fullscreen
    hide-overlay
  >
    <v-card>
      <v-container fluid>
        <v-row row wrap>
          <v-col cols="12" class="mt-12 text-center grey--text text--darken-1">
            <img :src="checkCircle" width="200" />
            <h2
              class="mt-12 grey--text text--darken-4"
              :class="{'display-1': $vuetify.breakpoint.xsOnly, 'display-2': $vuetify.breakpoint.smAndUp}"
            >
              {{ $t('evaluations.end') }}
            </h2>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue'
import finish from '../base64files/finish'

export default Vue.extend({
  props: {
    finishDialog: Boolean
  },
  data () {
    return {
      show: false,
      checkCircle: finish
    }
  },
  watch: {
    finishDialog: {
      handler () {
        this.show = this.finishDialog
      },
      immediate: true
    }
  },
  methods: {
    close () {
      this.show = false
      this.$emit('close')
    }
  }
})
</script>
