
export default {
  methods: {
    $generateTrend () {
      // 309DE0 EB493C 48BBA1
      const descriptions = [
        this.$t('organizationalReport.table23'),
        this.$t('organizationalReport.table24')
      ]
      let table23 = {}
      let trend23 = []
      let higherTrend = []
      let lowerTrend = []
      let respRanking = this.othersRespRank
      respRanking.sort((a, b) => b.trend - a.trend)
      for (let i = 0; i < 3; i++) {
        trend23.push([
          // Higher score
          { text: this.hasPrevious ? respRanking[i].abbreviation : '--', margin: [0, 5, 0, 0], fontSize: 10, border: [true, true, true, true], bold: true, style: { color: respRanking[i].abbreviation === 'P' ? '#0D47A1' : respRanking[i].abbreviation === 'D' ? '#b4cf32' : respRanking[i].abbreviation === 'M' ? '#4ec7aa' : '#43ace2' } },
          { text: this.hasPrevious ? respRanking[i].name : '--', margin: [0, 5, 0, 0], border: [true, true, true, true], style: { color: '#6d7a7a' } },
          { text: this.hasPrevious ? this.round(respRanking[i].previous, 2) : '--', alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], style: { color: '#6d7a7a' } },
          { text: this.hasPrevious ? this.round(respRanking[i].score, 2) : '--', alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], style: { color: '#6d7a7a' } },
          { text: this.hasPrevious ? this.round(respRanking[i].trend, 2) : '--', alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], style: { color: '#6d7a7a' } },
          // Lower score
          { text: this.hasPrevious ? respRanking[respRanking.length - (i + 1)].abbreviation : '--', margin: [0, 5, 0, 0], fontSize: 10, border: [true, true, true, true], bold: true, style: { color: respRanking[respRanking.length - (i + 1)].abbreviation === 'P' ? '#0D47A1' : respRanking[respRanking.length - (i + 1)].abbreviation === 'D' ? '#b4cf32' : respRanking[respRanking.length - (i + 1)].abbreviation === 'M' ? '#4ec7aa' : '#43ace2' } },
          { text: this.hasPrevious ? respRanking[respRanking.length - (i + 1)].name : '--', margin: [0, 5, 0, 0], border: [true, true, true, true], style: { color: '#6d7a7a' } },
          { text: this.hasPrevious ? this.round(respRanking[respRanking.length - (i + 1)].previous, 2) : '--', alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], style: { color: '#6d7a7a' } },
          { text: this.hasPrevious ? this.round(respRanking[respRanking.length - (i + 1)].score, 2) : '--', alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], style: { color: '#6d7a7a' } },
          { text: this.hasPrevious ? this.round(respRanking[respRanking.length - (i + 1)].trend, 2) : '--', alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], style: { color: '#6d7a7a' } }
        ])
      }
      table23 = {
        layout: {
          defaultBorder: '--',
          fillColor: function (rowIndex, node, columnIndex) {
            return (rowIndex <= 1) ? '#BFBFBF' : null
          },
          border: [false, false, false, true]
        },
        table: {
          headerRows: 1,
          body: [
            [
              { text: this.$t('organizationalReport.higher_gap').toUpperCase(), colSpan: 5, margin: [0, 3, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
              {},
              {},
              {},
              {},
              { text: this.$t('organizationalReport.lower_gap').toUpperCase(), colSpan: 5, margin: [0, 3, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
              {},
              {},
              {},
              {}
            ],
            // Header
            [
              // Positive
              { text: this.$t('organizationalReport.process'), margin: [0, 0, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
              { text: this.$t('organizationalReport.responsability'), margin: [15, 0, 15, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
              { text: this.$t('organizationalReport.previous'), margin: [-1, 0, -1, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
              { text: this.$t('organizationalReport.current'), margin: [2, 0, 2, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
              { text: this.$t('organizationalReport.gap'), margin: [1, 0, 1, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
              // Negative
              { text: this.$t('organizationalReport.process'), margin: [0, 0, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
              { text: this.$t('organizationalReport.responsability'), margin: [15, 0, 15, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
              { text: this.$t('organizationalReport.previous'), margin: [-1, 0, -1, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
              { text: this.$t('organizationalReport.current'), margin: [2, 0, 2, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
              { text: this.$t('organizationalReport.gap'), margin: [1, 0, 1, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } }
            ],
            // Body
            ...trend23
          ]
        },
        margin: [10, 0, 15, 10],
        border: [false, false, false, true],
        alignment: 'center',
        fontSize: 8
      }
      let ranking = this.othersRank
      ranking.sort((a, b) => b.trend - a.trend)
      for (let i = 0; i < 6; i++) {
        higherTrend.push([
          // Higher score
          { text: this.hasPrevious ? ranking[i].abbreviation : '--', margin: [0, 5, 0, 0], border: [true, true, true, true], fontSize: 10, bold: true, style: { color: ranking[i].abbreviation === 'P' ? '#0D47A1' : ranking[i].abbreviation === 'D' ? '#b4cf32' : ranking[i].abbreviation === 'M' ? '#4ec7aa' : '#43ace2' } },
          { text: this.hasPrevious ? ranking[i].responsability : '--', margin: [0, 5, 0, 0], border: [true, true, true, true], style: { color: '#6d7a7a' } },
          { text: this.hasPrevious ? ranking[i].question : '--', margin: [0, 5, 0, 0], border: [true, true, true, true], style: { color: '#6d7a7a' } },
          { text: this.hasPrevious ? this.round(ranking[i].previous, 2) : '--', alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], style: { color: '#6d7a7a' } },
          { text: this.hasPrevious ? this.round(ranking[i].score, 2) : '--', alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], style: { color: '#6d7a7a' } },
          { text: this.hasPrevious ? this.round(ranking[i].trend, 2) : '--', alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], style: { color: '#6d7a7a' } }
        ])
        lowerTrend.push([
          // Higher score
          { text: this.hasPrevious ? ranking[ranking.length - (i + 1)].abbreviation : '--', margin: [0, 5, 0, 0], border: [true, true, true, true], fontSize: 10, bold: true, style: { color: ranking[ranking.length - (i + 1)].abbreviation === 'P' ? '#0D47A1' : ranking[ranking.length - (i + 1)].abbreviation === 'D' ? '#b4cf32' : ranking[ranking.length - (i + 1)].abbreviation === 'M' ? '#4ec7aa' : '#43ace2' } },
          { text: this.hasPrevious ? ranking[ranking.length - (i + 1)].responsability : '--', margin: [0, 5, 0, 0], border: [true, true, true, true], style: { color: '#6d7a7a' } },
          { text: this.hasPrevious ? ranking[ranking.length - (i + 1)].question : '--', margin: [0, 5, 0, 0], border: [true, true, true, true], style: { color: '#6d7a7a' } },
          { text: this.hasPrevious ? this.round(ranking[ranking.length - (i + 1)].previous, 2) : '--', alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], style: { color: '#6d7a7a' } },
          { text: this.hasPrevious ? this.round(ranking[ranking.length - (i + 1)].score, 2) : '--', alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], style: { color: '#6d7a7a' } },
          { text: this.hasPrevious ? this.round(ranking[ranking.length - (i + 1)].trend, 2) : '--', alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], style: { color: '#6d7a7a' } }
        ])
      }
      return [
        {
          table: {
            widths: [479],
            body: [
              [{ text: this.textToUpper('organizationalReport.index[6]'), bold: true, color: '#fff', margin: [50, 8, 0, 5] }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [-40, 25, 0, 0],
          pageBreak: 'before'
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: '#aaaaaa'
          },
          table: {
            widths: [479],
            body: [
              [{ text: `${this.$t('organizationalReport.table')} 23. ${descriptions[0]}`, margin: [30, 5, 0, 5], bold: true, color: '#fff' }]
            ]
          },
          margin: [35, 10, 20, 10]
        },
        table23,
        {
          layout: {
            defaultBorder: '',
            fillColor: '#aaaaaa'
          },
          table: {
            widths: [479],
            body: [
              [{ text: `${this.$t('organizationalReport.table')} 24. ${descriptions[1]}`, margin: [30, 5, 0, 5], bold: true, color: '#fff' }]
            ]
          },
          margin: [35, 10, 20, 10]
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex <= 1) ? '#BFBFBF' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            body: [
              [
                { text: this.$t('organizationalReport.higher_gap').toUpperCase(), colSpan: 6, margin: [0, 3, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                {},
                {},
                {},
                {},
                {}
              ],
              // Header
              [
                { text: this.$t('organizationalReport.process'), margin: [0, 0, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('organizationalReport.responsability'), margin: [25, 0, 25, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('organizationalReport.behavior'), margin: [90, 0, 90, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('organizationalReport.previous'), margin: [-1, 0, -1, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('organizationalReport.current'), margin: [2, 0, 2, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('organizationalReport.gap'), margin: [1, 0, 1, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } }
              ],
              // Body
              ...higherTrend
            ]
          },
          margin: [10, 0, 15, 10],
          border: [false, false, false, true],
          alignment: 'center',
          fontSize: 8
        },
        !this.hasPrevious
          ? {}
          : {
            table: {
              widths: [479],
              body: [
                [{ text: this.textToUpper('organizationalReport.index[6]'), bold: true, color: '#fff', margin: [50, 8, 0, 5] }]
              ]
            },
            layout: {
              fillColor: '#aaaaaa',
              defaultBorder: ''
            },
            alignment: 'left',
            margin: [-40, 25, 0, 0],
            pageBreak: 'before'
          },
        !this.hasPrevious
          ? {}
          : {
            layout: {
              defaultBorder: '',
              fillColor: '#aaaaaa'
            },
            table: {
              widths: [479],
              body: [
                [{ text: `${this.$t('organizationalReport.table')} 24. ${descriptions[1]}`, margin: [30, 5, 0, 5], bold: true, color: '#fff' }]
              ]
            },
            margin: [35, 10, 20, 10]
          },
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex <= 1) ? '#BFBFBF' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            body: [
              [
                { text: this.$t('organizationalReport.lower_gap').toUpperCase(), colSpan: 6, margin: [0, 3, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                {},
                {},
                {},
                {},
                {}
              ],
              // Header
              [
                { text: this.$t('organizationalReport.process'), margin: [0, 0, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('organizationalReport.responsability'), margin: [25, 0, 25, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('organizationalReport.behavior'), margin: [90, 0, 90, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('organizationalReport.previous'), margin: [-1, 0, -1, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('organizationalReport.current'), margin: [2, 0, 2, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('organizationalReport.gap'), margin: [1, 0, 1, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } }
              ],
              // Body
              ...lowerTrend
            ]
          },
          margin: [10, 0, 15, 10],
          border: [false, false, false, true],
          alignment: 'center',
          fontSize: 8
        }
      ]
    }
  }
}
