
export default {
  add_enterprise: 'Agregar Empresa',
  admin_left_table_title: 'Empresas',
  admin_bottom_table_title: 'Encuestas recientes',
  create_poll: 'Crear encuesta',
  continue_poll: 'Continuar encuesta',
  continue: 'Continuar',
  employee_left_table_title: 'Encuestas en progreso',
  employee_progress: 'Completada al:',
  employee_right_table_title: 'Encuestas asignadas',
  enterprise_left_table_title: 'Plan actual',
  enterprise_right_table_title: 'Encuestas',
  expired_plan: '(Plan vencido)',
  general_list: 'Listado general',
  general_title: 'Tablero',
  has_started: 'Comenzada',
  not_started: 'Por comenzar',
  init: 'Inicio:',
  end: 'Finalización',
  ended: 'Finalizado el:',
  left_days: 'Días restantes',
  left_questions: 'Preguntas restantes:',
  finished: 'Finalizado',
  progress: 'En progreso',
  period_divisor: 'al',
  poll: 'Encuesta:',
  poll_completed: 'Si',
  poll_incompleted: 'No',
  poll_init_date: 'Inicio:',
  poll_end_date: 'Válida hasta:',
  qty_enterprises: 'Empresas activas',
  qty_employees: 'Colaboradores',
  qty_licesenses: 'Licencias',
  team_trends: 'Tendencias de equipo',
  trend_chart: 'Gráfico de tendencias',
  update_plan: 'Actualizar Plan',
  dimension_leaders: 'Los líderes',
  dimension_inspiration: 'Mi inspiración',
  dimension_job: 'Mi trabajo',
  dimension_environment: 'Ambiente laboral positivo',
  dimension_team: 'Mi equipo',
  dimension_development: 'Mi desarrollo y aprendizaje',
  view_poll_details: 'Ver detalles de la encuesta',
  has_no_polls: 'Necesita crear al menos 2 encuestas en el mismo periodo para ver la gráfica.',
  start: 'Comenzar',
  start_poll: 'Comenzar valoración',
  no_pending_activities: 'No posee actividades pendientes',
  employee_activities_table_header: [
    'Valoración (Rol)',
    'Accion'
  ],
  autoevaluation: 'Autoevaluacion',
  evaluators: 'Evaluadores',
  leader: 'Jefe',
  pair: 'Par',
  dependent: 'Colaborador',
  name: 'Nombre de valoración',
  dates: 'Fechas',
  status: 'Estado',
  enterprise: 'Empresa',
  customer: 'Cliente',
  actions: 'Acciones',
  to: 'hasta',
  last_polls: 'Ultimas valoraciones',
  see_evaluated: 'Ver evaluadores',
  peding: 'Pendiente',
  evaluations_resume: 'Resumen de valoraciones',
  no_created_evaluations: 'No posee valoraciones creadas',
  go_create_evaluation: 'Ir a creación de valoración',
  see_details: 'Ver detalles',
  evaluation_finished: 'Valoración finalizada'
}
