<template>
  <v-container flat>
    <x-technical-requirements></x-technical-requirements>
    <hr>
    <v-card flat>
      <v-row>
        <v-col cols="12">
          <v-row class="mt-4">
            <v-col xs="12" md="6" class="mb-3 my-2">
              <v-list-item>
                <v-list-item-action>
                  <v-icon>mdi-account-card-details</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    <h6 class="title">{{ $t('evaluations.poll_name') }}</h6>
                  </v-list-item-title>
                  <v-list-item-subtitle class="grey--text subheading">
                    {{ evaluation.name }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col xs="12" md="6" class="mb-3 my-2">
              <v-list-item>
                <v-list-item-action>
                  <v-icon>mdi-account-card-details-outline</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    <h6 class="title">{{ $t('evaluations.external_name') }}</h6>
                  </v-list-item-title>
                  <v-list-item-subtitle class="grey--text subheading">
                    {{ evaluation.displayName || evaluation.name }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
          <v-divider class="my-3"></v-divider>
          <v-row class="mt-4">
            <v-col xs="12" md="6" class="mb-3 my-2">
              <v-list-item>
                <v-list-item-action>
                  <v-icon>mdi-calendar-star</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    <h6 class="title">{{ $t('evaluations.date_delivery') }}</h6>
                  </v-list-item-title>
                  <v-list-item-subtitle class="grey--text subheading">
                    {{ formatDate(evaluation.deliveredAt) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col xs="12" md="6" class="mb-3 my-2">
              <v-list-item>
                <v-list-item-action>
                  <v-icon>mdi-calendar-minus</v-icon>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>
                    <h6 class="title">{{ $t('evaluations.poll_valid_until') }}</h6>
                  </v-list-item-title>
                  <v-list-item-subtitle class="grey--text subheading">
                    {{ formatDate(evaluation.validUntil) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col xs="12" md="6" class="mb-3 my-2">
              <v-list-item>
                <v-list-item-action>
                  <v-icon>mdi-camera-timer</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    <h6 class="title">{{ $t('evaluations.time_zone') }}</h6>
                  </v-list-item-title>
                  <v-list-item-subtitle class="grey--text subheading">
                    {{ evaluation.timeZone }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col xs="12" md="6" class="mb-3 my-2">
              <v-list-item>
                <v-list-item-action>
                  <v-icon>mdi-calendar-clock</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    <h6 class="title">{{ $t('evaluations.send_reminders') }}</h6>
                  </v-list-item-title>
                  <v-list-item-subtitle class="grey--text subheading">
                    {{ evaluation.switchDate ? 'Si' : 'No' }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
          <v-divider class="my-3"></v-divider>
          <v-row class="mt-4">
            <v-col xs="12" md="6" class="mb-3 my-2">
              <v-list-item>
                <v-list-item-action>
                  <v-icon>mdi-file-question</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    <h6 class="title">{{ $t('evaluations.questionnaire') }}</h6>
                  </v-list-item-title>
                  <v-list-item-subtitle class="grey--text subheading">
                    {{ getQuestionnaireName() }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col xs="12" md="6" class="mb-3 my-2">
              <v-list-item>
                <v-list-item-action>
                  <v-icon>fa-coins</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    <h6 class="title">{{ `${price} ${$t('evaluations.token_unit')}` }}</h6>
                  </v-list-item-title>
                  <v-list-item-subtitle class="grey--text subheading">
                    {{ price===0 ? $t('evaluations.paid_measuring') : $t('evaluations.workshop_cost') }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
          <v-divider class="my-3"></v-divider>
              <v-row class="mt-4">
                <v-col cols="12" class="mb-3">
                  <v-list-item>
                    <v-list-item-action>
                      <v-icon>mdi-email-open-outline</v-icon>
                    </v-list-item-action>

                    <v-list-item-content>
                      <v-list-item-title>
                        <h6 class="title">{{ $t('evaluations.personalization') }}</h6>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12">
                  <v-row align="center" justify="center">
                    <v-col lg="10" sm="9">
                      <v-tabs v-model="evaluation.active" fixed-tabs>
                        <v-tab :href="`#tab-1`" @click="tabSelected = 1">
                          {{$t('evaluations.poll_invitation')}}
                        </v-tab>
                        <v-tab :href="`#tab-2`" @click="tabSelected = 2">
                          {{$t('evaluations.reminder_mail')}}
                        </v-tab>
                        <!--
                        <v-tab :href="`#tab-3`" @click="tabSelected = 3">
                          {{$t('evaluations.tk_message')}}
                        </v-tab>
                        -->
                      </v-tabs>
                    </v-col>
                    <v-col cols="12" class="mt-1 text-center" v-if="tabSelected != 3">
                      <v-btn color="primary" style="letter-spacing: 0; font-size: .9em;" @click="displayPreview"> {{ $t('input.preview') }} </v-btn>
                    </v-col>
                  </v-row>
                  <v-container grid-list-md text-xs-center>

                    <v-tabs-items v-model="evaluation.active">

                        <v-tab-item :value="`tab-1`">
                          <v-container grid-list-md text-xs-center>
                            <v-row>
                              <v-col>
                                <v-text-field
                                  v-model="evaluation.pollInvitation.subject"
                                  light
                                  :label="$t('evaluations.message_subject')"
                                  name="message_subject"
                                  :append-outer-icon="$t('help.icon')"
                                  @click:append-outer="$store.dispatch('help/display', $t('help.engagement.create.subject'))"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                <quill-editor ref="pollInvitationBody"
                                  v-model="evaluation.pollInvitation.body"
                                  :options="editorOption">
                                </quill-editor>
                                <!--
                                <v-text-field
                                  :label="$t('input.select_video')"
                                  @click="pickFile"
                                  v-model="pollInvitationVideo.name"
                                  prepend-icon="attach_file"
                                  :color="videoColor"
                                  :append-outer-icon="$t('help.icon')"
                                  @click:append-outer="$store.dispatch('help/display', $t('help.enterprise.create.logo'))"
                                ></v-text-field>
                                <input
                                  type="file"
                                  style="display: none"
                                  ref="videoInput"
                                  accept="video/*"
                                  @change="onFilePicked"
                                >
                                <v-row v-if="invitationHasFile">
                                  <v-flex xs12>
                                    <b>{{ $t('evaluations.fileExistNote') }}</b>
                                  </v-flex>
                                  <v-flex xs12 sm6 md4>
                                    <v-card color="yellow lighten-4" class="mt-2">
                                      <v-card-title>
                                        <v-row justify="space-between">
                                          <span class="ml-2">{{ $t('evaluations.fileExist') }}</span>
                                          <v-tooltip right color="red">
                                            <template v-slot:activator="{ on }">
                                              <v-btn icon v-on="on" @click="deleteInvitationFile">
                                                <v-icon>mdi-delete</v-icon>
                                              </v-btn>
                                            </template>
                                            <span>{{ $t('evaluations.deleteFile') }}</span>
                                          </v-tooltip>
                                        </v-row>
                                      </v-card-title>
                                      <v-card-text>
                                        <span>
                                          <b>{{ evaluation.pollInvitation.attachment }}</b>
                                        </span>
                                      </v-card-text>
                                    </v-card>
                                  </v-flex>
                                </v-row>
                                -->
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-tab-item>

                        <v-tab-item :value="`tab-2`">
                          <v-container grid-list-md text-xs-center>
                            <v-row>
                              <v-col xs="12" sm="12">
                                <v-text-field
                                  v-model="evaluation.reminderMail.subject"
                                  light
                                  :label="$t('evaluations.message_subject')"
                                  name="message_subject"
                                  :append-outer-icon="$t('help.icon')"
                                  @click:append-outer="$store.dispatch('help/display', $t('help.engagement.create.subject'))"
                                ></v-text-field>
                              </v-col>
                              <v-col xs="12" sm="12">
                                <quill-editor ref="pollReminderBody"
                                    v-model="evaluation.reminderMail.body"
                                    :options="editorOption">
                                </quill-editor>
                                <!--
                                <v-text-field
                                  :label="$t('input.select_video')"
                                  @click="pickFile2"
                                  v-model="pollReminderVideo.name"
                                  prepend-icon="attach_file"
                                  :color="videoColor2"
                                  :append-outer-icon="$t('help.icon')"
                                  @click:append-outer="$store.dispatch('help/display', $t('help.enterprise.create.logo'))"
                                ></v-text-field>
                                <input
                                  type="file"
                                  style="display: none"
                                  ref="videoInput2"
                                  accept="video/*"
                                  @change="onFilePicked2"
                                >
                                <v-row v-if="reminderHasFile">
                                  <v-flex xs12>
                                    <b>{{ $t('evaluations.fileExistNote') }}</b>
                                  </v-flex>
                                  <v-flex xs12 sm6 md4>
                                    <v-card color="yellow lighten-4" class="mt-2">
                                      <v-card-title>
                                        <v-row justify="space-between">
                                          <span class="ml-2">{{ $t('evaluations.fileExist') }}</span>
                                          <v-tooltip right color="red">
                                            <template v-slot:activator="{ on }">
                                              <v-btn icon v-on="on" @click="deleteReminderFile">
                                                <v-icon>mdi-delete</v-icon>
                                              </v-btn>
                                            </template>
                                            <span>{{ $t('evaluations.deleteFile') }}</span>
                                          </v-tooltip>
                                        </v-row>
                                      </v-card-title>
                                      <v-card-text>
                                  <span>
                                    <b>{{ evaluation.reminderMail.attachment }}</b>
                                  </span>
                                      </v-card-text>
                                    </v-card>
                                  </v-flex>
                                </v-row>
                                -->
                              </v-col>

                            </v-row>

                          </v-container>
                        </v-tab-item>
                        <!--
                        <v-tab-item :value="`tab-3`">
                          <v-container grid-list-md text-xs-center>
                            <v-row>
                              <v-col xs="12" sm="12">
                                <v-textarea
                                  v-model="evaluation.thankMessage"
                                  light
                                  :label="$t('evaluations.tk_message')"
                                  name="thankMessage"
                                  :append-icon="$t('help.icon')"
                                  @click:append="$store.dispatch('help/display', $t('help.engagement.create.thankMessage'))"
                                ></v-textarea>
                              </v-col>
                            </v-row>

                          </v-container>
                        </v-tab-item>
                        -->
                      </v-tabs-items>
                  </v-container>
                </v-col>
              </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <v-btn
                block
                large
                @click="changeStep(true)"
              >{{ $t(prevAction) }}</v-btn>
            </v-col>
            <v-col cols="12" sm="6">
              <v-btn
                color="primary"
                block
                large
                @click="changeStep(false)"
              >{{ $t(nextAction) }}</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <v-dialog v-model="display" max-width="58.8rem">
      <div>
        <div style="background: #f3f3f3; wi">
          <p style="padding-left: 1em; margin-bottom: 0"> {{$t('evaluations.message_subject') }}: <strong> {{message.title}} </strong></p>
        </div>
        <div>
          <title>OCC Solutions</title>
        </div>

        <div style="background: #f3f3f3;">
          <table width="100%">
            <tr>
              <td align="center" bgcolor="#ffffff" style="border-bottom: 3px solid #f3f3f3;">
                <a href="#" target="_blank" style="color: #2E6B9C; font-weight: 900; text-decoration: none; line-height: 0.8;">
                  <h1 style="font-size: 3rem; margin: 0; padding: 30px 0;">OCC Solutions</h1>
                </a>
              </td>
            </tr>
            <tr style="font-family: Arial, sans-serif;">
              <td bgcolor="#FFF" style="padding: 40px 30px 40px 30px;">
                <table border="0" cellpadding="0" cellspacing="0" width="100%">
                  <tr>
                    <td style="color: #333333; font-size: 16px; padding: 20px 0 30px 0;">
                      <div style="padding-left: 1em; margin-bottom: 0"  class="ql-editor" v-html="message.body">  </div>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td align="center" bgcolor="#FFF" style="padding: 40px 0;color: #2E6B9C; font-family: Arial, sans-serif; font-size: 12px; border-top: 3px solid #f3f3f3;">
                <p>
                  {{$t('evaluations.all_right')}} OCC Solutions <br/>
                  info@occsolutions.org | +57 (1) 642 1300
                </p>
                <div> {{ $t('evaluations.automatic_message') }} <a href="#"> OCC Solutions </a></div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </v-dialog>
  </v-container>
</template>

<script>

import { mapState } from 'vuex'
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

export default {
  components: {
    quillEditor
  },
  props: {
    evaluation: Object,
    step: String,
    nextAction: String,
    prevAction: String,
    balance: Number,
    price: Number
  },
  data () {
    return {
      tabSelected: 1,
      display: false,
      message: {
        title: '',
        body: ''
      },
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote'],
            [{ 'header': 1 }, { 'header': 2 }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'align': [] }],
            ['clean'],
            ['link', 'image', 'video']
          ]
        },
        placeholder: ''
      },
      pollInvitationVideo: {
        src: '',
        name: ''
      },
      videoColor: 'primary',
      pollReminderVideo: {
        src: '',
        name: ''
      },
      videoColor2: 'primary',
      invitationHasFile: Boolean(this.evaluation.pollInvitation && this.evaluation.pollInvitation.attachment),
      reminderHasFile: Boolean(this.evaluation.reminderMail && this.evaluation.reminderMail.attachment)
    }
  },
  computed: {
    pollReminderEditor () {
      return this.$refs.pollReminderBody.quill
    },
    pollInvitationEditor () {
      return this.$refs.pollInvitationBody.quill
    },
    ...mapState({
      user: (state) => state.session.user
    })
  },
  methods: {
    getQuestionnaireName () {
      if (this.evaluation.questionnaires) {
        const questionnaire = this.evaluation.questionnaires.find(questionnaire => questionnaire.slug === this.evaluation.questionnaire)
        if (questionnaire) {
          return questionnaire.name
        }
      }
      return ''
    },
    changeStep (isBack = false) {
      if (!this.evaluation.pollInvitation.body) {
        this.evaluation.pollInvitation.body = this.$t('evaluations.custom_msg')
      }
      this.$emit('changeStep', this.evaluation, isBack ? +this.step - 1 : 6)
    },
    formatDate (data) {
      const [year, month, day] = data.value.split('-')
      return `${day}/${month}/${year} a las ${data.hour}:00`
    },
    displayPreview () {
      if (this.tabSelected === 1) {
        this.message.title = this.evaluation.pollInvitation.subject
        this.message.body = this.evaluation.pollInvitation.body
      } else {
        this.message.title = this.evaluation.reminderMail.subject
        this.message.body = this.evaluation.reminderMail.body
      }
      this.display = true
    },
    pickFile () {
      this.$refs.videoInput.click()
    },
    onFilePicked ($event) {
      const files = $event.target.files

      if (files[0] !== undefined) {
        if (files[0].size > 50000000) {
          this.$store.dispatch('alert/error', this.$t('errors.video/size'))
          this.videoColor = 'error'
        } else {
          this.videoColor = 'primary'
          this.pollInvitationVideo.name = files[0].name
          return this.pollInvitationVideo.name.lastIndexOf('.') > 0 ? this.readFile(files) : this.resetVideo()
        }
      } else {
        this.resetVideo()
      }
    },
    resetVideo () {
      this.pollInvitationVideo.src = ''
      this.pollInvitationVideo.name = ''
      this.evaluation.pollInvitation.file = ''
    },
    readFile (files) {
      const fr = new FileReader()
      fr.readAsDataURL(files[0])
      fr.addEventListener('load', () => {
        this.pollInvitationVideo.src = fr.result
        this.evaluation.pollInvitation.file = files[0]
      })
    },
    pickFile2 () {
      this.$refs.videoInput2.click()
    },
    onFilePicked2 ($event) {
      const files = $event.target.files
      this.reminderHasFile = false
      if (files[0] !== undefined) {
        if (files[0].size > 50000000) {
          this.$store.dispatch('alert/error', this.$t('errors.video/size'))
          this.videoColor2 = 'error'
        } else {
          this.videoColor2 = 'primary'
          this.pollReminderVideo.name = files[0].name
          return this.pollReminderVideo.name.lastIndexOf('.') > 0 ? this.readFile2(files) : this.resetVideo2()
        }
      } else {
        this.resetVideo2()
      }
    },
    resetVideo2 () {
      this.pollReminderVideo.src = ''
      this.pollReminderVideo.name = ''
      this.evaluation.reminderMail.file = ''
    },
    readFile2 (files) {
      const fr = new FileReader()
      fr.readAsDataURL(files[0])
      fr.addEventListener('load', () => {
        this.pollReminderVideo.src = fr.result
        this.evaluation.reminderMail.file = files[0]
      })
    },
    deleteInvitationFile () {
      this.evaluation.pollInvitation.attachment = undefined
      this.$emit('delete-invitation-file')
      this.invitationHasFile = false
    },
    deleteReminderFile () {
      this.evaluation.reminderMail.attachment = undefined
      this.$emit('delete-reminder-file')
      this.reminderHasFile = false
    }
  },
  created () {
    this.editorOption.placeholder = this.$t('evaluations.body_message')
    if (!this.evaluation.pollInvitation.body) {
      this.evaluation.pollInvitation.body = this.$t('evaluations.custom_msg')
    }
    if (!this.evaluation.reminderMail.body) {
      this.evaluation.reminderMail.body = this.$t('evaluations.custom_reminder')
    }
  }
}
</script>

<style lang="css">
.ql-container {
  min-height: 170px !important;
}
</style>
