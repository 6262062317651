
export default {
  title: 'Cofigurando Servicio',
  name: 'Nombre del Servicio',
  modality: 'Modalidad',
  periocity: 'Periocidad',
  periocities: {
    Annual: 'Anual',
    Biannual: 'Semestral',
    Quarterly: 'Trimestral',
    Monthly: 'Mensual',
    Biweekly: 'Bimensual',
    Weekly: 'Semanal',
    Diary: 'Diario',
    OneTime: 'Una vez',
    ByDemand: 'Bajo demanda'
  },
  recomendation: 'Recomendación',
  price: 'Precio en Tokens',
  modalities: {
    Download: 'Descarga',
    Person: 'Persona',
    Medition: 'Valoración'
  }
}
