
import pdfUtil from '../utils_old/pdf'

export default {
  methods: {
    generateParagraph (translate, repeat, fontSize, italic) {
      let array = []
      for (let index = 0; index < repeat; index++) {
        array.push(
          {
            text: this.$t(`${translate + '[' + index + ']'}`),
            alignment: 'justify',
            margin: [0, 0, 16, 12],
            color: '#6d7a7a',
            lineHeight: 1.5,
            fontSize: fontSize,
            italics: italic
          }
        )
      }
      return array
    },
    $generateAsPersonResults () {
      const asPersonResponsability = []
      const behavior1 = []
      let behavior2 = []
      let rowFive = []
      const legend = [
        this.$t('individualReport.no_answer'),
        this.$t('individualReport.data_range'),
        this.$t('individualReport.score'),
        this.$t('individualReport.ten_percent'),
        this.$t('individualReport.leaders_average'),
        this.$t('individualReport.higher_bold'),
        this.$t('individualReport.lower_sub')
      ]
      const evaluator = [
        this.$t('individualReport.me'),
        this.$t('individualReport.leaders'),
        this.$t('individualReport.pairs'),
        this.$t('individualReport.dependents'),
        this.$t('individualReport.others')
      ]
      const noZero = (n) => (n === 0 ? '--' : this.round(n, 2, '--'))
      const textColor = '#6d7a7a'
      // Obtenemos arreglo de puntajes por procesos para el higher negrita y lower subrayado
      let scoresFiltered = [this.autoScoresFiltered, this.leadersScoresFiltered, this.pairsScoresFiltered, this.dependentsScoresFiltered, this.noAutoScoresFiltered]
      let scores = []
      scoresFiltered.forEach((it) => {
        if (it[0]) {
          const total = it[0].total
          if (!isNaN(total) && total > 0) {
            scores.push(total)
          }
        }
      })
      let questionScores = []
      for (let j = 0; j < 10; j++) {
        let qScores = []
        scoresFiltered.forEach((it) => {
          if (it[0] && it[0].questions) {
            const score = it[0].questions[j].score
            if (!isNaN(score) && score > 0) {
              qScores.push(score)
            }
          }
        })
        questionScores.push(qScores)
      }

      for (let j = 0; j < 5; j++) {
        let data = j === 0 ? this.averageScores : j === 1 ? this.leadersScores : j === 2 ? this.pairsScores : j === 3 ? this.dependentsScores : this.noAutoScores
        let dataFiltered = scoresFiltered[j]
        let previousDataFiltered = j === 0 ? this.previousAutoScoresFiltered : j === 1 ? this.previousLeadersScoresFiltered : j === 2 ? this.previousPairsScoresFiltered : j === 3 ? this.previousDependentsScoresFiltered : this.previousNoAutoScoresFiltered

        let autoScore = noZero(this.autoScoresFiltered[0].total)
        let othersScore = noZero(this.noAutoScoresFiltered[0].total)
        let symbol = this.getSymbol(autoScore, othersScore)

        // Calcula porcentaje de respuestas "No sabe" para los pares
        // let unknownPercent = j === 2 ? this.getUnknownPercent(dataFiltered[0]) : ''

        let isValid = !isNaN(dataFiltered[0].total) && dataFiltered[0].total >= 1
        let hasValidTrend = this.hasPrevious && isValid && previousDataFiltered[0].total > 0
        asPersonResponsability.push([
          { text: dataFiltered[0].questions[0].responsability + symbol, rowSpan: 5, color: textColor, margin: [-1, 20, -1, 0], border: [true, true, true, true] },
          { text: evaluator[j], bold: true, color: textColor, margin: [-3, 0, -3, 0], border: [true, true, true, true] },
          // { text: evaluator[j] + unknownPercent, bold: true, color: textColor, margin: [-3, 0, -3, 0], border: [true, true, true, true] },
          isValid ? pdfUtil.generateXDCChartT3(dataFiltered, 0, this.round(data[0].total, 2), data[0].tenPercent) : { text: '--', border: [true, true, true, true] },
          pdfUtil.higherLowerRow(scores, isValid ? dataFiltered[0].total : '--', textColor, [0, 4, 0, 0], 'center', [true, true, true, true]),
          { text: data[0].tenPercent > 0 ? this.round(data[0].tenPercent, 2) : '--', bold: false, color: textColor, margin: [0, 4, 0, 0], border: [true, true, true, true], alignment: 'center' },
          { text: data[0].total > 0 ? this.round(data[0].total, 2, '--') : '--', bold: false, color: textColor, margin: [0, 4, 0, 0], border: [true, true, true, true], alignment: 'center' },
          { text: `${hasValidTrend ? this.round(dataFiltered[0].total - previousDataFiltered[0].total, 2, '--') : '--'}`, bold: false, color: textColor, margin: [0, 4, 0, 0], border: [true, true, true, true], alignment: 'center' }
        ])
        for (let i = 0; i < 5; i++) {
          let data = i === 0 ? this.averageScores : i === 1 ? this.leadersScores : i === 2 ? this.pairsScores : i === 3 ? this.dependentsScores : this.noAutoScores
          let dataFiltered = i === 0 ? this.autoScoresFiltered : i === 1 ? this.leadersScoresFiltered : i === 2 ? this.pairsScoresFiltered : i === 3 ? this.dependentsScoresFiltered : this.noAutoScoresFiltered
          let previousDataFiltered = i === 0 ? this.previousAutoScoresFiltered : i === 1 ? this.previousLeadersScoresFiltered : i === 2 ? this.previousPairsScoresFiltered : i === 3 ? this.previousDependentsScoresFiltered : this.previousNoAutoScoresFiltered

          autoScore = noZero(this.autoScoresFiltered[0].questions[j].score)
          othersScore = noZero(this.noAutoScoresFiltered[0].questions[j].score)
          symbol = this.getSymbol(autoScore, othersScore)

          // Calcula porcentaje de respuestas "No sabe" para los pares
          let unknownPercent = i === 2 ? this.getUnknownPercent(dataFiltered[0].questions[j]) : ''

          let isValid = !isNaN(dataFiltered[0].questions[j].score) && dataFiltered[0].questions[j].score >= 1
          let hasValidTrend = this.hasPrevious && isValid && previousDataFiltered[0].questions[j].score > 0
          if (j < 4) {
            behavior1.push([
              { text: dataFiltered[0].questions[j].question + symbol, rowSpan: 5, color: textColor, margin: [-1, 5, -1, 0], border: [true, true, true, true] },
              { text: evaluator[i] + unknownPercent, bold: true, color: textColor, margin: [-3, 0, -3, 0], border: [true, true, true, true] },
              isValid ? pdfUtil.generateXDCChartT7Slim(dataFiltered[0].questions, j, this.round(data[0].questions[j].leadersProm, 2), data[0].questions[j].tenPercent, 'detail') : { text: '--', border: [true, true, true, true] },
              pdfUtil.higherLowerRow(questionScores[j], isValid ? dataFiltered[0].questions[j].score : '--', textColor, [0, 0, 0, 0], 'center', [true, true, true, true], 'question'),
              { text: data[0].questions[j].tenPercent > 0 ? this.round(data[0].questions[j].tenPercent, 2) : '--', bold: false, color: textColor, margin: [0, 0, 0, 0], border: [true, true, true, true], alignment: 'center' },
              { text: data[0].questions[j].leadersProm > 0 ? this.round(data[0].questions[j].leadersProm, 2) : '--', bold: false, color: textColor, margin: [0, 0, 0, 0], border: [true, true, true, true], alignment: 'center' },
              { text: `${hasValidTrend ? this.round(dataFiltered[0].questions[j].score - previousDataFiltered[0].questions[j].score, 2, '--') : '--'}`, bold: false, color: textColor, margin: [0, 0, 0, 0], border: [true, true, true, true], alignment: 'center' }
            ])
          }
          if (j === 4) {
            rowFive.push([
              { text: dataFiltered[0].questions[j].question + symbol, rowSpan: 5, color: textColor, margin: [-1, 5, -1, 0], border: [true, true, true, true] },
              { text: evaluator[i] + unknownPercent, bold: true, color: textColor, margin: [-3, 0, -3, 0], border: [true, true, true, true] },
              isValid ? pdfUtil.generateXDCChartT7Slim(dataFiltered[0].questions, j, this.round(data[0].questions[j].leadersProm, 2), data[0].questions[j].tenPercent, 'detail') : { text: '--', border: [true, true, true, true] },
              pdfUtil.higherLowerRow(questionScores[j], isValid ? dataFiltered[0].questions[j].score : '--', textColor, [0, 0, 0, 0], 'center', [true, true, true, true], 'question'),
              { text: data[0].questions[j].tenPercent > 0 ? this.round(data[0].questions[j].tenPercent, 2) : '--', bold: false, color: textColor, margin: [0, 0, 0, 0], border: [true, true, true, true], alignment: 'center' },
              { text: data[0].questions[j].leadersProm > 0 ? this.round(data[0].questions[j].leadersProm, 2) : '--', bold: false, color: textColor, margin: [0, 0, 0, 0], border: [true, true, true, true], alignment: 'center' },
              { text: `${hasValidTrend ? this.round(dataFiltered[0].questions[j].score - previousDataFiltered[0].questions[j].score, 2, '--') : '--'}`, bold: false, color: textColor, margin: [0, 0, 0, 0], border: [true, true, true, true], alignment: 'center' }
            ])
          }

          autoScore = noZero(this.autoScoresFiltered[0].questions[j + 5].score)
          othersScore = noZero(this.noAutoScoresFiltered[0].questions[j + 5].score)
          symbol = this.getSymbol(autoScore, othersScore)

          // Calcula porcentaje de respuestas "No sabe" para los pares
          unknownPercent = i === 2 ? this.getUnknownPercent(dataFiltered[0].questions[j + 5]) : ''

          isValid = !isNaN(dataFiltered[0].questions[j + 5].score) && dataFiltered[0].questions[j + 5].score >= 1
          hasValidTrend = this.hasPrevious && isValid && previousDataFiltered[0].questions[j + 5].score > 0
          behavior2.push([
            { text: dataFiltered[0].questions[j + 5].question + symbol, rowSpan: 5, color: textColor, margin: [-1, 5, -1, 0], border: [true, true, true, true] },
            { text: evaluator[i] + unknownPercent, bold: true, color: textColor, margin: [0, 0, 0, 0], border: [true, true, true, true] },
            isValid ? pdfUtil.generateXDCChartT7Slim(dataFiltered[0].questions, j + 5, this.round(data[0].questions[j + 5].leadersProm, 2), data[0].questions[j + 5].tenPercent, 'detail') : { text: '--', border: [true, true, true, true] },
            pdfUtil.higherLowerRow(questionScores[j + 5], isValid ? dataFiltered[0].questions[j + 5].score : '--', textColor, [0, 0, 0, 0], 'center', [true, true, true, true], 'question'),
            { text: data[0].questions[j + 5].tenPercent > 0 ? this.round(data[0].questions[j + 5].tenPercent, 2) : '--', bold: false, color: textColor, margin: [0, 0, 0, 0], border: [true, true, true, true], alignment: 'center' },
            { text: data[0].questions[j + 5].leadersProm > 0 ? this.round(data[0].questions[j + 5].leadersProm, 2) : '--', bold: false, color: textColor, margin: [0, 0, 0, 0], border: [true, true, true, true], alignment: 'center' },
            { text: `${hasValidTrend ? this.round(dataFiltered[0].questions[j + 5].score - previousDataFiltered[0].questions[j + 5].score, 2, '--') : '--'}`, bold: false, color: textColor, margin: [0, 0, 0, 0], border: [true, true, true, true], alignment: 'center' }
          ])
        }
      }
      behavior2 = [...rowFive, ...behavior2]
      return [
        {
          table: {
            widths: [479],
            body: [
              [{ text: `${this.$t('individualReport.behavior_results_title')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 6, 0, 6] }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [-40, 0, 0, 10],
          pageBreak: 'before'
        },
        {
          table: {
            widths: [479],
            body: [
              [{ text: [
                `${this.$t('individualReport.table')} 7. ${this.$t('individualReport.table7')}`,
                { text: this.$t('individualReport.leader_as_person').toUpperCase(), color: '#0D47A1' }
              ],
              bold: true,
              color: '#fff',
              margin: [20, 6, 0, 6] }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [0, 5, 135, 10]
        },
        pdfUtil.generateDmlLegendIndividual(legend),
        {
          layout: {
            defaultBorder: '',
            border: [false, false, false, true],
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#BFBFBF' : null
            }
          },
          table: {
            headerRows: 1,
            widths: ['16%', '14%', '38%', '8%', '8%', '8%', '8%'],
            body: [
              [
                { text: `${this.$t('individualReport.responsability')}`, bold: true, color: '#fff', margin: [0, 5, 0, 0], border: [true, true, true, true], fontSize: 9 },
                { text: `${this.$t('individualReport.evaluator')}`, bold: true, color: '#fff', border: [true, true, true, true], margin: [0, 5, 0, 0], fontSize: 9 },
                { layout: { defaultBorder: '', fillColor: '#BFBFBF' },
                  table: {
                    headerRows: 1,
                    body: [[
                      { text: '1\n|', margin: [10, 2, 15, 0] },
                      { text: '2\n|', margin: [0, 2, 15, 0] },
                      { text: '3\n|', margin: [0, 2, 15, 0] },
                      { text: '4\n|', margin: [0, 2, 15, 0] },
                      { text: '5\n|', margin: [0, 2, 15, 0] },
                      { text: '6\n|', margin: [0, 2, 15, 0] }
                    ]]
                  },
                  bold: true,
                  style: { color: '#fff' },
                  border: [true, true, true, true]
                },
                { text: this.$t('individualReport.score'), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 },
                { text: this.$t('individualReport.ten_percent'), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 },
                { text: this.$t('individualReport.leaders_average'), margin: [-3, 5, -3, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 },
                { text: this.$t('individualReport.trend'), margin: [-4, 5, -4, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 }
              ],
              ...asPersonResponsability
            ]
          },
          margin: [-10, -5, 0, 10],
          border: [false, false, false, true],
          alignment: 'center',
          fontSize: 9
        },
        {
          layout: {
            defaultBorder: '',
            border: [false, false, false, true],
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#BFBFBF' : null
            }
          },
          table: {
            headerRows: 1,
            widths: ['16%', '14%', '38%', '8%', '8%', '8%', '8%'],
            body: [
              [
                { text: `${this.$t('individualReport.behavior')}`, bold: true, color: '#fff', margin: [0, 5, 0, 0], border: [true, true, true, true], fontSize: 9 },
                { text: `${this.$t('individualReport.evaluator')}`, bold: true, color: '#fff', border: [true, true, true, true], margin: [0, 5, 0, 0], fontSize: 9 },
                { layout: { defaultBorder: '', fillColor: '#BFBFBF' },
                  table: {
                    headerRows: 1,
                    body: [[
                      { text: '1\n|', margin: [10, 2, 15, 0] },
                      { text: '2\n|', margin: [0, 2, 15, 0] },
                      { text: '3\n|', margin: [0, 2, 15, 0] },
                      { text: '4\n|', margin: [0, 2, 15, 0] },
                      { text: '5\n|', margin: [0, 2, 15, 0] },
                      { text: '6\n|', margin: [0, 2, 15, 0] }
                    ]]
                  },
                  bold: true,
                  style: { color: '#fff' },
                  border: [true, true, true, true]
                },
                { text: this.$t('individualReport.score'), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 },
                { text: this.$t('individualReport.ten_percent'), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 },
                { text: this.$t('individualReport.leaders_average'), margin: [-3, 5, -3, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 },
                { text: this.$t('individualReport.trend'), margin: [-4, 5, -4, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 }
              ],
              ...behavior1
            ]
          },
          margin: [-10, 10, 0, 10],
          border: [false, false, false, true],
          alignment: 'center',
          fontSize: 9,
          pageBreak: ''
        },
        {
          table: {
            widths: [479],
            body: [
              [{ text: [
                `${this.$t('individualReport.table')} 8. ${this.$t('individualReport.table7')}`,
                { text: this.$t('individualReport.leader_as_person').toUpperCase(), color: '#0D47A1' }
              ],
              bold: true,
              color: '#fff',
              margin: [20, 6, 0, 6] }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [0, 0, 135, 10],
          pageBreak: 'before'
        },
        pdfUtil.generateDmlLegendIndividual(legend),
        {
          layout: {
            defaultBorder: '',
            border: [false, false, false, true],
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#BFBFBF' : null
            }
          },
          table: {
            headerRows: 1,
            widths: ['16%', '14%', '38%', '8%', '8%', '8%', '8%'],
            body: [
              [
                { text: `${this.$t('individualReport.behavior')}`, bold: true, color: '#fff', margin: [0, 5, 0, 0], border: [true, true, true, true], fontSize: 9 },
                { text: `${this.$t('individualReport.evaluator')}`, bold: true, color: '#fff', border: [true, true, true, true], margin: [0, 5, 0, 0], fontSize: 9 },
                { layout: { defaultBorder: '', fillColor: '#BFBFBF' },
                  table: {
                    headerRows: 1,
                    body: [[
                      { text: '1\n|', margin: [10, 2, 15, 0] },
                      { text: '2\n|', margin: [0, 2, 15, 0] },
                      { text: '3\n|', margin: [0, 2, 15, 0] },
                      { text: '4\n|', margin: [0, 2, 15, 0] },
                      { text: '5\n|', margin: [0, 2, 15, 0] },
                      { text: '6\n|', margin: [0, 2, 15, 0] }
                    ]]
                  },
                  bold: true,
                  style: { color: '#fff' },
                  border: [true, true, true, true]
                },
                { text: this.$t('individualReport.score'), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 },
                { text: this.$t('individualReport.ten_percent'), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 },
                { text: this.$t('individualReport.leaders_average'), margin: [-3, 5, -3, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 },
                { text: this.$t('individualReport.trend'), margin: [-4, 5, -4, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 }
              ],
              ...behavior2
            ]
          },
          margin: [-10, 0, 0, 10],
          border: [false, false, false, true],
          alignment: 'center',
          fontSize: 9
        }
      ]
    }
  }
}
