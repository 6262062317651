<template>
  <v-dialog
    v-model="show"
    fullscreen
    hide-overlay
  >
    <v-card class="wellcome-dialog">
      <v-container fluid>
        <v-row row wrap>
          <v-col cols="12"
            class="text-center grey--text text--darken-1"
            style="margin: 30px auto!important;"
          >
            <section class="enterprise-logo">
              <img src="/img/20200301_occ_solution_logo.png" v-if="!evaluation.enterprise.logo"/>
              <img v-else :src="evaluation.enterprise.logo" alt="Enterprise Logo"/>
            </section>
            <br>
            <h2 class="display-3 mt-4 mx-3" style="color: #42A5F5;">{{ $t('evaluations.wellcome_title') }}</h2>
            <h3 class="display-2 mt-4 mx-3" style="color: #42A5F5;">{{ evaluation.displayName || evaluation.name }}</h3>
            <p class="mt-4 mx-5 px-5" v-html="$t('evaluations.wellcome_instructions', { deadline: this.deadLine })"></p>
            <v-row justify="center">
              <v-col cols="12">
                <v-btn
                  @click="close"
                  class="mt-4"
                  color="primary"
                  large
                >{{ $t('input.answer_poll') }}</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  props: {
    startDialog: Boolean,
    evaluatedRole: String,
    evaluation: Object
  },
  data () {
    return {
      deadLine: '',
      show: false
    }
  },
  watch: {
    startDialog: {
      handler () {
        this.show = this.startDialog
        if (this.startDialog) {
          this.setDeadLine()
        }
      },
      immediate: true
    }
  },
  methods: {
    setDeadLine () {
      let timeZone = this.evaluation.timeZone.split('UTC')[1].split(')')[0]
      let maxDateTime = new Date(Date.parse(this.evaluation.validUntil.split('Z')[0] + timeZone))
      let months = ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic']
      let ampm = maxDateTime.getHours() >= 12 ? ' pm' : ' am'
      let hour = (maxDateTime.getHours() % 12) || 12
      this.deadLine = (maxDateTime.getDate() < 10 ? '0' + maxDateTime.getDate() : maxDateTime.getDate()) + ' ' +
        months[maxDateTime.getMonth()] + ' de ' + maxDateTime.getFullYear() + ', ' +
        (hour < 10 ? '0' + hour : hour) + ':' + (maxDateTime.getMinutes() < 10 ? '0' + maxDateTime.getMinutes() : maxDateTime.getMinutes()) + ':' +
        (maxDateTime.getSeconds() < 10 ? '0' + maxDateTime.getSeconds() : maxDateTime.getSeconds()) + ampm
    },
    close () {
      this.show = false
      this.$emit('close')
    }
  }
})
</script>
