
export default {
  methods: {
    $generateDispersion () {
      const higherDispersion = []
      const lowerDispersion = []
      let ranking = this.noAutoOthersRankFiltered
      ranking.sort((a, b) => b.dispersion - a.dispersion)
      const textColor = '#6d7a7a'
      if (this.othersPopulationFiltered > 0) {
        for (let i = 0; i < 6; i++) {
          higherDispersion.push([
            // Higher score
            { text: ranking[i].abbreviation, margin: [0, 5, 0, 0], fontSize: 10, border: [true, true, true, true], bold: true, style: { color: ranking[i].abbreviation === 'P' ? '#0D47A1' : ranking[i].abbreviation === 'D' ? '#b4cf32' : ranking[i].abbreviation === 'M' ? '#4ec7aa' : '#43ace2' } },
            { text: ranking[i].responsability, margin: [0, 5, 0, 0], border: [true, true, true, true], style: { color: textColor } },
            { text: ranking[i].question, margin: [0, 5, 0, 0], border: [true, true, true, true], style: { color: textColor } },
            { text: this.round(ranking[i].dispersion, 2), alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } },
            { text: this.round(ranking[i].auto, 2), alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } },
            { text: this.round(ranking[i].score, 2), alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } }
          ])
          lowerDispersion.push([
            { text: ranking[ranking.length - (i + 1)].abbreviation, margin: [0, 5, 0, 0], fontSize: 10, border: [true, true, true, true], bold: true, style: { color: ranking[ranking.length - (i + 1)].abbreviation === 'P' ? '#0D47A1' : ranking[ranking.length - (i + 1)].abbreviation === 'D' ? '#b4cf32' : ranking[ranking.length - (i + 1)].abbreviation === 'M' ? '#4ec7aa' : '#43ace2' } },
            { text: ranking[ranking.length - (i + 1)].responsability, margin: [0, 5, 0, 0], border: [true, true, true, true], style: { color: textColor } },
            { text: ranking[ranking.length - (i + 1)].question, margin: [0, 5, 0, 0], border: [true, true, true, true], style: { color: textColor } },
            { text: this.round(ranking[ranking.length - (i + 1)].dispersion, 2), alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } },
            { text: this.round(ranking[ranking.length - (i + 1)].auto, 2), alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } },
            { text: this.round(ranking[ranking.length - (i + 1)].score, 2), alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } }
          ])
        }
      } else {
        higherDispersion.push([
          { text: this.$t('individualReport.no_others_evaluators'), margin: [0, 5, 0, 0], fontSize: 10, border: [true, true, true, true], bold: false, colSpan: 6 }
        ])
        lowerDispersion.push([
          { text: this.$t('individualReport.no_others_evaluators'), margin: [0, 5, 0, 0], fontSize: 10, border: [true, true, true, true], bold: false, colSpan: 6 }
        ])
      }
      return [
        {
          table: {
            widths: [479],
            body: [
              [{ text: `6.8. ${this.$t('individualReport.dispersion_analysis[0]')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 6, 0, 6] }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [-40, 0, 0, 20],
          pageBreak: 'before'
        },
        {
          text: this.$t('individualReport.dispersion_analysis[1]'),
          alignment: 'justify',
          margin: [0, 0, 16, 12],
          color: textColor,
          lineHeight: 1.5,
          fontSize: 10
        },
        {
          table: {
            widths: [479],
            body: [
              [{ text: [
                `${this.$t('individualReport.table')} 27. ${this.$t('individualReport.dispersion_analysis[0]')}`
              ],
              bold: true,
              color: '#fff',
              margin: [20, 6, 0, 6] }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [35, 5, 100, 20]
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex <= 1) ? '#BFBFBF' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            widths: ['6%', '33%', '40%', '7%', '7%', '7%'],
            body: [
              [
                { text: this.$t('individualReport.higher_dispersion').toUpperCase(), colSpan: 6, margin: [0, 3, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                {},
                {},
                {},
                {},
                {}
              ],
              // Header
              [
                { text: this.$t('individualReport.process'), margin: [-2, 5, -2, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.responsability'), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.behavior'), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.dispersion'), margin: [-3, 5, -3, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.auto'), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.others'), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } }
              ],
              // Body
              ...higherDispersion
            ]
          },
          margin: [0, 0, 0, 10],
          border: [false, false, false, true],
          alignment: 'center',
          fontSize: 8
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex <= 1) ? '#BFBFBF' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            widths: ['6%', '33%', '40%', '7%', '7%', '7%'],
            body: [
              [
                { text: this.$t('individualReport.lower_dispersion').toUpperCase(), colSpan: 6, margin: [0, 3, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                {},
                {},
                {},
                {},
                {}
              ],
              // Header
              [
                { text: this.$t('individualReport.process'), margin: [-2, 5, -2, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.responsability'), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.behavior'), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.dispersion'), margin: [-3, 5, -3, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.auto'), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.others'), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } }
              ],
              // Body
              ...lowerDispersion
            ]
          },
          margin: [0, 0, 0, 10],
          border: [false, false, false, true],
          alignment: 'center',
          fontSize: 8
        }
      ]
    }
  }
}
