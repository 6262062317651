
export default {
  methods: {
    textToUpper (key) {
      const t = this.$t(key)
      return (t.text ? t.text : t).toUpperCase()
    },
    getObjPropsRankingAbbrLength (listData, index) {
      const result = {
        ranking: [],
        higherAbbr: '',
        lowerAbbr: '',
        length: 0
      }
      result.ranking = listData
      result.ranking.sort((a, b) => a.score - b.score)
      result.higherAbbr = result.ranking[listData.length - (index + 1)].abbreviation
      result.lowerAbbr = result.ranking[index].abbreviation
      result.length = listData.length
      return result
    },
    $generateResponsability () {
      let tables = []
      // 309DE0 EB493C 48BBA1
      const descriptions = [
        this.$t('demographicReport.table1'),
        this.$t('demographicReport.table2'),
        this.$t('demographicReport.table3'),
        this.$t('demographicReport.table4')
      ]
      for (let i = 0; i < 8; i++) {
        let rows = []
        let ranking = []
        let higherAbbr = ''
        let lowerAbbr = ''
        let length = 0
        let isHigherScore = i === 0 || i === 2 || i === 4 || i === 6 // Retorna true o false
        for (let j = 0; j < 3; j++) {
          if (i === 0 || i === 1) {
            const result = this.getObjPropsRankingAbbrLength(this.rankResults.noAutoOthersRespRank, j)
            ranking = result.ranking
            higherAbbr = result.higherAbbr
            lowerAbbr = result.lowerAbbr
            length = result.length
          } else if (i === 2 || i === 3) {
            const result = this.getObjPropsRankingAbbrLength(this.rankResults.leadersRespRank, j)
            ranking = result.ranking
            higherAbbr = result.higherAbbr
            lowerAbbr = result.lowerAbbr
            length = result.length
          } else if (i === 5 || i === 6) {
            const result = this.getObjPropsRankingAbbrLength(this.rankResults.pairsRespRank, j)
            ranking = result.ranking
            higherAbbr = result.higherAbbr
            lowerAbbr = result.lowerAbbr
            length = result.length
          } else {
            const result = this.getObjPropsRankingAbbrLength(this.rankResults.dependentsRespRank, j)
            ranking = result.ranking
            higherAbbr = result.higherAbbr
            lowerAbbr = result.lowerAbbr
            length = result.length
          }
          if (isHigherScore) {
            rows.push([
              // Higher score
              { text: ranking[length - (j + 1)].score !== '--' ? ranking[length - (j + 1)].abbreviation : '--', margin: [0, 6, 0, 0], border: [true, true, true, true], bold: true, fontSize: 12, style: { color: higherAbbr === 'P' ? '#0D47A1' : higherAbbr === 'D' ? '#b4cf32' : higherAbbr === 'M' ? '#4ec7aa' : '#43ace2' } },
              { text: ranking[length - (j + 1)].score !== '--' ? ranking[length - (j + 1)].name : '--', alignment: 'left', margin: [0, 3, 0, 0], border: [true, true, true, true], style: { color: '#6d7a7a' } },
              { text: this.round(ranking[length - (j + 1)].score, 2), margin: [0, 6, 0, 0], alignment: 'center', border: [true, true, true, true], style: { color: '#6d7a7a' } },
              { text: this.round(ranking[length - (j + 1)].currentScore, 2), margin: [0, 6, 0, 0], alignment: 'center', border: [true, true, true, true], style: { color: '#6d7a7a' } },
              { text: this.round(ranking[length - (j + 1)].gap, 2), margin: [0, 6, 0, 0], alignment: 'center', border: [true, true, true, true], style: { color: '#6d7a7a' } },
              { text: this.round(ranking[length - (j + 1)].tenPercent, 2), margin: [0, 6, 0, 0], alignment: 'center', border: [true, true, true, true], style: { color: '#6d7a7a' } },
              { text: `${this.hasPrevious ? this.round(ranking[length - (j + 1)].trend, 2) : '--'}`, margin: [0, 6, 0, 0], alignment: 'center', border: [true, true, true, true], style: { color: '#6d7a7a' } }
            ])
          } else {
            rows.push([
              // Lower score
              { text: ranking[j].score !== '--' ? ranking[j].abbreviation : '--', margin: [0, 6, 0, 0], border: [true, true, true, true], bold: true, fontSize: 12, style: { color: lowerAbbr === 'P' ? '#0D47A1' : lowerAbbr === 'D' ? '#b4cf32' : lowerAbbr === 'M' ? '#4ec7aa' : '#43ace2' } },
              { text: ranking[j].score !== '--' ? ranking[j].name : '--', alignment: 'left', margin: [0, 3, 0, 0], border: [true, true, true, true], style: { color: '#6d7a7a' } },
              { text: this.round(ranking[j].score, 2), margin: [0, 6, 0, 0], alignment: 'center', border: [true, true, true, true], style: { color: '#6d7a7a' } },
              { text: this.round(ranking[j].currentScore, 2), margin: [0, 6, 0, 0], alignment: 'center', border: [true, true, true, true], style: { color: '#6d7a7a' } },
              { text: this.round(ranking[j].gap, 2), margin: [0, 6, 0, 0], alignment: 'center', border: [true, true, true, true], style: { color: '#6d7a7a' } },
              { text: this.round(ranking[j].tenPercent, 2), margin: [0, 6, 0, 0], alignment: 'center', border: [true, true, true, true], style: { color: '#6d7a7a' } },
              { text: `${this.hasPrevious ? this.round(ranking[j].trend, 2) : '--'}`, margin: [0, 6, 0, 0], alignment: 'center', border: [true, true, true, true], style: { color: '#6d7a7a' } }
            ])
          }
        }
        let title = {}
        if (isHigherScore) {
          title = {
            table: {
              widths: [479],
              body: [
                [{ text: this.textToUpper('demographicReport.index[0]'), bold: true, color: '#fff', margin: [50, 8, 0, 5] }]
              ]
            },
            layout: {
              fillColor: '#aaaaaa',
              defaultBorder: ''
            },
            alignment: 'left',
            margin: [-40, 40, 0, 0],
            pageBreak: isHigherScore ? 'before' : ''
          }
        }
        let layoutTableMarginObject = {}
        if (isHigherScore) {
          let index = 0
          if (i === 0) index = 0
          if (i === 2) index = 1
          if (i === 4) index = 2
          if (i === 6) index = 3
          layoutTableMarginObject = {
            layout: {
              defaultBorder: '',
              fillColor: '#aaaaaa'
            },
            table: {
              widths: [479],
              body: [
                [{ text: `${this.$t('demographicReport.table')} ${index + 1}. ${descriptions[index]}`, margin: [30, 5, 0, 5], bold: true, color: '#fff' }]
              ]
            },
            margin: [35, 10, 20, 20]
          }
        }
        let tableTitle = isHigherScore ? 'demographicReport.higher_scores' : 'demographicReport.lower_scores'
        tables.push(
          title,
          layoutTableMarginObject,
          {
            layout: {
              defaultBorder: '',
              fillColor: function (rowIndex, node, columnIndex) {
                return (rowIndex <= 1 && columnIndex !== 7) ? '#BFBFBF' : null
              },
              border: [false, false, false, true]
            },
            table: {
              headerRows: 1,
              widths: ['8%', '37%', '11%', '11%', '11%', '11%', '11%'],
              body: [
                [
                  { text: this.$t(tableTitle).toUpperCase(), colSpan: 7, margin: [0, 3, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                  {},
                  {},
                  {},
                  {},
                  {},
                  {}
                ],
                // Header
                [
                  { text: this.$t('demographicReport.process'), margin: [-1, 8, -1, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                  { text: this.$t('demographicReport.responsability'), margin: [5, 8, 5, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                  { text: this.$t('demographicReport.cutScore'), margin: [3, 8, 3, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                  { text: this.$t('demographicReport.score'), margin: [3, 8, 3, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                  { text: this.$t('demographicReport.gap'), margin: [3, 8, 3, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                  { text: this.$t('demographicReport.ten_percent'), margin: [1, 2, 1, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                  { text: this.$t('demographicReport.trend'), margin: [-2, 8, -2, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } }
                ],
                // Body
                ...rows
              ]
            },
            margin: [10, 0, 20, 40],
            border: [false, false, false, true],
            alignment: 'center',
            verticalAlignment: 'center',
            fontSize: 8
          }
        )
      }
      return tables
    }
  }
}
