<template>
    <v-container flat>
      <v-card flat>
        <ValidationObserver v-slot="{ handleSubmit }">
          <v-form @submit.prevent="handleSubmit(changeStep)">
            <v-row>
              <v-col>
                {{ $t('evaluations.questions') }}
                <v-divider></v-divider>
              </v-col>
            </v-row>
            <v-row
              v-for="(item,index) in evaluation.questionnaires"
              v-bind:key="index"
            >
              <v-col cols="12" sm="7" max-height="20px" class="pt-3 pl-4">
                <v-radio-group
                  v-model="evaluation.questionnaire"
                  :value="evaluation.questionnaire"
                  :mandatory="false"
                  class="my-0"
                >
                  <v-radio
                    color="primary"
                    :label="item.name"
                    :value="item.slug"
                    :readonly="evaluation.status != 'pending'"
                    :disabled="evaluation.status != 'pending'"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" sm="5" class="pt-4">
                <v-btn
                  class="white--text mr-4"
                  color="primary"
                  style="margin-right=100%;"
                  dark
                  small
                  @click="getPdf(item)"
                >
                  {{$t('input.download')}} {{ $t('evaluations.questionnaire') }}
                  <v-icon dark right small>mdi-file-pdf</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-alert type="info" color="grey lighten-1">
              {{ $t('evaluations.custom_questionnaire_info') }}
            </v-alert>
            <v-row>
              <v-col cols="12" sm="6">
                <v-btn
                  block
                  large
                  @click="changeStep(true)"
                >{{ $t('input.back') }}</v-btn>
              </v-col>
              <v-col cols="12" sm="6">
                <v-btn
                  v-if="evaluation.questionnaires.length"
                  large
                  block
                  color="primary"
                  type="submit"
                >{{ $t(nextAction) }}
                </v-btn>
                <v-btn
                  v-else
                  large
                  block
                  disabled
                  color="primary"
                  type="submit"
                >{{ $t(nextAction) }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </ValidationObserver>
      </v-card>
      <img
        src="../../../../public/img/20200303_x_occ_dml_logo.png"
        style="visibility:hidden;"
        id="occDML360Logo"
        width="0"
        height="0"
        alt=""
      />
    </v-container>
</template>

<script>

import { mapState } from 'vuex'
import is from 'is_js'
import questionnairesService from '../../../services/questionnaires'

const pdfmake = require('pdfmake/build/pdfmake')
const pdffonts = require('pdfmake/build/vfs_fonts.js')
pdfmake.vfs = pdffonts.pdfMake.vfs

export default {
  props: {
    evaluation: Object,
    step: String,
    nextAction: String,
    prevAction: String
  },
  data () {
    return {
      dimensions: [],
      attributes: [],
      dmlLogoSrc: null,
      pulseLogoBase64: null,
      minOpenQuestion: 1,
      maxOpenQuestion: 3,
      minOptionOpenQuestion: 2,
      maxOptionOpenQuestion: 5,
      showDialog: false
    }
  },
  watch: {
    dmlLogoSrc (newVal, oldVal) {
      if (newVal) {
        this.toDataURL(this.dmlLogoSrc, (dataURL) => {
          this.pulseLogoBase64 = dataURL
        })
      }
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    })
  },
  methods: {
    changeStep (isBack = false) {
      this.$emit('changeStep', this.engagement, isBack ? +this.step - 1 : +this.step + 1)
    },
    removeOptionOpenQuestion (open, idx) {
      open.options.splice(idx, 1)
    },
    addOptionOpenQuestion (open) {
      open.options.push('')
    },
    toDataURL (url, callback) {
      const xhr = new XMLHttpRequest()
      xhr.open('get', url)
      xhr.responseType = 'blob'

      xhr.onload = function () {
        const fr = new FileReader()

        fr.onload = function () {
          callback(this.result)
        }

        fr.readAsDataURL(xhr.response)
      }

      xhr.send()
    },
    writeRotatedText (text) {
      const canvas = document.createElement('canvas')
      canvas.width = 50
      canvas.height = 845

      const ctx = canvas.getContext('2d')

      // Genera color de fondo
      ctx.fillStyle = '#2196F3'
      ctx.fillRect(0, 0, canvas.width, canvas.height)
      ctx.save()

      // Posiciona el elemento al costado derecho de la página
      ctx.translate(50, 845)
      ctx.rotate(-0.5 * Math.PI)

      // Formatea el texto
      ctx.font = '20pt Roboto'
      ctx.fillStyle = 'white'
      ctx.fillText(text.toUpperCase(), 290, -15)
      ctx.restore()

      return canvas.toDataURL()
    },
    getPdf (questionnaire) {
      this.$store.dispatch('loading/show')
      let details = []
      questionnaire.evaluations.autoEvaluation.forEach((dimension, index) => {
        details.push({
          text: this.$t(`questionnaires.${dimension.name}`).toUpperCase(),
          bold: true,
          fontSize: 15,
          pageBreak: index === 2 ? 'before' : '',
          margin: index === 2 ? [0, 5, 0, 0] : []
        })
        dimension.responsability.forEach((resp) => {
          details.push({
            text: resp[this.user.lang],
            bold: true,
            fontSize: 12
          })
          const questions = []
          resp.questions.forEach((question) => {
            questions.push(question[this.user.lang])
          })
          details.push({ ul: questions, margin: [15, 0, 0, 15] })
        })
      })

      const configuration = {
        pageSize: 'A4',
        info: {
          title: this.$t('evaluations.title'),
          author: 'OCC',
          subject: this.$t('evaluations.title')
        },
        defaultStyle: {
          fontSize: 11,
          font: 'Roboto',
          lineHeight: 1.2
        },
        header: () => {
          return [{
            image: this.pulseLogoBase64,
            height: 45,
            width: 117,
            margin: [15, 0, 25, 15]
          }]
        },
        footer: () => {
          return [
            {
              columns: [
                { width: '*', text: '' },
                {
                  width: 'auto',
                  text: this.$t('evaluations.copyright'),
                  color: 'grey',
                  fontSize: 10
                },
                { width: '*', text: '' }
              ]
            }
          ]
        },
        background: () => {
          const result = {
            image: this.writeRotatedText(questionnaire.name),
            aligment: 'center',
            absolutePosition: { x: 545, y: 0 }
          }

          return result
        },
        content: [
          // Título
          {
            text: this.$t('evaluations.questionnaire_e'),
            fontSize: 20,
            margin: [0, 20, 0, 10]
          },
          // Explicación
          {
            text: this.$t('evaluations.pdf_explained', { name: questionnaire.name }),
            alignment: 'justify',
            margin: [0, 0, 20, 10]
          },
          // Cuestionario
          ...details
        ]
      }

      if (is.edge() || is.ie()) {
        const pdfDocGenerator = pdfMake.createPdf(configuration)
        pdfDocGenerator.getBlob((blob) => {
          window.navigator.msSaveBlob(blob, 'questionnaire.pdf')
          this.$store.dispatch('loading/hide')
        })
      } else {
        pdfmake.createPdf(configuration).download('questionnaire')
        this.$store.dispatch('loading/hide')
      }
    },
    getQuestionnaires () {
      this.$store.dispatch('loading/show')
      return questionnairesService.listFiltered().then((res) => {
        this.evaluation.questionnaires = res.items
        if (!this.$route.params.slug && this.evaluation.questionnaires.length) {
          this.evaluation.questionnaire = this.evaluation.questionnaires[0].slug
        } else {
          if (this.evaluation.questionnaire.slug) {
            this.evaluation.questionnaire = this.evaluation.questionnaire.slug
          }
        }
        this.$store.dispatch('loading/hide')
      })
    }
  },
  created () {
    this.getQuestionnaires()
  },
  mounted () {
    this.dmlLogoSrc = document.getElementById('occDML360Logo').src
  }
}
</script>
