
export default {
  methods: {
    $setPreviousScore (res) {
      let count = 0
      let countFiltered = 0
      let population = 0
      let populationFiltered = 0
      let scores = []
      let scoresFiltered = []
      let autoScoresFiltered = []
      let autoScoresPopulation = 0
      let noAutoScores = []
      let noAutoScoresFiltered = []
      let noAutoPopulation = 0
      let noAutoPopulationFiltered = 0
      let leadersScores = []
      let leadersScoresFiltered = []
      let leadersPopulation = 0
      let leadersPopulationFiltered = 0
      let pairsScores = []
      let pairsScoresFiltered = []
      let pairsPopulation = 0
      let pairsPopulationFiltered = 0
      let dependentsScores = []
      let dependentsScoresFiltered = []
      let dependentsPopulation = 0
      let dependentsPopulationFiltered = 0
      let scoresResponsability = []
      let scoresResponsabilityFiltered = []
      let autoScoresResponsabilityFiltered = []
      let noAutoScoresResponsability = []
      let noAutoScoresResponsabilityFiltered = []
      let leadersResponsability = []
      let leadersResponsabilityFiltered = []
      let pairsResponsability = []
      let pairsResponsabilityFiltered = []
      let dependentsResponsability = []
      let dependentsResponsabilityFiltered = []
      let headers = ['as_person', 'management', 'mobilization', 'achievement']
      const generalEvaluation = res.questionnaire.evaluations.generalEvaluation
      // Inicializando objetos
      for (let i = 0; i < headers.length; i++) {
        scores[i] = { name: headers[i], questions: [], total: 0 }
        scoresResponsability[i] = { name: headers[i], responsability: [] }
        noAutoScores[i] = { name: headers[i], questions: [], total: 0 }
        noAutoScoresResponsability[i] = { name: headers[i], responsability: [] }
        leadersScores[i] = { name: headers[i], questions: [], total: 0 }
        leadersResponsability[i] = { name: headers[i], responsability: [] }
        pairsScores[i] = { name: headers[i], questions: [], total: 0 }
        pairsResponsability[i] = { name: headers[i], responsability: [] }
        dependentsScores[i] = { name: headers[i], questions: [], total: 0 }
        dependentsResponsability[i] = { name: headers[i], responsability: [] }
        switch (i) {
          case 0:
            for (const j of generalEvaluation[i].responsability) {
              scoresResponsability[i].responsability[0] = { name: j[this.user.lang], score: 0, index: 1, questions: [], nullCount: 0 }
              noAutoScoresResponsability[i].responsability[0] = { name: j[this.user.lang], score: 0, index: 1, questions: [], nullCount: 0 }
              leadersResponsability[i].responsability[0] = { name: j[this.user.lang], score: 0, index: 1, questions: [], nullCount: 0 }
              pairsResponsability[i].responsability[0] = { name: j[this.user.lang], score: 0, index: 1, questions: [], nullCount: 0 }
              dependentsResponsability[i].responsability[0] = { name: j[this.user.lang], score: 0, index: 1, questions: [], nullCount: 0 }
              for (let k = 0; k < 10; k++) {
                scoresResponsability[i].responsability[0].questions.push(k + 1)
                noAutoScoresResponsability[i].responsability[0].questions.push(k + 1)
                leadersResponsability[i].responsability[0].questions.push(k + 1)
                pairsResponsability[i].responsability[0].questions.push(k + 1)
                dependentsResponsability[i].responsability[0].questions.push(k + 1)
                scores[i].questions[k] = { score: 0, index: k + 1, question: j.questions[k][this.user.lang], nullCount: 0, responsability: j[this.user.lang] }
                noAutoScores[i].questions[k] = { score: 0, index: k + 1, question: j.questions[k][this.user.lang], nullCount: 0, responsability: j[this.user.lang] }
                leadersScores[i].questions[k] = { score: 0, index: k + 1, question: j.questions[k][this.user.lang], nullCount: 0, responsability: j[this.user.lang] }
                pairsScores[i].questions[k] = { score: 0, index: k + 1, question: j.questions[k][this.user.lang], nullCount: 0, responsability: j[this.user.lang] }
                dependentsScores[i].questions[k] = { score: 0, index: k + 1, question: j.questions[k][this.user.lang], nullCount: 0, responsability: j[this.user.lang] }
              }
            }
            break
          default:
            let counter = 0
            for (const j of generalEvaluation[i].responsability) {
              scoresResponsability[i].responsability[j.index - 1] = { name: j[this.user.lang], score: 0, index: j.index, questions: [], nullCount: 0 }
              noAutoScoresResponsability[i].responsability[j.index - 1] = { name: j[this.user.lang], score: 0, index: j.index, questions: [], nullCount: 0 }
              leadersResponsability[i].responsability[j.index - 1] = { name: j[this.user.lang], score: 0, index: j.index, questions: [], nullCount: 0 }
              pairsResponsability[i].responsability[j.index - 1] = { name: j[this.user.lang], score: 0, index: j.index, questions: [], nullCount: 0 }
              dependentsResponsability[i].responsability[j.index - 1] = { name: j[this.user.lang], score: 0, index: j.index, questions: [], nullCount: 0 }
              for (let k = 0; k < j.questions.length; k++) {
                scoresResponsability[i].responsability[j.index - 1].questions.push(counter + 1)
                noAutoScoresResponsability[i].responsability[j.index - 1].questions.push(counter + 1)
                leadersResponsability[i].responsability[j.index - 1].questions.push(counter + 1)
                pairsResponsability[i].responsability[j.index - 1].questions.push(counter + 1)
                dependentsResponsability[i].responsability[j.index - 1].questions.push(counter + 1)
                scores[i].questions[counter] = { score: 0, index: counter + 1, question: j.questions[k][this.user.lang], nullCount: 0, responsability: j[this.user.lang] }
                noAutoScores[i].questions[counter] = { score: 0, index: counter + 1, question: j.questions[k][this.user.lang], nullCount: 0, responsability: j[this.user.lang] }
                leadersScores[i].questions[counter] = { score: 0, index: counter + 1, question: j.questions[k][this.user.lang], nullCount: 0, responsability: j[this.user.lang] }
                pairsScores[i].questions[counter] = { score: 0, index: counter + 1, question: j.questions[k][this.user.lang], nullCount: 0, responsability: j[this.user.lang] }
                dependentsScores[i].questions[counter] = { score: 0, index: counter + 1, question: j.questions[k][this.user.lang], nullCount: 0, responsability: j[this.user.lang] }
                counter++
              }
            }
        }
      }
      /** ********************************************************************************************************************************************* */
      // if (res.evaluated[index].employee.employee.id === this.evaluatedId) {
      // Recorrer el evaluado para sacar los promedios
      for (let i = 0; i < headers.length; i++) {
        scoresFiltered[i] = { name: headers[i], questions: [], total: 0 }
        autoScoresFiltered[i] = { name: headers[i], questions: [], total: 0 }
        autoScoresResponsabilityFiltered[i] = { name: headers[i], responsability: [] }
        scoresResponsabilityFiltered[i] = { name: headers[i], responsability: [] }
        noAutoScoresFiltered[i] = { name: headers[i], questions: [], total: 0 }
        noAutoScoresResponsabilityFiltered[i] = { name: headers[i], responsability: [] }
        leadersScoresFiltered[i] = { name: headers[i], questions: [], total: 0 }
        leadersResponsabilityFiltered[i] = { name: headers[i], responsability: [] }
        pairsScoresFiltered[i] = { name: headers[i], questions: [], total: 0 }
        pairsResponsabilityFiltered[i] = { name: headers[i], responsability: [] }
        dependentsScoresFiltered[i] = { name: headers[i], questions: [], total: 0 }
        dependentsResponsabilityFiltered[i] = { name: headers[i], responsability: [] }
        switch (i) {
          case 0:
            for (const j of generalEvaluation[i].responsability) {
              scoresResponsabilityFiltered[i].responsability[0] = { name: j[this.user.lang], score: 0, index: 1, questions: [], nullCount: 0 }
              autoScoresResponsabilityFiltered[i].responsability[0] = { name: j[this.user.lang], score: 0, index: 1, questions: [], nullCount: 0 }
              noAutoScoresResponsabilityFiltered[i].responsability[0] = { name: j[this.user.lang], score: 0, index: 1, questions: [], nullCount: 0 }
              leadersResponsabilityFiltered[i].responsability[0] = { name: j[this.user.lang], score: 0, index: 1, questions: [], nullCount: 0 }
              pairsResponsabilityFiltered[i].responsability[0] = { name: j[this.user.lang], score: 0, index: 1, questions: [], nullCount: 0 }
              dependentsResponsabilityFiltered[i].responsability[0] = { name: j[this.user.lang], score: 0, index: 1, questions: [], nullCount: 0 }
              for (let k = 0; k < 10; k++) {
                scoresResponsabilityFiltered[i].responsability[0].questions.push(k + 1)
                autoScoresResponsabilityFiltered[i].responsability[0].questions.push(k + 1)
                noAutoScoresResponsabilityFiltered[i].responsability[0].questions.push(k + 1)
                leadersResponsabilityFiltered[i].responsability[0].questions.push(k + 1)
                pairsResponsabilityFiltered[i].responsability[0].questions.push(k + 1)
                dependentsResponsabilityFiltered[i].responsability[0].questions.push(k + 1)
                scoresFiltered[i].questions[k] = { score: 0, index: k + 1, question: j.questions[k][this.user.lang], nullCount: 0, responsability: j[this.user.lang] }
                autoScoresFiltered[i].questions[k] = { score: 0, index: k + 1, question: j.questions[k][this.user.lang], nullCount: 0, responsability: j[this.user.lang] }
                noAutoScoresFiltered[i].questions[k] = { score: 0, index: k + 1, question: j.questions[k][this.user.lang], nullCount: 0, responsability: j[this.user.lang] }
                leadersScoresFiltered[i].questions[k] = { score: 0, index: k + 1, question: j.questions[k][this.user.lang], nullCount: 0, responsability: j[this.user.lang] }
                pairsScoresFiltered[i].questions[k] = { score: 0, index: k + 1, question: j.questions[k][this.user.lang], nullCount: 0, responsability: j[this.user.lang] }
                dependentsScoresFiltered[i].questions[k] = { score: 0, index: k + 1, question: j.questions[k][this.user.lang], nullCount: 0, responsability: j[this.user.lang] }
              }
            }
            break
          default:
            let counter = 0
            for (const j of generalEvaluation[i].responsability) {
              scoresResponsabilityFiltered[i].responsability[j.index - 1] = { name: j[this.user.lang], score: 0, index: j.index, questions: [], nullCount: 0 }
              autoScoresResponsabilityFiltered[i].responsability[j.index - 1] = { name: j[this.user.lang], score: 0, index: j.index, questions: [], nullCount: 0 }
              noAutoScoresResponsabilityFiltered[i].responsability[j.index - 1] = { name: j[this.user.lang], score: 0, index: j.index, questions: [], nullCount: 0 }
              leadersResponsabilityFiltered[i].responsability[j.index - 1] = { name: j[this.user.lang], score: 0, index: j.index, questions: [], nullCount: 0 }
              pairsResponsabilityFiltered[i].responsability[j.index - 1] = { name: j[this.user.lang], score: 0, index: j.index, questions: [], nullCount: 0 }
              dependentsResponsabilityFiltered[i].responsability[j.index - 1] = { name: j[this.user.lang], score: 0, index: j.index, questions: [], nullCount: 0 }
              for (let k = 0; k < j.questions.length; k++) {
                scoresResponsabilityFiltered[i].responsability[j.index - 1].questions.push(counter + 1)
                autoScoresResponsabilityFiltered[i].responsability[j.index - 1].questions.push(counter + 1)
                noAutoScoresResponsabilityFiltered[i].responsability[j.index - 1].questions.push(counter + 1)
                leadersResponsabilityFiltered[i].responsability[j.index - 1].questions.push(counter + 1)
                pairsResponsabilityFiltered[i].responsability[j.index - 1].questions.push(counter + 1)
                dependentsResponsabilityFiltered[i].responsability[j.index - 1].questions.push(counter + 1)
                scoresFiltered[i].questions[counter] = { score: 0, index: counter + 1, question: j.questions[k][this.user.lang], nullCount: 0, responsability: j[this.user.lang] }
                autoScoresFiltered[i].questions[counter] = { score: 0, index: counter + 1, question: j.questions[k][this.user.lang], nullCount: 0, responsability: j[this.user.lang] }
                noAutoScoresFiltered[i].questions[counter] = { score: 0, index: counter + 1, question: j.questions[k][this.user.lang], nullCount: 0, responsability: j[this.user.lang] }
                leadersScoresFiltered[i].questions[counter] = { score: 0, index: counter + 1, question: j.questions[k][this.user.lang], nullCount: 0, responsability: j[this.user.lang] }
                pairsScoresFiltered[i].questions[counter] = { score: 0, index: counter + 1, question: j.questions[k][this.user.lang], nullCount: 0, responsability: j[this.user.lang] }
                dependentsScoresFiltered[i].questions[counter] = { score: 0, index: counter + 1, question: j.questions[k][this.user.lang], nullCount: 0, responsability: j[this.user.lang] }
                counter++
              }
            }
        }
      }
      /** ********************************************************************************************************************************************* */

      let answersDimention = []
      let arrayEvaluators = []
      // Recorrer cada evaluado para sacar los promedios
      for (let index = 0; index < res.evaluated.length; index++) {
        for (let key in res.evaluated[index]) {
          if (res.evaluated[index][key].length === undefined) { // Para autoevaluación
            if (res.evaluated[index][key].completed) {
              answersDimention = res.evaluated[index][key].answersDimention
              count = 0
              for (const j of answersDimention) {
                let counter = 0
                let respCount = 0
                let dimensionTotal = 0
                for (const k of j.responsability) {
                  let respTotal = 0
                  let nullCount = 0
                  for (let l = 0; l < k.questions.length; l++) {
                    if (k.questions[l].score !== -1) {
                      scores[count].questions[counter].score += k.questions[l].score
                      respTotal += k.questions[l].score
                    } else {
                      scores[count].questions[counter].nullCount += 1
                      nullCount++
                    }
                    counter++
                  }
                  dimensionTotal += respTotal / (k.questions.length - nullCount)
                  respCount++
                }
                count++
              }
              population++
            }
          } else { // Para el resto (lideres, pares, colaboradores)
            arrayEvaluators = res.evaluated[index][key]
            for (const i of arrayEvaluators) {
              if (key === 'leadersEvaluators') {
                if (i.completed) {
                  answersDimention = i.answersDimention
                  count = 0
                  for (const j of answersDimention) {
                    let counter = 0
                    let respCount = 0
                    for (const k of j.responsability) {
                      let respTotal = 0
                      let nullCount = 0
                      for (let l = 0; l < k.questions.length; l++) {
                        if (k.questions[l].score !== -1) {
                          leadersScores[count].questions[counter].score += k.questions[l].score
                          respTotal += k.questions[l].score
                        } else {
                          leadersScores[count].questions[counter].nullCount += 1
                          nullCount++
                        }
                        counter++
                      }
                      respCount++
                    }
                    count++
                  }
                  leadersPopulation++
                }
              } else if (key === 'pairsEvaluators') {
                if (i.completed) {
                  answersDimention = i.answersDimention
                  count = 0
                  for (const j of answersDimention) {
                    let counter = 0
                    let respCount = 0
                    for (const k of j.responsability) {
                      let respTotal = 0
                      let nullCount = 0
                      for (let l = 0; l < k.questions.length; l++) {
                        if (k.questions[l].score !== -1) {
                          pairsScores[count].questions[counter].score += k.questions[l].score
                          respTotal += k.questions[l].score
                        } else {
                          pairsScores[count].questions[counter].nullCount += 1
                          nullCount++
                        }
                        counter++
                      }
                      respCount++
                    }
                    count++
                  }
                  pairsPopulation++
                }
              } else {
                if (i.completed) {
                  answersDimention = i.answersDimention
                  count = 0
                  for (const j of answersDimention) {
                    let counter = 0
                    let respCount = 0
                    for (const k of j.responsability) {
                      let respTotal = 0
                      let nullCount = 0
                      for (let l = 0; l < k.questions.length; l++) {
                        if (k.questions[l].score !== -1) {
                          dependentsScores[count].questions[counter].score += k.questions[l].score
                          respTotal += k.questions[l].score
                        } else {
                          dependentsScores[count].questions[counter].nullCount += 1
                          nullCount++
                        }
                        counter++
                      }
                      respCount++
                    }
                    count++
                  }
                  dependentsPopulation++
                }
              }
              // Total promedio de cada pregunta incluyendo autoevaluación.
              if (i.completed) {
                answersDimention = i.answersDimention
                count = 0
                for (const j of answersDimention) {
                  let counter = 0
                  let respCount = 0
                  let dimensionTotal = 0
                  for (const k of j.responsability) {
                    let respTotal = 0
                    let nullCount = 0
                    for (let l = 0; l < k.questions.length; l++) {
                      if (k.questions[l].score !== -1) {
                        scores[count].questions[counter].score += k.questions[l].score
                        respTotal += k.questions[l].score
                      } else {
                        scores[count].questions[counter].nullCount += 1
                        nullCount++
                      }
                      counter++
                    }
                    dimensionTotal += respTotal / (k.questions.length - nullCount)
                    respCount++
                  }
                  count++
                }
                population++
                // Total promedio de cada pregunta sin autoevaluación.
                count = 0
                for (const j of answersDimention) {
                  let counter = 0
                  let respCount = 0
                  for (const k of j.responsability) {
                    let respTotal = 0
                    let nullCount = 0
                    for (let l = 0; l < k.questions.length; l++) {
                      if (k.questions[l].score !== -1) {
                        noAutoScores[count].questions[counter].score += k.questions[l].score
                        respTotal += k.questions[l].score
                      } else {
                        noAutoScores[count].questions[counter].nullCount += 1
                        nullCount++
                      }
                      counter++
                    }
                    respCount++
                  }
                  count++
                }
                noAutoPopulation++
              }
            }
          }
        }
        /** ********************************************************************************************************************************************* */
        // if (res.evaluated[index].employee.employee.id === this.evaluatedId) {
        // Recorrer el evaluado para sacar los promedios
        if (res.evaluated[index].employee.employee.id === this.evaluatedId) {
          for (let key in res.evaluated[index]) {
            if (res.evaluated[index][key].length === undefined) { // Para autoevaluación
              if (res.evaluated[index][key].completed) {
                answersDimention = res.evaluated[index][key].answersDimention
                count = 0
                for (const j of answersDimention) {
                  let counter = 0
                  let respCount = 0
                  let dimensionTotal = 0
                  for (const k of j.responsability) {
                    let respTotal = 0
                    let nullCount = 0
                    for (let l = 0; l < k.questions.length; l++) {
                      if (k.questions[l].score !== -1) {
                        scoresFiltered[count].questions[counter].score += k.questions[l].score
                        autoScoresFiltered[count].questions[counter].score += k.questions[l].score
                        respTotal += k.questions[l].score
                      } else {
                        scoresFiltered[count].questions[counter].nullCount += 1
                        autoScoresFiltered[count].questions[counter].nullCount += 1
                        nullCount++
                      }
                      counter++
                    }
                    dimensionTotal += respTotal / (k.questions.length - nullCount)
                    respCount++
                  }
                  count++
                }
                populationFiltered++
                autoScoresPopulation++
              }
            } else { // Para el resto (lideres, pares, colaboradores)
              arrayEvaluators = res.evaluated[index][key]
              for (const i of arrayEvaluators) {
                if (key === 'leadersEvaluators') {
                  if (i.completed) {
                    answersDimention = i.answersDimention
                    count = 0
                    for (const j of answersDimention) {
                      let counter = 0
                      let respCount = 0
                      for (const k of j.responsability) {
                        let respTotal = 0
                        let nullCount = 0
                        for (let l = 0; l < k.questions.length; l++) {
                          if (k.questions[l].score !== -1) {
                            leadersScoresFiltered[count].questions[counter].score += k.questions[l].score
                            respTotal += k.questions[l].score
                          } else {
                            leadersScoresFiltered[count].questions[counter].nullCount += 1
                            nullCount++
                          }
                          counter++
                        }
                        respCount++
                      }
                      count++
                    }
                    leadersPopulationFiltered++
                  }
                } else if (key === 'pairsEvaluators') {
                  if (i.completed) {
                    answersDimention = i.answersDimention
                    count = 0
                    for (const j of answersDimention) {
                      let counter = 0
                      let respCount = 0
                      for (const k of j.responsability) {
                        let respTotal = 0
                        let nullCount = 0
                        for (let l = 0; l < k.questions.length; l++) {
                          if (k.questions[l].score !== -1) {
                            pairsScoresFiltered[count].questions[counter].score += k.questions[l].score
                            respTotal += k.questions[l].score
                          } else {
                            pairsScoresFiltered[count].questions[counter].nullCount += 1
                            nullCount++
                          }
                          counter++
                        }
                        respCount++
                      }
                      count++
                    }
                    pairsPopulationFiltered++
                  }
                } else {
                  if (i.completed) {
                    answersDimention = i.answersDimention
                    count = 0
                    for (const j of answersDimention) {
                      let counter = 0
                      let respCount = 0
                      for (const k of j.responsability) {
                        let respTotal = 0
                        let nullCount = 0
                        for (let l = 0; l < k.questions.length; l++) {
                          if (k.questions[l].score !== -1) {
                            dependentsScoresFiltered[count].questions[counter].score += k.questions[l].score
                            respTotal += k.questions[l].score
                          } else {
                            dependentsScoresFiltered[count].questions[counter].nullCount += 1
                            nullCount++
                          }
                          counter++
                        }
                        respCount++
                      }
                      count++
                    }
                    dependentsPopulationFiltered++
                  }
                }
                // Total promedio de cada pregunta incluyendo autoevaluación.
                if (i.completed) {
                  answersDimention = i.answersDimention
                  count = 0
                  for (const j of answersDimention) {
                    let counter = 0
                    let respCount = 0
                    let dimensionTotal = 0
                    for (const k of j.responsability) {
                      let respTotal = 0
                      let nullCount = 0
                      for (let l = 0; l < k.questions.length; l++) {
                        if (k.questions[l].score !== -1) {
                          scoresFiltered[count].questions[counter].score += k.questions[l].score
                          respTotal += k.questions[l].score
                        } else {
                          scoresFiltered[count].questions[counter].nullCount += 1
                          nullCount++
                        }
                        counter++
                      }
                      dimensionTotal += respTotal / (k.questions.length - nullCount)
                      respCount++
                    }
                    count++
                  }
                  populationFiltered++
                  // Total promedio de cada pregunta sin autoevaluación.
                  count = 0
                  for (const j of answersDimention) {
                    let counter = 0
                    let respCount = 0
                    for (const k of j.responsability) {
                      let respTotal = 0
                      let nullCount = 0
                      for (let l = 0; l < k.questions.length; l++) {
                        if (k.questions[l].score !== -1) {
                          noAutoScoresFiltered[count].questions[counter].score += k.questions[l].score
                          respTotal += k.questions[l].score
                        } else {
                          noAutoScoresFiltered[count].questions[counter].nullCount += 1
                          nullCount++
                        }
                        counter++
                      }
                      respCount++
                    }
                    count++
                  }
                  noAutoPopulationFiltered++
                }
              }
            }
          }
        }
        /** ********************************************************************************************************************************************* */
      }
      this.previousLeadersPopulation = leadersPopulation
      this.previousLeadersPopulationFiltered = leadersPopulationFiltered
      this.previousPairsPopulation = pairsPopulation
      this.previousPairsPopulationFiltered = pairsPopulationFiltered
      this.previousDependentsPopulation = dependentsPopulation
      this.previousDependentsPopulationFiltered = dependentsPopulationFiltered
      for (let i = 0; i < scores.length; i++) {
        let acumDimension = 0
        let noAutoAcumDimension = 0
        let leadersAcumDimension = 0
        let pairsAcumDimension = 0
        let dependentsAcumDimension = 0
        let scoresCount = 0
        let noAutoCount = 0
        let leadersCount = 0
        let pairsCount = 0
        let dependentsCount = 0
        for (let j = 0; j < scores[i].questions.length; j++) {
          // Promedio de todos incluyendo autoevaluación
          if ((population - scores[i].questions[j].nullCount) === 0) {
            scores[i].questions[j].score = 0
          } else {
            scores[i].questions[j].score = scores[i].questions[j].score / (population - scores[i].questions[j].nullCount)
            scoresCount++
          }
          acumDimension += scores[i].questions[j].score
          // Promedio de todos sin autoevaluación
          if ((noAutoPopulation - noAutoScores[i].questions[j].nullCount) === 0) {
            noAutoScores[i].questions[j].score = 0
          } else {
            noAutoScores[i].questions[j].score = noAutoScores[i].questions[j].score / (noAutoPopulation - noAutoScores[i].questions[j].nullCount)
            noAutoCount++
          }
          noAutoAcumDimension += noAutoScores[i].questions[j].score
          // Lideres
          if ((leadersPopulation - leadersScores[i].questions[j].nullCount) === 0) {
            leadersScores[i].questions[j].score = 0
          } else {
            leadersScores[i].questions[j].score = leadersScores[i].questions[j].score / (leadersPopulation - leadersScores[i].questions[j].nullCount)
            leadersCount++
          }
          leadersAcumDimension += leadersScores[i].questions[j].score
          // Pares
          if ((pairsPopulation - pairsScores[i].questions[j].nullCount) === 0) {
            pairsScores[i].questions[j].score = 0
          } else {
            pairsScores[i].questions[j].score = pairsScores[i].questions[j].score / (pairsPopulation - pairsScores[i].questions[j].nullCount)
            pairsCount++
          }
          pairsAcumDimension += pairsScores[i].questions[j].score
          // Colaboradores
          if ((dependentsPopulation - dependentsScores[i].questions[j].nullCount) === 0) {
            dependentsScores[i].questions[j].score = 0
          } else {
            dependentsScores[i].questions[j].score = dependentsScores[i].questions[j].score / (dependentsPopulation - dependentsScores[i].questions[j].nullCount)
            dependentsCount++
          }
          dependentsAcumDimension += dependentsScores[i].questions[j].score
        }
        for (let resp = 0; resp < scoresResponsability[i].responsability.length; resp++) {
          for (let sc = 0; sc < scoresResponsability[i].responsability[resp].questions.length; sc++) {
            if (scores[i].questions[scoresResponsability[i].responsability[resp].questions[sc] - 1].score === 0) {
              scoresResponsability[i].responsability[resp].nullCount++
            }
            scoresResponsability[i].responsability[resp].score += scores[i].questions[scoresResponsability[i].responsability[resp].questions[sc] - 1].score
            if (noAutoScores[i].questions[noAutoScoresResponsability[i].responsability[resp].questions[sc] - 1].score === 0) {
              noAutoScoresResponsability[i].responsability[resp].nullCount++
            }
            noAutoScoresResponsability[i].responsability[resp].score += noAutoScores[i].questions[noAutoScoresResponsability[i].responsability[resp].questions[sc] - 1].score
            if (leadersScores[i].questions[leadersResponsability[i].responsability[resp].questions[sc] - 1].score === 0) {
              leadersResponsability[i].responsability[resp].nullCount++
            }
            leadersResponsability[i].responsability[resp].score += leadersScores[i].questions[leadersResponsability[i].responsability[resp].questions[sc] - 1].score
            if (pairsScores[i].questions[pairsResponsability[i].responsability[resp].questions[sc] - 1].score === 0) {
              pairsResponsability[i].responsability[resp].nullCount++
            }
            pairsResponsability[i].responsability[resp].score += pairsScores[i].questions[pairsResponsability[i].responsability[resp].questions[sc] - 1].score
            if (dependentsScores[i].questions[dependentsResponsability[i].responsability[resp].questions[sc] - 1].score === 0) {
              dependentsResponsability[i].responsability[resp].nullCount++
            }
            dependentsResponsability[i].responsability[resp].score += dependentsScores[i].questions[dependentsResponsability[i].responsability[resp].questions[sc] - 1].score
          }
          scoresResponsability[i].responsability[resp].score = scoresResponsability[i].responsability[resp].score / (scoresResponsability[i].responsability[resp].questions.length - scoresResponsability[i].responsability[resp].nullCount)
          noAutoScoresResponsability[i].responsability[resp].score = noAutoScoresResponsability[i].responsability[resp].score / (noAutoScoresResponsability[i].responsability[resp].questions.length - noAutoScoresResponsability[i].responsability[resp].nullCount)
          leadersResponsability[i].responsability[resp].score = leadersResponsability[i].responsability[resp].score / (leadersResponsability[i].responsability[resp].questions.length - leadersResponsability[i].responsability[resp].nullCount)
          pairsResponsability[i].responsability[resp].score = pairsResponsability[i].responsability[resp].score / (pairsResponsability[i].responsability[resp].questions.length - pairsResponsability[i].responsability[resp].nullCount)
          dependentsResponsability[i].responsability[resp].score = dependentsResponsability[i].responsability[resp].score / (dependentsResponsability[i].responsability[resp].questions.length - dependentsResponsability[i].responsability[resp].nullCount)
        }
        scores[i].total = acumDimension / scoresCount
        noAutoScores[i].total = noAutoAcumDimension / noAutoCount
        leadersScores[i].total = leadersAcumDimension / leadersCount
        pairsScores[i].total = pairsAcumDimension / pairsCount
        dependentsScores[i].total = dependentsAcumDimension / dependentsCount
      }

      /** ********************************************************************************************************************************************* */
      // if (res.evaluated[index].employee.employee.id === this.evaluatedId) {
      // Recorrer el evaluado para sacar los promedios
      for (let i = 0; i < scoresFiltered.length; i++) {
        let acumDimension = 0
        let autoAcumDimension = 0
        let noAutoAcumDimension = 0
        let leadersAcumDimension = 0
        let pairsAcumDimension = 0
        let dependentsAcumDimension = 0
        let scoresCount = 0
        let autoScoresCount = 0
        let noAutoCount = 0
        let leadersCount = 0
        let pairsCount = 0
        let dependentsCount = 0
        for (let j = 0; j < scoresFiltered[i].questions.length; j++) {
          // Promedio de todos incluyendo autoevaluación
          if ((populationFiltered - scoresFiltered[i].questions[j].nullCount) === 0) {
            scoresFiltered[i].questions[j].score = 0
          } else {
            scoresFiltered[i].questions[j].score = scoresFiltered[i].questions[j].score / (populationFiltered - scoresFiltered[i].questions[j].nullCount)
            scoresCount++
          }
          acumDimension += scoresFiltered[i].questions[j].score
          // Autoevaluación
          if ((autoScoresPopulation - autoScoresFiltered[i].questions[j].nullCount) === 0) {
            autoScoresFiltered[i].questions[j].score = 0
          } else {
            autoScoresFiltered[i].questions[j].score = autoScoresFiltered[i].questions[j].score / (autoScoresPopulation - autoScoresFiltered[i].questions[j].nullCount)
            autoScoresCount++
          }
          autoAcumDimension += autoScoresFiltered[i].questions[j].score
          // Promedio de todos sin autoevaluación
          if ((noAutoPopulationFiltered - noAutoScoresFiltered[i].questions[j].nullCount) === 0) {
            noAutoScoresFiltered[i].questions[j].score = 0
          } else {
            noAutoScoresFiltered[i].questions[j].score = noAutoScoresFiltered[i].questions[j].score / (noAutoPopulationFiltered - noAutoScoresFiltered[i].questions[j].nullCount)
            noAutoCount++
          }
          noAutoAcumDimension += noAutoScoresFiltered[i].questions[j].score
          // Lideres
          if ((leadersPopulationFiltered - leadersScoresFiltered[i].questions[j].nullCount) === 0) {
            leadersScoresFiltered[i].questions[j].score = 0
          } else {
            leadersScoresFiltered[i].questions[j].score = leadersScoresFiltered[i].questions[j].score / (leadersPopulationFiltered - leadersScoresFiltered[i].questions[j].nullCount)
            leadersCount++
          }
          leadersAcumDimension += leadersScoresFiltered[i].questions[j].score
          // Pares
          if ((pairsPopulationFiltered - pairsScoresFiltered[i].questions[j].nullCount) === 0) {
            pairsScoresFiltered[i].questions[j].score = 0
          } else {
            pairsScoresFiltered[i].questions[j].score = pairsScoresFiltered[i].questions[j].score / (pairsPopulationFiltered - pairsScoresFiltered[i].questions[j].nullCount)
            pairsCount++
          }
          pairsAcumDimension += pairsScoresFiltered[i].questions[j].score
          // Colaboradores
          if ((dependentsPopulationFiltered - dependentsScoresFiltered[i].questions[j].nullCount) === 0) {
            dependentsScoresFiltered[i].questions[j].score = 0
          } else {
            dependentsScoresFiltered[i].questions[j].score = dependentsScoresFiltered[i].questions[j].score / (dependentsPopulationFiltered - dependentsScoresFiltered[i].questions[j].nullCount)
            dependentsCount++
          }
          dependentsAcumDimension += dependentsScoresFiltered[i].questions[j].score
        }
        for (let resp = 0; resp < scoresResponsabilityFiltered[i].responsability.length; resp++) {
          for (let sc = 0; sc < scoresResponsabilityFiltered[i].responsability[resp].questions.length; sc++) {
            if (scoresFiltered[i].questions[scoresResponsabilityFiltered[i].responsability[resp].questions[sc] - 1].score === 0) {
              scoresResponsabilityFiltered[i].responsability[resp].nullCount++
            }
            scoresResponsabilityFiltered[i].responsability[resp].score += scoresFiltered[i].questions[scoresResponsabilityFiltered[i].responsability[resp].questions[sc] - 1].score
            if (autoScoresFiltered[i].questions[autoScoresResponsabilityFiltered[i].responsability[resp].questions[sc] - 1].score === 0) {
              autoScoresResponsabilityFiltered[i].responsability[resp].nullCount++
            }
            autoScoresResponsabilityFiltered[i].responsability[resp].score += autoScoresFiltered[i].questions[autoScoresResponsabilityFiltered[i].responsability[resp].questions[sc] - 1].score
            if (noAutoScoresFiltered[i].questions[noAutoScoresResponsabilityFiltered[i].responsability[resp].questions[sc] - 1].score === 0) {
              noAutoScoresResponsabilityFiltered[i].responsability[resp].nullCount++
            }
            noAutoScoresResponsabilityFiltered[i].responsability[resp].score += noAutoScoresFiltered[i].questions[noAutoScoresResponsabilityFiltered[i].responsability[resp].questions[sc] - 1].score
            if (leadersScoresFiltered[i].questions[leadersResponsabilityFiltered[i].responsability[resp].questions[sc] - 1].score === 0) {
              leadersResponsabilityFiltered[i].responsability[resp].nullCount++
            }
            leadersResponsabilityFiltered[i].responsability[resp].score += leadersScoresFiltered[i].questions[leadersResponsabilityFiltered[i].responsability[resp].questions[sc] - 1].score
            if (pairsScoresFiltered[i].questions[pairsResponsabilityFiltered[i].responsability[resp].questions[sc] - 1].score === 0) {
              pairsResponsabilityFiltered[i].responsability[resp].nullCount++
            }
            pairsResponsabilityFiltered[i].responsability[resp].score += pairsScoresFiltered[i].questions[pairsResponsabilityFiltered[i].responsability[resp].questions[sc] - 1].score
            if (dependentsScoresFiltered[i].questions[dependentsResponsabilityFiltered[i].responsability[resp].questions[sc] - 1].score === 0) {
              dependentsResponsabilityFiltered[i].responsability[resp].nullCount++
            }
            dependentsResponsabilityFiltered[i].responsability[resp].score += dependentsScoresFiltered[i].questions[dependentsResponsabilityFiltered[i].responsability[resp].questions[sc] - 1].score
          }
          scoresResponsabilityFiltered[i].responsability[resp].score = scoresResponsabilityFiltered[i].responsability[resp].score / (scoresResponsabilityFiltered[i].responsability[resp].questions.length - scoresResponsabilityFiltered[i].responsability[resp].nullCount)
          autoScoresResponsabilityFiltered[i].responsability[resp].score = autoScoresResponsabilityFiltered[i].responsability[resp].score / (autoScoresResponsabilityFiltered[i].responsability[resp].questions.length - autoScoresResponsabilityFiltered[i].responsability[resp].nullCount)
          noAutoScoresResponsabilityFiltered[i].responsability[resp].score = noAutoScoresResponsabilityFiltered[i].responsability[resp].score / (noAutoScoresResponsabilityFiltered[i].responsability[resp].questions.length - noAutoScoresResponsabilityFiltered[i].responsability[resp].nullCount)
          leadersResponsabilityFiltered[i].responsability[resp].score = leadersResponsabilityFiltered[i].responsability[resp].score / (leadersResponsabilityFiltered[i].responsability[resp].questions.length - leadersResponsabilityFiltered[i].responsability[resp].nullCount)
          pairsResponsabilityFiltered[i].responsability[resp].score = pairsResponsabilityFiltered[i].responsability[resp].score / (pairsResponsabilityFiltered[i].responsability[resp].questions.length - pairsResponsabilityFiltered[i].responsability[resp].nullCount)
          dependentsResponsabilityFiltered[i].responsability[resp].score = dependentsResponsabilityFiltered[i].responsability[resp].score / (dependentsResponsabilityFiltered[i].responsability[resp].questions.length - dependentsResponsabilityFiltered[i].responsability[resp].nullCount)
        }
        scoresFiltered[i].total = acumDimension / scoresCount
        autoScoresFiltered[i].total = autoAcumDimension / autoScoresCount
        noAutoScoresFiltered[i].total = noAutoAcumDimension / noAutoCount
        leadersScoresFiltered[i].total = leadersAcumDimension / leadersCount
        pairsScoresFiltered[i].total = pairsAcumDimension / pairsCount
        dependentsScoresFiltered[i].total = dependentsAcumDimension / dependentsCount
      }
      /** ********************************************************************************************************************************************* */
      this.previousAverageScores = scores
      this.previousAverageScoresFiltered = scoresFiltered
      this.previousAutoScoresFiltered = autoScoresFiltered
      this.previousNoAutoScores = noAutoScores
      this.previousNoAutoScoresFiltered = noAutoScoresFiltered
      this.previousLeadersScores = leadersScores
      this.previousLeadersScoresFiltered = leadersScoresFiltered
      this.previousPairsScores = pairsScores
      this.previousPairsScoresFiltered = pairsScoresFiltered
      this.previousDependentsScores = dependentsScores
      this.previousDependentsScoresFiltered = dependentsScoresFiltered
      this.previousAverageResponsability = scoresResponsability
      this.previousAverageResponsabilityFiltered = scoresResponsabilityFiltered
      this.previousAutoResponsabilityFiltered = autoScoresResponsabilityFiltered
      this.previousNoAutoScoresResponsability = noAutoScoresResponsability
      this.previousNoAutoScoresResponsabilityFiltered = noAutoScoresResponsabilityFiltered
      this.previousLeadersResponsability = leadersResponsability
      this.previousLeadersResponsabilityFiltered = leadersResponsabilityFiltered
      this.previousPairsResponsability = pairsResponsability
      this.previousPairsResponsabilityFiltered = pairsResponsabilityFiltered
      this.previousDependentsResponsability = dependentsResponsability
      this.previousDependentsResponsabilityFiltered = dependentsResponsabilityFiltered
    }
  }
}
