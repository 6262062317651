
<template>
  <div style="display:inline;">
    <v-btn
      @click="openPdf"
      color="success"
      large
      :disabled="thread.status !== 'completed'"
      :loading="lockPdfButton"
    >
      <v-icon>mdi-file-pdf</v-icon>
      <span v-if="thread.status === 'pending'">{{ $t('individualReport.input_generating_report') }}</span>
      <span v-else-if="thread.status === 'in_action' || thread.status === 'in_progress'">{{ $t('individualReport.input_generating_report') + ` ${thread.data.progress}%` }}</span>
      <span v-else-if="thread.status === 'failed'">{{ $t('individualReport.input_failed_generation') }}</span>
      <span v-else>{{ $t('individualReport.input_download_report') }}</span>
    </v-btn>

    <img
      src="/img/portada-individual2.jpg"
      style="visibility:hidden;"
      id="occCultureCoverOnly"
      alt="hidden"
      width="0"
      height="0"
    />
    <img
      src="/img/20200303_x_occ_dml_logo_r.png"
      style="visibility:hidden;"
      id="occCultureCover"
      alt="hidden"
      width="0"
      height="0"
    />
    <img
      src="/img/stamp.png"
      style="visibility:hidden;"
      id="stamp"
      alt="hidden"
      width="0"
      height="0"
    />
  </div>
</template>

<script>

import { mapState } from 'vuex'
import pdfUtil from './utils_old/pdf'
import dataObj from './utils_old/data-obj'

import is from 'is_js'
import cover from './mixins_individual_old/cover'
import introduction from './mixins_individual_old/introduction'
import initial from './mixins_individual_old/initial_calcs'
import previousPoll from './mixins_individual_old/previous_poll'
import information from './mixins_individual_old/informative_content'
import results from './mixins_individual_old/results'
import processResults from './mixins_individual_old/process_results'
import responsabilityResults from './mixins_individual_old/responsability_results'
import asPersonResults from './mixins_individual_old/results_as_person'
import directionResults from './mixins_individual_old/results_direction'
import mobilizationResults from './mixins_individual_old/results_mobilization'
import achievementResults from './mixins_individual_old/results_achievement'
import foResults from './mixins_individual_old/fo_confirmed'
import gapsAnalysis from './mixins_individual_old/gaps'
import dispersion from './mixins_individual_old/dispersion'
import trends from './mixins_individual_old/trends'
import openQuestions from './mixins_individual_old/open_questions'

const pdfmake = require('pdfmake/build/pdfmake')
const pdffonts = require('pdfmake/build/vfs_fonts.js')
const echarts = require('echarts')

pdfmake.vfs = pdffonts.pdfMake.vfs

export default {
  name: 'required-report',
  mixins: [
    cover,
    initial,
    previousPoll,
    introduction,
    information,
    results,
    processResults,
    responsabilityResults,
    asPersonResults,
    directionResults,
    mobilizationResults,
    achievementResults,
    foResults,
    gapsAnalysis,
    dispersion,
    trends,
    openQuestions
  ],
  props: {
    evaluatedId: Number,
    buttonText: String,
    thread: Object
  },
  data () {
    return {
      renderPart: {
        flowerChart: false
      },
      // Chart
      flowerChart: null,
      // IMÁGENES
      cultureCoverSrc: null,
      cultureCoverOnlySrc: null,
      cultureCoverBase64: null,
      cultureCoverOnlyBase64: null,
      stampSrc: null,
      stampBase64: null,
      lockPdfButton: false,
      // DATOS DE ENCUESTAS
      averages: {},
      evaluation: {},
      currentPoll: {},
      previousAverages: {},
      currentEvaluation: {},
      reportType: '',
      hasPrevious: false,
      averageScores: [],
      averageScoresFiltered: [],
      autoScoresFiltered: [],
      noAutoScores: [],
      noAutoScoresFiltered: [],
      leadersScores: [],
      leadersScoresFiltered: [],
      pairsScores: [],
      pairsScoresFiltered: [],
      dependentsScores: [],
      dependentsScoresFiltered: [],
      averageResponsability: [],
      averageResponsabilityFiltered: [],
      autoResponsabilityFiltered: [],
      noAutoScoresResponsability: [],
      noAutoScoresResponsabilityFiltered: [],
      leadersResponsability: [],
      leadersResponsabilityFiltered: [],
      pairsResponsability: [],
      pairsResponsabilityFiltered: [],
      dependentsResponsability: [],
      dependentsResponsabilityFiltered: [],
      leadersPopulation: 0,
      leadersPopulationFiltered: 0,
      pairsPopulation: 0,
      pairsPopulationFiltered: 0,
      dependentsPopulation: 0,
      dependentsPopulationFiltered: 0,
      previousAverageScores: [],
      previousAverageScoresFiltered: [],
      previousAutoScoresFiltered: [],
      previousNoAutoScores: [],
      previousNoAutoScoresFiltered: [],
      previousLeadersScores: [],
      previousLeadersScoresFiltered: [],
      previousPairsScores: [],
      previousPairsScoresFiltered: [],
      previousDependentsScores: [],
      previousDependentsScoresFiltered: [],
      previousAverageResponsability: [],
      previousAverageResponsabilityFiltered: [],
      previousAutoResponsabilityFiltered: [],
      previousNoAutoScoresResponsability: [],
      previousNoAutoScoresResponsabilityFiltered: [],
      previousLeadersResponsability: [],
      previousLeadersResponsabilityFiltered: [],
      previousPairsResponsability: [],
      previousPairsResponsabilityFiltered: [],
      previousDependentsResponsability: [],
      previousDependentsResponsabilityFiltered: [],
      previousLeadersPopulation: 0,
      previousLeadersPopulationFiltered: 0,
      previousPairsPopulation: 0,
      previousPairsPopulationFiltered: 0,
      previousDependentsPopulation: 0,
      previousDependentsPopulationFiltered: 0,
      othersRank: [],
      othersRankFiltered: [],
      autoRank: [],
      autoRankFiltered: [],
      noAutoOthersRank: [],
      noAutoOthersRankFiltered: [],
      leadersRank: [],
      leadersRankFiltered: [],
      dependentsRank: [],
      dependentsRankFiltered: [],
      pairsRank: [],
      pairsRankFiltered: [],
      othersRespRank: [],
      othersRespRankFiltered: [],
      autoRespRank: [],
      autoRespRankFiltered: [],
      noAutoOthersRespRank: [],
      noAutoOthersRespRankFiltered: [],
      leadersRespRank: [],
      leadersRespRankFiltered: [],
      dependentsRespRank: [],
      dependentsRespRankFiltered: [],
      pairsRespRank: [],
      pairsRespRankFiltered: [],
      completedPolls: {},
      completedPollsFiltered: {},
      expectedPolls: {},
      expectedPollsFiltered: {},
      evaluatedName: '',
      openQuestions: [],
      generalGap: [],
      confirmedStrengths: [],
      unknownStrengths: [],
      confirmedOpportunities: [],
      unknownOpportunities: []
    }
  },
  mounted () {
    this.cultureCoverSrc = document.getElementById('occCultureCover').src
    this.cultureCoverOnlySrc = document.getElementById('occCultureCoverOnly').src
    this.stampSrc = document.getElementById('stamp').src
  },
  watch: {
    renderPart: {
      handler () {
        const hasFalses = Object.values(this.renderPart).includes(false)
        if (!hasFalses) this.renderPdf()
      },
      deep: true
    },
    cultureCoverSrc (newVal, oldVal) {
      if (newVal) {
        this.toDataURL(this.cultureCoverSrc, (dataURL) => {
          this.cultureCoverBase64 = dataURL
        })
      }
    },
    cultureCoverOnlySrc (newVal, oldVal) {
      if (newVal) {
        this.toDataURL(this.cultureCoverOnlySrc, (dataURL) => {
          this.cultureCoverOnlyBase64 = dataURL
        })
      }
    },
    stampSrc (newVal, oldVal) {
      if (newVal) {
        this.toDataURL(this.stampSrc, (dataURL) => {
          this.stampBase64 = dataURL
        })
      }
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    })
  },
  methods: {
    toDataURL (url, callback) {
      const xhr = new XMLHttpRequest()
      xhr.open('get', url)
      xhr.responseType = 'blob'

      xhr.onload = function () {
        const fr = new FileReader()

        fr.onload = function () {
          callback(this.result)
        }

        fr.readAsDataURL(xhr.response)
      }

      xhr.send()
    },
    round (value, decimals, df) {
      if (isNaN(value)) return df !== undefined ? df : value
      return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals).toFixed(decimals)
    },
    insertLineBreak (text, lineLength) {
      lineLength = lineLength || 20
      let texto = text.split('')
      const long = texto.length
      if (long > lineLength) {
        for (let i = lineLength; i >= 0; i--) {
          if (texto[i] === ' ') {
            texto[i] = '\n'
            const restan = long - (i + 1)
            if (restan > lineLength) {
              const text1 = texto.splice(i + 1, restan).join('')
              const resto = this.insertLineBreak(text1)
              texto = texto.splice(0, i + 1).concat(resto.split(''))
            }
            break
          }
        }
      }
      return texto.join('')
    },
    generateFlowerChart () {
      const canvas = document.createElement('canvas')
      canvas.width = 1040 * 2
      canvas.height = 740 * 2

      const chartPieLocal = echarts.init(canvas)

      const base = {
        type: 'bar',
        coordinateSystem: 'polar',
        stack: 'a'
      }

      const baseBlack = {
        ...base,
        name: 'z',
        color: '#BDBDBD'
      }

      const baseBlank = {
        ...base,
        name: 'blank',
        color: 'rgba(0,0,0,0)'
      }
      let resp = []
      for (let i = 0; i < this.autoResponsabilityFiltered.length; i++) {
        for (let j = 0; j < this.autoResponsabilityFiltered[i].responsability.length; j++) {
          let obj = {
            name: this.autoResponsabilityFiltered[i].responsability[j].name,
            auto: this.round(dataObj.propertieOrDefault(this.autoResponsabilityFiltered[i].responsability[j], 'score', '0'), 2),
            others: this.round(dataObj.propertieOrDefault(this.noAutoScoresResponsabilityFiltered[i].responsability[j], 'score', '0'), 2, 0)
          }
          resp.push(obj)
        }
      }
      const headerData = [
        { value: '', textStyle: { fontSize: 12 } },
        {
          value: `{a|${resp[1].auto}}{b| | ${dataObj.previousScore(resp[1].others)}}\n{a|${this.insertLineBreak(resp[1].name)}}`,
          textStyle: {
            rich: {
              a: { fontSize: 35, color: '#C0CA33', align: 'center' },
              b: { fontSize: 35, color: '#000000', align: 'center' }
            }
          }
        }, {
          value: `{a|${resp[2].auto}}{b| | ${dataObj.previousScore(resp[2].others)}}\n{a|${this.insertLineBreak(resp[2].name)}}`,
          textStyle: {
            rich: {
              a: { fontSize: 35, color: '#C0CA33', align: 'center' },
              b: { fontSize: 35, color: '#000000', align: 'center' }
            }
          }
        }, {
          value: `{a|${resp[3].auto}}{b| | ${dataObj.previousScore(resp[3].others)}}\n{a|${this.insertLineBreak(resp[3].name)}}`,
          textStyle: {
            rich: {
              a: { fontSize: 35, color: '#C0CA33', align: 'center' },
              b: { fontSize: 35, color: '#000000', align: 'center' }
            }
          }
        }, {
          value: `{a|${resp[4].auto}}{b| | ${dataObj.previousScore(resp[4].others)}}\n{a|${this.insertLineBreak(resp[4].name)}}`,
          textStyle: {
            rich: {
              a: { fontSize: 35, color: '#C0CA33', align: 'center' },
              b: { fontSize: 35, color: '#000000', align: 'center' }
            }
          }
        }, {
          value: `{a|${resp[5].auto}}{b| | ${dataObj.previousScore(resp[5].others)}}\n{a|${this.insertLineBreak(resp[5].name)}}`,
          textStyle: {
            rich: {
              a: { fontSize: 35, color: '#C0CA33', align: 'center' },
              b: { fontSize: 35, color: '#000000', align: 'center' }
            }
          }
        }, {
          value: `{a|${resp[6].auto}}{b| | ${dataObj.previousScore(resp[6].others)}}\n{a|${this.insertLineBreak(resp[6].name)}}`,
          textStyle: {
            rich: {
              a: { fontSize: 35, color: '#00897B', align: 'center' },
              b: { fontSize: 35, color: '#000000', align: 'center' }
            }
          }
        }, {
          value: `{a|${resp[7].auto}}{b| | ${dataObj.previousScore(resp[7].others)}}\n{a|${this.insertLineBreak(resp[7].name)}}`,
          textStyle: {
            padding: [30, 0, 0, 0],
            rich: {
              a: { fontSize: 35, color: '#00897B', align: 'center' },
              b: { fontSize: 35, color: '#000000', align: 'center' }
            }
          }
        }, {
          value: `{a|${resp[8].auto}}{b| | ${dataObj.previousScore(resp[8].others)}}\n{a|${this.insertLineBreak(resp[8].name)}}`,
          textStyle: {
            rich: {
              a: { fontSize: 35, color: '#00897B', align: 'center' },
              b: { fontSize: 35, color: '#000000', align: 'center' }
            }
          }
        }, {
          value: `{a|${resp[9].auto}}{b| | ${dataObj.previousScore(resp[9].others)}}\n{a|${this.insertLineBreak(resp[9].name)}}`,
          textStyle: {
            rich: {
              a: { fontSize: 35, color: '#00897B', align: 'center' },
              b: { fontSize: 35, color: '#000000', align: 'center' }
            }
          }
        }, {
          value: `{a|${resp[10].auto}}{b| | ${dataObj.previousScore(resp[10].others)}}\n{a|${this.insertLineBreak(resp[10].name)}}`,
          textStyle: {
            rich: {
              a: { fontSize: 35, color: '#00897B', align: 'center' },
              b: { fontSize: 35, color: '#000000', align: 'center' }
            }
          }
        }, {
          value: `{a|${resp[11].auto}}{b| | ${dataObj.previousScore(resp[11].others)}}\n{a|${this.insertLineBreak(resp[11].name)}}`,
          textStyle: {
            rich: {
              a: { fontSize: 35, color: '#039BE5', align: 'center' },
              b: { fontSize: 35, color: '#000000', align: 'center' }
            }
          }
        }, {
          value: `{a|${resp[12].auto}}{b| | ${dataObj.previousScore(resp[12].others)}}\n{a|${this.insertLineBreak(resp[12].name)}}`,
          textStyle: {
            rich: {
              a: { fontSize: 35, color: '#039BE5', align: 'center' },
              b: { fontSize: 35, color: '#000000', align: 'center' }
            }
          }
        }, {
          value: `{a|${resp[13].auto}}{b| | ${dataObj.previousScore(resp[13].others)}}\n{a|${this.insertLineBreak(resp[13].name)}}`,
          textStyle: {
            rich: {
              a: { fontSize: 35, color: '#039BE5', align: 'center' },
              b: { fontSize: 35, color: '#000000', align: 'center' }
            }
          }
        }, {
          value: `{a|${resp[14].auto}}{b| | ${dataObj.previousScore(resp[14].others)}}\n{a|${this.insertLineBreak(resp[14].name)}}`,
          textStyle: {
            rich: {
              a: { fontSize: 35, color: '#039BE5', align: 'center' },
              b: { fontSize: 35, color: '#000000', align: 'center' }
            }
          }
        }, {
          value: `{a|${resp[15].auto}}{b| | ${dataObj.previousScore(resp[15].others)}}\n{a|${this.insertLineBreak(resp[15].name)}}`,
          textStyle: {
            rich: {
              a: { fontSize: 35, color: '#039BE5', align: 'center' },
              b: { fontSize: 35, color: '#000000', align: 'center' }
            }
          }
        }, {
          value: `{a|${resp[0].auto}}{b| | ${dataObj.previousScore(resp[0].others)}}\n{a|${this.insertLineBreak(resp[0].name)}}`,
          textStyle: {
            padding: [0, 0, 20, 0],
            rich: {
              a: { fontSize: 35, color: '#0D47A1', align: 'center' },
              b: { fontSize: 35, color: '#000000', align: 'center' }
            }
          }
        }]
      // 0D47A1
      const colorData = [
        'rgba(13, 71, 161, 0.8)', // como persona
        'rgba(192, 202, 51, 0.8)', // dirección
        'rgba(212, 225, 87, 0.8)',
        'rgba(220, 231, 117, 0.8)',
        'rgba(230, 238, 156, 0.8)',
        'rgba(240, 244, 195, 0.8)',
        'rgba(29, 145, 134, 0.8)', // Movilización
        'rgba(38, 166, 154, 0.8)',
        'rgba(77, 182, 172, 0.8)',
        'rgba(128, 203, 196, 0.8)',
        'rgba(178, 223, 219, 0.8)',
        'rgba(41, 182, 246, 0.8)', // Logro
        'rgba(79, 195, 247, 0.8)',
        'rgba(77, 208, 225, 0.8)',
        'rgba(129, 212, 2100, 0.8)',
        'rgba(179, 229, 252, 0.8)',
        'rgba(13, 71, 161, 0.8)' // Como persona
      ]

      const setData = (idx, value) => {
        const baseData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        baseData[idx] = value
        return baseData
      }

      const setColor = (idx, value) => ({
        ...base,
        data: setData(idx, value),
        color: colorData[idx],
        name: headerData[idx]
      })

      const setSpace = (idx, value) => ({
        ...baseBlank,
        data: setData(idx, value)
      })

      const setBlack = (idx) => ({
        ...baseBlack,
        data: setData(idx, 0.1)
      })

      const getData = (auto, others, idx) => {
        auto = auto - 1
        others = others - 1
        const data = []
        if (others === -1) {
          data.push(setColor(idx, auto))
        } else if (auto > others) {
          data.push(setColor(idx, others - 0.1))
          data.push(setBlack(idx))
          data.push(setColor(idx, (auto - others)))
        } else {
          data.push(setColor(idx, auto))
          data.push(setSpace(idx, (others - auto) - 0.1))
          data.push(setBlack(idx))
        }
        return data
      }

      chartPieLocal.setOption({
        angleAxis: {
          type: 'category',
          data: headerData,
          z: 10
        },
        radiusAxis: {
          min: 0,
          max: 5,
          interval: 0.25,
          axisLabel: {
            show: true,
            fontWeight: 'bold',
            fontSize: 40,
            formatter: (value) => +value % 1 === 0 ? (value + 1) : ''
          },
          splitLine: {
            interval: 0.25,
            show: true
          },
          splitArea: {
            interval: 0.25,
            show: true
          }
        },
        polar: {},
        series: [
          ...getData(resp[0].auto, resp[0].others, 0),
          ...getData(resp[1].auto, resp[1].others, 1),
          ...getData(resp[2].auto, resp[2].others, 2),
          ...getData(resp[3].auto, resp[3].others, 3),
          ...getData(resp[4].auto, resp[4].others, 4),
          ...getData(resp[5].auto, resp[5].others, 5),
          ...getData(resp[6].auto, resp[6].others, 6),
          ...getData(resp[7].auto, resp[7].others, 7),
          ...getData(resp[8].auto, resp[8].others, 8),
          ...getData(resp[9].auto, resp[9].others, 9),
          ...getData(resp[10].auto, resp[10].others, 10),
          ...getData(resp[11].auto, resp[11].others, 11),
          ...getData(resp[12].auto, resp[12].others, 12),
          ...getData(resp[13].auto, resp[13].others, 13),
          ...getData(resp[14].auto, resp[14].others, 14),
          ...getData(resp[15].auto, resp[15].others, 15),
          ...getData(resp[0].auto, resp[0].others, 16)
        ],
        barWidth: '100%'
      })

      chartPieLocal.on('finished', () => {
        this.flowerChart = chartPieLocal.getDataURL()
        this.renderPart.flowerChart = true
      })
    },
    async openPdf () {
      this.$store.dispatch('loading/show')
      this.lockPdfButton = true
      await this.$getInitialData(this.$route.params.id)
      await this.$getPreviousData(this.$route.params.id)
      this.generateFlowerChart()
    },
    writeRotatedText (text, color) {
      const canvas = document.createElement('canvas')
      canvas.width = 40
      canvas.height = 842
      const ctx = canvas.getContext('2d')

      // Genera color de fondo
      ctx.fillStyle = color
      ctx.fillRect(0, 0, canvas.width * 2, canvas.height * 2)
      ctx.save()

      // Posiciona el elemento al costado derecho de la página
      ctx.translate(40, 845)
      ctx.rotate(-0.5 * Math.PI)

      // Formatea el texto
      ctx.font = '17pt Roboto'
      ctx.fillStyle = 'white'
      ctx.fillText(text.toUpperCase(), 290, -15)
      ctx.restore()

      return canvas.toDataURL()
    },
    calculatePages () {
      // #EB493C #48BBA1
      return [
        { text: `${this.$t('individualReport.leadership')}`, sta: 3, end: 4, color: '#BFBFBF' },
        { text: `${this.$t('individualReport.direction')}`, sta: 5, end: 5, color: '#97b526' },
        { text: `${this.$t('individualReport.mobilization')}`, sta: 6, end: 6, color: '#45c0a3' },
        { text: `${this.$t('individualReport.achievement')}`, sta: 7, end: 7, color: '#3397d9' },
        { text: `${this.$t('individualReport.concepts')}`, sta: 8, end: 11, color: '#BFBFBF' },
        { text: `${this.$t('individualReport.results')}`, sta: 12, end: 35, color: '#BFBFBF' },
        { text: `${this.$t('individualReport.strengths_and_opportunities')}`, sta: 36, end: 36, color: '#BFBFBF' },
        { text: `${this.$t('individualReport.gaps')}`, sta: 37, end: 38, color: '#BFBFBF' },
        { text: `${this.$t('individualReport.dispersion')}`, sta: 39, end: 39, color: '#BFBFBF' },
        { text: `${this.$t('individualReport.trend')}`, sta: 40, end: 40, color: '#BFBFBF' },
        { text: `${this.$t('individualReport.open_questions_t')}`, sta: 41, end: 99, color: '#BFBFBF' }
      ]
    },
    renderPdf () {
      this.$emit('render-pdf')
      Object.keys(this.renderPart).forEach((key) => { this.renderPart[key] = false })
      const configuration = {
        pageSize: 'A4',
        pageMargins: [40, 60, 40, 40],
        info: {
          title: this.$t('individualReport.title'),
          author: 'OCC',
          subject: this.$t('individualReport.title')
        },
        defaultStyle: {
          fontSize: 11,
          font: 'Roboto',
          lineHeight: 1.2,
          margin: [0, 25, 0, 0]
        },
        header: (currentPage) => {
          const resultObj = {
            image: this.cultureCoverBase64,
            width: 131,
            height: 50,
            margin: [15, 5, 25, 15]
          }
          if (currentPage === 1) return [{}]
          return [resultObj]
        },
        footer: (currentPage) => {
          return [
            {
              columns: pdfUtil.generateCenteredText(this.$t('individualReport.copyright'), 10),
              color: 'grey'
            },
            {
              margin: [0, 0, 60, 0],
              text: currentPage.toString(),
              alignment: 'right',
              color: 'grey'
            }
          ]
        },
        background: (currentPage) => {
          const temp = this.calculatePages()
          const pageCont = temp.find(t => t.sta <= currentPage && t.end >= currentPage)

          if (!pageCont) return {}

          const label = pageCont.text
          const color = pageCont.color
          const result = {
            image: this.writeRotatedText(label, color),
            aligment: 'center',
            absolutePosition: { x: 565, y: 0 }
          }

          return result
        },
        content: [
          // Portada
          this.$generateCover(),
          // Índice
          this.$generateIntroduction(),
          // Informacion
          this.$generateInformationContent(),
          this.$generateDmlResults(),
          this.$generateProcessResults(),
          this.$generateResponsabilityResults(),
          this.$generateAsPersonResults(),
          this.$generateDirectionResults(),
          this.$generateMobilizationResults(),
          this.$generateAchievementResults(),
          this.$generateFoResults(),
          this.$generateGapsAnalysis(),
          this.$generateDispersion(),
          this.$generateTrends(),
          this.$generateOpenQuestions()
        ]
      }
      if (is.edge() || is.ie()) {
        const pdfDocGenerator = pdfMake.createPdf(configuration)
        pdfDocGenerator.getBlob((blob) => {
          window.navigator.msSaveBlob(blob, `${this.currentEvaluation.name} - Individual - ${this.evaluatedName}.pdf`)
          this.$store.dispatch('loading/hide')
        })
      } else {
        pdfmake.createPdf(configuration).download(`${this.currentEvaluation.name} - Individual - ${this.evaluatedName}.pdf`, () => {
          this.$store.dispatch('loading/hide')
        })
      }
      this.lockPdfButton = false
    },
    getSymbol (auto, others) {
      let dif = auto - others
      return dif > 1 ? ' (+)' : (dif < -1 ? ' (-)' : '')
    },
    getUnknownPercent (data) {
      let unknownCount = data.nullCount
      let total = unknownCount + data.individualScores.length
      const percent = (unknownCount * 100) / total
      return unknownCount > 0 ? ` (${unknownCount};${percent !== 100 ? this.round(percent, 0) : percent}%)` : ''
    }
  }
}
</script>
