
export default {
  index_title: 'Report content',
  index1: [
    '1. What is Leadership important to?',
    '2. Why a 360° Leadership assessment?',
    '3. What is the DML 360° ® Leadership Model?'
  ],
  index2: [
    { name: 'DIRECTION', index: 1 },
    { name: 'MOBILIZATION', index: 2 },
    { name: 'ACHIEVEMENT', index: 3 }
  ],
  index3: [
    '4. How is the assessment tool?',
    '5. How to interpret the report?',
    '6. Results DML 360° Assessment',
    '6.1. Higher scores',
    '6.2. Lower scores',
    '6.3. Results by Process',
    '6.4. Results by Liability',
    '6.5. Results for Conducts',
    '6.6. Unknown and confirmed strengths and opportunities for improvement',
    '6.7. Gap analysis',
    '6.8. Dispersion analysis',
    '6.9. Ranking of trends',
    '6.10. Consolidation of open questions'
  ],
  for_what_leadership_important: '1. What is Leadership important to?',
  for_what_leadership_important_texts: [
    `In today's world, where change is permanent and its rapid speed, the role of organizations, understood as dynamic systems, is to anticipate change. If such a forecast is not possible, at least they must continually adapt to the new realities. Its leaders must be active agents, responsible for managing such changes, adapting, monitoring and permanently executing strategies that guarantee the organization's competitiveness in a sustainable way.`,
    'Ricardo Matamala Señor, consulting partner and founder of the OCC Solutions and Liderazgo & Transformación firms, defines Leadership as the art of inspiring, mobilizing and directing all the intelligence, emotions and energy of a team, to ensure the achievement of common purposes and objectives. . In the same sense, Ronald A. Heifetz, professor of Leadership at Harvard University, defines it as a “mobilization process in which we all help each other, to face an uncertain reality and develop new capacities that allow us to confront with Adaptive challenges succeed.”',
    'In light of these definitions, Leadership must be understood as a dynamic and continually changing process, which, far from depending on a single person or leader, involves a set of interrelated people who, after coordinating and aligning their respective jobs, achieve achieve outstanding results. In this measure, we could affirm that Leadership is a collaborative process to achieve common dreams, purposes and goals. Hence, this process involves the ability of people to take initiative, create and consolidate teams, promote, encourage and inspire other human beings.',
    'Likewise, attracting, developing and maintaining the best talent become the key functions of a leader, who will be responsible for facilitating the development of people and directing them to be their best version for the world. From the above, the great importance of Leadership within the development of teams and organizations becomes evident. It is the leaders who, together with their work team, transform a certain vision of reality, mobilizing and guiding all the intelligence, emotions and collective energy, to achieve outstanding and sustainable achievements.',
    `However, each of the leaders' own competencies can be developed and adopted, as long as there is a true process of awareness and a real and legitimate will for change and development. In other words, Leadership can be developed once sufficient information is available about the impact our actions have on other people and our own self-worth. Thus, once our strengths and areas for improvement have been identified, it is possible to structure a focused and pertinent action plan. The DML 360° model (Management, Mobilization and Achievement) started from the previous premises for its conceptual development, and therefore includes the competencies that a high-performance leader must have in today's world.`
  ],
  for_what_360_leadership_measure_title: '2. Why a 360° leadership assessment?',
  for_what_360_leadership_measure: [
    `A 360° assessment reflects the perceptions of people and serves as the basis for in-depth work to develop leaders' competencies. By its nature it cannot be determined that it is an exact reflection of reality, but it does help to understand how leaders behave in the organization and the impact they have on others.`,
    'This report shows the results of your DML 360° Leadership assessment. Your openness and attitude during the reading of this report will be fundamental for the analysis of the results and the effectiveness of the process. The most important recommendations are:'
  ],
  preparation: [
    '1. PREPARATION: ',
    'Have a prudent time to analyze the results, be sure to review the entire report with conscience and depth, before beginning to prioritize solutions and define concrete actions.'
  ],
  commitment: [
    '2. COMMITMENT: ',
    'Define a first version of the development plan using the suggested formats.'
  ],
  feedback: [
    '3. FEEDBACK: ',
    'Once the action plan is defined and the final version is finished, it is suggested to present it in three conversations, with the direct leader, with the peers and with collaborators, in which time and sincerity are appreciated and the development plan is shown.'
  ],
  follow_up: [
    '4. TRACING: ',
    'The key to success is in following up on actions. We also recommend doing this assessment in six-month cycles to streamline the development process and observe trends. In case of doing the assessment frequently, it is very important to analyze the trend report.'
  ],
  for_what_360_leadership_measure2: 'Feedback is a gift. The leader who does not know its impact is doomed to failure. This information is of high value and a pretext to open transparent and constructive conversation spaces.',
  what_is_leadership_model: '3. What is the DML 360° Leadership Model?',
  what_is_leadership_model_text: 'The DML 360° Leadership model is made up of three Processes: Management, Mobilization and Achievement, which, in turn, carry five responsibilities respectively.',
  title: 'Results report',
  subtitle: 'Individual Leadership Report',
  direction: 'Address',
  direction_texts: [
    'A leader is an authentic and upright person who, through his consistent performance, shows the team what is right and proper, both with his personal and organizational principles.',
    'It is he who, with his example, guides the organizational culture and the behaviors of the other members of the work team through the observable experience of a set of values. The leader creates an accompaniment scenario to ensure the successful development of the culture that the organization requires, when executing its strategies and achieving its objectives.',
    `Leaders permanently adjust such strategies to the new realities of the environment and ensure everyone's understanding and commitment to goals and plans; They mobilize the team's strategic thinking through regular conversations and the creation of spaces that promote collective intelligence. Finally, they contribute so that each one internalizes his personal purpose in front of the assigned work and leads his personal and professional life in a balanced way. For this reason, leaders do not exhaust their impact in the professional sphere, they focus their effort on the integral growth of the human being in all aspects of life.`,
    'Consequently, Business Leadership must start from the identification of a purpose that, for the leader and the team, can be translated into an inspiring vision, a transcendent mission and challenging objectives. Hence, its main responsibilities are as follows:',
    '√ Agree with the team on vision, mission and objectives.\n√ Live the values ​​and lead the culture.\n√ Build strategies collectively and permanently with the team.\n√ Inspire and lead by example.\n√ Promote leadership personal.'
  ],
  mobilization: 'Mobilization',
  mobilization_texts: [
    `Leaders have a responsibility to inspire others, to create a sense of belonging, pride and a desire to commit and give their best for a higher purpose. Mobilize refers, mainly, to the effective management of change, innovation and the enhancement of the commitment and competencies of each of the team members, as a vital aspect of the organization's competitiveness and sustainability.`,
    `Therefore, the leader recognizes the need to create and maintain relationships of trust, based on effective two-way communication: that in which listening, asking and sharing information are essential elements to lead people's motivation and ensure performance. successful. The mobilization of talent requires empathy, accompaniment, closeness and generally good relationships.`,
    'Similarly, leaders recognize the importance of trusting their team. Delegating and empowering people, assigning and balancing responsibilities both individually and collectively, is part of the daily dynamics of their work team; This ensures autonomous and committed people and teams.',
    'Finally, they must create and maintain conditions where the intrinsic motivation of each person can emerge and contribute to the achievement of common goals. It is important to know what motivates each human being and to direct their talent in a manner consistent with those motivations. From the foregoing, the responsibilities associated with the Mobilization process are as follows:',
    '√ Ensure effective communication.\n√ Recognize empowerment and responsibility.\n√ Manage change and promote innovation.\n√ Maintain relationships of trust.\n√ Lead the motivation of people.'
  ],
  achievement: 'Achievement',
  achievement_texts: [
    'Achievement is achieved through the development of high-performance teams, where people share goals, values ​​and rules of the game. These teams are characterized by the fact that people perceive that the problem of one is a problem of all, therefore, both the responsibilities and the achievements and the challenges that arise are shared.',
    'Achievement relates to long-term vision and disciplined action. For this reason, the leader promotes the integral development of each individual, ensuring their continuous learning, through follow-up actions that they repeatedly implement. With this discipline, the leader has a high possibility of anticipating contingencies and carrying out the necessary plans effectively.',
    'Finally, all of the above occurs whenever there is a healthy work environment, a high level of engagement, where relationships, enjoyment and the way people feel working are determining factors for successful, competitive and satisfactory execution in terms of results.',
    `Having leaders promoting business sustainability, under a conscious perspective of delivering results that generate value and balance for all stakeholders, is transcendental and imperative in today's world.`,
    'Therefore, the Achievement process involves the following responsibilities:',
    '√ Develop high-performance teams.\n√ Ensure continuous learning and comprehensive development.\n√ Maintain follow-up discipline.\n√ Lead the work environment and create healthy environments.\n√ Promote business sustainability.'
  ],
  how_is_the_tool_title: '4. How is the assessment tool?',
  how_is_the_tool: [
    'Leadership DML 360° is a tool for assessing leadership competencies, which in an integral and systematized way, generates an assessment of the behavior of leaders within an organization. The tool consolidates the perceptions of different people (Leader (s), Peers and Collaborators), who hold various positions and relate to them in different ways, to measure their performance and skills and contrast them with their self-perception.'
  ],
  what_is_the_utility: 'What is it for?',
  what_is_the_utility_text: [
    'The purpose of the DML 360° Leadership assessment tool is to give the leader the necessary feedback to define a competency development plan that allows him to improve his performance and grow comprehensively as a human being.',
    'The scopes of the assessment of Leadership DML 360° are as follows:',
    '√ Raise awareness of the impact that is generated in others and contrast it with self-assessment.\n√ Identify strengths to reinforce them and weaknesses to turn them into opportunities for growth and development.\n√ Motivate people to improve their performance and involve them with the objectives of the organization.\n √ Encourage conversations and feedback spaces in all directions.\n√ Define individual and collective competency development plans.'
  ],
  how_it_works: 'How does it work?',
  how_it_works_text: [
    `The assessment is made up of a series of questions, which correspond to the leader's own personal attributes (the Leader as a person) and to the processes of the DML 360° model (Management, Mobilization and Achievement).`,
    'The assessment is made up of a series of behaviors and behaviors related to the competencies and values ​​of the DML 360° Leadership Model, on which each evaluator rates the frequency between 1 and 6 according to the following scale:'
  ],
  scores_meaning: [
    'Never',
    'Hardly ever',
    'Rarely',
    'Sometimes',
    'Usually',
    'Forever'
  ],
  how_to_interpret_the_report_title: '5. How to interpret the report?',
  how_to_interpret_the_report: [
    'The effectiveness of the assessment depends on the good understanding and interpretation of the results. The report highlights the strengths and opportunities for improvement of the person evaluated and allows analysis of trends, gaps and dispersion of results.',
    'The report must be understood according to the actor it values, that is:'
  ],
  me: 'Me',
  relations_texts: [
    'Corresponds to the self-assessment you assigned',
    'Corresponds to the appreciation of your leader(s) or superior(s).',
    'Corresponds to the average perceptions of people with whom you have the same position or rank within the organizational structure',
    'Corresponds to the average of the evaluations made by those who report to you',
    'Corresponds to the average of the ratings given by its leader, peers and collaborators'
  ],
  references_text: 'For each of the categories indicated above you will find the following references',
  bars: 'SHADED BARS',
  bars_text: 'They show the dispersion of the responses, that is, the range in which they were. Note that the scores mentioned above appear on the bar.',
  score: 'Score',
  scores: 'Scores',
  notes: 'Notes',
  score_text: 'It presents the score obtained according to each evaluator or group of evaluators and is represented as the symbol X.',
  ten_percent: '10% Higher',
  ten_percent_text: 'Corresponds to the average rating of 10% of the Leaders of the organization that obtained the highest score and is represented with the symbol of a green rhombus:',
  leaders_average: 'Average leaders',
  leaders_average_text: 'Reflects the average of the total value of the group valued in each of the skills and values. With this reference you will be able to know if it is located in the average of the valued group, above or below. It is represented by a circle:',
  trend: 'Trend',
  trend_text: 'This column applies for cases where the test is performed more than once, exposing the changes that have occurred. The trend symbol will appear if the valuation in each case increases or decreases by 0.4. This column is key to monitoring the process and verifying whether or not the action plan has yielded the expected results.',
  plus_minus_symbols: 'Symbols (+) and (-)',
  plus_minus_symbols_text: 'If after the text of a Responsibility or Conduct a positive symbol (+) appears at the end, it means that the self-assessment is 1 point higher than the average of Others, which implies that their self-perception is overrated; on the contrary, a negative symbol (-) will appear when your self-perception is less than 1 in relation to the average of Others, which means that your self-perception is undervalued.',
  others_symbols: 'Symbols (#;%)',
  others_symbols_text: `For the peer relationship, the survey has the option of Don't know / No answer and for this reason a number may appear followed by a percentage in some of the behaviors. This means the number and percentage of people who rated this option. At the Processes and Responsibilities level, only the percentage of said group is shown.`,
  bold_text: 'The highest scores in bold and the lowest underlined will appear in each table. Self-assessment scores will not be considered.',
  how_to_interpret_the_report2: [
    'This way of presenting the information facilitates understanding and at the same time allows for in-depth analysis of each valued element.',
    'The DML Model 360° ',
    'You have 10 questions of personal leadership attributes (the Leader as a person). Additionally, each of the 3 Processes of the Leadership model (Management, Mobilization and Achievement) has 5 Responsibilities and each responsibility in turn 3 Behaviors.',
    'The following is a brief description of the content of the report:'
  ],
  higher_scores: [
    'Higher scores',
    `Identifying the highest scores allows observing the person's strengths in such a way that they are recognized, strengthened and deepened. The table presents the 6 highest behaviors according to the score of Others and the corresponding value for each category of evaluator. Leveraging strengths is recommended to work in areas of improvement`
  ],
  lower_scores: [
    'Lower scores',
    'The lowest scores refer to weaknesses or areas for improvement. It is of great importance to recognize that as human beings we have some competencies where there are opportunities for improvement. The table presents the 6 behaviors with the lowest scores according to the average of Other evaluators. In order to read these results, a high commitment and humility are required to look at yourself and become aware of the aspects that require a greater effort for development.'
  ],
  process_results: [
    'Results by Process',
    'This section shows the result of the averages for each of the processes of the DML 360° Leadership Model (The Leader as Person, Direction, Mobilization and Achievement). These results serve to evaluate the state of the competences in general and to have a first image of perceptions and opportunities. The information is presented graphically and numerically to facilitate analysis. In the cases that apply, the score of the previous assessment and the trend can be observed.'
  ],
  responsability_results: [
    'Results by Liability',
    'In this part of the report, the information grouped for the Leader as a Person and for each of the 15 responsibilities of the DML 360° Leadership Model is presented, grouped in each of the 3 Processes.'
  ],
  behavior_results: [
    'Results for Conducts',
    'The tables of the detailed questions show the results precisely for each of the questions that were answered (Behaviors), grouped by each of the Processes and Responsibilities.'
  ],
  confirmed_fo: [
    'Confirmed and Unknown Strengths and Opportunities for Improvement',
    'This section categorizes the behaviors of its assessment according to the following criteria:',
    '√ If the average of Others was greater than or equal to 5 and their Self-evaluation was 5 or 6, this is a confirmed Strength; if his Self Rating was 4 or less it is an unknown Stronghold.',
    '√ If the average of Others was less than or equal to 4 and their Self-Assessment was 4 or less, this is a confirmed Opportunity for improvement; if your Self Assessment was 5 or higher it is an unknown Opportunity for Improvement.',
    'In all cases, up to 6 behaviors will appear, those with the highest and lowest scores depending on the case.'
  ],
  gaps_analysis_title: 'Gap Analysis',
  gaps_analysis: [
    'Gaps between Self-assessment and the average of Others:',
    'The gaps allow us to observe the differences that exist between self-assessment and the perceptions of the different people who answer the survey, so that the differences between them are visible. This information is important to analyze the levels of consciousness of the leader and the knowledge he has about his impact on others.',
    'These tables show the highest gaps at the Behavior level. The largest gaps are shown first when the Self Assessment is higher than the Others, and then the highest gaps are shown when the Others assessment is higher than the Self Assessment.'
  ],
  gaps_analysis2: [
    'Gaps between evaluators',
    'This table presents the highest gaps found between the averages of the different evaluators. This information is important as it shows the behaviors in which the leader is being perceived differently according to the different populations of evaluators: Leader (s), Peers and Collaborators.'
  ],
  dispersion_analysis: [
    'Dispersion Analysis',
    'This part of the report shows the behaviors where there was greater dispersion (diverse opinions) and where there was less dispersion (greater consensus). This assessment is obtained through the standard deviation of the responses of Others.'
  ],
  trends_report: [
    'Trend Report',
    'This part of the report shows the trends where there were greater positive and negative changes with respect to the previous assessment.'
  ],
  open_questions: [
    'Open questions',
    'This section contains the compilation of answers given by those who valued you and are related to the opportunities and strengths that your valuers find in you and the advice they would give you to be more effective in your role as Leader.'
  ],
  thanks_message: 'THANK YOU FOR YOUR INTEREST AND WE HOPE THAT THIS INFORMATION WILL BE OF GREAT USE FOR YOUR DEVELOPMENT AS A LEADER AND AS A PERSON',
  results_dml: '6. Results Assessment DML 360°',
  results_text: 'In Tables 1 and 2, each column corresponds to the average assessment of:',
  results_note: '* Throughout the report, the Others category refers to the average of Leader(s), Peers and Collaborators.',
  higher_scores_title: '6.1. Higher scores',
  lower_scores_title: '6.2. Lower scores',
  process_results_title: '6.3. Results by Process',
  responsability_results_title: '6.4. Results by Liability',
  behavior_results_title: '6.5. Results for Conducts',
  confirmed_fo_title: '6.6. Confirmed and Unknown Strengths and Opportunities for Improvement',
  trend_ranking: 'Trends Ranking',
  open_questions_title: 'Consolidation of Open Questions',
  open_questions_t: 'Open questions',
  higher_positives_trends: 'Greater Positive Trends',
  higher_negatives_trends: 'Greater Negative Trends',
  process_results_text: 'Result by Process',
  table: 'Table',
  table1: 'Six Highest Scores (according to Others)',
  table2: 'Six Lowest Scores (according to Others)',
  table4: 'Results by Responsibility for the Process ',
  table7: 'Results for Responsibility and Conduct for the Process ',
  table24: 'Confirmed and Unknown Strengths and Opportunities for Improvement',
  table25: 'Analysis of Gaps between Self-Assessment and Average of Others',
  table26: 'Gap Analysis between Appraisers',
  chart1: 'This graph compares the average of your Self-Assessment in each Responsibility (color strip) against the average obtained by the rating of other assessors (gray line).',
  responsability_results_text: 'This part of the report presents the results for liability. In total there are 15 responsibilities, 5 for Management, 5 for Mobilization and 5 for Achievement.',
  higher_dispersion: 'Greater dispersion',
  lower_dispersion: 'Less dispersion',
  higher_bold: 'Higher Bold Ratings',
  lower_sub: 'Lower Underlined Ratings',
  // FO
  confirmed_strengths: 'Confirmed strengths',
  confirmed_strengths_text: 'Average of Others greater than or equal to 5 and Self-assessment 5 or 6.',
  unknown_strengths: 'Unknown Strengths',
  unknown_strengths_text: 'Average of Others greater than or equal to 5 and Self-evaluation 4 or less.',
  confirmed_improvement_opportunities: 'Confirmed Opportunities for Improvement',
  confirmed_improvement_opportunities_text: 'Average of Others less than 5 and Self-evaluation 4 or less.',
  unknown_improvement_opportunities: 'Unknown Improvement Opportunities',
  unknown_improvement_opportunities_text: 'Average of Others less than 5 and Self-assessment 5 or higher.',
  greater_auto: 'Self-assessment greater than the average of Others',
  lower_auto: 'Self-evaluation less than the average of Others',
  higher_score: 'Highest score',
  lower_score: 'Lower score',
  // Resultados Generales
  general_results: '9. General Results',
  general_results_text: 'In summary, the average obtained by the leaders (white bar) and their Individual Self-Assessment (color bar) are presented for each Process. The dark color in each Process corresponds to the current assessment and the light color to the previous assessment (in a first application of the Survey you will only find one bar per Process).',
  // Brechas
  positive_negative_gaps: '10. Positive and negative gaps',
  positive_negative_gaps_text: `Taking the result of the entire survey, these are the biggest gaps between your Individual Self-Assessment and the Leaders' Average. On the right side are the positive ones (where you value yourself better than the average of leaders) and on the left side are the negative ones (where you value yourself below the average).`,
  // Fortalezas y oportunidades de mejora
  strengths_opportunities: '11. Strengths and Opportunities for Improvement',
  strengths_opportunities_text: 'The following is the summary of the 5 highest scores (Strengths) and the 5 lowest (Opportunities for Improvement) of the leaders, with their respective dispersion and Individual Self-evaluation.',
  // Seguimiento
  strengths: 'Strengths',
  leader_strengths: 'The strengths of this leader are: ',
  leader_opportunities: 'The opportunities for improvement of this leader are:',
  leader_tips: 'The challenges to being a more inspiring and effective leader are:',
  information: 'information',
  ranking: 'Ranking',
  improvement_opportunities: 'Improvement opportunities',
  actual_evaluation: 'Current rating',
  average_team: 'Team Average',
  individual: 'Individual',
  dimension: 'Dimension',
  attribute: 'Attribute',
  average_leaders: 'Leaders Average',
  average: 'Average',
  poll_answers: 'Survey responses',
  autoevaluation: 'Self-assessment',
  autoevaluations: 'Self-assessments',
  leaders: 'Leader(s)',
  pairs: 'Pairs',
  dependents: 'Collaborators',
  others: 'Others',
  total: 'Total',
  auto: 'Auto',
  evaluator: 'Evaluator',
  received_evaluations: 'Ratings Received',
  received: 'Received',
  requested_evaluations: 'Requested Ratings',
  requested: 'Expected',
  answers_rate: 'Response Percentage',
  process_abbr: 'P',
  responsability: 'Responsibility',
  behavior: 'Conduct',
  process: 'Process',
  actual_dispersion: 'Current Dispersion',
  previous_average: 'Previous Average',
  actual_average: 'Current Average',
  previous_score: 'Previous Score',
  actual_score: 'Current Score',
  previous: 'Previous',
  percent_break: 'Jump %',
  current: 'Current',
  dispersion: 'Dispersion',
  as_person: 'As a person',
  average_as_person: 'Average as a person',
  direction_average: 'Average address',
  mobilization_average: 'Mobilization average',
  achievement_average: 'Mobilization average',
  leader_as_person: 'Leader as a person',
  results: 'Results',
  copyright: 'OCC - All rights reserved.',
  higher_gap: 'Positive higher gaps',
  lower_gap: 'Negative higher gaps',
  gap: 'Gap',
  gaps: 'Gaps',
  chart: 'Graph',
  strengths_and_opportunities: 'Strengths and Opportunities',
  months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ],
  data_range: 'Data range (Max-Min)',
  no_answer: '(#;%) Do not know/Not answer',
  leadership: 'Leadership',
  concepts: 'Concepts',
  no_evaluators: 'No evaluators',
  no_others_evaluators: 'No others evaluators',
  there_is_no: 'There is no '
}
