
export default {
  no_answers_modal_msg: 'The current survey does not have answers, so it will not be possible to generate a report.',
  general_report: 'Generate report',
  download_report: 'Download report',
  required_general_title: 'Organizational Leadership Report',
  required_general_desc: 'This report groups the scores of all the leaders included in this measurement.',
  change_report: 'Do you want to generate an organizational report by population?',
  select_general_demographics_items: 'Select the demographics you need to view in the answer table',
  required_current_culture_title: 'Current Culture vs. Required Culture Report',
  required_current_culture_description: 'Report that has statistics of the entire population included in the survey and the required culture assigned.',
  required_actual_button: 'Generate Report',
  select_report_type: 'Select the type of report you want to generate',
  individual_title: 'Individual leadership report',
  individual_desc: 'This report has the results of the DML 360° evaluation for each leader.',
  demographic_title: 'Demographic cut report',
  demographic_desc: 'This report has the results of the DML 360° evaluation segmented by demographics cuts',
  number_leaders_evaluated: 'Number of leaders evaluated',
  message_number_leaders_evaluated: 'There must be at least one leader who meets the filter criteria',
  evaluated: 'Evaluated Leader',
  no_autocompleted: 'This Leader did not carry out self-evaluation',
  no_autocompleteds: 'At least one Leader must complete the self-assessment',
  generated_reports: 'Generated Reports',
  generated_reports_desc: 'In this section you will find all the reports you have generated.<br>Depending on the number of participants, each report can take up to 30 minutes to generate. You can update the progress by reloading the page.<br>It is recommended to generate a maximum of 5 reports at the same time, to improve the performance of the platform.',
  already_generated_report: 'This report has already been generated. You can download it at the section below, where all the generated reports are located.',
  demographicCuts: {
    jobTypes: 'Job Types',
    headquarter: 'Headquarters',
    gender: 'Gender',
    departments: 'Departments',
    country: 'Countries',
    charge: 'Charges',
    antiquity: 'Antiquity',
    age: 'Age Range',
    academicDegree: 'Academic Degree'
  }
}
