
import dataObj from '../utils_old/data-obj'
import pdfUtil from '../utils_old/pdf'

export default {
  methods: {
    $generateResultsPerBehavior () {
      // 309DE0 EB493C 48BBA1
      const descriptions = [
        this.$t('organizationalReport.table17')
      ]
      const legend = [
        this.$t('organizationalReport.data_range'),
        this.$t('organizationalReport.score'),
        this.$t('organizationalReport.ten_percent'),
        this.$t('organizationalReport.higher_bold'),
        this.$t('organizationalReport.lower_sub')
      ]
      const process = [
        { name: this.$t('organizationalReport.as_person'), table: this.$t('organizationalReport.leader_as_person'), color: '#0D47A1' },
        { name: this.$t('organizationalReport.management'), table: this.$t('organizationalReport.management'), color: '#bcd835' },
        { name: this.$t('organizationalReport.mobilization'), table: this.$t('organizationalReport.mobilization'), color: '#93dbca' },
        { name: this.$t('organizationalReport.achievement'), table: this.$t('organizationalReport.achievement'), color: '#309DE0' }
      ]
      let tables = []
      for (let i = 0; i < 4; i++) {
        let scores = []
        let tableProcess = {}
        let scoreProcess = []
        const averageScores = this.averageScores[i].questions.map(averageScores => averageScores.score)
        for (let j = 0; j < this.averageScores[i].questions.length; j++) {
          scores.push([
            { text: this.averageScores[i].questions[j].question, margin: [0, -2, 0, -2], border: [true, true, true, true], style: { color: '#6d7a7a' } },
            this.averageScores[i].questions[j].score > 0 ? pdfUtil.generateXDChart(this.averageScores, i, j) : { text: '--', alignment: 'center', margin: [0, -2, 0, -2], border: [true, true, true, true], style: { color: '#6d7a7a' } },
            this.averageScores[i].questions[j].score > 0 ? pdfUtil.higherLowerRow(averageScores, this.averageScores[i].questions[j].score, '#2e2d2d', [0, 4, 0, 0], 'center', [true, true, true, true], 'question') : { text: '--', alignment: 'center', margin: [0, -2, 0, -2], border: [true, true, true, true], style: { color: '#6d7a7a' } },
            { text: this.averageScores[i].questions[j].score > 0 ? this.round(this.averageScores[i].questions[j].tenPercent, 2) : '--', alignment: 'center', margin: [0, 4, 0, 0], border: [true, true, true, true], style: { color: '#6d7a7a' } },
            { text: `${this.hasPrevious ? this.round(dataObj.previousScore(this.previousAverageScores[i].questions[j].score), 2) : ''}`, alignment: 'center', margin: [0, 4, 0, 0], border: [true, true, true, true], style: { color: '#6d7a7a' } },
            { text: `${this.hasPrevious ? this.round(this.averageScores[i].questions[j].score - dataObj.previousScore(this.previousAverageScores[i].questions[j].score), 2) : ''}`, alignment: 'center', margin: [0, 4, 0, 0], border: [true, true, true, true], style: { color: '#6d7a7a' } }
          ])
        }
        scoreProcess.push([
          { text: process[i].table.toUpperCase(), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: process[i].color }, fontSize: 10 },
          pdfUtil.generateXDChart(this.averageScores, i),
          { text: this.round(this.averageScores[i].total, 2), alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], style: { color: '#6d7a7a' } },
          { text: this.round(this.averageScores[i].tenPercent, 2), alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], style: { color: '#6d7a7a' } },
          { text: `${this.hasPrevious ? this.round(this.previousAverageScores[i].total, 2) : ''}`, alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], style: { color: '#6d7a7a' } },
          { text: `${this.hasPrevious ? this.round(this.averageScores[i].total - this.previousAverageScores[i].total, 2) : ''}`, alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], style: { color: '#6d7a7a' } }
        ])
        tableProcess = {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#BFBFBF' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            widths: ['28%', '40%', '8%', '8%', '8%', '8%'],
            body: [
              // Header
              [
                { text: this.$t('organizationalReport.process'), margin: [0, 4, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 },
                { layout: { defaultBorder: '', fillColor: '#BFBFBF' },
                  table: {
                    headerRows: 1,
                    body: [[
                      { text: '1\n|', margin: [10, -5, 15, 0] },
                      { text: '2\n|', margin: [0, -5, 15, 0] },
                      { text: '3\n|', margin: [0, -5, 15, 0] },
                      { text: '4\n|', margin: [0, -5, 15, 0] },
                      { text: '5\n|', margin: [0, -5, 15, 0] },
                      { text: '6\n|', margin: [0, -5, 15, 0] }
                    ]]
                  },
                  bold: true,
                  style: { color: '#fff' },
                  border: [true, true, true, true]
                },
                { text: this.$t('organizationalReport.score'), margin: [0, 4, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 },
                { text: this.$t('organizationalReport.ten_percent'), margin: [0, 0, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 },
                { text: this.$t('organizationalReport.previous_score_abbr'), margin: [0, 0, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 },
                { text: this.$t('organizationalReport.trend_abbr'), margin: [0, 4, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 }
              ],
              // Body
              ...scoreProcess
            ]
          },
          margin: [10, 0, 15, 10],
          border: [false, false, false, true],
          alignment: 'center',
          fontSize: 8
        }
        let title = {}
        if (i > -1) {
          title = {
            table: {
              widths: [479],
              body: [
                [{ text: this.textToUpper('organizationalReport.index[4]'), bold: true, color: '#fff', margin: [50, 8, 0, 5] }]
              ]
            },
            layout: {
              fillColor: '#aaaaaa',
              defaultBorder: ''
            },
            alignment: 'left',
            margin: [-40, 40, 0, 0],
            pageBreak: 'before'
          }
        }
        tables.push(
          title,
          {
            layout: {
              defaultBorder: '',
              fillColor: '#aaaaaa'
            },
            table: {
              widths: [300, 170],
              body: [
                [
                  { text: `${this.$t('organizationalReport.table')} ${i + 17}. ${descriptions[0]}`, margin: [30, 5, 0, 5], bold: true, color: '#fff' },
                  { text: `${process[i].name}`.toUpperCase(), margin: [0, 5, 0, 5], bold: true, color: process[i].color }
                ]
              ]
            },
            margin: i > 0 ? [35, 20, 20, 10] : [35, 10, 20, 10],
            // pageBreak: i > 0 ? 'before' : ''
            pageBreak: ''
          },
          {
            layout: {
              defaultBorder: ''
            },
            table: {
              widths: [479],
              body: [
                [{ text: this.$t('organizationalReport.table_description'), margin: [30, 0, 0, 0], color: '#6d7a7a' }]
              ]
            },
            margin: [35, 0, 20, 0]
          },
          pdfUtil.generateDmlLegend(legend),
          tableProcess,
          {
            layout: {
              defaultBorder: '',
              fillColor: function (rowIndex, node, columnIndex) {
                return (rowIndex === 0) ? '#BFBFBF' : null
              },
              border: [false, false, false, true]
            },
            table: {
              headerRows: 1,
              widths: ['28%', '40%', '8%', '8%', '8%', '8%'],
              body: [
                // Header
                [
                  { text: this.$t('organizationalReport.behavior'), margin: [0, 4, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 },
                  { layout: { defaultBorder: '', fillColor: '#BFBFBF' },
                    table: {
                      headerRows: 1,
                      body: [[
                        { text: '1\n|', margin: [10, -5, 15, 0] },
                        { text: '2\n|', margin: [0, -5, 15, 0] },
                        { text: '3\n|', margin: [0, -5, 15, 0] },
                        { text: '4\n|', margin: [0, -5, 15, 0] },
                        { text: '5\n|', margin: [0, -5, 15, 0] },
                        { text: '6\n|', margin: [0, -5, 15, 0] }
                      ]]
                    },
                    bold: true,
                    style: { color: '#fff' },
                    border: [true, true, true, true]
                  },
                  { text: this.$t('organizationalReport.score'), margin: [0, 4, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 },
                  { text: this.$t('organizationalReport.ten_percent'), margin: [0, 0, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 },
                  { text: this.$t('organizationalReport.previous_score_abbr'), margin: [0, 0, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 },
                  { text: this.$t('organizationalReport.trend_abbr'), margin: [0, 4, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 }
                ],
                // Body
                ...scores
              ]
            },
            margin: [10, 0, 15, 10],
            border: [false, false, false, true],
            alignment: 'center',
            fontSize: 8,
            lineHeight: 1
          },
          i !== 0
            ? {}
            : {
              layout: {
                defaultBorder: '',
                border: [false, false, false, true]
              },
              table: {
                headerRows: 1,
                widths: [300],
                body: [
                  [
                    { text: `${this.$t('individualReport.notes')}:`, bold: true, color: '#000', margin: [0, 0, 0, 0], border: [false, false, false, true] }
                  ]
                ]
              },
              margin: [30, 0, 15, 10],
              border: [false, false, false, true],
              alignment: 'left',
              fontSize: 10
            }
        )
      }
      return tables
    }
  }
}
