<template>
  <v-dialog v-model="modalErrors" width="70em">
    <v-card>
      <v-card-title
        class="headline grey lighten-2"
        style="padding: 1em"
        primary-title
      >
        Listado de errores
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-alert v-for="noEvaluator in errors.noEvaluator" :key="noEvaluator.id"
              type="error"
              dense
              text
            >
              {{ $t('evaluations.the_evaluated') }} <strong>{{ noEvaluator.evaluated.firstName }} {{ noEvaluator.evaluated.lastName }}</strong> {{ $t('evaluations.no_evaluated_type') }}
              &nbsp;{{ noEvaluator.type }} {{ $t('evaluations.fix_document_or_try_again') }}
            </v-alert>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="text-uppercase"
          large
          text
          @click="hideModalErrors"
        >
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  props: {
    errors: Object
  },
  data () {
    return {
      modalErrors: true
    }
  },
  methods: {
    hideModalErrors () {
      this.$emit('hideModalErrors')
    }
  }
})
</script>
