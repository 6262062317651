export default {
  index_title: 'Contenido del informe',
  index: [
    '1. Responsibility with Highest and Lowest Score for Relationship',
    '2. Behaviors with Higher and Lower Scores according to Relationship',
    '3. Results by Process',
    '4. Results by Liability',
    '5. Results by Conduct',
    '6. Dispersion Analysis for Responsibility and Conduct',
    '7. Trend Analysis for Responsibility and Conduct',
    '8. Gap Analysis for Responsibility and Conduct'
  ],
  organizational_score_abbr: 'Score Org.'
}
