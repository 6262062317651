
import pdfUtil from '../utils/pdf'

const fontSizeBylength = [
  { min: 0, max: 30, fontSize: 20 },
  { min: 31, max: 34, fontSize: 18 },
  { min: 35, max: 38, fontSize: 16 },
  { min: 39, max: 42, fontSize: 14 },
  { min: 43, max: 57, fontSize: 12 },
  { min: 57, max: 70, fontSize: 10 },
  { min: 70, max: 9999999, fontSize: 8 }
]

const getFontSizeBylength = (l) => fontSizeBylength.find((fsbl) => fsbl.min <= l && fsbl.max >= l).fontSize

export default {
  data () {
    return {
      today: new Date()
    }
  },
  methods: {
    $generateCover () {
      let cutSelectedTemp = {
        country: this.cutsSelected.country || [],
        headquarter: this.cutsSelected.headquarter || [],
        departments: this.cutsSelected.departments || [],
        charge: this.cutsSelected.charge || [],
        jobTypes: this.cutsSelected.jobTypes || [],
        academicDegree: this.cutsSelected.academicDegree || [],
        gender: this.cutsSelected.gender || [],
        age: this.cutsSelected.age || [],
        antiquity: this.cutsSelected.antiquity || []
      }

      let previousText = ''
      let lastSpace
      let maxLength = 262
      let totalSelectionCutsText = ''
      let element
      for (let item in cutSelectedTemp) {
        if (cutSelectedTemp[item].length === 0) {
          continue
        }
        previousText += this.$t(`reports.demographicCuts.${item}`) + ': '
        if (item === 'age' || item === 'antiquity') {
          // Ordenando el array para mostrar de forma ordenanda
          cutSelectedTemp[item].sort((a, b) => a.min - b.min)
          for (let j of cutSelectedTemp[item]) {
            element = this.demographicCuts[item].es.find(e => e.range.min === j.min && e.range.max === j.max)
            previousText += element.name + ', '
          }
        } else if (item === 'headquarter') {
          // Ordenando el array para mostrar de forma ordenanda
          cutSelectedTemp[item].sort((a, b) => a - b)
          for (let j of cutSelectedTemp[item]) {
            element = this.demographicCuts[item].find(e => e.id === j)
            previousText += element.name + ', '
          }
        } else {
          // Ordenando el array para mostrar de forma ordenanda
          cutSelectedTemp[item].sort((a, b) => a - b)
          for (let j of cutSelectedTemp[item]) {
            element = this.demographicCuts[item].find(e => e.id === j)
            previousText += element.translate.label + ', '
          }
        }
      }
      // Validando el máximo de longitud del string que puede mostrar en la portada
      if (previousText.slice(0, -2).length > maxLength) {
        lastSpace = previousText.slice(0, maxLength).lastIndexOf(' ')
        totalSelectionCutsText = previousText.slice(0, lastSpace) + '...'
      } else {
        totalSelectionCutsText = previousText.slice(0, -2) + '.'
      }
      const selectionCutsFontSize = getFontSizeBylength((totalSelectionCutsText || '').length)
      const pollNameFontSize = getFontSizeBylength((this.reportName || '').length)
      const enterpriseFontSize = getFontSizeBylength((this.user.enterprise.name || '').length)
      const reportName = this.reportName
      this.reportName = this.reportName + ` (${totalSelectionCutsText.split(':').join(' ')})`
      const evaluators = [
        this.$t('demographicReport.autoevaluations'),
        this.$t('demographicReport.leaders'),
        this.$t('demographicReport.pairs'),
        this.$t('demographicReport.dependents')
      ]
      const keys = ['auto', 'leaders', 'pairs', 'dependents']
      let rows = []
      let total = []
      let receivedTotal = 0
      let expectedTotal = 0
      let rateTotal = 0
      for (let i = 0; i < evaluators.length; i++) {
        let received = this.completedPolls[keys[i]]
        let expected = this.expectedPolls[keys[i]]
        let rate = null
        if (expected > 0) {
          rate = (received * 100) / expected
        }
        rows.push([
          { text: evaluators[i].toUpperCase(), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: '#6d7a7a' } },
          { text: expected === 0 ? '--' : expected, margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: '#6d7a7a' } },
          { text: expected === 0 && received === 0 ? '--' : received, margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: '#6d7a7a' } },
          { text: rate == null ? '--' : this.round(rate, 2) + '%', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: '#6d7a7a' } }
        ])
        receivedTotal += received
        expectedTotal += expected
      }
      if (expectedTotal > 0) {
        rateTotal = (receivedTotal * 100) / expectedTotal
      }
      total.push([
        { text: this.$t('demographicReport.total').toUpperCase(), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#6d7a7a' } },
        { text: expectedTotal === 0 ? '--' : expectedTotal, margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#6d7a7a' } },
        { text: expectedTotal === 0 && receivedTotal === 0 ? '--' : receivedTotal, margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#6d7a7a' } },
        { text: rateTotal === 0 ? '--' : this.round(rateTotal, 2) + '%', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#6d7a7a' } }
      ])
      return [
        {
          image: this.coverOnlyBase64,
          width: 610,
          height: 800,
          aligment: 'center',
          margin: [0, 0, 0, 0],
          absolutePosition: { x: 0, y: 0 }
        },
        {
          margin: [0, 70, 0, 0],
          columns: pdfUtil.generateCenteredText(this.$t('demographicReport.title').toUpperCase(), 26, true, false, false)
        },
        {
          margin: [0, 10, 0, 20],
          columns: pdfUtil.generateCenteredText(this.$t('demographicReport.subtitle').toUpperCase(), 24, false)
        },
        {
          margin: [0, 150, 0, 0],
          columns: pdfUtil.generateCenteredText(this.user.enterprise.name, enterpriseFontSize, true)
        },
        {
          columns: pdfUtil.generateCenteredText(`${this.$t(`demographicReport.months[${this.today.getMonth()}]`)} ${this.today.getDate()} de ${this.today.getFullYear()}`, 15, true)
        },
        {
          columns: pdfUtil.generateCenteredText(reportName, pollNameFontSize, false),
          margin: [0, 10, 0, 8]
        },
        {
          margin: [15, 0, 0, 15],
          columns: pdfUtil.generateCenteredText(totalSelectionCutsText, selectionCutsFontSize, false)
        },
        {
          margin: [0, 0, 0, 15],
          columns: pdfUtil.generateCenteredText('', 8, true)
        },
        {
          margin: [0, 10, 0, 0],
          columns: pdfUtil.generateCenteredText(this.$t('demographicReport.poll_answers'), 20, true)
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#BFBFBF' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            body: [
              [
                { text: '', margin: [0, 3, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.requested').toUpperCase(), margin: [15, 8, 15, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.received').toUpperCase(), margin: [15, 8, 15, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('demographicReport.answers_rate').toUpperCase(), margin: [15, 3, 15, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } }
              ],
              // Body
              ...rows,
              ...total
            ]
          },
          pageBreak: 'after',
          margin: [35, 5, 15, 10],
          border: [false, false, false, true],
          alignment: 'center',
          fontSize: 10
        }
      ]
    }
  }
}
