<template>
  <v-container flat>
    <v-card flat>
      <v-card-subtitle class="pa-1 pt-0 body-2">
        {{ $t('evaluations.evaluated_selection_desc') }}
      </v-card-subtitle>
      <!--
      <p class="mt-4 body-2 grey--text text--darken-2">
        <v-icon class="mb-1" color="warning">mdi-alert</v-icon>
        {{ $t('evaluations.evaluated_selection_warning') }}
      </p>
      -->
      <v-divider class="mt-7"></v-divider>
      <v-row align="center" justify="center" v-if="canEdit">
        <v-col align="center" justify="center" cols="12" class="mb-3">
          <v-switch hide-details
            v-if="!reviewMassive"
            v-model="isMassive"
            color="primary"
            :label="$t('evaluations.want_massive')"
          ></v-switch>
        </v-col>
      </v-row>
      <template v-if="!isMassive">
        <x-evaluated-table
          :status="evaluation.status"
          :evaluated="evaluation.evaluated"
          @showList="showList"
          @edit="(item) => editEvaluated = item"
          @delete="openModal"
        ></x-evaluated-table>
        <v-row v-if="canEdit">
          <v-col cols="12" align="center">
            <v-btn x-large
              outlined
              color="green"
              class="mr"
              @click="addEvaluated"
            >
              <v-icon large class="mr-2">add_circle_outline</v-icon> {{$t('evaluations.add_evaluated')}}

            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <v-btn
              block
              large
              @click="changeStep(true)"
            >{{ $t(prevAction) }}</v-btn>
          </v-col>
          <v-col cols="12" sm="6">
            <v-btn
              color="primary"
              block
              large
              @click="changeStep(false)"
              :disabled="!evaluation.evaluated.length"
            >{{ $t(nextAction) }}</v-btn>
          </v-col>
        </v-row>
      </template>
      <template v-else-if="isMassive && !reviewMassive">
        <div class="mb-3 pr-6 text-justify body-2 error--text text--darken-1">
          <b class="caption font-weight-bold">{{ $t('pricing.recomendations') }}</b>:
          <p class="text-recomendations mb-1">
            {{ $t('evaluations.massive_upload_recomendations') }}
          </p>
          <ul class="mb-4">
            <li v-for="(item, i ) in $t('evaluations.massive_upload_recomendations_items')"
              :key="i"
              class="text-recomendations"
            >
              {{ item }}
            </li>
          </ul>
        </div>
        <ValidationObserver v-slot="{ handleSubmit }">
          <v-form @submit.prevent="handleSubmit(massiveUpload)">
            <v-row>
              <v-col align="end">
                <x-generate-instructive class="mr-2"/>
                <x-generate-template :emplooyes="evaluation.evaluated" :edit="evaluation.edit"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <x-file-upload
                  class="mt-1"
                  v-model="file"
                  @file-picked="filePicked($event)"
                  :label="$t('employees.select_file_to_upload')"
                  reff="employees-massive-upload"
                  :extensions="extensions"
                  name="employees-file"
                  :help="$t('help.enterprise.massive.file_input')"
                  error-messages="error"
                  :rules="'ext:csv,xls,xlsx'"
                ></x-file-upload>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-btn
                    block
                    large
                    @click="changeStep(true)"
                >{{ $t(prevAction) }}</v-btn>
              </v-col>
              <v-col cols="12" sm="6">
                <v-btn
                  color="primary"
                  block
                  large
                  type="submit"
                >{{$t('input.upload_file')}}</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </ValidationObserver>
      </template>
      <template v-else-if="isMassive && reviewMassive">
        <v-row>
          <v-col cols="12">
            <v-alert
              v-if="errors.evaluatedNotFound.length ||
              errors.evaluatedDuplicated.length ||
              errors.evaluatorDuplicated.length ||
              errors.evaluatedEvaluator.length ||
              errors.evaluatorNotFound.length ||
              errors.evaluatorTypeError.length"
              type="warning"
              prominent
              dismissible
            >
              <v-row align="center">
                <v-col class="grow"><strong>{{$t('input.warning')}}:</strong> {{$t('evaluations.warning_text')}}</v-col>
                <v-col class="shrink">
                  <v-btn small text
                    @click="showModalWarnings"
                  >{{$t('input.see_warnings')}}</v-btn>
                </v-col>
              </v-row>
            </v-alert>
          </v-col>
        </v-row>
        <x-evaluated-table
          :evaluated="evaluation.evaluated"
          @showList="showList"
          @edit="(item) => editEvaluated = item"
          @delete="openModal"
        ></x-evaluated-table>
        <v-row>
          <v-col cols="12" sm="6">
            <v-btn block large
              @click="backToMassive"
            >
              {{ $t('input.cancel') }}
            </v-btn>
          </v-col>
          <v-col cols="12" sm="6">
            <v-btn block large
              color="primary"
              @click="changeStep(false)"
            >
              {{ $t(nextAction) }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </v-card>
    <x-evaluators-dialog
      v-if="showEvaluatedList"
      :evaluation="evaluation"
      :evaluated-list="evaluatedList"
      @hideList="hideList"
    ></x-evaluators-dialog>
    <x-warnings-dialog
      :errors="errors"
      v-if="modalWarnings"
      @hideModalWarnings="hideModalWarnings"
    ></x-warnings-dialog>
    <x-errors-dialog
      :errors="errors"
      v-if="modalErrors"
      @hideModalErrors="hideModalErrors"
    ></x-errors-dialog>
    <x-add-evaluator-dialog
      v-if="addEvaluator"
      :employees="employees"
      :evaluation="evaluation"
      @closeDialog="addEvaluator = false"
      @pushEvaluator="pushEvaluator"
    ></x-add-evaluator-dialog>
    <x-add-evaluator-dialog
      v-if="editEvaluated"
      :employees="employees"
      :evaluation="evaluation"
      :editEvaluated="editEvaluated"
      @closeDialog="editEvaluated = null"
      @pushEvaluator="editEvaluators"
    ></x-add-evaluator-dialog>
    <x-confirmation-modal
      :show.sync="modalSkipWarnings"
      :reversible="true"
      :title="$t('evaluations.are_you_sure_continue_warnings')"
      :action="continueFill"
      @close="modalSkipWarnings = false"
    >
      <template v-slot:question> {{$t('evaluations.remember_warnings_msg')}} </template>
    </x-confirmation-modal>

    <x-confirmation-modal
      :show="modalDel.open"
      :title="$t('evaluations.modal_del_title')"
      :action="deleteEvaluated"
      :btn-save="$t('input.trash')"
      color="error"
      @close="modalDel.open = false"
    >
        <template v-slot:question>{{ $t('evaluations.modal_del_question') }}</template>
    </x-confirmation-modal>
  </v-container>
</template>

<style lang="scss" scoped>
  .text-recomendations {
    font-size: 13.5px;
    text-align: justify;
  }
</style>

<script>
import Vue from 'vue'

import evaluationsService from '../../../services/evaluations'

import XEvaluatedTable from '../components/evaluated-table.vue'
import XWarningsDialog from '../components/warnings-dialog.vue'
import XErrorsDialog from '../components/errors-dialog.vue'
import XAddEvaluatorDialog from '../components/add-evaluator-dialog.vue'
import XEvaluatorsDialog from '../components/evaluators-dialog.vue'
import XGenerateInstructive from '../components/generate-instructive.vue'
import XGenerateTemplate from '../components/generate-template.vue'

export default Vue.extend({
  components: {
    XEvaluatedTable,
    XWarningsDialog,
    XErrorsDialog,
    XEvaluatorsDialog,
    XGenerateInstructive,
    XGenerateTemplate,
    XAddEvaluatorDialog
  },
  props: {
    evaluation: Object,
    step: String,
    nextAction: String,
    prevAction: String,
    employees: Array
  },
  data () {
    return {
      leadersAvailable: [[]],
      pairsAvailable: [[]],
      dependentsAvailable: [[]],
      file: '',
      extensions: ['.xls', '.xlsx', '.csv'],
      // baseEmployees: null,
      evaluated: null,
      showEvaluatedList: false,
      evaluatedList: null,
      modalWarnings: false,
      modalErrors: false,
      modalSkipWarnings: false,
      isContinueFill: false,
      addEvaluator: false,
      editEvaluated: null,
      modalDel: {
        open: false,
        item: null
      },
      errors: {},
      isMassive: false,
      reviewMassive: false
    }
  },
  created () {
    // this.baseEmployees = this.employees
    // this.reviewMassive = false
  },
  computed: {
    canEdit () {
      return !this.evaluation.status || this.evaluation.status === 'pending' || this.evaluation.status === 'in_progress'
    }
  },
  methods: {
    pushEvaluator (evaluated) {
      this.evaluation.evaluated.push(evaluated)
      this.addEvaluator = false
    },
    editEvaluators (evaluated) {
      this.editEvaluated.leadersEvaluators = evaluated.leadersEvaluators
      this.editEvaluated.dependentsEvaluators = evaluated.dependentsEvaluators
      this.editEvaluated.pairsEvaluators = evaluated.pairsEvaluators
      this.editEvaluated = null
    },
    changeStep (isBack = false) {
      if (this.reviewMassive && !this.isContinueFill && !isBack) {
        if (this.errors.evaluatedNotFound.length ||
            this.errors.evaluatedDuplicated.length ||
            this.errors.evaluatorDuplicated.length ||
            this.errors.evaluatedEvaluator.length ||
            this.errors.evaluatorNotFound.length ||
            this.errors.evaluatorTypeError.length) {
          this.modalSkipWarnings = true
        } else {
          this.$emit('changeStep', this.evaluation, isBack ? +this.step - 1 : +this.step + 1)
        }
      } else {
        this.$emit('changeStep', this.evaluation, isBack ? +this.step - 1 : +this.step + 1)
      }
    },
    continueFill () {
      this.isContinueFill = true
      this.changeStep(false)
    },
    backToMassive () {
      this.evaluation.evaluated = []
      this.reviewMassive = false
    },
    deleteEvaluated () {
      this.evaluation.evaluated.splice(this.modalDel.item, 1)
      return Promise.resolve()
    },
    massiveUpload () {
      this.$store.dispatch('loading/show')
      if (!this.file) {
        this.$store.dispatch('alert/error', this.$t('evaluations.incorrect_file'))
        this.$store.dispatch('loading/hide')
      } else {
        return evaluationsService.massiveUpload(this.file)
          .then((res) => {
            this.reviewMassive = true
            this.updateListFromFile(res)
            this.errors = res.errors
            this.$store.dispatch('loading/hide')
          }).catch(err => {
            console.log(err)
          })
      }
    },
    updateListFromFile (res) {
      if (this.evaluation.edit && this.isMassive) {
        this.$emit('methodCreate', true)
      }
      this.evaluation.evaluated = this.assembleEvaluated(res.evaluated)
    },
    assembleEvaluated (evals) {
      evals.forEach((evaluated) => {
        // Evaluated
        evaluated.employee.employee = {
          text: `${evaluated.employee.employee.firstName} ${evaluated.employee.employee.lastName}`,
          value: evaluated.employee.employee.id,
          id: evaluated.employee.employee.id,
          firstName: evaluated.employee.employee.firstName,
          lastName: evaluated.employee.employee.lastName,
          employee: { id: evaluated.employee.employee.employee.id }
        }
        // Leaders
        evaluated.leadersEvaluators = evaluated.leadersEvaluators.map((leader) => {
          return {
            text: `${leader.firstName} ${leader.lastName}`,
            value: leader.id,
            id: leader.id,
            firstName: leader.firstName,
            lastName: leader.lastName,
            employee: { id: leader.employee.id }
          }
        })
        // Colaborators
        evaluated.dependentsEvaluators = evaluated.dependentsEvaluators.map((dependent) => {
          return {
            text: `${dependent.firstName} ${dependent.lastName}`,
            value: dependent.id,
            id: dependent.id,
            firstName: dependent.firstName,
            lastName: dependent.lastName,
            employee: { id: dependent.employee.id }
          }
        })
        // Pairs
        evaluated.pairsEvaluators = evaluated.pairsEvaluators.map((pair) => {
          return {
            text: `${pair.firstName} ${pair.lastName}`,
            value: pair.id,
            id: pair.id,
            firstName: pair.firstName,
            lastName: pair.lastName,
            employee: { id: pair.employee.id }
          }
        })
      })

      return evals
    },
    showList (evaluated) {
      this.evaluatedList = evaluated
      this.showEvaluatedList = true
    },
    hideList () {
      this.evaluatedList = null
      this.showEvaluatedList = false
    },
    showModalWarnings () {
      this.modalWarnings = true
    },
    showModalErrors () {
      this.modalErrors = true
    },
    hideModalWarnings () {
      this.modalWarnings = false
    },
    hideModalErrors () {
      this.modalErrors = false
    },
    addEvaluated () {
      this.addEvaluator = true
    },
    filePicked (e) {
      this.file = e
    },
    openModal (index) {
      this.modalDel.item = index
      this.modalDel.open = true
    }
  }
})
</script>
