// TODO
/*
  Gráfica por rango
  10% superior por dimensión. listo
  Dispersión por responsabilidad. listo
  Probar puntaje anterior y tendencia
  Ranking tendencia
  Tabla de respuestas. listo
*/
import evaluationService from '../../../services/evaluations'
export default {
  methods: {
    setScores (res) {
      let count = 0
      let population = 0
      let scores = []
      let noAutoScores = []
      let noAutoPopulation = 0
      let leadersScores = []
      let leadersPopulation = 0
      let pairsScores = []
      let pairsPopulation = 0
      let dependentsScores = []
      let dependentsPopulation = 0
      let scoresResponsability = []
      let noAutoScoresResponsability = []
      let leadersResponsability = []
      let pairsResponsability = []
      let dependentsResponsability = []
      let headers = ['as_person', 'management', 'mobilization', 'achievement']
      const generalEvaluation = res.questionnaire.evaluations.generalEvaluation
      // Inicializando objetos
      for (let i = 0; i < headers.length; i++) {
        scores[i] = { name: headers[i], questions: [], total: 0, individualScores: [], tenPercent: 0, questionsScores: [] }
        scoresResponsability[i] = { name: headers[i], responsability: [] }
        noAutoScores[i] = { name: headers[i], questions: [], total: 0, individualScores: [], tenPercent: 0 }
        noAutoScoresResponsability[i] = { name: headers[i], responsability: [] }
        leadersScores[i] = { name: headers[i], questions: [], total: 0, individualScores: [], tenPercent: 0 }
        leadersResponsability[i] = { name: headers[i], responsability: [] }
        pairsScores[i] = { name: headers[i], questions: [], total: 0, individualScores: [], tenPercent: 0 }
        pairsResponsability[i] = { name: headers[i], responsability: [] }
        dependentsScores[i] = { name: headers[i], questions: [], total: 0, individualScores: [], tenPercent: 0 }
        dependentsResponsability[i] = { name: headers[i], responsability: [] }
        if (i === 0) {
          // as_person
          for (const j of generalEvaluation[i].responsability) {
            scoresResponsability[i].responsability[0] = { name: j[this.user.lang], score: 0, index: 1, questions: [], nullCount: 0, dispersion: 0, tenPercent: 0, individualScores: [], questionsScores: [] }
            noAutoScoresResponsability[i].responsability[0] = { name: j[this.user.lang], score: 0, index: 1, questions: [], nullCount: 0, dispersion: 0, tenPercent: 0, individualScores: [] }
            leadersResponsability[i].responsability[0] = { name: j[this.user.lang], score: 0, index: 1, questions: [], nullCount: 0, dispersion: 0, tenPercent: 0, individualScores: [] }
            pairsResponsability[i].responsability[0] = { name: j[this.user.lang], score: 0, index: 1, questions: [], nullCount: 0, dispersion: 0, tenPercent: 0, individualScores: [] }
            dependentsResponsability[i].responsability[0] = { name: j[this.user.lang], score: 0, index: 1, questions: [], nullCount: 0, dispersion: 0, tenPercent: 0, individualScores: [] }
            for (let k = 0; k < 10; k++) {
              scoresResponsability[i].responsability[0].questions.push(k + 1)
              noAutoScoresResponsability[i].responsability[0].questions.push(k + 1)
              leadersResponsability[i].responsability[0].questions.push(k + 1)
              pairsResponsability[i].responsability[0].questions.push(k + 1)
              dependentsResponsability[i].responsability[0].questions.push(k + 1)
              scores[i].questions[k] = { score: 0, index: k + 1, question: j.questions[k][this.user.lang], tenPercent: 0, nullCount: 0, dispersion: 0, responsability: j[this.user.lang], individualScores: [] }
              noAutoScores[i].questions[k] = { score: 0, index: k + 1, question: j.questions[k][this.user.lang], tenPercent: 0, nullCount: 0, dispersion: 0, responsability: j[this.user.lang], individualScores: [] }
              leadersScores[i].questions[k] = { score: 0, index: k + 1, question: j.questions[k][this.user.lang], tenPercent: 0, nullCount: 0, dispersion: 0, responsability: j[this.user.lang], individualScores: [] }
              pairsScores[i].questions[k] = { score: 0, index: k + 1, question: j.questions[k][this.user.lang], tenPercent: 0, nullCount: 0, dispersion: 0, responsability: j[this.user.lang], individualScores: [] }
              dependentsScores[i].questions[k] = { score: 0, index: k + 1, question: j.questions[k][this.user.lang], tenPercent: 0, nullCount: 0, dispersion: 0, responsability: j[this.user.lang], individualScores: [] }
            }
          }
        } else {
          // management, mobilization, achievement
          let counter = 0
          for (const j of generalEvaluation[i].responsability) {
            scoresResponsability[i].responsability[j.index - 1] = { name: j[this.user.lang], score: 0, index: j.index, questions: [], nullCount: 0, dispersion: 0, tenPercent: 0, individualScores: [], questionsScores: [] }
            noAutoScoresResponsability[i].responsability[j.index - 1] = { name: j[this.user.lang], score: 0, index: j.index, questions: [], nullCount: 0, dispersion: 0, tenPercent: 0, individualScores: [] }
            leadersResponsability[i].responsability[j.index - 1] = { name: j[this.user.lang], score: 0, index: j.index, questions: [], nullCount: 0, dispersion: 0, tenPercent: 0, individualScores: [] }
            pairsResponsability[i].responsability[j.index - 1] = { name: j[this.user.lang], score: 0, index: j.index, questions: [], nullCount: 0, dispersion: 0, tenPercent: 0, individualScores: [] }
            dependentsResponsability[i].responsability[j.index - 1] = { name: j[this.user.lang], score: 0, index: j.index, questions: [], nullCount: 0, dispersion: 0, tenPercent: 0, individualScores: [] }
            for (let k = 0; k < j.questions.length; k++) {
              scoresResponsability[i].responsability[j.index - 1].questions.push(counter + 1)
              noAutoScoresResponsability[i].responsability[j.index - 1].questions.push(counter + 1)
              leadersResponsability[i].responsability[j.index - 1].questions.push(counter + 1)
              pairsResponsability[i].responsability[j.index - 1].questions.push(counter + 1)
              dependentsResponsability[i].responsability[j.index - 1].questions.push(counter + 1)
              scores[i].questions[counter] = { score: 0, index: counter + 1, question: j.questions[k][this.user.lang], tenPercent: 0, nullCount: 0, dispersion: 0, responsability: j[this.user.lang], individualScores: [] }
              noAutoScores[i].questions[counter] = { score: 0, index: counter + 1, question: j.questions[k][this.user.lang], tenPercent: 0, nullCount: 0, dispersion: 0, responsability: j[this.user.lang], individualScores: [] }
              leadersScores[i].questions[counter] = { score: 0, index: counter + 1, question: j.questions[k][this.user.lang], tenPercent: 0, nullCount: 0, dispersion: 0, responsability: j[this.user.lang], individualScores: [] }
              pairsScores[i].questions[counter] = { score: 0, index: counter + 1, question: j.questions[k][this.user.lang], tenPercent: 0, nullCount: 0, dispersion: 0, responsability: j[this.user.lang], individualScores: [] }
              dependentsScores[i].questions[counter] = { score: 0, index: counter + 1, question: j.questions[k][this.user.lang], tenPercent: 0, nullCount: 0, dispersion: 0, responsability: j[this.user.lang], individualScores: [] }
              counter++
            }
          }
        }
      }
      let answersDimention = []
      let arrayEvaluators = []
      let autoevaluationCompleted = false
      const keysName = ['employee', 'leadersEvaluators', 'dependentsEvaluators', 'pairsEvaluators']
      // Recorrer cada evaluado para sacar los promedios
      for (let index = 0; index < res.evaluated.length; index++) {
        for (let key of keysName) {
          if (res.evaluated[index][key].length === undefined) { // Para autoevaluación
            this.expectedPolls.auto++
            if (res.evaluated[index][key].completed) {
              autoevaluationCompleted = true
              this.completedPolls.auto++
              answersDimention = res.evaluated[index][key].answersDimention
              count = 0
              for (const j of answersDimention) {
                let counter = 0
                let respCount = 0
                let dimensionTotal = 0
                for (const k of j.responsability) {
                  let respTotal = 0
                  let nullCount = 0
                  for (let l = 0; l < k.questions.length; l++) {
                    if (k.questions[l].score !== -1) {
                      scores[count].questions[counter].score += k.questions[l].score
                      respTotal += k.questions[l].score
                      scores[count].questions[counter].individualScores.push(k.questions[l].score)
                      scoresResponsability[count].responsability[respCount].questionsScores.push(k.questions[l].score)
                      scores[count].questionsScores.push(k.questions[l].score)
                    } else {
                      scores[count].questions[counter].nullCount += 1
                      nullCount++
                    }
                    counter++
                  }
                  if (k.questions.length - nullCount > 0) {
                    scoresResponsability[count].responsability[respCount].individualScores.push(respTotal / (k.questions.length - nullCount))
                    dimensionTotal += (respTotal / (k.questions.length - nullCount))
                  }
                  respCount++
                }
                scores[count].individualScores.push(dimensionTotal / j.responsability.length)
                count++
              }
              population++
            }
          } else { // Para el resto (lideres, pares, colaboradores)
            arrayEvaluators = res.evaluated[index][key]
            for (const i of arrayEvaluators) {
              if (key === 'leadersEvaluators') {
                this.expectedPolls.leaders++
                if (i.completed) {
                  this.completedPolls.leaders++
                  answersDimention = i.answersDimention
                  count = 0
                  if (autoevaluationCompleted) {
                    for (const j of answersDimention) {
                      let counter = 0
                      let respCount = 0
                      for (const k of j.responsability) {
                        let respTotal = 0
                        let nullCount = 0
                        for (let l = 0; l < k.questions.length; l++) {
                          if (k.questions[l].score !== -1) {
                            leadersScores[count].questions[counter].score += k.questions[l].score
                            respTotal += k.questions[l].score
                            leadersScores[count].questions[counter].individualScores.push(k.questions[l].score)
                          } else {
                            leadersScores[count].questions[counter].nullCount += 1
                            nullCount++
                          }
                          counter++
                        }
                        if (k.questions.length - nullCount > 0) {
                          leadersResponsability[count].responsability[respCount].individualScores.push(respTotal / (k.questions.length - nullCount))
                        }
                        respCount++
                      }
                      count++
                    }
                    leadersPopulation++
                  }
                }
              } else if (key === 'pairsEvaluators') {
                this.expectedPolls.pairs++
                if (i.completed) {
                  this.completedPolls.pairs++
                  answersDimention = i.answersDimention
                  count = 0
                  if (autoevaluationCompleted) {
                    for (const j of answersDimention) {
                      let counter = 0
                      let respCount = 0
                      for (const k of j.responsability) {
                        let respTotal = 0
                        let nullCount = 0
                        for (let l = 0; l < k.questions.length; l++) {
                          if (k.questions[l].score !== -1) {
                            pairsScores[count].questions[counter].score += k.questions[l].score
                            respTotal += k.questions[l].score
                            pairsScores[count].questions[counter].individualScores.push(k.questions[l].score)
                          } else {
                            pairsScores[count].questions[counter].nullCount += 1
                            nullCount++
                          }
                          counter++
                        }
                        if (k.questions.length - nullCount > 0) {
                          pairsResponsability[count].responsability[respCount].individualScores.push(respTotal / (k.questions.length - nullCount))
                        }
                        respCount++
                      }
                      count++
                    }
                    pairsPopulation++
                  }
                }
              } else {
                this.expectedPolls.dependents++
                if (i.completed) {
                  this.completedPolls.dependents++
                  answersDimention = i.answersDimention
                  count = 0
                  if (autoevaluationCompleted) {
                    for (const j of answersDimention) {
                      let counter = 0
                      let respCount = 0
                      for (const k of j.responsability) {
                        let respTotal = 0
                        let nullCount = 0
                        for (let l = 0; l < k.questions.length; l++) {
                          if (k.questions[l].score !== -1) {
                            dependentsScores[count].questions[counter].score += k.questions[l].score
                            respTotal += k.questions[l].score
                            dependentsScores[count].questions[counter].individualScores.push(k.questions[l].score)
                          } else {
                            dependentsScores[count].questions[counter].nullCount += 1
                            nullCount++
                          }
                          counter++
                        }
                        if (k.questions.length - nullCount > 0) {
                          dependentsResponsability[count].responsability[respCount].individualScores.push(respTotal / (k.questions.length - nullCount))
                        }
                        respCount++
                      }
                      count++
                    }
                    dependentsPopulation++
                  }
                }
              }
              // Total promedio de cada pregunta incluyendo autoevaluación.
              if (i.completed) {
                answersDimention = i.answersDimention
                count = 0
                if (autoevaluationCompleted) {
                  for (const j of answersDimention) {
                    let counter = 0
                    let respCount = 0
                    let dimensionTotal = 0
                    for (const k of j.responsability) {
                      let respTotal = 0
                      let nullCount = 0
                      for (let l = 0; l < k.questions.length; l++) {
                        if (k.questions[l].score !== -1) {
                          scores[count].questions[counter].score += k.questions[l].score
                          respTotal += k.questions[l].score
                          scores[count].questions[counter].individualScores.push(k.questions[l].score)
                          scoresResponsability[count].responsability[respCount].questionsScores.push(k.questions[l].score)
                          scores[count].questionsScores.push(k.questions[l].score)
                        } else {
                          scores[count].questions[counter].nullCount += 1
                          nullCount++
                        }
                        counter++
                      }
                      if (k.questions.length - nullCount > 0) {
                        scoresResponsability[count].responsability[respCount].individualScores.push(respTotal / (k.questions.length - nullCount))
                        dimensionTotal += respTotal / (k.questions.length - nullCount)
                      }
                      respCount++
                    }
                    scores[count].individualScores.push(dimensionTotal / j.responsability.length)
                    count++
                  }
                  population++
                }
                // Total promedio de cada pregunta sin autoevaluación.
                count = 0
                if (autoevaluationCompleted) {
                  for (const j of answersDimention) {
                    let counter = 0
                    let respCount = 0
                    for (const k of j.responsability) {
                      let respTotal = 0
                      let nullCount = 0
                      for (let l = 0; l < k.questions.length; l++) {
                        if (k.questions[l].score !== -1) {
                          noAutoScores[count].questions[counter].score += k.questions[l].score
                          respTotal += k.questions[l].score
                          noAutoScores[count].questions[counter].individualScores.push(k.questions[l].score)
                        } else {
                          noAutoScores[count].questions[counter].nullCount += 1
                          nullCount++
                        }
                        counter++
                      }
                      if (k.questions.length - nullCount) {
                        noAutoScoresResponsability[count].responsability[respCount].individualScores.push(respTotal / (k.questions.length - nullCount))
                      }
                      respCount++
                    }
                    count++
                  }
                  noAutoPopulation++
                }
              }
            }
          }
        }
      }

      this.leadersPopulation = leadersPopulation
      this.pairsPopulation = pairsPopulation
      this.dependentsPopulation = dependentsPopulation
      for (let i = 0; i < scores.length; i++) {
        let acumDimension = 0
        let noAutoAcumDimension = 0
        let leadersAcumDimension = 0
        let pairsAcumDimension = 0
        let dependentsAcumDimension = 0
        let scoresCount = 0
        let noAutoCount = 0
        let leadersCount = 0
        let pairsCount = 0
        let dependentsCount = 0
        for (let j = 0; j < scores[i].questions.length; j++) {
          // Promedio de todos incluyendo autoevaluación
          if ((population - scores[i].questions[j].nullCount) === 0) {
            scores[i].questions[j].score = 0
          } else {
            scores[i].questions[j].score = scores[i].questions[j].score / (population - scores[i].questions[j].nullCount)
            scoresCount++
          }
          acumDimension += scores[i].questions[j].score
          // Promedio de todos sin autoevaluación
          if ((noAutoPopulation - noAutoScores[i].questions[j].nullCount) === 0) {
            noAutoScores[i].questions[j].score = 0
          } else {
            noAutoScores[i].questions[j].score = noAutoScores[i].questions[j].score / (noAutoPopulation - noAutoScores[i].questions[j].nullCount)
            noAutoCount++
          }
          noAutoAcumDimension += noAutoScores[i].questions[j].score
          // Lideres
          if ((leadersPopulation - leadersScores[i].questions[j].nullCount) === 0) {
            leadersScores[i].questions[j].score = 0
          } else {
            leadersScores[i].questions[j].score = leadersScores[i].questions[j].score / (leadersPopulation - leadersScores[i].questions[j].nullCount)
            leadersCount++
          }
          leadersAcumDimension += leadersScores[i].questions[j].score
          // Pares
          if ((pairsPopulation - pairsScores[i].questions[j].nullCount) === 0) {
            pairsScores[i].questions[j].score = 0
          } else {
            pairsScores[i].questions[j].score = pairsScores[i].questions[j].score / (pairsPopulation - pairsScores[i].questions[j].nullCount)
            pairsCount++
          }
          pairsAcumDimension += pairsScores[i].questions[j].score
          // Colaboradores
          if ((dependentsPopulation - dependentsScores[i].questions[j].nullCount) === 0) {
            dependentsScores[i].questions[j].score = 0
          } else {
            dependentsScores[i].questions[j].score = dependentsScores[i].questions[j].score / (dependentsPopulation - dependentsScores[i].questions[j].nullCount)
            dependentsCount++
          }
          dependentsAcumDimension += dependentsScores[i].questions[j].score

          scores[i].questions[j].tenPercent = this.calculateTenPercent(scores[i].questions[j].individualScores)
          noAutoScores[i].questions[j].tenPercent = this.calculateTenPercent(noAutoScores[i].questions[j].individualScores)
          leadersScores[i].questions[j].tenPercent = this.calculateTenPercent(leadersScores[i].questions[j].individualScores)
          pairsScores[i].questions[j].tenPercent = this.calculateTenPercent(pairsScores[i].questions[j].individualScores)
          dependentsScores[i].questions[j].tenPercent = this.calculateTenPercent(dependentsScores[i].questions[j].individualScores)
          // ------------------------------ DISPERSIÓN, SUMATORIA -------------------------------------------
          for (let k = 0; k < scores[i].questions[j].individualScores.length; k++) {
            scores[i].questions[j].dispersion += Math.pow(scores[i].questions[j].individualScores[k] - scores[i].questions[j].score, 2)
          }
        }
        // ---------------------------------- DISPERSIÓN TOTAL ------------------------------------------------
        for (let j = 0; j < scores[i].questions.length; j++) {
          // Promedio de todos
          if ((population - scores[i].questions[j].nullCount) === 0) {
            scores[i].questions[j].dispersion = 0
          } else {
            scores[i].questions[j].dispersion = Math.sqrt(scores[i].questions[j].dispersion / (population - scores[i].questions[j].nullCount))
          }
        }
        // ----------------------------------------------------------------------------------------------------
        for (let resp = 0; resp < scoresResponsability[i].responsability.length; resp++) {
          for (let sc = 0; sc < scoresResponsability[i].responsability[resp].questions.length; sc++) {
            if (scores[i].questions[scoresResponsability[i].responsability[resp].questions[sc] - 1].score === 0) {
              scoresResponsability[i].responsability[resp].nullCount++
            }
            scoresResponsability[i].responsability[resp].score += scores[i].questions[scoresResponsability[i].responsability[resp].questions[sc] - 1].score
            if (noAutoScores[i].questions[noAutoScoresResponsability[i].responsability[resp].questions[sc] - 1].score === 0) {
              noAutoScoresResponsability[i].responsability[resp].nullCount++
            }
            noAutoScoresResponsability[i].responsability[resp].score += noAutoScores[i].questions[noAutoScoresResponsability[i].responsability[resp].questions[sc] - 1].score
            if (leadersScores[i].questions[leadersResponsability[i].responsability[resp].questions[sc] - 1].score === 0) {
              leadersResponsability[i].responsability[resp].nullCount++
            }
            leadersResponsability[i].responsability[resp].score += leadersScores[i].questions[leadersResponsability[i].responsability[resp].questions[sc] - 1].score
            if (pairsScores[i].questions[pairsResponsability[i].responsability[resp].questions[sc] - 1].score === 0) {
              pairsResponsability[i].responsability[resp].nullCount++
            }
            pairsResponsability[i].responsability[resp].score += pairsScores[i].questions[pairsResponsability[i].responsability[resp].questions[sc] - 1].score
            if (dependentsScores[i].questions[dependentsResponsability[i].responsability[resp].questions[sc] - 1].score === 0) {
              dependentsResponsability[i].responsability[resp].nullCount++
            }
            dependentsResponsability[i].responsability[resp].score += dependentsScores[i].questions[dependentsResponsability[i].responsability[resp].questions[sc] - 1].score
          }
          scoresResponsability[i].responsability[resp].score = scoresResponsability[i].responsability[resp].questions.length - scoresResponsability[i].responsability[resp].nullCount > 0
            ? scoresResponsability[i].responsability[resp].score / (scoresResponsability[i].responsability[resp].questions.length - scoresResponsability[i].responsability[resp].nullCount) : '--'
          noAutoScoresResponsability[i].responsability[resp].score = noAutoScoresResponsability[i].responsability[resp].questions.length - noAutoScoresResponsability[i].responsability[resp].nullCount > 0
            ? noAutoScoresResponsability[i].responsability[resp].score / (noAutoScoresResponsability[i].responsability[resp].questions.length - noAutoScoresResponsability[i].responsability[resp].nullCount) : '--'
          leadersResponsability[i].responsability[resp].score = leadersResponsability[i].responsability[resp].questions.length - leadersResponsability[i].responsability[resp].nullCount > 0
            ? leadersResponsability[i].responsability[resp].score / (leadersResponsability[i].responsability[resp].questions.length - leadersResponsability[i].responsability[resp].nullCount) : '--'
          pairsResponsability[i].responsability[resp].score = pairsResponsability[i].responsability[resp].questions.length - pairsResponsability[i].responsability[resp].nullCount > 0
            ? pairsResponsability[i].responsability[resp].score / (pairsResponsability[i].responsability[resp].questions.length - pairsResponsability[i].responsability[resp].nullCount) : '--'
          dependentsResponsability[i].responsability[resp].score = dependentsResponsability[i].responsability[resp].questions.length - dependentsResponsability[i].responsability[resp].nullCount > 0
            ? dependentsResponsability[i].responsability[resp].score / (dependentsResponsability[i].responsability[resp].questions.length - dependentsResponsability[i].responsability[resp].nullCount) : '--'
          scoresResponsability[i].responsability[resp].tenPercent = this.calculateTenPercent(scoresResponsability[i].responsability[resp].individualScores)
          noAutoScoresResponsability[i].responsability[resp].tenPercent = this.calculateTenPercent(noAutoScoresResponsability[i].responsability[resp].individualScores)
          leadersResponsability[i].responsability[resp].tenPercent = this.calculateTenPercent(leadersResponsability[i].responsability[resp].individualScores)
          pairsResponsability[i].responsability[resp].tenPercent = this.calculateTenPercent(pairsResponsability[i].responsability[resp].individualScores)
          dependentsResponsability[i].responsability[resp].tenPercent = this.calculateTenPercent(dependentsResponsability[i].responsability[resp].individualScores)
          // DISPERSIÓN POR RESPONSABILIDAD, SUMATORIA
          for (let j = 0; j < scoresResponsability[i].responsability[resp].individualScores.length; j++) {
            scoresResponsability[i].responsability[resp].dispersion += Math.pow(scoresResponsability[i].responsability[resp].individualScores[j] - scoresResponsability[i].responsability[resp].score, 2)
          }
        }
        // DISPERSIÓN POR RESPONSABILIDAD
        for (let resp = 0; resp < scoresResponsability[i].responsability.length; resp++) {
          if ((scoresResponsability[i].responsability[resp].questions.length - scoresResponsability[i].responsability[resp].nullCount) === 0) {
            scoresResponsability[i].responsability[resp].dispersion = 0
          } else {
            scoresResponsability[i].responsability[resp].dispersion = Math.sqrt(scoresResponsability[i].responsability[resp].dispersion / (scoresResponsability[i].responsability[resp].individualScores.length - scoresResponsability[i].responsability[resp].nullCount))
          }
        }
        scores[i].total = acumDimension / scoresCount
        noAutoScores[i].total = noAutoAcumDimension / noAutoCount
        leadersScores[i].total = leadersAcumDimension / leadersCount
        pairsScores[i].total = pairsAcumDimension / pairsCount
        dependentsScores[i].total = dependentsAcumDimension / dependentsCount

        scores[i].tenPercent = this.calculateTenPercent(scores[i].individualScores)
      }

      this.averageScores = scores
      this.noAutoScores = noAutoScores
      this.leadersScores = leadersScores
      this.pairsScores = pairsScores
      this.dependentsScores = dependentsScores
      this.averageResponsability = scoresResponsability
      this.noAutoScoresResponsability = noAutoScoresResponsability
      this.leadersResponsability = leadersResponsability
      this.pairsResponsability = pairsResponsability
      this.dependentsResponsability = dependentsResponsability
    },
    async $getInitialData (id) {
      // let data = {
      //   product: this.productService.productId,
      //   service: this.productService.productServiceId,
      //   qty: 1
      // }
      // await evaluationService.getOrganizationalReport(id, data)
      await evaluationService.getOrganizationalReport(id)
        .then((res) => {
          this.currentEvaluation = res
        })
        .catch((err) => {
          console.log(err)
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        })
      this.completedPolls = {
        auto: 0,
        leaders: 0,
        pairs: 0,
        dependents: 0
      }
      this.expectedPolls = {
        auto: 0,
        leaders: 0,
        pairs: 0,
        dependents: 0
      }
      this.setScores(this.currentEvaluation)
    },
    async $getPreviousData (id) {
      let previousEvaluation = await evaluationService.getPrevious(id)
      if (!previousEvaluation || previousEvaluation.status || previousEvaluation.length === 0) {
        this.hasPrevious = false
      } else {
        this.hasPrevious = true
        this.$setPreviousScore(previousEvaluation[0])
      }
      this.getResults()
    },
    calculateTenPercent (individualScores) {
      const total = individualScores.sort((a, b) => b - a).length
      if (!total) {
        return '--'
      }
      let tenPercent = Math.ceil(total * 0.10)
      let average = 0
      for (let t = 0; t < tenPercent; t++) {
        average += individualScores[t]
      }
      return Math.round((average / tenPercent) * 100) / 100
    },
    getResults () {
      this.othersRank = []
      this.noAutoOthersRank = []
      this.leadersRank = []
      this.pairsRank = []
      this.dependentsRank = []
      this.othersRespRank = []
      this.noAutoOthersRespRank = []
      this.leadersRespRank = []
      this.pairsRespRank = []
      this.dependentsRespRank = []
      for (let i = 0; i < this.averageScores.length; i++) {
        for (let j = 0; j < this.averageScores[i].questions.length; j++) {
          // Otros
          let obj = {}
          const baseObj = {
            process: '--',
            abbreviation: '--',
            score: '--',
            question: '--',
            dispersion: '--',
            tenPercent: '--',
            previous: '--',
            trend: '--',
            responsability: '--'
          }
          if (this.averageScores[i].questions[j].score !== 0) {
            obj = {
              process: this.averageScores[i].name,
              abbreviation: this.averageScores[i].name === 'as_person' ? 'P' : this.averageScores[i].name === 'management' ? 'D' : this.averageScores[i].name === 'mobilization' ? 'M' : 'L',
              score: this.averageScores[i].questions[j].score,
              question: this.averageScores[i].questions[j].question,
              tenPercent: this.averageScores[i].questions[j].tenPercent,
              dispersion: this.averageScores[i].questions[j].dispersion,
              previous: 0,
              trend: 0,
              responsability: this.averageScores[i].questions[j].responsability
            }
            if (this.hasPrevious) {
              obj.previous = this.previousAverageScores[i].questions[j].score > 0 ? this.previousAverageScores[i].questions[j].score : '--'
              obj.trend = this.previousAverageScores[i].questions[j].score > 0 ? this.averageScores[i].questions[j].score - this.previousAverageScores[i].questions[j].score : '--'
            }
          }
          this.othersRank.push(obj)
          if (this.noAutoScores[i].questions[j].score !== 0) {
            obj = {
              process: this.noAutoScores[i].name,
              abbreviation: this.noAutoScores[i].name === 'as_person' ? 'P' : this.noAutoScores[i].name === 'management' ? 'D' : this.noAutoScores[i].name === 'mobilization' ? 'M' : 'L',
              score: this.noAutoScores[i].questions[j].score,
              question: this.noAutoScores[i].questions[j].question,
              tenPercent: this.noAutoScores[i].questions[j].tenPercent,
              dispersion: this.noAutoScores[i].questions[j].dispersion,
              previous: 0,
              trend: 0,
              responsability: this.noAutoScores[i].questions[j].responsability
            }
            if (this.hasPrevious) {
              obj.previous = this.previousNoAutoScores[i].questions[j].score > 0 ? this.previousNoAutoScores[i].questions[j].score : '--'
              obj.trend = this.previousNoAutoScores[i].questions[j].score > 0 ? this.noAutoScores[i].questions[j].score - this.previousNoAutoScores[i].questions[j].score : '--'
            }
            this.noAutoOthersRank.push(obj)
          } else {
            this.noAutoOthersRank.push(baseObj)
          }
          // Por Líder
          if (this.leadersPopulation === 0) {
            this.leadersRank.push(baseObj)
          } else if (this.leadersScores[i].questions[j].score !== 0) {
            obj = {
              process: this.leadersScores[i].name,
              abbreviation: this.leadersScores[i].name === 'as_person' ? 'P' : this.leadersScores[i].name === 'management' ? 'D' : this.leadersScores[i].name === 'mobilization' ? 'M' : 'L',
              score: this.leadersScores[i].questions[j].score,
              question: this.leadersScores[i].questions[j].question,
              tenPercent: this.leadersScores[i].questions[j].tenPercent,
              dispersion: this.leadersScores[i].questions[j].dispersion,
              previous: 0,
              trend: 0,
              responsability: this.leadersScores[i].questions[j].responsability
            }
            if (this.hasPrevious) {
              obj.previous = this.previousLeadersScores[i].questions[j].score > 0 ? this.previousLeadersScores[i].questions[j].score : '--'
              obj.trend = this.previousLeadersScores[i].questions[j].score > 0 ? this.leadersScores[i].questions[j].score - this.previousLeadersScores[i].questions[j].score : '--'
            }
            this.leadersRank.push(obj)
          }

          // Por Pares
          if (this.pairsPopulation === 0) {
            this.pairsRank.push(baseObj)
          } else if (this.pairsScores[i].questions[j].score !== 0) {
            obj = {
              process: this.pairsScores[i].name,
              abbreviation: this.pairsScores[i].name === 'as_person' ? 'P' : this.pairsScores[i].name === 'management' ? 'D' : this.pairsScores[i].name === 'mobilization' ? 'M' : 'L',
              score: this.pairsScores[i].questions[j].score,
              question: this.pairsScores[i].questions[j].question,
              tenPercent: this.pairsScores[i].questions[j].tenPercent,
              dispersion: this.pairsScores[i].questions[j].dispersion,
              previous: 0,
              trend: 0,
              responsability: this.pairsScores[i].questions[j].responsability
            }
            if (this.hasPrevious) {
              obj.previous = this.previousPairsScores[i].questions[j].score > 0 ? this.previousPairsScores[i].questions[j].score : '--'
              obj.trend = this.previousPairsScores[i].questions[j].score > 0 ? this.pairsScores[i].questions[j].score - this.previousPairsScores[i].questions[j].score : '--'
            }
            this.pairsRank.push(obj)
          } else {
            this.pairsRank.push(baseObj)
          }
          if (this.dependentsPopulation === 0) {
            this.dependentsRank.push(baseObj)
          } else if (this.dependentsScores[i].questions[j].score !== 0) {
            obj = {
              process: this.dependentsScores[i].name,
              abbreviation: this.dependentsScores[i].name === 'as_person' ? 'P' : this.dependentsScores[i].name === 'management' ? 'D' : this.dependentsScores[i].name === 'mobilization' ? 'M' : 'L',
              score: this.dependentsScores[i].questions[j].score,
              question: this.dependentsScores[i].questions[j].question,
              tenPercent: this.dependentsScores[i].questions[j].tenPercent,
              dispersion: this.dependentsScores[i].questions[j].dispersion,
              previous: 0,
              trend: 0,
              responsability: this.dependentsScores[i].questions[j].responsability
            }
            if (this.hasPrevious) {
              obj.previous = this.previousDependentsScores[i].questions[j].score > 0 ? this.previousDependentsScores[i].questions[j].score : '--'
              obj.trend = this.previousDependentsScores[i].questions[j].score > 0 ? this.dependentsScores[i].questions[j].score - this.previousDependentsScores[i].questions[j].score : '--'
            }
            this.dependentsRank.push(obj)
          }
        }
        // Responsabilidad
      }
      // Limpiando
      /*
      this.noAutoOthersRank
      this.leadersRank
      this.pairsRank
      this.dependentsRank
      */
      let localRankingClean = this.noAutoOthersRank.filter((elem) => elem.score !== '--' && elem.score !== 0)
      this.noAutoOthersRank = localRankingClean.length > 5 ? localRankingClean : this.noAutoOthersRank
      localRankingClean = this.leadersRank.filter((elem) => elem.score !== '--' && elem.score !== 0)
      this.leadersRank = localRankingClean.length > 5 ? localRankingClean : this.leadersRank
      localRankingClean = this.pairsRank.filter((elem) => elem.score !== '--' && elem.score !== 0)
      this.pairsRank = localRankingClean.length > 5 ? localRankingClean : this.pairsRank
      localRankingClean = this.dependentsRank.filter((elem) => elem.score !== '--' && elem.score !== 0)
      this.dependentsRank = localRankingClean.length > 5 ? localRankingClean : this.dependentsRank
      // Fin limpieza

      for (let i = 0; i < this.averageResponsability.length; i++) {
        for (let j = 0; j < this.averageResponsability[i].responsability.length; j++) {
          let obj = {}
          const baseObj = {
            name: '--',
            abbreviation: '--',
            score: '--',
            dispersion: '--',
            tenPercent: '--',
            previous: '--',
            trend: '--'
          }
          if (this.averageResponsability[i].responsability[j].score !== 0) {
            obj = {
              name: this.averageResponsability[i].responsability[j].name,
              abbreviation: this.averageResponsability[i].name === 'as_person' ? 'P' : this.averageResponsability[i].name === 'management' ? 'D' : this.averageResponsability[i].name === 'mobilization' ? 'M' : 'L',
              score: this.averageResponsability[i].responsability[j].score,
              tenPercent: this.averageResponsability[i].responsability[j].tenPercent,
              dispersion: this.averageResponsability[i].responsability[j].dispersion,
              previous: 0,
              trend: 0
            }
            if (this.hasPrevious) {
              obj.previous = this.previousAverageResponsability[i].responsability[j].score > 0 ? this.previousAverageResponsability[i].responsability[j].score : '--'
              obj.trend = this.previousAverageResponsability[i].responsability[j].score > 0 ? this.averageResponsability[i].responsability[j].score - this.previousAverageResponsability[i].responsability[j].score : '--'
            }
          }
          this.othersRespRank.push(obj)
          if (this.noAutoScoresResponsability[i].responsability[j].score !== 0) {
            obj = {
              name: this.noAutoScoresResponsability[i].responsability[j].name,
              abbreviation: this.noAutoScoresResponsability[i].name === 'as_person' ? 'P' : this.noAutoScoresResponsability[i].name === 'management' ? 'D' : this.noAutoScoresResponsability[i].name === 'mobilization' ? 'M' : 'L',
              score: this.noAutoScoresResponsability[i].responsability[j].score,
              tenPercent: this.noAutoScoresResponsability[i].responsability[j].tenPercent,
              dispersion: 0,
              previous: 0,
              trend: 0
            }
            if (this.hasPrevious) {
              obj.previous = this.previousNoAutoScoresResponsability[i].responsability[j].score > 0 ? this.previousNoAutoScoresResponsability[i].responsability[j].score : '--'
              obj.trend = this.previousNoAutoScoresResponsability[i].responsability[j].score > 0 ? this.noAutoScoresResponsability[i].responsability[j].score - this.previousNoAutoScoresResponsability[i].responsability[j].score : '--'
            }
          }
          this.noAutoOthersRespRank.push(obj)
          // Por Líder
          if (this.leadersPopulation === 0) {
            obj = baseObj
          } else if (this.leadersResponsability[i].responsability[j].score !== 0) {
            obj = {
              name: this.leadersResponsability[i].responsability[j].name,
              abbreviation: this.leadersResponsability[i].name === 'as_person' ? 'P' : this.leadersResponsability[i].name === 'management' ? 'D' : this.leadersResponsability[i].name === 'mobilization' ? 'M' : 'L',
              score: this.leadersResponsability[i].responsability[j].score,
              tenPercent: this.leadersResponsability[i].responsability[j].tenPercent,
              dispersion: 0,
              previous: 0,
              trend: 0
            }
            if (this.hasPrevious) {
              obj.previous = this.previousLeadersResponsability[i].responsability[j].score > 0 ? this.previousLeadersResponsability[i].responsability[j].score : '--'
              obj.trend = this.previousLeadersResponsability[i].responsability[j].score > 0 ? this.leadersResponsability[i].responsability[j].score - this.previousLeadersResponsability[i].responsability[j].score : '--'
            }
          }
          this.leadersRespRank.push(obj)
          // Por Pares
          if (this.pairsPopulation === 0) {
            obj = baseObj
          } else if (this.pairsResponsability[i].responsability[j].score !== 0) {
            obj = {
              name: this.pairsResponsability[i].responsability[j].name,
              abbreviation: this.pairsResponsability[i].name === 'as_person' ? 'P' : this.pairsResponsability[i].name === 'management' ? 'D' : this.pairsResponsability[i].name === 'mobilization' ? 'M' : 'L',
              score: this.pairsResponsability[i].responsability[j].score,
              tenPercent: this.pairsResponsability[i].responsability[j].tenPercent,
              dispersion: 0,
              previous: 0,
              trend: 0
            }
            if (this.hasPrevious) {
              obj.previous = this.previousPairsResponsability[i].responsability[j].score > 0 ? this.previousPairsResponsability[i].responsability[j].score : '--'
              obj.trend = this.previousPairsResponsability[i].responsability[j].score > 0 ? this.pairsResponsability[i].responsability[j].score - this.previousPairsResponsability[i].responsability[j].score : '--'
            }
          }
          this.pairsRespRank.push(obj)
          if (this.dependentsPopulation === 0) {
            obj = baseObj
          } else if (this.dependentsResponsability[i].responsability[j].score !== 0) {
            obj = {
              name: this.dependentsResponsability[i].responsability[j].name,
              abbreviation: this.dependentsResponsability[i].name === 'as_person' ? 'P' : this.dependentsResponsability[i].name === 'management' ? 'D' : this.dependentsResponsability[i].name === 'mobilization' ? 'M' : 'L',
              score: this.dependentsResponsability[i].responsability[j].score,
              tenPercent: this.dependentsResponsability[i].responsability[j].tenPercent,
              dispersion: 0,
              previous: 0,
              trend: 0
            }
            if (this.hasPrevious) {
              obj.previous = this.previousDependentsResponsability[i].responsability[j].score > 0 ? this.previousDependentsResponsability[i].responsability[j].score : '--'
              obj.trend = this.previousDependentsResponsability[i].responsability[j].score > 0 ? this.dependentsResponsability[i].responsability[j].score - this.previousDependentsResponsability[i].responsability[j].score : '--'
            }
          }
          this.dependentsRespRank.push(obj)
        }
      }
    }
  }
}
