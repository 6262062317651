
import pdfUtil from '../utils_old/pdf'

export default {
  methods: {
    generateParagraph (translate, repeat, fontSize, italic) {
      let array = []
      for (let index = 0; index < repeat; index++) {
        array.push(
          {
            text: this.$t(`${translate + '[' + index + ']'}`),
            alignment: 'justify',
            margin: [0, 0, 16, 12],
            color: '#6d7a7a',
            lineHeight: 1.5,
            fontSize: fontSize,
            italics: italic
          }
        )
      }
      return array
    },
    $generateProcessResults () {
      const asPerson = []
      const management = []
      const mobilization = []
      const achievement = []
      const responsabilities = []
      const legend = [
        this.$t('individualReport.no_answer'),
        this.$t('individualReport.data_range'),
        this.$t('individualReport.score'),
        this.$t('individualReport.ten_percent'),
        this.$t('individualReport.leaders_average'),
        this.$t('individualReport.higher_bold'),
        this.$t('individualReport.lower_sub')
      ]
      const evaluator = [
        this.$t('individualReport.me'),
        this.$t('individualReport.leaders'),
        this.$t('individualReport.pairs'),
        this.$t('individualReport.dependents'),
        this.$t('individualReport.others')
      ]
      const noZero = (n) => (n === 0 ? '--' : this.round(n, 2, '--'))

      let resp = []
      for (let i = 0; i < this.autoResponsabilityFiltered.length; i++) {
        for (let j = 0; j < this.autoResponsabilityFiltered[i].responsability.length; j++) {
          let obj = {
            name: this.autoResponsabilityFiltered[i].responsability[j].name,
            auto: this.autoResponsabilityFiltered[i].responsability[j].score,
            others: this.noAutoScoresResponsabilityFiltered[i].responsability[j].score,
            trend: 0
          }
          if (this.hasPrevious) {
            const previousScore = this.previousNoAutoScoresResponsabilityFiltered[i].responsability[j].score || 0
            obj.trend = this.noAutoScoresResponsabilityFiltered[i].responsability[j].score - previousScore
          }
          resp.push(obj)
        }
      }
      // Obtenemos arreglo de puntajes por procesos para el higher negrita y lower subrayado
      let scoresFiltered = [this.autoScoresFiltered, this.leadersScoresFiltered, this.pairsScoresFiltered, this.dependentsScoresFiltered, this.noAutoScoresFiltered]
      let processScores = []
      for (let i = 0; i < 5; i++) {
        let scores = []
        scoresFiltered.forEach((it) => {
          if (it[i]) {
            const total = it[i].total
            if (!isNaN(total) && total > 0) {
              scores.push(total)
            }
          }
        })
        processScores.push(scores)
      }

      const textColor = '#6d7a7a'
      for (let i = 0; i < 5; i++) {
        let data = i === 0 ? this.averageScores : i === 1 ? this.leadersScores : i === 2 ? this.pairsScores : i === 3 ? this.dependentsScores : this.noAutoScores
        let dataFiltered = scoresFiltered[i]
        let previousDataFiltered = i === 0 ? this.previousAutoScoresFiltered : i === 1 ? this.previousLeadersScoresFiltered : i === 2 ? this.previousPairsScoresFiltered : i === 3 ? this.previousDependentsScoresFiltered : this.previousNoAutoScoresFiltered

        // Calcula porcentaje de respuestas "No sabe" para los pares
        // let unknownPercent = i === 2 ? this.getUnknownPercent(dataFiltered[0]) : ''

        let isValid = !isNaN(dataFiltered[0].total) && dataFiltered[0].total >= 1
        asPerson.push([
          i === 0 ? { text: `${this.$t('individualReport.leader_as_person')}`, rowSpan: 5, bold: true, color: '#0D47A1', margin: [0, 20, 0, 0], border: [true, true, true, true] } : {},
          { text: evaluator[i], bold: true, color: textColor, margin: [-3, 0, -3, 0], border: [true, true, true, true] },
          // { text: evaluator[i] + unknownPercent, bold: true, color: textColor, margin: [-3, 0, -3, 0], border: [true, true, true, true] },
          isValid ? pdfUtil.generateXDCChartT3(dataFiltered, 0, this.round(data[0].total, 2), data[0].tenPercent) : { text: '--', border: [true, true, true, true] },
          pdfUtil.higherLowerRow(processScores[0], isValid ? dataFiltered[0].total : '--', textColor, [0, 4, 0, 0], 'center', [true, true, true, true]),
          { ...pdfUtil.colResul(data[0].tenPercent, undefined, data[0].tenPercent > 0), bold: false, color: textColor },
          { ...pdfUtil.colResul(data[0].total, undefined, data[0].total > 0), bold: false, color: textColor },
          { ...pdfUtil.colResul(() => (dataFiltered[0].total - previousDataFiltered[0].total), 7, this.hasPrevious && isValid), bold: false, color: textColor }
        ])

        // Calcula porcentaje de respuestas "No sabe" para los pares
        // unknownPercent = i === 2 ? this.getUnknownPercent(dataFiltered[1]) : ''

        isValid = !isNaN(dataFiltered[1].total) && dataFiltered[1].total >= 1
        management.push([
          i === 0 ? { text: `${this.$t('individualReport.direction')}`, rowSpan: 5, bold: true, color: '#b4cf32', margin: [0, 20, 0, 0], border: [true, true, true, true] } : {},
          { text: evaluator[i], bold: true, color: textColor, margin: [-3, 0, -3, 0], border: [true, true, true, true] },
          // { text: evaluator[i] + unknownPercent, bold: true, color: textColor, margin: [-3, 0, -3, 0], border: [true, true, true, true] },
          isValid ? pdfUtil.generateXDCChartT3(dataFiltered, 1, this.round(data[1].total, 2), data[1].tenPercent) : { text: '--', border: [true, true, true, true] },
          pdfUtil.higherLowerRow(processScores[1], isValid ? dataFiltered[1].total : '--', textColor, [0, 4, 0, 0], 'center', [true, true, true, true]),
          { ...pdfUtil.colResul(data[1].tenPercent, undefined, data[1].tenPercent > 0), bold: false, color: textColor },
          { ...pdfUtil.colResul(data[1].total, undefined, data[1].total > 0), bold: false, color: textColor },
          { ...pdfUtil.colResul(() => (dataFiltered[1].total - previousDataFiltered[1].total), 7, this.hasPrevious && isValid), bold: false, color: textColor }
        ])

        // Calcula porcentaje de respuestas "No sabe" para los pares
        // unknownPercent = i === 2 ? this.getUnknownPercent(dataFiltered[2]) : ''

        isValid = !isNaN(dataFiltered[2].total) && dataFiltered[2].total >= 1
        mobilization.push([
          i === 0 ? { text: `${this.$t('individualReport.mobilization')}`, rowSpan: 5, bold: true, color: '#4ec7aa', margin: [0, 20, 0, 0], border: [true, true, true, true] } : {},
          { text: evaluator[i], bold: true, color: textColor, margin: [-3, 0, -3, 0], border: [true, true, true, true] },
          // { text: evaluator[i] + unknownPercent, bold: true, color: textColor, margin: [-3, 0, -3, 0], border: [true, true, true, true] },
          isValid ? pdfUtil.generateXDCChartT3(dataFiltered, 2, this.round(data[2].total, 2), data[2].tenPercent) : { text: '--', border: [true, true, true, true] },
          pdfUtil.higherLowerRow(processScores[2], isValid ? dataFiltered[2].total : '--', textColor, [0, 4, 0, 0], 'center', [true, true, true, true]),
          { ...pdfUtil.colResul(data[2].tenPercent, undefined, data[2].tenPercent > 0), bold: false, color: textColor },
          { ...pdfUtil.colResul(data[2].total, undefined, data[2].total > 0), bold: false, color: textColor },
          { ...pdfUtil.colResul(() => (dataFiltered[2].total - previousDataFiltered[2].total), 7, this.hasPrevious && isValid), bold: false, color: textColor }
        ])

        // Calcula porcentaje de respuestas "No sabe" para los pares
        // unknownPercent = i === 2 ? this.getUnknownPercent(dataFiltered[3]) : ''

        isValid = !isNaN(dataFiltered[3].total) && dataFiltered[3].total >= 1
        achievement.push([
          i === 0 ? { text: `${this.$t('individualReport.achievement')}`, rowSpan: 5, bold: true, color: '#43ace2', margin: [0, 20, 0, 0], border: [true, true, true, true] } : {},
          { text: evaluator[i], bold: true, color: textColor, margin: [-3, 0, -3, 0], border: [true, true, true, true] },
          // { text: evaluator[i] + unknownPercent, bold: true, color: textColor, margin: [-3, 0, -3, 0], border: [true, true, true, true] },
          isValid ? pdfUtil.generateXDCChartT3(dataFiltered, 3, this.round(data[3].total, 2), data[3].tenPercent) : { text: '--', border: [true, true, true, true] },
          pdfUtil.higherLowerRow(processScores[3], isValid ? dataFiltered[3].total : '--', textColor, [0, 4, 0, 0], 'center', [true, true, true, true]),
          { ...pdfUtil.colResul(data[3].tenPercent, undefined, data[3].tenPercent > 0), bold: false, color: textColor },
          { ...pdfUtil.colResul(data[3].total, undefined, data[3].total > 0), bold: false, color: textColor },
          { ...pdfUtil.colResul(() => (dataFiltered[3].total - previousDataFiltered[3].total), 7, this.hasPrevious && isValid), bold: false, color: textColor }
        ])
      }
      for (let i = 0; i < 16; i++) {
        responsabilities.push([
          { text: resp[i].name, color: textColor, margin: [0, 5, 0, 0], alignment: 'left', border: [true, true, true, true], fontSize: 8 },
          { text: noZero(resp[i].auto), color: textColor, border: [true, true, true, true], margin: [0, 5, 0, 0], fontSize: 8 },
          { text: noZero(resp[i].others), margin: [5, 5, 5, 0], border: [true, true, true, true], style: { color: textColor }, fontSize: 8 },
          { text: `${this.hasPrevious ? this.round(resp[i].trend, 2) : '--'}`, margin: [5, 5, 5, 0], border: [true, true, true, true], style: { color: textColor }, fontSize: 8 }
        ])
      }
      return [
        {
          table: {
            widths: [479],
            body: [
              [{ text: `${this.$t('individualReport.process_results_title')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 6, 0, 6] }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [-40, 0, 0, 20],
          pageBreak: 'before'
        },
        // {
        //   text: `${this.$t('individualReport.process_results[1]')}`,
        //   alignment: 'justify',
        //   margin: [0, 0, 22, 0],
        //   color: '#6d7a7a',
        //   lineHeight: 1.5
        // },
        {
          table: {
            widths: [479],
            body: [
              [{ text: `${this.$t('individualReport.table')} 3. ${this.$t('individualReport.process_results_text')}`, bold: true, color: '#fff', margin: [20, 6, 0, 6] }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [35, 5, 100, 20]
        },
        pdfUtil.generateDmlLegendIndividual(legend),
        {
          layout: {
            defaultBorder: '',
            border: [false, false, false, true],
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#BFBFBF' : null
            }
          },
          table: {
            headerRows: 1,
            widths: ['15%', '14%', '39%', '8%', '8%', '8%', '8%'],
            body: [
              [
                { text: `${this.$t('individualReport.process')}`, bold: true, color: '#fff', margin: [0, 5, 0, 0], border: [true, true, true, true], fontSize: 9 },
                { text: `${this.$t('individualReport.evaluator')}`, bold: true, color: '#fff', border: [true, true, true, true], margin: [0, 5, 0, 0], fontSize: 9 },
                { layout: { defaultBorder: '', fillColor: '#BFBFBF' },
                  table: {
                    headerRows: 1,
                    body: [[
                      { text: '1\n|', margin: [10, 2, 15, 0] },
                      { text: '2\n|', margin: [0, 2, 15, 0] },
                      { text: '3\n|', margin: [0, 2, 15, 0] },
                      { text: '4\n|', margin: [0, 2, 15, 0] },
                      { text: '5\n|', margin: [0, 2, 15, 0] },
                      { text: '6\n|', margin: [0, 2, 15, 0] }
                    ]]
                  },
                  bold: true,
                  style: { color: '#fff' },
                  border: [true, true, true, true]
                },
                { text: this.$t('individualReport.score'), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 },
                { text: this.$t('individualReport.ten_percent'), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 },
                { text: this.$t('individualReport.leaders_average'), margin: [-3, 5, -3, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 },
                { text: this.$t('individualReport.trend'), margin: [-3, 5, -3, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 }
              ],
              ...asPerson,
              ...management,
              ...mobilization,
              ...achievement
            ]
          },
          margin: [0, 0, 0, 10],
          border: [false, false, false, true],
          alignment: 'center',
          fontSize: 9
        },
        {
          table: {
            widths: [479],
            body: [
              [{ text: `${this.$t('individualReport.chart')} 1 ${this.$t('individualReport.process_results_text')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 6, 0, 6] }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [-40, 0, 0, 0],
          pageBreak: 'before'
        },
        // Gráfica va aqui
        {
          image: this.flowerChart,
          width: 500,
          height: 300,
          margin: [0, 0, 0, 0]
        },
        {
          columns: [
            { width: '*', text: ' \u0020 ', background: '#0D47A1' },
            { width: '*', text: ' \u0020 ', background: '#C0CA33' },
            { width: '*', text: ' \u0020 ', background: '#00897B' },
            { width: '*', text: ' \u0020 ', background: '#039BE5' },
            { width: '94%', text: this.$t('individualReport.autoevaluation') }
          ],
          margin: [0, 0, 0, 4]
        },
        {
          columns: [
            { width: '5%', text: '' },
            { width: '*', text: ' \u0020  \u0020  \u0020  \u0020 ', background: '#BFBFBF' },
            { width: '94%', text: this.$t('individualReport.others') }
          ]
        },
        {
          layout: {
            defaultBorder: '',
            border: [false, false, false, true],
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#BFBFBF' : null
            }
          },
          table: {
            headerRows: 1,
            widths: ['73%', '9%', '9%', '9%'],
            body: [
              [
                { text: `${this.$t('individualReport.responsability')}`.toUpperCase(), bold: true, color: '#fff', margin: [0, 5, 0, 0], border: [true, true, true, true], fontSize: 9 },
                { text: `${this.$t('individualReport.auto')}`.toUpperCase(), bold: true, color: '#fff', border: [true, true, true, true], margin: [0, 5, 0, 0], fontSize: 9 },
                { text: `${this.$t('individualReport.others')}`.toUpperCase(), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 },
                { text: `${this.$t('individualReport.trend')}`.toUpperCase(), margin: [-3, 5, -3, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 }
              ],
              ...responsabilities
            ]
          },
          margin: [0, 5, 0, 10],
          border: [false, false, false, true],
          alignment: 'center',
          fontSize: 8
        }
      ]
    }
  }
}
