<template>
  <v-dialog v-model="show" width="70em" persistent>
    <v-card>
      <v-card-title
        class="headline white--text primary lighten-2"
        style="padding: 1em"
        primary-title
      >
        {{ editEvaluated ? $t('evaluations.edit_evaluators') : $t('evaluations.add_evaluated') }}
      </v-card-title>

      <v-card-text class="pb-0">
        <v-row>
          <v-col cols="12">
            <ValidationObserver v-slot="{ handleSubmit }">
              <v-form @submit.prevent="handleSubmit(pushEvaluator)">
                <v-row>
                <!-- <v-row v-for="(ev, n) in evaluated" :key="n"> -->
                  <v-col cols="12" align="center">
                    <h2 class="mt-3">
                      {{ $t('evaluations.evaluate') }}
                    </h2>
                  </v-col>
                  <v-col cols="12">
                    <x-inputs-autocomplete
                      :items="filterLeaders(evaluated)"
                      :input="evaluated.employee.employee"
                      @updateInput="($event) => evaluated.employee.employee = $event"
                      light
                      :label="$t('evaluations.evaluate')"
                      name="evaluated"
                      rules="required"
                      autofocus
                      return-object
                      :disabled="!!editEvaluated"
                    />
                  </v-col>
                  <v-col cols="12" align="center">
                    <h2>
                      {{ $t('evaluations.evaluators') }}
                    </h2>
                  </v-col>
                  <v-col cols="4">
                    <x-inputs-autocomplete
                      :items="filterEvaluators(evaluated, 'leadersEvaluators')"
                      :input="leadersEvaluators"
                      @updateInput="($event) => setEvaluators(evaluated, 'leadersEvaluators', $event)"
                      light
                      :label="$t('evaluations.leaders')"
                      name="leaders"
                      multiple chips
                      return-object
                    />
                  </v-col>
                  <v-col cols="4">
                    <x-inputs-autocomplete
                      :items="filterEvaluators(evaluated, 'pairsEvaluators')"
                      :input="pairsEvaluators"
                      @updateInput="($event) => setEvaluators(evaluated, 'pairsEvaluators', $event)"
                      light
                      :label="$t('evaluations.pairs')"
                      name="pairs"
                      multiple chips
                      return-object
                    />
                  </v-col>
                  <v-col cols="4">
                    <x-inputs-autocomplete
                      :items="filterEvaluators(evaluated, 'dependentsEvaluators')"
                      :input="dependentsEvaluators"
                      @updateInput="($event) => setEvaluators(evaluated, 'dependentsEvaluators', $event)"
                      light
                      :label="$t('evaluations.dependents')"
                      name="dependents"
                      multiple chips
                      return-object
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-btn block large
                      @click="closeDialog"
                    >
                      {{ $t('input.cancel') }}
                    </v-btn>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-btn block large
                      color="primary"
                      type="submit"
                    >
                      {{ editEvaluated ? $t('input.edit') : $t('input.add') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </ValidationObserver>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

import Vue from 'vue'

export default Vue.extend({
  props: {
    employees: Array,
    evaluation: Object,
    editEvaluated: {
      type: Object,
      default: () => (null)
    }
  },
  data () {
    return {
      show: true,
      evaluated: {
        employee: {
          employee: null
        },
        leadersEvaluators: [],
        dependentsEvaluators: [],
        pairsEvaluators: []
      }
    }
  },
  methods: {
    init () {
      if (this.editEvaluated) {
        ['leadersEvaluators', 'dependentsEvaluators', 'pairsEvaluators'].forEach((key) => {
          if (this.editEvaluated[key][0]) {
            if (this.editEvaluated[key][0].firstName) {
              this.editEvaluated[key] = this.editEvaluated[key].map(this.employeeMapper)
            } else {
              this.editEvaluated[key].forEach((it) => {
                it.employee = this.employeeMapper(it.employee)
              })
            }
          }
        })
        this.editEvaluated.employee.employee = this.employeeMapper(this.editEvaluated.employee.employee)
        this.evaluated = { ...this.editEvaluated }
        this.evaluated.leadersEvaluators = [...this.editEvaluated.leadersEvaluators]
        this.evaluated.dependentsEvaluators = [...this.editEvaluated.dependentsEvaluators]
        this.evaluated.pairsEvaluators = [...this.editEvaluated.pairsEvaluators]
      }
    },
    employeeMapper (employee) {
      const emp = employee.firstName ? employee : employee.employee
      if (emp.text) {
        return emp
      } else {
        return {
          text: `${emp.firstName} ${emp.lastName}`,
          value: emp.id,
          ...emp
        }
      }
    },
    closeDialog () {
      this.$emit('closeDialog')
    },
    pushEvaluator () {
      this.$emit('pushEvaluator', this.evaluated)
    },
    filterLeaders (item) {
      if (this.editEvaluated) {
        return [this.editEvaluated.employee.employee]
      } else {
        return this.employees.filter(employee => {
          let valid = true
          if (this.evaluation.evaluated.length) {
            valid = !this.evaluation.evaluated.find(evaluated => {
              return evaluated !== item && evaluated.employee.employee.id === employee.id
            })
          }
          if (valid) {
            const evaluators = item.leadersEvaluators.concat(item.dependentsEvaluators).concat(item.pairsEvaluators)
            if (evaluators.length) {
              valid = !evaluators.find(evaluator => evaluator.id === employee.id)
            }
          }
          return valid
        })
      }
    },
    getEmployee (item) {
      return item.firstName ? item : item.employee
    },
    filterEvaluators (item, key) {
      const keys = ['leadersEvaluators', 'dependentsEvaluators', 'pairsEvaluators'].filter((k) => k !== key)
      const cloneItem = { ...item }
      const employees = []
      keys.forEach((k) => {
        employees.push(...cloneItem[k])
      })
      return this.employees.filter(employee => {
        let valid = true
        if (item.employee.employee) {
          valid = item.employee.employee.id !== employee.id
        }
        if (valid && employees.length) {
          valid = !employees.find(empl => this.getEmployee(empl).id === employee.id)
        }
        return valid
      })
    },
    getEvaluators (evaluated, key) {
      if (evaluated[key].length === 0 || evaluated[key][0].firstName) {
        return evaluated[key]
      } else {
        return evaluated[key].map((it) => it.employee)
      }
    },
    setEvaluators (evaluated, key, list) {
      if (!this.evaluation.edit) {
        evaluated[key] = list
      } else if (evaluated[key].length === 0 || evaluated[key][0].firstName) {
        evaluated[key] = list.map((employee) => ({ employee }))
      } else {
        evaluated[key].forEach((it, ind) => {
          const emp = list.find((item) => item.id === it.employee.id)
          if (!emp) {
            evaluated[key].splice(ind, 1)
          }
        })
        list.forEach((it) => {
          const emp = evaluated[key].find((item) => it.id === item.employee.id)
          if (!emp) {
            evaluated[key].push({ employee: it })
          }
        })
      }
    }
  },
  computed: {
    leadersEvaluators () {
      return this.getEvaluators(this.evaluated, 'leadersEvaluators')
    },
    pairsEvaluators () {
      return this.getEvaluators(this.evaluated, 'pairsEvaluators')
    },
    dependentsEvaluators () {
      return this.getEvaluators(this.evaluated, 'dependentsEvaluators')
    }
  },
  mounted () {
    this.init()
  }
})
</script>
