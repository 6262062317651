
<template>
  <div style="display:inline;">
    <v-btn large
      color="primary"
      :loading="loading"
      :disabled="loading"
      @click="showModalConfirm = true"
    >
      <v-icon>mdi-file-pdf</v-icon>
      {{ $t('reports.general_report') }}
    </v-btn>

    <x-confirm-spend-dialog
      :confirmText="$t('demographicReport.confirm_report_title')"
      :costText="$t('demographicReport.report_cost')"
      :showModalConfirm="showModalConfirm && !updatingBalance"
      :balance="balance"
      :price="price"
      :enoughBalance="enoughBalance"
      :noActiveEmployee="false"
      :operationsLink="operationsLink"
      :noBalanceResponse="noBalanceResponse"
      :noBalanceMsg="noBalanceMsg()"
      :balanceAfterMsg="$t('demographicReport.balance_after')"
      :confirmBtn="$t('demographicReport.confirm_btn')"
      @result="verifySpend"
    ></x-confirm-spend-dialog>
  </div>
</template>

<script>

import { mapState } from 'vuex'

import evaluationService from '../../services/evaluations'
import balanceService from '../../services/token-account-detail'
import servicePriceService from '../../services/product-services'

export default {
  name: 'thread-demographic-report',
  props: {
    cutsSelected: Object,
    pollId: String
  },
  data () {
    return {
      loading: false,
      showModalConfirm: false,
      updatingBalance: false,
      balance: 0,
      price: 0,
      enoughBalance: false,
      operationsLink: '',
      noBalanceResponse: false
    }
  },
  created () {
    this.getBalance()
    this.$store.dispatch('session/getSuiteWebHost')
      .then((res) => {
        if (res) {
          this.operationsLink = `${res}/tokens/operations`
        } else {
          next('/404')
        }
      })
  },
  mounted () {
    //
  },
  watch: {
    //
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    })
  },
  methods: {
    runOpenReport () {
      this.cutsSelected.thread = true
      this.loading = true
      return evaluationService.getDemographicReport(this.pollId, this.cutsSelected)
        .then(() => {
          this.$store.dispatch('alert/success', this.$t('demographicReport.operation_init'))
          this.showModalConfirm = false
          setTimeout(this.refreshPage, 2000)
        })
        .catch((err) => {
          if (err.code === 'suite-fail/evaluation/spend-fail') {
            this.noBalanceResponse = true
            this.disableButtonModal = true
            this.$store.dispatch('alert/error', this.$t('errors.no_balance'))
          } else {
            this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
          }
          this.loading = false
        })
    },
    noBalanceMsg () {
      if (this.user.customer.type === 'personal') {
        return this.$t('errors.no_balance')
      } else {
        return this.$t('errors.no_balance_msg')
      }
    },
    verifySpend ($event) {
      return $event ? this.runOpenReport() : (this.showModalConfirm = false)
    },
    getBalance () {
      this.updatingBalance = true
      return balanceService.getBalanceFromSuite()
        .then((res) => {
          this.balance = res.balance
          this.getServicePrice()
        })
    },
    getServicePrice () {
      return servicePriceService.getOne('REPORTE POR CORTE DEMOGRAFICO')
        .then((res) => {
          this.productService = res
          this.getServicePriceFromSuite(res)
        })
    },
    getServicePriceFromSuite () {
      return servicePriceService.getByCodeFromSuite(this.productService.code)
        .then((res) => {
          this.price = res.tokenPrice
          this.enoughBalance = this.balance > this.price
          this.updatingBalance = false
        })
    },
    refreshPage () {
      window.location.reload()
    }
  }
}
</script>
