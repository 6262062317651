
export default {
  index_title: 'Report content',
  index: [
    '1. Responsibility with Highest and Lowest Score for Relationship',
    '2. Behaviors with Higher and Lower Scores according to Relationship',
    '3. Results by Process',
    '4. Results by Liability',
    '5. Results by Conduct',
    '6. Dispersion Analysis for Responsibility and Conduct',
    '7. Trend Analysis for Responsibility and Conduct'
  ],
  title: 'Results report',
  subtitle: 'Organizational Leadership Report',
  poll_answers: 'Survey responses',
  autoevaluations: 'Self-assessments',
  leaders: 'Leader(s)',
  pairs: 'Pairs',
  dependents: 'Collaborators',
  total: 'Total',
  received_evaluations: 'Ratings Received',
  requested_evaluations: 'Requested Ratings',
  answers_rate: 'Response Percentage',
  table: 'Table',
  table1: 'Three highest and lowest scores according to Others; where Others is the average of all the relationships (Leaders, Peers and Collaborators).',
  table2: 'Three highest and lowest scores according to Leader(s)',
  table3: 'Three Higher and Lower Peer Scores',
  table4: 'Three highest and lowest scores according to collaborators',
  table5: 'Six Highest Scores According to Others; where Others is the average of all the relationships (Leaders, Peers and Collaborators).',
  table6: 'Six Lower Scores According to Others; where Others is the average of all the relationships (Leaders, Peers and Collaborators).',
  table7: 'Six Highest Scores by Leader(s)',
  table8: 'Six Lower Scores by Leader(s)',
  table9: 'Six Highest Peer Scores',
  table10: 'Six Lower Pairs Scores',
  table11: 'Six Highest Scores According to Collaborators',
  table12: 'Six Lower Scores According to Collaborators',
  table13: 'Results by process',
  table14: 'Results by Responsibility for the process of ',
  table17: 'Results by Conduct for the process ',
  table21: 'Dispersion by Responsibility',
  table22: 'Dispersion for Conduct',
  table23: 'Responsibility trend',
  table24: 'Conduct Trend',
  legend_description: 'In the bars of the following table, the gray color, the rhombus and the cross represent:',
  dispersion_description: 'Dispersion is calculated as the standard deviation of the data',
  table_description: 'In the bars of the tables, the gray color, the cross and the rhombus represent: ',
  higher_scores: 'Higher scores',
  lower_scores: 'Lower scores',
  process: 'Process',
  process_abbr: 'P',
  responsability: 'Responsibility',
  behavior: 'Conduct',
  score: 'Score',
  current_score: 'Puntaje Actual',
  cut_score: 'Puntaje Corte',
  previous_score: 'Previous Score',
  previous_score_abbr: 'Prev. Scor',
  previous: 'Previous',
  current: 'Current',
  ten_percent: '10% Higher',
  dispersion: 'Dispersion',
  trend: 'Trend',
  higher_trends: 'Positive higher trends',
  lower_trends: 'Negative higher trends',
  trend_abbr: 'Trend',
  data_range: 'Data range (max-min)',
  higher_bold: 'Higher Bold Ratings',
  lower_sub: 'Lower Underlined Ratings',
  management: 'Management',
  mobilization: 'Mobilization',
  achievement: 'Achievement',
  as_person: 'As a person',
  leader_as_person: 'Leader as a person',
  results: 'Results',
  copyright: 'OCC - All rights reserved.',
  higher_dispersion: 'Greater dispersion',
  lower_dispersion: 'Less dispersion',
  higher_gap: 'Positive higher gaps',
  lower_gap: 'Negative higher gaps',
  gap: 'Gap',
  months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ],
  lead_by_example: 'Lead culture by example',
  ensures_learning: 'Ensures continuous learning and comprehensive development',
  confirm_report_title: 'Confirm report generation',
  report_cost: 'Report cost',
  balance_after: 'Balance after generating the report:',
  confirm_btn: 'Confirm and Generate'
}
