
<template>
  <div style="display:inline;">
    <v-btn
      @click="openPdf"
      color="success"
      large
      :disabled="thread.status !== 'completed'"
    >
      <v-icon>mdi-file-pdf</v-icon>
      <span v-if="thread.status === 'pending'">{{ $t('reports.threadOrganizationalReportExec.input_generating_report') }}</span>
      <span v-else-if="thread.status === 'in_action' || thread.status === 'in_progress'">{{ $t('reports.threadOrganizationalReportExec.input_generating_report') + ` ${thread.data.progress}%` }}</span>
      <span v-else-if="thread.status === 'failed'">{{ $t('reports.threadOrganizationalReportExec.input_failed_generation') }}</span>
      <span v-else>{{ $t('reports.threadOrganizationalReportExec.input_download_report') }}</span>
    </v-btn>
    <!--
    <img
      src="/img/portada-individual2.jpg"
      style="visibility:hidden;"
      id="occCultureCoverOnly"
      alt="hidden"
      width="0"
      height="0"
    />
    <img
      src="/img/20200303_x_occ_dml_logo_r.png"
      style="visibility:hidden;"
      id="occCultureCover"
      alt="hidden"
      width="0"
      height="0"
    />
    -->
    <!--
    <x-confirm-spend-dialog
      :confirmText="$t('organizationalReport.confirm_report_title')"
      :costText="$t('organizationalReport.report_cost')"
      :showModalConfirm="showModalConfirm && !updatingBalance"
      :balance="balance"
      :price="price"
      :enoughBalance="enoughBalance"
      :noActiveEmployee="false"
      :operationsLink="operationsLink"
      :noBalanceResponse="noBalanceResponse"
      :noBalanceMsg="noBalanceMsg()"
      :canCreate="canPrintPdf()"
      :balanceAfterMsg="$t('organizationalReport.balance_after')"
      :confirmBtn="$t('organizationalReport.confirm_btn')"
      @result="verifySpend">
    </x-confirm-spend-dialog>
    -->
  </div>
</template>

<script>

import { mapState } from 'vuex'
import pdfUtil from './utils/pdf'

import is from 'is_js'
import cover from './mixins_organizational_old/cover'
import introduction from './mixins_organizational_old/introduction'
import responsability from './mixins_organizational_old/responsability'
import behavior from './mixins_organizational_old/behavior'
import resultsPerProcess from './mixins_organizational_old/results_per_process'
import resultsPerResponsability from './mixins_organizational_old/results_per_responsability'
import resultsPerBehavior from './mixins_organizational_old/results_per_behavior'
import dispersion from './mixins_organizational_old/dispersion'
import trend from './mixins_organizational_old/trend'
import initial from './mixins_organizational_old/initial_calcs'
import previousPoll from './mixins_organizational_old/previous_poll'
import balanceService from '../../services/token-account-detail'
import servicePriceService from '../../services/product-services'
import cultureCoverBase64 from './base64Files/logoX'
const pdfmake = require('pdfmake/build/pdfmake')
const pdffonts = require('pdfmake/build/vfs_fonts.js')
// const echarts = require('echarts')
// require('echarts-wordcloud')

pdfmake.vfs = pdffonts.pdfMake.vfs

export default {
  name: 'org-report',
  mixins: [
    cover,
    initial,
    previousPoll,
    introduction,
    responsability,
    behavior,
    resultsPerProcess,
    resultsPerResponsability,
    resultsPerBehavior,
    dispersion,
    trend
  ],
  props: {
    disableButton: Boolean,
    thread: Object,
    buttonText: String
  },
  data () {
    return {
      // IMÁGENES
      // cultureCoverSrc: null,
      // cultureCoverOnlySrc: null,
      // cultureCoverBase64: null,
      // cultureCoverOnlyBase64: null,
      lockPdfButton: false,
      // DATOS DE ENCUESTAS
      averages: {},
      evaluation: {},
      currentPoll: {},
      previousAverages: {},
      currentEvaluation: {},
      reportType: '',
      hasPrevious: false,
      averageScores: [],
      noAutoScores: [],
      leadersScores: [],
      pairsScores: [],
      dependentsScores: [],
      averageResponsability: [],
      noAutoScoresResponsability: [],
      leadersResponsability: [],
      pairsResponsability: [],
      dependentsResponsability: [],
      leadersPopulation: 0,
      pairsPopulation: 0,
      dependentsPopulation: 0,
      previousAverageScores: [],
      previousNoAutoScores: [],
      previousLeadersScores: [],
      previousPairsScores: [],
      previousDependentsScores: [],
      previousAverageResponsability: [],
      previousNoAutoScoresResponsability: [],
      previousLeadersResponsability: [],
      previousPairsResponsability: [],
      previousDependentsResponsability: [],
      previousLeadersPopulation: 0,
      previousPairsPopulation: 0,
      previousDependentsPopulation: 0,
      othersRank: [],
      noAutoOthersRank: [],
      leadersRank: [],
      dependentsRank: [],
      pairsRank: [],
      othersRespRank: [],
      noAutoOthersRespRank: [],
      leadersRespRank: [],
      dependentsRespRank: [],
      pairsRespRank: [],
      completedPolls: {},
      expectedPolls: {}
      // showModalConfirm: false,
      // updatingBalance: false,
      // balance: 0,
      // price: 0,
      // enoughBalance: false,
      // operationsLink: '',
      // noBalanceResponse: false
    }
  },
  created () {
    // this.getBalance()
    // this.$store.dispatch('session/getSuiteWebHost')
    //   .then((res) => {
    //     if (res) {
    //       this.operationsLink = `${res}/tokens/operations`
    //     } else {
    //       next('/404')
    //     }
    //   })
  },
  mounted () {
    // this.cultureCoverSrc = document.getElementById('occCultureCover').src
    // this.cultureCoverOnlySrc = document.getElementById('occCultureCoverOnly').src
  },
  watch: {
    // renderPart: {
    //   handler () {
    //     const hasFalses = Object.values(this.renderPart).includes(false)
    //     if (!hasFalses) this.renderPdf()
    //   },
    //   deep: true
    // },
    // cultureCoverSrc (newVal, oldVal) {
    //   if (newVal) {
    //     this.toDataURL(this.cultureCoverSrc, (dataURL) => {
    //       this.cultureCoverBase64 = dataURL
    //     })
    //   }
    // },
    // cultureCoverOnlySrc (newVal, oldVal) {
    //   if (newVal) {
    //     this.toDataURL(this.cultureCoverOnlySrc, (dataURL) => {
    //       this.cultureCoverOnlyBase64 = dataURL
    //     })
    //   }
    // }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    })
  },
  methods: {
    // canPrintPdf () {
    //   if (this.balance - this.price < 0) {
    //     return this.user.customer.type === 'commercial'
    //   }
    //   return true
    // },
    // noBalanceMsg () {
    //   if (this.user.customer.type === 'personal') {
    //     return this.$t('errors.no_balance')
    //   } else {
    //     return this.$t('errors.no_balance_msg')
    //   }
    // },
    // verifySpend (data) {
    //   if (data === 1) {
    //     this.openPdf()
    //   }
    //   this.showModalConfirm = false
    // },
    // getBalance () {
    //   this.updatingBalance = true
    //   return balanceService.getBalanceFromSuite()
    //     .then((res) => {
    //       this.balance = res.balance
    //       this.getServicePrice()
    //     })
    // },
    // getServicePrice () {
    //   return servicePriceService.getOne('REPORTE ORGANIZACIONAL OCC DML 360°')
    //     .then((res) => {
    //       this.productService = res
    //       this.getServicePriceFromSuite(res)
    //     })
    // },
    // getServicePriceFromSuite (service) {
    //   return servicePriceService.getByIdFromSuite(service.productServiceId ? service.productServiceId : 6)
    //     .then((res) => {
    //       this.price = res.tokenPrice
    //       this.enoughBalance = this.balance >= this.price
    //       this.updatingBalance = false
    //     })
    // },
    // toConfirm () {
    //   this.getBalance()
    //   this.showModalConfirm = true
    // },
    // toDataURL (url, callback) {
    //   const xhr = new XMLHttpRequest()
    //   xhr.open('get', url)
    //   xhr.responseType = 'blob'

    //   xhr.onload = function () {
    //     const fr = new FileReader()

    //     fr.onload = function () {
    //       callback(this.result)
    //     }

    //     fr.readAsDataURL(xhr.response)
    //   }

    //   xhr.send()
    // },
    async openPdf () {
      this.$store.dispatch('loading/show')
      this.lockPdfButton = true
      await this.$getInitialData(this.$route.params.id)
      await this.$getPreviousData(this.$route.params.id)
      await this.renderPdf()
    },
    writeRotatedText (text, color) {
      const canvas = document.createElement('canvas')
      canvas.width = 40
      canvas.height = 842
      const ctx = canvas.getContext('2d')

      // Genera color de fondo
      ctx.fillStyle = color
      ctx.fillRect(0, 0, canvas.width * 2, canvas.height * 2)
      ctx.save()

      // Posiciona el elemento al costado derecho de la página
      ctx.translate(40, 845)
      ctx.rotate(-0.5 * Math.PI)

      // Formatea el texto
      ctx.font = '17pt Roboto'
      ctx.fillStyle = 'white'
      ctx.fillText(text.toUpperCase(), 290, -15)
      ctx.restore()

      return canvas.toDataURL()
    },
    calculatePages () {
      // #EB493C #48BBA1
      return [
        { text: this.$t('organizationalReport.responsability'), sta: 3, end: 4, color: '#BFBFBF' }, // '#97b526'
        { text: this.$t('organizationalReport.behavior'), sta: 5, end: 8, color: '#BFBFBF' }, // '#45c0a3'
        { text: this.$t('organizationalReport.results'), sta: 9, end: 14, color: '#BFBFBF' }, // '#3397d9'
        { text: this.$t('organizationalReport.dispersion'), sta: 15, end: 15, color: '#BFBFBF' },
        { text: this.$t('organizationalReport.trend'), sta: 16, end: 17, color: '#BFBFBF' }
      ]
    },
    async renderPdf () {
      this.$emit('render-pdf')

      let configuration = {
        pageSize: 'A4',
        info: {
          title: this.$t('organizationalReport.title'),
          author: 'OCC',
          subject: this.$t('organizationalReport.title')
        },
        defaultStyle: {
          fontSize: 11,
          font: 'Roboto',
          lineHeight: 1.2
        },
        header: (currentPage, pageSize) => {
          const resultObj = {
            image: cultureCoverBase64,
            // image: this.cultureCoverBase64,
            width: 131,
            height: 55,
            margin: [15, 10, 25, 15]
          }
          if (currentPage === 1) return [{}]
          return [resultObj]
        },
        footer: (currentPage) => {
          return [
            {
              columns: pdfUtil.generateCenteredText(this.$t('organizationalReport.copyright'), 10),
              color: 'grey'
            },
            {
              margin: [0, 0, 60, 0],
              text: currentPage.toString(),
              alignment: 'right',
              color: 'grey'
            }
          ]
        },
        background: (currentPage, pageSize) => {
          const temp = this.calculatePages()
          const pageCont = temp.find(t => t.sta <= currentPage && t.end >= currentPage)

          if (!pageCont) return {}

          const label = pageCont.text
          const color = pageCont.color
          const result = {
            image: this.writeRotatedText(label, color),
            aligment: 'center',
            absolutePosition: { x: 565, y: 0 }
          }

          return result
        },
        content: [
          // Portada
          this.$generateCover(),
          // Índice
          this.$generateIntroduction(),
          // Por resposabilidad
          this.$generateResponsability(),
          // Por conducta
          this.$generateBehavior(),
          // Resultados por Proceso
          this.$generateResultsPerProcess(),
          // Resultados por Responsabilidad
          this.$generateResultsPerResponsability(),
          // Resultados por Conducta
          this.$generateResultsPerBehavior(),
          // Ranking Dispersion
          this.$generateDispersion(),
          // Ranking Brechas (Tendencias)
          this.$generateTrend()
        ]
      }

      if (is.edge() || is.ie()) {
        const pdfDocGenerator = pdfMake.createPdf(configuration)
        pdfDocGenerator.getBlob((blob) => {
          window.navigator.msSaveBlob(blob, `${this.currentEvaluation.name}.pdf`)
          this.$store.dispatch('loading/hide')
        })
      } else {
        pdfmake.createPdf(configuration).download(`${this.currentEvaluation.name}.pdf`, () => {
          this.$store.dispatch('loading/hide')
        })
      }
      this.lockPdfButton = false
    },
    round (value, decimals) {
      if (isNaN(Number(value))) {
        return '--'
      }
      if ((value * 100) < 1 && (value * 100) > -1) {
        value = 0
      }
      return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals).toFixed(decimals)
    }
  }
}
</script>
