
import pdfUtil from '../utils_old/pdf'

export default {
  methods: {
    $generateDirectionResults () {
      let behavior = []
      let responsability = []
      let tables = []
      const legend = [
        this.$t('individualReport.no_answer'),
        this.$t('individualReport.data_range'),
        this.$t('individualReport.score'),
        this.$t('individualReport.ten_percent'),
        this.$t('individualReport.leaders_average'),
        this.$t('individualReport.higher_bold'),
        this.$t('individualReport.lower_sub')
      ]
      const evaluator = [
        this.$t('individualReport.me'),
        this.$t('individualReport.leaders'),
        this.$t('individualReport.pairs'),
        this.$t('individualReport.dependents'),
        this.$t('individualReport.others')
      ]
      const noZero = (n) => (n === 0 ? '--' : this.round(n, 2, '--'))
      // Obtenemos arreglo de puntajes por procesos para el higher negrita y lower subrayado
      let responsabilityFiltered = [this.autoResponsabilityFiltered, this.leadersResponsabilityFiltered, this.pairsResponsabilityFiltered, this.dependentsResponsabilityFiltered, this.noAutoScoresResponsabilityFiltered]
      let scoresFiltered = [this.autoScoresFiltered, this.leadersScoresFiltered, this.pairsScoresFiltered, this.dependentsScoresFiltered, this.noAutoScoresFiltered]
      let responsabilityScores = []
      let respQuestionScores = []
      for (let k = 0; k < 5; k++) {
        let scores = []
        responsabilityFiltered.forEach((it) => {
          if (it[1] && it[1].responsability[k]) {
            const score = it[1].responsability[k].score
            if (!isNaN(score) && score > 0) {
              scores.push(score)
            }
          }
        })
        responsabilityScores.push(scores)

        let questionScores = []
        for (let j = 0; j < 3; j++) {
          let qScores = []
          scoresFiltered.forEach((it) => {
            if (it[1] && it[1].questions) {
              const score = it[1].questions[(k * 3) + j].score
              if (!isNaN(score) && score > 0) {
                qScores.push(score)
              }
            }
          })
          questionScores.push(qScores)
        }
        respQuestionScores.push(questionScores)
      }

      for (let k = 0; k < 5; k++) {
        behavior = []
        responsability = []
        const textColor = '#6d7a7a'
        for (let j = 0; j < 5; j++) {
          let data = j === 0 ? this.averageResponsability : j === 1 ? this.leadersResponsability : j === 2 ? this.pairsResponsability : j === 3 ? this.dependentsResponsability : this.noAutoScoresResponsability
          let dataFiltered = j === 0 ? this.autoResponsabilityFiltered : j === 1 ? this.leadersResponsabilityFiltered : j === 2 ? this.pairsResponsabilityFiltered : j === 3 ? this.dependentsResponsabilityFiltered : this.noAutoScoresResponsabilityFiltered
          let previousDataFiltered = j === 0 ? this.previousAutoResponsabilityFiltered : j === 1 ? this.previousLeadersResponsabilityFiltered : j === 2 ? this.previousPairsResponsabilityFiltered : j === 3 ? this.previousDependentsResponsabilityFiltered : this.previousNoAutoScoresResponsabilityFiltered

          let autoScore = noZero(this.autoResponsabilityFiltered[1].responsability[k].score)
          let othersScore = noZero(this.noAutoScoresResponsabilityFiltered[1].responsability[k].score)
          let symbol = this.getSymbol(autoScore, othersScore)

          // Calcula porcentaje de respuestas "No sabe" para los pares
          // let unknownPercent = j === 2 ? this.getUnknownPercent(dataFiltered[1].responsability[k]) : ''

          let isValid = !isNaN(dataFiltered[1].responsability[k].score) && dataFiltered[1].responsability[k].score >= 1
          let hasValidTrend = this.hasPrevious && isValid && previousDataFiltered[1].responsability[k].score > 0
          responsability.push([
            { text: dataFiltered[1].responsability[k].name + symbol, rowSpan: 5, color: textColor, margin: [0, 20, 0, 0], border: [true, true, true, true] },
            { text: evaluator[j], bold: true, color: textColor, margin: [-3, 0, -3, 0], border: [true, true, true, true] },
            // { text: evaluator[j] + unknownPercent, bold: true, color: textColor, margin: [-3, 0, -3, 0], border: [true, true, true, true] },
            isValid ? pdfUtil.generateXDCChartT3(dataFiltered[1].responsability, k, this.round(data[1].responsability[k].score, 2), data[1].responsability[k].tenPercent, 'responsability') : { text: '--', border: [true, true, true, true] },
            pdfUtil.higherLowerResponsability(responsabilityScores[k], isValid ? dataFiltered[1].responsability[k].score : '--', textColor, [0, 4, 0, 0], 'center', [true, true, true, true]),
            { text: data[1].responsability[k].tenPercent > 0 ? this.round(data[1].responsability[k].tenPercent, 2) : '--', bold: false, color: textColor, margin: [0, 4, 0, 0], border: [true, true, true, true], alignment: 'center' },
            { text: data[1].responsability[k].score > 0 ? this.round(data[1].responsability[k].score, 2, '--') : '--', bold: false, color: textColor, margin: [0, 4, 0, 0], border: [true, true, true, true], alignment: 'center' },
            { text: `${hasValidTrend ? this.round(dataFiltered[1].responsability[k].score - previousDataFiltered[1].responsability[k].score, 2, '--') : '--'}`, bold: false, color: textColor, margin: [0, 4, 0, 0], border: [true, true, true, true], alignment: 'center' }
          ])
        }
        for (let j = 0; j < 3; j++) {
          for (let i = 0; i < 5; i++) {
            let data = i === 0 ? this.averageScores : i === 1 ? this.leadersScores : i === 2 ? this.pairsScores : i === 3 ? this.dependentsScores : this.noAutoScores
            let dataFiltered = i === 0 ? this.autoScoresFiltered : i === 1 ? this.leadersScoresFiltered : i === 2 ? this.pairsScoresFiltered : i === 3 ? this.dependentsScoresFiltered : this.noAutoScoresFiltered
            let previousDataFiltered = i === 0 ? this.previousAutoScoresFiltered : i === 1 ? this.previousLeadersScoresFiltered : i === 2 ? this.previousPairsScoresFiltered : i === 3 ? this.previousDependentsScoresFiltered : this.previousNoAutoScoresFiltered

            let autoScore = noZero(this.autoScoresFiltered[1].questions[(k * 3) + j].score)
            let othersScore = noZero(this.noAutoScoresFiltered[1].questions[(k * 3) + j].score)
            let symbol = this.getSymbol(autoScore, othersScore)

            // Calcula porcentaje de respuestas "No sabe" para los pares
            let unknownPercent = i === 2 ? this.getUnknownPercent(dataFiltered[1].questions[(k * 3) + j]) : ''

            let isValid = !isNaN(dataFiltered[1].questions[(k * 3) + j].score) && dataFiltered[1].questions[(k * 3) + j].score >= 1
            let hasValidTrend = this.hasPrevious && isValid && previousDataFiltered[1].questions[(k * 3) + j].score > 0
            behavior.push([
              { text: dataFiltered[1].questions[(k * 3) + j].question + symbol, rowSpan: 5, color: textColor, margin: [0, 20, 0, 0], border: [true, true, true, true] },
              { text: evaluator[i] + unknownPercent, bold: true, color: textColor, margin: [-3, 0, -3, 0], border: [true, true, true, true] },
              isValid ? pdfUtil.generateXDCChartT9(dataFiltered[1].questions, (k * 3) + j, this.round(data[1].questions[(k * 3) + j].leadersProm, 2), data[1].questions[(k * 3) + j].tenPercent, 'detail') : { text: '--', border: [true, true, true, true] },
              pdfUtil.higherLowerRow(respQuestionScores[k][j], isValid ? dataFiltered[1].questions[(k * 3) + j].score : '--', textColor, [0, 4, 0, 0], 'center', [true, true, true, true], 'question'),
              { text: data[1].questions[(k * 3) + j].tenPercent > 0 ? this.round(data[1].questions[(k * 3) + j].tenPercent, 2) : '--', bold: false, color: textColor, margin: [0, 4, 0, 0], border: [true, true, true, true], alignment: 'center' },
              { text: data[1].questions[(k * 3) + j].leadersProm > 0 ? this.round(data[1].questions[(k * 3) + j].leadersProm, 2) : '--', bold: false, color: textColor, margin: [0, 4, 0, 0], border: [true, true, true, true], alignment: 'center' },
              { text: `${hasValidTrend ? this.round(dataFiltered[1].questions[(k * 3) + j].score - previousDataFiltered[1].questions[(k * 3) + j].score, 2, '--') : '--'}`, bold: false, color: textColor, margin: [0, 4, 0, 0], border: [true, true, true, true], alignment: 'center' }
            ])
          }
        }
        tables.push(
          {
            table: {
              widths: [479],
              body: [
                [{ text: [
                  `${this.$t('individualReport.table')} ${9 + k}. ${this.$t('individualReport.table7')}`,
                  { text: this.$t('individualReport.direction').toUpperCase(), color: '#bcd835' }
                ],
                bold: true,
                color: '#fff',
                margin: [20, 6, 0, 6] }]
              ]
            },
            layout: {
              fillColor: '#aaaaaa',
              defaultBorder: ''
            },
            alignment: 'left',
            margin: [0, 0, 135, 10],
            pageBreak: 'before'
          },
          pdfUtil.generateDmlLegendIndividual(legend),
          {
            layout: {
              defaultBorder: '',
              border: [false, false, false, true],
              fillColor: function (rowIndex, node, columnIndex) {
                return (rowIndex === 0) ? '#BFBFBF' : null
              }
            },
            table: {
              headerRows: 1,
              widths: ['16%', '14%', '38%', '8%', '8%', '8%', '8%'],
              body: [
                [
                  { text: `${this.$t('individualReport.responsability')}`, bold: true, color: '#fff', margin: [0, 5, 0, 0], border: [true, true, true, true], fontSize: 9 },
                  { text: `${this.$t('individualReport.evaluator')}`, bold: true, color: '#fff', border: [true, true, true, true], margin: [0, 5, 0, 0], fontSize: 9 },
                  { layout: { defaultBorder: '', fillColor: '#BFBFBF' },
                    table: {
                      headerRows: 1,
                      body: [[
                        { text: '1\n|', margin: [10, 2, 15, 0] },
                        { text: '2\n|', margin: [0, 2, 15, 0] },
                        { text: '3\n|', margin: [0, 2, 15, 0] },
                        { text: '4\n|', margin: [0, 2, 15, 0] },
                        { text: '5\n|', margin: [0, 2, 15, 0] },
                        { text: '6\n|', margin: [0, 2, 15, 0] }
                      ]]
                    },
                    bold: true,
                    style: { color: '#fff' },
                    border: [true, true, true, true]
                  },
                  { text: this.$t('individualReport.score'), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 },
                  { text: this.$t('individualReport.ten_percent'), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 },
                  { text: this.$t('individualReport.leaders_average'), margin: [-3, 5, -3, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 },
                  { text: this.$t('individualReport.trend'), margin: [-3, 5, -3, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 }
                ],
                ...responsability
              ]
            },
            margin: [-10, 0, 0, 10],
            border: [false, false, false, true],
            alignment: 'center',
            fontSize: 9
          },
          {
            layout: {
              defaultBorder: '',
              border: [false, false, false, true],
              fillColor: function (rowIndex, node, columnIndex) {
                return (rowIndex === 0) ? '#BFBFBF' : null
              }
            },
            table: {
              headerRows: 1,
              widths: ['16%', '14%', '38%', '8%', '8%', '8%', '8%'],
              body: [
                [
                  { text: `${this.$t('individualReport.behavior')}`, bold: true, color: '#fff', margin: [0, 5, 0, 0], border: [true, true, true, true], fontSize: 9 },
                  { text: `${this.$t('individualReport.evaluator')}`, bold: true, color: '#fff', border: [true, true, true, true], margin: [0, 5, 0, 0], fontSize: 9 },
                  { layout: { defaultBorder: '', fillColor: '#BFBFBF' },
                    table: {
                      headerRows: 1,
                      body: [[
                        { text: '1\n|', margin: [10, 2, 15, 0] },
                        { text: '2\n|', margin: [0, 2, 15, 0] },
                        { text: '3\n|', margin: [0, 2, 15, 0] },
                        { text: '4\n|', margin: [0, 2, 15, 0] },
                        { text: '5\n|', margin: [0, 2, 15, 0] },
                        { text: '6\n|', margin: [0, 2, 15, 0] }
                      ]]
                    },
                    bold: true,
                    style: { color: '#fff' },
                    border: [true, true, true, true]
                  },
                  { text: this.$t('individualReport.score'), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 },
                  { text: this.$t('individualReport.ten_percent'), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 },
                  { text: this.$t('individualReport.leaders_average'), margin: [-3, 5, -3, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 },
                  { text: this.$t('individualReport.trend'), margin: [-3, 5, -3, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 }
                ],
                ...behavior
              ]
            },
            margin: [-10, 5, 0, 10],
            border: [false, false, false, true],
            alignment: 'center',
            fontSize: 9
          }
        )
      }
      return tables
    }
  }
}
