
export default {
  methods: {
    $setPreviousScore (res) {
      let count = 0
      let population = 0
      let scores = []
      let noAutoScores = []
      let noAutoPopulation = 0
      let leadersScores = []
      let leadersPopulation = 0
      let pairsScores = []
      let pairsPopulation = 0
      let dependentsScores = []
      let dependentsPopulation = 0
      let scoresResponsability = []
      let noAutoScoresResponsability = []
      let leadersResponsability = []
      let pairsResponsability = []
      let dependentsResponsability = []
      let headers = ['as_person', 'management', 'mobilization', 'achievement']
      const generalEvaluation = res.questionnaire.evaluations.generalEvaluation
      // Inicializando objetos
      for (let i = 0; i < headers.length; i++) {
        scores[i] = { name: headers[i], questions: [], total: 0 }
        scoresResponsability[i] = { name: headers[i], responsability: [] }
        noAutoScores[i] = { name: headers[i], questions: [], total: 0 }
        noAutoScoresResponsability[i] = { name: headers[i], responsability: [] }
        leadersScores[i] = { name: headers[i], questions: [], total: 0 }
        leadersResponsability[i] = { name: headers[i], responsability: [] }
        pairsScores[i] = { name: headers[i], questions: [], total: 0 }
        pairsResponsability[i] = { name: headers[i], responsability: [] }
        dependentsScores[i] = { name: headers[i], questions: [], total: 0 }
        dependentsResponsability[i] = { name: headers[i], responsability: [] }
        if (i === 0) {
          // as_person
          for (const j of generalEvaluation[i].responsability) {
            scoresResponsability[i].responsability[0] = { name: j[this.user.lang], score: 0, index: 1, questions: [], nullCount: 0 }
            noAutoScoresResponsability[i].responsability[0] = { name: j[this.user.lang], score: 0, index: 1, questions: [], nullCount: 0 }
            leadersResponsability[i].responsability[0] = { name: j[this.user.lang], score: 0, index: 1, questions: [], nullCount: 0 }
            pairsResponsability[i].responsability[0] = { name: j[this.user.lang], score: 0, index: 1, questions: [], nullCount: 0 }
            dependentsResponsability[i].responsability[0] = { name: j[this.user.lang], score: 0, index: 1, questions: [], nullCount: 0 }
            for (let k = 0; k < 10; k++) {
              scoresResponsability[i].responsability[0].questions.push(k + 1)
              noAutoScoresResponsability[i].responsability[0].questions.push(k + 1)
              leadersResponsability[i].responsability[0].questions.push(k + 1)
              pairsResponsability[i].responsability[0].questions.push(k + 1)
              dependentsResponsability[i].responsability[0].questions.push(k + 1)
              scores[i].questions[k] = { score: 0, index: k + 1, question: j.questions[k][this.user.lang], nullCount: 0, responsability: j[this.user.lang] }
              noAutoScores[i].questions[k] = { score: 0, index: k + 1, question: j.questions[k][this.user.lang], nullCount: 0, responsability: j[this.user.lang] }
              leadersScores[i].questions[k] = { score: 0, index: k + 1, question: j.questions[k][this.user.lang], nullCount: 0, responsability: j[this.user.lang] }
              pairsScores[i].questions[k] = { score: 0, index: k + 1, question: j.questions[k][this.user.lang], nullCount: 0, responsability: j[this.user.lang] }
              dependentsScores[i].questions[k] = { score: 0, index: k + 1, question: j.questions[k][this.user.lang], nullCount: 0, responsability: j[this.user.lang] }
            }
          }
        } else {
          // management, mobilization, achievement
          let counter = 0
          for (const j of generalEvaluation[i].responsability) {
            scoresResponsability[i].responsability[j.index - 1] = { name: j[this.user.lang], score: 0, index: j.index, questions: [], nullCount: 0 }
            noAutoScoresResponsability[i].responsability[j.index - 1] = { name: j[this.user.lang], score: 0, index: j.index, questions: [], nullCount: 0 }
            leadersResponsability[i].responsability[j.index - 1] = { name: j[this.user.lang], score: 0, index: j.index, questions: [], nullCount: 0 }
            pairsResponsability[i].responsability[j.index - 1] = { name: j[this.user.lang], score: 0, index: j.index, questions: [], nullCount: 0 }
            dependentsResponsability[i].responsability[j.index - 1] = { name: j[this.user.lang], score: 0, index: j.index, questions: [], nullCount: 0 }
            for (let k = 0; k < j.questions.length; k++) {
              scoresResponsability[i].responsability[j.index - 1].questions.push(counter + 1)
              noAutoScoresResponsability[i].responsability[j.index - 1].questions.push(counter + 1)
              leadersResponsability[i].responsability[j.index - 1].questions.push(counter + 1)
              pairsResponsability[i].responsability[j.index - 1].questions.push(counter + 1)
              dependentsResponsability[i].responsability[j.index - 1].questions.push(counter + 1)
              scores[i].questions[counter] = { score: 0, index: counter + 1, question: j.questions[k][this.user.lang], nullCount: 0, responsability: j[this.user.lang] }
              noAutoScores[i].questions[counter] = { score: 0, index: counter + 1, question: j.questions[k][this.user.lang], nullCount: 0, responsability: j[this.user.lang] }
              leadersScores[i].questions[counter] = { score: 0, index: counter + 1, question: j.questions[k][this.user.lang], nullCount: 0, responsability: j[this.user.lang] }
              pairsScores[i].questions[counter] = { score: 0, index: counter + 1, question: j.questions[k][this.user.lang], nullCount: 0, responsability: j[this.user.lang] }
              dependentsScores[i].questions[counter] = { score: 0, index: counter + 1, question: j.questions[k][this.user.lang], nullCount: 0, responsability: j[this.user.lang] }
              counter++
            }
          }
        }
      }
      let answersDimention = []
      let arrayEvaluators = []
      let autoevaluationCompleted = false
      // Recorrer cada evaluado para sacar los promedios
      for (let index = 0; index < res.evaluated.length; index++) {
        for (let key in res.evaluated[index]) {
          if (res.evaluated[index][key].length === undefined) { // Para autoevaluación
            if (res.evaluated[index][key].completed) {
              autoevaluationCompleted = true
              answersDimention = res.evaluated[index][key].answersDimention
              count = 0
              for (const j of answersDimention) {
                let counter = 0
                let respCount = 0
                let dimensionTotal = 0
                for (const k of j.responsability) {
                  let respTotal = 0
                  let nullCount = 0
                  for (let l = 0; l < k.questions.length; l++) {
                    if (k.questions[l].score !== -1) {
                      scores[count].questions[counter].score += k.questions[l].score
                      respTotal += k.questions[l].score
                    } else {
                      scores[count].questions[counter].nullCount += 1
                      nullCount++
                    }
                    counter++
                  }
                  if (k.questions.length - nullCount > 0) {
                    dimensionTotal += respTotal / (k.questions.length - nullCount)
                  }
                  respCount++
                }
                count++
              }
              population++
            }
          } else { // Para el resto (lideres, pares, colaboradores)
            arrayEvaluators = res.evaluated[index][key]
            for (const i of arrayEvaluators) {
              if (key === 'leadersEvaluators') {
                if (i.completed) {
                  answersDimention = i.answersDimention
                  count = 0
                  if (autoevaluationCompleted) {
                    for (const j of answersDimention) {
                      let counter = 0
                      let respCount = 0
                      for (const k of j.responsability) {
                        let respTotal = 0
                        let nullCount = 0
                        for (let l = 0; l < k.questions.length; l++) {
                          if (k.questions[l].score !== -1) {
                            leadersScores[count].questions[counter].score += k.questions[l].score
                            respTotal += k.questions[l].score
                          } else {
                            leadersScores[count].questions[counter].nullCount += 1
                            nullCount++
                          }
                          counter++
                        }
                        respCount++
                      }
                      count++
                    }
                    leadersPopulation++
                  }
                }
              } else if (key === 'pairsEvaluators') {
                if (i.completed) {
                  answersDimention = i.answersDimention
                  count = 0
                  if (autoevaluationCompleted) {
                    for (const j of answersDimention) {
                      let counter = 0
                      let respCount = 0
                      for (const k of j.responsability) {
                        let respTotal = 0
                        let nullCount = 0
                        for (let l = 0; l < k.questions.length; l++) {
                          if (k.questions[l].score !== -1) {
                            pairsScores[count].questions[counter].score += k.questions[l].score
                            respTotal += k.questions[l].score
                          } else {
                            pairsScores[count].questions[counter].nullCount += 1
                            nullCount++
                          }
                          counter++
                        }
                        respCount++
                      }
                      count++
                    }
                    pairsPopulation++
                  }
                }
              } else {
                if (i.completed) {
                  answersDimention = i.answersDimention
                  count = 0
                  if (autoevaluationCompleted) {
                    for (const j of answersDimention) {
                      let counter = 0
                      let respCount = 0
                      for (const k of j.responsability) {
                        let respTotal = 0
                        let nullCount = 0
                        for (let l = 0; l < k.questions.length; l++) {
                          if (k.questions[l].score !== -1) {
                            dependentsScores[count].questions[counter].score += k.questions[l].score
                            respTotal += k.questions[l].score
                          } else {
                            dependentsScores[count].questions[counter].nullCount += 1
                            nullCount++
                          }
                          counter++
                        }
                        respCount++
                      }
                      count++
                    }
                    dependentsPopulation++
                  }
                }
              }
              // Total promedio de cada pregunta incluyendo autoevaluación.
              if (i.completed) {
                answersDimention = i.answersDimention
                count = 0
                if (autoevaluationCompleted) {
                  for (const j of answersDimention) {
                    let counter = 0
                    let respCount = 0
                    let dimensionTotal = 0
                    for (const k of j.responsability) {
                      let respTotal = 0
                      let nullCount = 0
                      for (let l = 0; l < k.questions.length; l++) {
                        if (k.questions[l].score !== -1) {
                          scores[count].questions[counter].score += k.questions[l].score
                          respTotal += k.questions[l].score
                        } else {
                          scores[count].questions[counter].nullCount += 1
                          nullCount++
                        }
                        counter++
                      }
                      if (k.questions.length - nullCount > 0) {
                        dimensionTotal += respTotal / (k.questions.length - nullCount)
                      }
                      respCount++
                    }
                    count++
                  }
                  population++
                }
                // Total promedio de cada pregunta sin autoevaluación.
                count = 0
                if (autoevaluationCompleted) {
                  for (const j of answersDimention) {
                    let counter = 0
                    let respCount = 0
                    for (const k of j.responsability) {
                      let respTotal = 0
                      let nullCount = 0
                      for (let l = 0; l < k.questions.length; l++) {
                        if (k.questions[l].score !== -1) {
                          noAutoScores[count].questions[counter].score += k.questions[l].score
                          respTotal += k.questions[l].score
                        } else {
                          noAutoScores[count].questions[counter].nullCount += 1
                          nullCount++
                        }
                        counter++
                      }
                      respCount++
                    }
                    count++
                  }
                  noAutoPopulation++
                }
              }
            }
          }
        }
      }
      this.previousLeadersPopulation = leadersPopulation
      this.previousPairsPopulation = pairsPopulation
      this.previousDependentsPopulation = dependentsPopulation
      for (let i = 0; i < scores.length; i++) {
        let acumDimension = 0
        let noAutoAcumDimension = 0
        let leadersAcumDimension = 0
        let pairsAcumDimension = 0
        let dependentsAcumDimension = 0
        let scoresCount = 0
        let noAutoCount = 0
        let leadersCount = 0
        let pairsCount = 0
        let dependentsCount = 0
        for (let j = 0; j < scores[i].questions.length; j++) {
          // Promedio de todos incluyendo autoevaluación
          if ((population - scores[i].questions[j].nullCount) === 0) {
            scores[i].questions[j].score = 0
          } else {
            scores[i].questions[j].score = scores[i].questions[j].score / (population - scores[i].questions[j].nullCount)
            scoresCount++
          }
          acumDimension += scores[i].questions[j].score
          // Promedio de todos sin autoevaluación
          if ((noAutoPopulation - noAutoScores[i].questions[j].nullCount) === 0) {
            noAutoScores[i].questions[j].score = 0
          } else {
            noAutoScores[i].questions[j].score = noAutoScores[i].questions[j].score / (noAutoPopulation - noAutoScores[i].questions[j].nullCount)
            noAutoCount++
          }
          noAutoAcumDimension += noAutoScores[i].questions[j].score
          // Lideres
          if ((leadersPopulation - leadersScores[i].questions[j].nullCount) === 0) {
            leadersScores[i].questions[j].score = 0
          } else {
            leadersScores[i].questions[j].score = leadersScores[i].questions[j].score / (leadersPopulation - leadersScores[i].questions[j].nullCount)
            leadersCount++
          }
          leadersAcumDimension += leadersScores[i].questions[j].score
          // Pares
          if ((pairsPopulation - pairsScores[i].questions[j].nullCount) === 0) {
            pairsScores[i].questions[j].score = 0
          } else {
            pairsScores[i].questions[j].score = pairsScores[i].questions[j].score / (pairsPopulation - pairsScores[i].questions[j].nullCount)
            pairsCount++
          }
          pairsAcumDimension += pairsScores[i].questions[j].score
          // Colaboradores
          if ((dependentsPopulation - dependentsScores[i].questions[j].nullCount) === 0) {
            dependentsScores[i].questions[j].score = 0
          } else {
            dependentsScores[i].questions[j].score = dependentsScores[i].questions[j].score / (dependentsPopulation - dependentsScores[i].questions[j].nullCount)
            dependentsCount++
          }
          dependentsAcumDimension += dependentsScores[i].questions[j].score
        }
        for (let resp = 0; resp < scoresResponsability[i].responsability.length; resp++) {
          for (let sc = 0; sc < scoresResponsability[i].responsability[resp].questions.length; sc++) {
            if (scores[i].questions[scoresResponsability[i].responsability[resp].questions[sc] - 1].score === 0) {
              scoresResponsability[i].responsability[resp].nullCount++
            }
            scoresResponsability[i].responsability[resp].score += scores[i].questions[scoresResponsability[i].responsability[resp].questions[sc] - 1].score
            if (noAutoScores[i].questions[noAutoScoresResponsability[i].responsability[resp].questions[sc] - 1].score === 0) {
              noAutoScoresResponsability[i].responsability[resp].nullCount++
            }
            noAutoScoresResponsability[i].responsability[resp].score += noAutoScores[i].questions[noAutoScoresResponsability[i].responsability[resp].questions[sc] - 1].score
            if (leadersScores[i].questions[leadersResponsability[i].responsability[resp].questions[sc] - 1].score === 0) {
              leadersResponsability[i].responsability[resp].nullCount++
            }
            leadersResponsability[i].responsability[resp].score += leadersScores[i].questions[leadersResponsability[i].responsability[resp].questions[sc] - 1].score
            if (pairsScores[i].questions[pairsResponsability[i].responsability[resp].questions[sc] - 1].score === 0) {
              pairsResponsability[i].responsability[resp].nullCount++
            }
            pairsResponsability[i].responsability[resp].score += pairsScores[i].questions[pairsResponsability[i].responsability[resp].questions[sc] - 1].score
            if (dependentsScores[i].questions[dependentsResponsability[i].responsability[resp].questions[sc] - 1].score === 0) {
              dependentsResponsability[i].responsability[resp].nullCount++
            }
            dependentsResponsability[i].responsability[resp].score += dependentsScores[i].questions[dependentsResponsability[i].responsability[resp].questions[sc] - 1].score
          }
          scoresResponsability[i].responsability[resp].score = scoresResponsability[i].responsability[resp].questions.length - scoresResponsability[i].responsability[resp].nullCount > 0
            ? scoresResponsability[i].responsability[resp].score / (scoresResponsability[i].responsability[resp].questions.length - scoresResponsability[i].responsability[resp].nullCount) : '--'
          noAutoScoresResponsability[i].responsability[resp].score = noAutoScoresResponsability[i].responsability[resp].questions.length - noAutoScoresResponsability[i].responsability[resp].nullCount > 0
            ? noAutoScoresResponsability[i].responsability[resp].score / (noAutoScoresResponsability[i].responsability[resp].questions.length - noAutoScoresResponsability[i].responsability[resp].nullCount) : '--'
          leadersResponsability[i].responsability[resp].score = leadersResponsability[i].responsability[resp].questions.length - leadersResponsability[i].responsability[resp].nullCount > 0
            ? leadersResponsability[i].responsability[resp].score / (leadersResponsability[i].responsability[resp].questions.length - leadersResponsability[i].responsability[resp].nullCount) : '--'
          pairsResponsability[i].responsability[resp].score = pairsResponsability[i].responsability[resp].questions.length - pairsResponsability[i].responsability[resp].nullCount > 0
            ? pairsResponsability[i].responsability[resp].score / (pairsResponsability[i].responsability[resp].questions.length - pairsResponsability[i].responsability[resp].nullCount) : '--'
          dependentsResponsability[i].responsability[resp].score = dependentsResponsability[i].responsability[resp].questions.length - dependentsResponsability[i].responsability[resp].nullCount > 0
            ? dependentsResponsability[i].responsability[resp].score / (dependentsResponsability[i].responsability[resp].questions.length - dependentsResponsability[i].responsability[resp].nullCount) : '--'
        }
        scores[i].total = scoresCount > 0 ? acumDimension / scoresCount : '--'
        noAutoScores[i].total = noAutoCount > 0 ? noAutoAcumDimension / noAutoCount : '--'
        leadersScores[i].total = leadersCount > 0 ? leadersAcumDimension / leadersCount : '--'
        pairsScores[i].total = pairsCount > 0 ? pairsAcumDimension / pairsCount : '--'
        dependentsScores[i].total = dependentsCount > 0 ? dependentsAcumDimension / dependentsCount : '--'
      }
      this.previousAverageScores = scores
      this.previousNoAutoScores = noAutoScores
      this.previousLeadersScores = leadersScores
      this.previousPairsScores = pairsScores
      this.previousDependentsScores = dependentsScores
      this.previousAverageResponsability = scoresResponsability
      this.previousNoAutoScoresResponsability = noAutoScoresResponsability
      this.previousLeadersResponsability = leadersResponsability
      this.previousPairsResponsability = pairsResponsability
      this.previousDependentsResponsability = dependentsResponsability
    }
  }
}
