
export default {
  dashboard: 'Dashboard',
  enterprises: 'Enterprises',
  employees: 'Employees',
  engagements: 'Engagement',
  questionnairesEngagement: 'Questionnaires Engagement',
  questionnairesPulse: 'Questionnaires Pulse',
  engagement: 'Engagement',
  pulse: 'Pulse',
  setting: 'Setting',
  academic_degrees: 'Academic degrees',
  languages: 'Languages',
  departments: 'Departments',
  countries: 'Countries',
  demographic_cuts: 'Demographic cuts',
  enterprise_sizes: 'Enterprise Size',
  genders: 'Genders',
  job_types: 'Job types',
  sectors: 'Sectors',
  committalQuestions: 'Committal questions',
  wordQuestions: 'Open questions',
  group: 'Group',
  headquarters: 'Headquarters',
  charges: 'Charges levels',
  ages: 'Age range',
  antiquity: 'Antiquity range',
  sign_out: 'Sign out',
  token: 'OCC Tokens',
  profile: 'Profile',
  go_back: 'Go back',
  language: 'Language',
  calculator: 'Calculator',
  operations: 'Operations Resume',
  services: 'Prices and recomendations',
  enterprises_list: 'Enterprises',
  profile_list: 'Profile',
  measuring_tools: 'Medition tools',
  customers: 'Customers',
  terms_and_conditions: 'Terms and conditions',
  products: 'Products',
  token_price: 'Price of Tokens',
  questionnaires: 'Questionnaires',
  evaluations: 'Evaluations',
  suite: 'Suite'
}
