
import mapParameters from '../utils/map-parameters'
import Services from './base-services'

const service = new Services('token-account-detail')

interface IOptionsList {
  page?: number;
  itemsPerPage?: number;
  filter?: string;
  search?: string;
}

export default {
  listByUser: (options: IOptionsList) => {
    const params = mapParameters({
      page: options && options.page ? options.page : null,
      rowsPerPage: options && options.itemsPerPage ? options.itemsPerPage : null,
      filter: options && options.filter ? options.filter : null,
      search: options && options.search ? options.search : null
    })
    return service.get('by-user', params)
  },
  listByUserWithEnterprises: (options: IOptionsList) => {
    const params = mapParameters({
      page: options && options.page ? options.page : null,
      rowsPerPage: options && options.itemsPerPage ? options.itemsPerPage : null,
      filter: options && options.filter ? options.filter : null,
      search: options && options.search ? options.search : null
    })
    return service.get('by-user-with-enterprises', params)
  },
  listByGroup: (options: IOptionsList) => {
    const params = mapParameters({
      page: options && options.page ? options.page : null,
      rowsPerPage: options && options.itemsPerPage ? options.itemsPerPage : null,
      filter: options && options.filter ? options.filter : null,
      search: options && options.search ? options.search : null
    })
    return service.get('by-group', params)
  },
  listByGroupWithCustomers: (options: IOptionsList) => {
    const params = mapParameters({
      page: options && options.page ? options.page : null,
      rowsPerPage: options && options.itemsPerPage ? options.itemsPerPage : null,
      filter: options && options.filter ? options.filter : null,
      search: options && options.search ? options.search : null
    })
    return service.get('by-group-with-customers', params)
  },
  getBalance: () => {
    return service.get('balance')
  },
  getBalanceFromSuite: () => {
    return service.suiteOperation(() => service.get('balance'))
  },
  getBalanceByGroup: () => {
    return service.get('balance-group')
  },
  buy: (mount: number, isGroupPurchase?: boolean) => {
    return service.post('buy', { mount, isGroupPurchase })
  },
  create: (data: object) => {
    return service.post('create', data)
  },
  getOne: (id: number) => {
    return service.get(`${id}`)
  },
  move: (toEntityId: number, mount: number, isGroupMovement?: boolean) => {
    return service.post('move', { toEntityId, mount, isGroupMovement })
  },
  getCustomerBalance: (customerId: number) => {
    return service.get(`/by-customer/${customerId}`)
  },
  getGroupBalance: (groupId: number) => {
    return service.get(`/by-group/${groupId}`)
  },
  spendSuite: (data: object) => {
    return service.suiteOperation(() => service.post('spend', data))
  }
}
