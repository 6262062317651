<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="10">
        <h4 class="display-1"> {{$t('navigation.questionnaires')}} </h4>
      </v-col>
      <v-col cols="12" sm="2" align="end">
        <v-btn
          to="/questionnaires/create"
          class="text-capitalize"
          color="primary"
          block
        >
          <v-icon left>fa-plus-circle</v-icon>&nbsp;&nbsp;{{ $t('input.create') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-card class="mt-2 px-4">
      <v-row>
        <v-col>
          <x-data-table
            :headers="headers"
            :parent-fetch-data="getQuestionnaires"
            :options="options"
            :reload="reloadTable"
            no-data="questionnaires.no_data"
            @offReload="offReload"
          >
            <template v-slot:structure="prop">
              <td class="text-left text-capitalize">
                {{ prop.item.name }}
              </td>
              <td class="text-center" style="width: 26%;">
                {{ prop.item.updatedAt.split('T')[0] | date({date: true, hour:false}) }}
              </td>
              <td class="text-center align-center px-0" style="width: 14%;">
                <v-switch hide-details
                  v-model="prop.item.active"
                  :loading="loadingSwitch[prop.item._id]"
                  class="mt-n1 ml-12 mr-0"
                  color="primary"
                  @click="openModal(prop.item, prop.item.active)"
                />
              </td>
              <td class="text-center" style="width: 17%;">
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      :to="`questionnaires/${prop.item.slug}/edit`"
                      v-on="on"
                      text icon>
                      <v-icon small>fa-edit</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('questionnaires.edit_questions') }}</span>
                </v-tooltip>
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      :to="`questionnaires/${prop.item.slug}/edit-questionnaire`"
                      v-on="on"
                      text icon>
                      <v-icon small>fa-cog</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('questionnaires.settings') }}</span>
                </v-tooltip>
              </td>
            </template>
          </x-data-table>
        </v-col>
      </v-row>
      <x-confirmation-modal
        :show.sync="modalEna.open"
        :reversible="true"
        :title="$t('questionnaires.modal_enable')"
        :btn-save="$t('input.enable')"
        :action="enable"
        @close="modalEna.open = false"
      >
        <template v-slot:question>{{ $t('questionnaires.modal_enable_msg') }}</template>
      </x-confirmation-modal>

      <x-confirmation-modal
        :show.sync="modalDis.open"
        :reversible="true"
        :title="$t('questionnaires.modal_disable')"
        :btn-save="$t('input.disable')"
        :action="disable"
        @close="modalDis.open = false"
      >
        <template v-slot:question>{{ $t('questionnaires.modal_disable_msg') }}</template>
      </x-confirmation-modal>
    </v-card>
  </v-container>
</template>

<script>
import Vue from 'vue'

import questionnairesService from '../../services/questionnaires'

export default Vue.extend({
  data () {
    return {
      loadingSwitch: {},
      currentLoadingSwitch: '',
      questionnaries: [],
      headers: [
        'questionnaires.name',
        'questionnaires.last_edition',
        'questionnaires.status',
        'questionnaires.actions'
      ],
      options: {
        filter: null,
        search: null
      },
      reloadTable: false,
      modalDis: {
        open: false,
        item: {}
      },
      modalEna: {
        open: false,
        item: {}
      }
    }
  },
  methods: {
    getQuestionnaires (options) {
      return questionnairesService.list(options)
        .then(resp => {
          this.questionnaries = resp.items
          return resp
        })
    },
    openModal (item, activate) {
      this.modalEna.open = !activate
      this.modalDis.open = activate
      this.currentLoadingSwitch = item._id
      this.modalEna.item = item
      this.modalDis.item = item
      item.active = !activate
    },
    disable () {
      return questionnairesService.toggle(this.modalDis.item.slug, false)
        .then((res) => {
          this.modalDis.item.active = false
          this.reloadTable = true
        })
        .catch((err) => {
          if (err.code === 'limit') {
            this.$store.dispatch('alert/error', this.$t('errors.error_disable_questionnaires'))
          } else {
            this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
          }
        })
        .finally(() => {
          this.modalDis.open = false
        })
    },
    enable () {
      return questionnairesService.toggle(this.modalEna.item.slug, true)
        .then((res) => {
          this.modalEna.item.active = true
          this.reloadTable = true
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.modalEna.open = false
        })
    },
    offReload () {
      this.reloadTable = false
    },
    toggleActive (id) {
      const matchQ = this.questionnaries.find(q => q._id === id)
      matchQ.active = !matchQ.active
    }
  },
  watch: {
    'modalEna.open': {
      handler (val) {
        this.toggleActive(this.currentLoadingSwitch)
        if (val) {
          this.loadingSwitch[this.currentLoadingSwitch] = true
        } else {
          this.loadingSwitch[this.currentLoadingSwitch] = false
          this.currentLoadingSwitch = ''
        }
      }
    },
    'modalDis.open': {
      handler (val) {
        this.toggleActive(this.currentLoadingSwitch)
        if (val) {
          this.loadingSwitch[this.currentLoadingSwitch] = true
        } else {
          this.loadingSwitch[this.currentLoadingSwitch] = false
          this.currentLoadingSwitch = ''
        }
      }
    }
  }
})
</script>
