
export default {
  add_enterprise: 'Create Enterprise',
  admin_left_table_title: 'Enterprises',
  admin_bottom_table_title: 'Recent surveys',
  admin_bottom_table_header: [
    { text: 'Name', value: '', sortable: false, class: 'text-xs-left' },
    { text: 'Enterprise', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Status', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Delivered at', value: '', sortable: false, class: 'text-xs-center' }
  ],
  admin_right_table_title: 'Enterprises with plans near to expire',
  admin_right_table_header: [
    { text: 'Enterprise', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Plan', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Expire Date', value: '', sortable: false, class: 'text-xs-center' }
  ],
  create_poll: 'Create poll',
  continue_poll: 'Continue poll',
  continue: 'Continue',
  employee_left_table_title: 'Polls in progress',
  employee_progress: 'Progress:',
  enterprise_left_table_title: 'Actual plan',
  employee_right_table_title: 'Asigned Polls',
  employee_right_table_header: [
    { text: 'Name', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Period', value: '', sortable: false, class: 'text-xs-center' }
  ],
  enterprise_right_table_header: [
    { text: 'Name', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Type', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Participants', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Status', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Details', value: '', sortable: false, class: 'text-xs-center' }
  ],
  enterprise_right_table_title: 'Surveys',
  expired_plan: '(Expired plan)',
  general_list: 'General list',
  general_title: 'Dashboard',
  has_started: 'Started',
  not_started: 'To start',
  init: 'Start:',
  end: 'Expires at:',
  ended: 'Expired at:',
  finished: 'Finished',
  progress: 'In progress',
  left_days: 'Left days',
  left_questions: 'Left questions:',
  period_divisor: 'to',
  poll: 'Poll:',
  poll_completed: 'Yes',
  poll_incompleted: 'No',
  poll_init_date: 'Start:',
  poll_end_date: 'Valid until:',
  qty_enterprises: 'Active enterprises',
  qty_employees: 'Employees',
  qty_licesenses: 'Licenses',
  team_trends: 'Team trends',
  trend_chart: 'Trend chart',
  update_plan: 'Update Plan',
  dimension_leaders: 'The leaders',
  dimension_inspiration: 'My inspiration',
  dimension_job: 'My job',
  dimension_environment: 'My work environment',
  dimension_team: 'My team',
  dimension_development: 'My development and learning',
  view_poll_details: 'View poll details',
  has_no_polls: 'You need to create at least 2 polls on the same period to see the chart',
  start: 'Start',
  start_poll: 'Start assessment',
  no_pending_activities: 'No pending activities',
  employee_activities_table_header: [
    'Evaluation (Role)',
    'Action'
  ],
  autoevaluation: 'Autoevaluation',
  evaluators: 'Evaluators',
  leader: 'Leader',
  pair: 'pair',
  dependent: 'Dependent',
  name: 'Evaluation name',
  dates: 'Dates',
  status: 'Status',
  enterprise: 'Enterprise',
  customer: 'Client',
  actions: 'Actions',
  to: 'to',
  last_polls: 'Last evaluations',
  see_evaluated: 'See evaluators',
  peding: 'Pending',
  evaluations_resume: 'Evaluations resume',
  no_created_evaluations: 'You do not have evaluations created',
  go_create_evaluation: 'Go create evaluation',
  see_details: 'See details',
  evaluation_finished: 'Evaluation finished'
}
