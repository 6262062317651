export default {
  create: 'Crear cuestionario',
  edit: 'Editar cuestionario',
  no_data: 'No hay cuestionarios para mostrar',
  autoEvaluation: 'Autoevaluación',
  generalEvaluation: '360°',
  as_person: 'Como persona',
  management: 'Desarrollo',
  mobilization: 'Movilización',
  achievement: 'Logro',
  tooltip_edit: 'Debe presionar ENTER para editar',
  edit_questions: 'Editar preguntas',
  settings: 'Configuración de cuestionario',
  name: 'Nombre',
  last_edition: 'Ultima edición',
  status: 'Estado',
  actions: 'Acciones',
  customer: 'Cliente',
  enterprise: 'Empresa',
  sector: 'Sector',
  created_succesfully: 'Cuestionario creado correctamente',
  error_on_creation: 'Error en la creación del cuestionario',
  asignation_type: 'Tipo de asignación',
  assign_to: 'Asignar a',
  edited_succesfully: 'Cuestionario editado correctamente',
  error_editing: 'Error en la edición del cuestionario',
  responsability: 'Responsabilidad',
  modal_enable: '¿Desea habilitar el cuestionario?',
  modal_enable_msg: 'Una vez habilitado, el cuestionario nuevamente podrá ser seleccionado para la creación de mediciones por los clientes que lo disponen',
  modal_disable: '¿Desea deshabilitar el cuestionario?',
  modal_disable_msg: 'Una vez deshabilitado, los clientes que disponen de este cuestionario no podrán elegir el cuestionario para crear una medición',
  question: 'Pregunta'
}
