<template>
  <v-data-table
    :items="evaluated"
    :options="options"
    :items-per-page="10"
  >
    <template v-slot:header="{ props }">
      <thead>
      <tr>
        <th style="border: none"></th>
        <th :colspan="3" class="text-center">{{ $t('evaluations.evaluators') }}</th>
        <th style="border: none"></th>
      </tr>
      <tr>
        <th v-for="header in formattedHeaders()" :key="header" class="text-center">{{ header }}</th>
      </tr>
      </thead>
    </template>

    <template v-slot:body="{ items }">
      <tbody>
      <tr v-if="items.length === 0">
        <td :colspan="formattedHeaders().length" class="text-center">{{ $t('evaluations.eval_no_data') }}</td>
      </tr>
      <tr v-for="(item, index) in items" :key="item.employee.employee.employeeId">
        <td class="text-center">{{ item.employee.employee.firstName }} {{ item.employee.employee.lastName }}</td>
        <td class="text-center">{{ item.leadersEvaluators.length }}</td>
        <td class="text-center">{{ item.pairsEvaluators.length }}</td>
        <td class="text-center">{{ item.dependentsEvaluators.length }}</td>
        <td class="text-center">
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on }">
              <v-btn
                @click="$emit('showList', item)"
                v-on="on"
                text icon>
                <v-icon small>fa-eye</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('evaluations.show_list') }}</span>
          </v-tooltip>
          <v-tooltip bottom color="primary" v-if="canEdit">
            <template v-slot:activator="{ on }">
              <v-btn
                @click="$emit('edit', item)"
                v-on="on"
                text icon>
                <v-icon small>edit</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('evaluations.edit_evaluators') }}</span>
          </v-tooltip>
          <v-tooltip bottom color="primary" v-if="!item.permanent">
            <template v-slot:activator="{ on }">
              <v-btn
                @click="$emit('delete', index)"
                v-on="on"
                icon color="red">
                <v-icon small>mdi-trash-can</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('input.trash') }}</span>
          </v-tooltip>
        </td>
      </tr>
      </tbody>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: 'evaluated-table',
  props: {
    status: String,
    evaluated: Array
  },
  data () {
    return {
      headers: [
        'evaluations.evaluate',
        'evaluations.leaders',
        'evaluations.pairs',
        'evaluations.dependents',
        'evaluations.actions'
      ],
      options: {
        filter: null,
        search: null
      }
    }
  },
  computed: {
    canEdit () {
      return !this.status || this.status === 'pending' || this.status === 'in_progress'
    }
  },
  methods: {
    formattedHeaders () {
      return this.headers.map((header) => {
        return this.$t(header)
      })
    }
  }
}
</script>

<style scoped>
  .v-data-table th {
    font-size: 16px;
    border-bottom: 1px solid #0000001f;
  }
</style>
