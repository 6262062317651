
<template>
  <v-app>
    <v-content>
      <v-container style="backgorund-coler: grey;">
        <v-row align="center" justify="center">
          <v-col cols="12" class="text-center">
            <img class="hidden-xs-only" src="/img/20200301_occ_solution_logo.png" alt="OCC - Solutions Logo" style="width: 25em; height: 25em;"/>
            <img class="hidden-sm-and-up" src="/img/20200301_occ_solution_logo.png" alt="OCC - Solutions Logo" style="width: 250px;"/>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="12" class="mb-3 mt-4">
            <h2 class="display-2 text-center">{{ $t('operationSummary.title')}}</h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="text-center">
            <span class="display-1">{{ $t('evaluations.workshop_cost') }}: </span>
            <span class="display-1">
              {{price}} {{ $t('evaluations.token_unit') }}
            </span>
          </v-col>
          <v-col cols="12" class="text-center">
            <span class="display-1">{{ $t('evaluations.your_balance') }} </span>
            <span class="display-1">
              {{balance}} {{ $t('evaluations.token_unit') }}
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="text-center">
            <span>{{ $t('operationSummary.information') }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="text-center">
            <v-btn :href="operationsLink" color="primary">{{ $t('operationSummary.get_more_tokens') }}</v-btn>
          </v-col>
          <v-col cols="12" class="text-center">
            <span class="display-1">{{ $t('operationSummary.or') }}</span>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-btn v-if="$route.params.type === 'individual'" @click="$router.push('/evaluations')" color="primary">{{ $t('operationSummary.go_to_evaluations_list') }}</v-btn>
            <v-btn v-else @click="$router.push('/evaluations')" color="primary">{{ $t('operationSummary.go_to_evaluations_list') }}</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>

import Vue from 'vue'
import { mapState } from 'vuex'

import balanceService from '../../services/token-account-detail'
import evaluationsService from '../../services/evaluations'
import servicePriceService from '../../services/product-services'

export default Vue.extend({
  data () {
    return {
      price: 0,
      balance: 0,
      servicePrice: 0,
      operationsLink: '',
      type: '',
      population: 0
    }
  },
  methods: {
    getBalance () {
      this.$store.dispatch('loading/show')
      return balanceService.getBalanceFromSuite()
        .then((res) => {
          if (res.balance === undefined) {
            this.$store.dispatch('alert/error', this.$t('errors.no_balance'))
            this.$store.dispatch('loading/hide')
          } else {
            this.balance = res.balance
          }
        })
    },
    getEvaluation () {
      this.$store.dispatch('loading/show')
      if (this.$route.params.type === 'individual') {
        return evaluationsService.getOne(this.$route.params.slug)
          .then((res) => {
            if (res.evaluatedCount) {
              this.price = this.price * res.evaluatedCount
              this.$store.dispatch('loading/hide')
            } else {
              this.price = 0
              this.$store.dispatch('alert/error', this.$t('errors.invalid_evaluation'))
              this.$store.dispatch('loading/hide')
            }
          }).catch((err) => {
            this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
            this.$store.dispatch('loading/hide')
          })
      } else if (this.$route.params.type === 'organizational') {
        this.$store.dispatch('loading/hide')
      }
    },
    getServicePrice () {
      if (this.$route.params.type === 'individual') {
        this.type = 'OCC DML 360° INDIVIDUAL'
      } else if (this.$route.params.type === 'organizational') {
        this.type = 'REPORTE ORGANIZACIONAL OCC DML 360°'
      }
      this.$store.dispatch('loading/show')
      return servicePriceService.getOne(this.type)
        .then((res) => {
          if (res.productServiceId) {
            this.getServicePriceFromSuite(res)
          } else {
            this.$store.dispatch('alert/error', this.$t('errors.invalid_service'))
            this.$store.dispatch('loading/hide')
          }
        })
    },
    getServicePriceFromSuite (service) {
      this.$store.dispatch('loading/show')
      return servicePriceService.getByIdFromSuite(service.productServiceId)
        .then((res) => {
          if (res.tokenPrice) {
            this.price = res.tokenPrice
            this.getEvaluation()
          } else {
            this.$store.dispatch('alert/error', this.$t('errors.invalid_service'))
            this.$store.dispatch('loading/hide')
          }
        })
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    })
  },
  created () {
    this.$store.dispatch('loading/show')
    this.getBalance()
    this.getServicePrice()
    this.$store.dispatch('session/getSuiteWebHost')
      .then((res) => {
        if (res) {
          this.operationsLink = `${res}/tokens/operations`
        } else {
          next('/404')
        }
      })
  }
})
</script>
