
<template>
  <v-app>
    <v-row class="grey lighten-3">
      <v-col cols="12">
        <router-view />
      </v-col>
    </v-row>
  </v-app>
</template>
<script>
export default {
  data () {
    return {}
  }
}
</script>
<style lang="scss">

</style>
