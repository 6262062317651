export default {
  index_title: 'Contenido del informe',
  index: [
    '1. Responsabilidad con Puntaje Mayor y Menor según Relación',
    '2. Conductas con Puntaje Mayor y Menor según Relación',
    '3. Resultados por Proceso',
    '4. Resultados por Responsabilidad',
    '5. Resultados por Conducta',
    '6. Análisis de Dispersión por Responsabilidad y Conducta',
    '7. Análisis de Tendencia por Responsabilidad y Conducta',
    '8. Análisis de Brechas por Responsabilidad y Conducta'
  ],
  title: 'Informe de Resultados',
  subtitle: 'Reporte Por Corte Demográfico',
  poll_answers: 'Respuestas a la encuesta',
  autoevaluations: 'Autovaloraciones',
  leaders: 'Líder(es)',
  pairs: 'Pares',
  dependents: 'Colaboradores',
  total: 'Total',
  received_evaluations: 'Valoraciones Recibidas',
  requested_evaluations: 'Valoraciones Solicitadas',
  answers_rate: 'Porcentaje de Respuestas',
  table: 'Tabla',
  table1: 'Tres Puntajes más altos y bajos según Otros; donde Otros es el promedio de todas las relaciones (Líderes, Pares y Colaboradores)',
  table2: 'Tres Puntajes más altos y bajos según Líder(es)',
  table3: 'Tres Puntajes más altos y bajos según Pares',
  table4: 'Tres Puntajes más altos y bajos según Colaboradores',
  table5: 'Seis Puntajes más altos según Otros; donde Otros es el promedio de todas las relaciones (Líderes, Pares y Colaboradores)',
  table6: 'Seis Puntajes más bajos según Otros; donde Otros es el promedio de todas las relaciones (Líderes, Pares y Colaboradores)',
  table7: 'Seis Puntajes más altos según Líder(es)',
  table8: 'Seis Puntajes más bajos según Líder(es)',
  table9: 'Seis Puntajes más altos según Pares',
  table10: 'Seis Puntajes más bajos según Pares',
  table11: 'Seis Puntajes más altos según Colaboradores',
  table12: 'Seis Puntajes más bajos según Colaboradores',
  table13: 'Resultados por proceso',
  table14: 'Resultados por Responsabilidad para el proceso de ',
  table17: 'Resultados por Conducta para el proceso de ',
  table21: 'Dispersión por Responsabilidad',
  table22: 'Dispersión por Conducta',
  table23: 'Tendencia por Responsabilidad',
  table24: 'Tendencia por Conducta',
  table25: 'Brecha por Responsabilidad',
  table26: 'Brecha por Conducta',
  legend_description: 'En las barras de la siguiente tabla, el color gris, el rombo y la cruz representan:',
  dispersion_description: 'La dispersión se calcula como la desviación estándar de los datos',
  table_description: 'En las barras de las tablas, el color gris, la cruz y el rombo representan: ',
  higher_scores: 'Puntajes más altos',
  lower_scores: 'Puntajes más bajos',
  process: 'Proceso',
  process_abbr: 'P',
  responsability: 'Responsabilidad',
  behavior: 'Conducta',
  score: 'Puntaje Total',
  cutScore: 'Puntaje Corte',
  cutScore_abbr: 'Punt. Corte',
  previous_score: 'Puntaje Anterior',
  previous_score_abbr: 'Punt. Ant.',
  previous: 'Anterior',
  current: 'Actual',
  ten_percent: '10% Superior',
  ten_percent_abbr: '10% Sup.',
  dispersion: 'Dispersión',
  trend: 'Tendencia',
  trend_abbr: 'Tend.',
  data_range: 'Rango de datos (máx-min)',
  higher_bold: 'Calificaciones más altas en negrilla',
  lower_sub: 'Calificaciones más bajas subrayadas',
  management: 'Dirección',
  mobilization: 'Movilización',
  achievement: 'Logro',
  as_person: 'Como persona',
  leader_as_person: 'Líder como persona',
  results: 'Resultados',
  copyright: 'OCC - Todos los derechos reservados.',
  higher_dispersion: 'Mayor dispersión',
  lower_dispersion: 'Menor dispersión',
  higher_gap: 'Brechas más altas positivas',
  lower_gap: 'Brechas más altas negativas',
  gap: 'Brecha',
  operation_init: 'Se ha dado inicio a un proceso de descarga "Reporte por corte demográfico", este proceso puede tardar varios minutos, puede ver su progreso al refrescar la página',
  months: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
  ],
  lead_by_example: 'Lidera la cultura con el ejemplo',
  ensures_learning: 'Asegura aprendizaje continuo y desarrollo integral',
  confirm_report_title: 'Confirme la generación del reporte',
  report_cost: 'Costo del reporte',
  balance_after: 'Saldo luego de generar el reporte:',
  confirm_btn: 'Confirmar y Generar',
  organizational_score_abbr: 'Punt. Org.'
}
