
export default {
  profile_information: 'Profile information',
  has_not_enterprise: '(Do not have assigned enterprise)',
  commercial_account: 'Commercia account',
  available_tokens: 'Available tokens',
  contact_information: 'ID - Contact information',
  registered_enterprises: 'Registered enterprises',
  changed_password_msg: 'Your password has been changed succesfully </br>Please login again',
  changed_email_msg: 'We have sent an email to <span style="color: #3899da">{email}</span>,<br> to verify your account, please check your email inbox o the <i>spam</ i > folder. Confirm your account and login again',
  customers_list: 'Customers list',
  resumen: 'Customers Resume',
  look_for: 'Search customer',
  filter_by: 'Filter by',
  no_data: 'There is no customers to show',
  customer_name: 'Name',
  register_date: 'Register date',
  balance: 'Balance',
  status: 'Status',
  actions: 'Actions',
  modal_enable: 'Do you want to enable the customer?',
  modal_disable: 'Do you want to disable the customer?',
  modal_enable_des: 'Once enabled, the customer can access the system again',
  // tslint:disable-next-line:max-line-length
  modal_disable_des: 'Once the customer is disabled, it will not be able to access the system until it is enabled again',
  add_customer: 'Create customer',
  type: 'type',
  commercial: 'Commercial',
  personal: 'Personal'
}
