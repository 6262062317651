<template>
      <v-container flat>
        <v-card flat>
          <ValidationObserver v-slot="{ handleSubmit }">
            <v-form @submit.prevent="handleSubmit(changeStep)">
              <v-row>
                <v-col cols="12" class="pa-3 headline">
                  {{ $t('evaluations.overview') }}
                  <v-divider></v-divider>
                  <v-spacer></v-spacer>
                </v-col>
              </v-row>
              <v-row>

                <v-col cols="12">
                  <v-tooltip bottom color='blue'>
                    <template v-slot:activator="{ on }">
                       <x-inputs-input
                        :input="evaluation.name"
                        @updateInput="($event) => evaluation.name = $event"
                        :append-outer-icon="$t('help.icon')"
                        :label="$t('input.name')"
                        rules="required"
                        autofocus
                        light
                        name="poll_name"
                        :help-message="$t('help.engagement.create.name')"
                        v-on="on"
                      ></x-inputs-input>
                    </template>
                    <span>{{ $t('evaluations.tooltip_name') }}</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="12">
                  <v-switch
                    v-model="evaluation.switchName"
                    color="primary"
                    :label="$t('evaluations.want_external_name')"
                  ></v-switch>
                  <v-tooltip
                    bottom
                    color='blue'
                  >
                    <template v-slot:activator="{ on }">
                      <x-inputs-input
                        v-on="on"
                        v-show="evaluation.switchName"
                        :input="evaluation.displayName"
                        @updateInput="($event) => evaluation.displayName = $event"
                        :append-outer-icon="$t('help.icon')"
                        :label="$t('input.display_name')"
                        :rules="evaluation.switchName ? 'required' : ''"
                        autofocus
                        light
                        name="poll_name"
                        :help-message="$t('help.engagement.create.displayName')"
                      ></x-inputs-input>
                    </template>
                    <span>{{ $t('evaluations.tooltip_display_name') }}</span>
                  </v-tooltip>
                </v-col>
                <!-- Load/Update Participants Reminder -->
                <v-col cols="12" sm="8" class="pt-5 caption primary--text">
                  {{ $t('help.engagement.create.remember_load_update_collaborators') }}
                </v-col>
                <!-- Engagement Participant Count -->
                <v-col cols="12" sm="4" v-if="totalReceptors" class="text-right headline">
                  {{ $t('evaluations.total_receptors', {n: `${totalReceptors}`}) }}
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-btn
                    block
                    large
                    @click="changeStep(true)"
                  >{{ $t(prevAction) }}</v-btn>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-btn
                    color="primary"
                    class="white--text"
                    type="submit"
                    large
                    block
                  >{{ $t(nextAction) }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </ValidationObserver>
        </v-card>
      </v-container>
</template>

<script>

export default {
  props: {
    totalReceptors: [Object, Number],
    evaluation: Object,
    step: String,
    nextAction: String,
    prevAction: String
  },
  methods: {
    changeStep (isBack = false) {
      this.$emit('changeStep', this.evaluation, isBack ? +this.step - 1 : +this.step + 1)
    }
  }
}
</script>
