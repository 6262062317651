
export default {
  methods: {
    $generateOpenQuestions () {
      const strengths = [[]]
      const opportunities = [[]]
      const tips = [[]]
      const textColor = '#6d7a7a'
      const getOpenRow = (answer) => ({ text: answer, margin: [0, 1, 0, 0], border: [true, true, true, true], fontSize: 10, bold: false, style: { color: textColor }, alignment: 'left' })
      const getOpenTable = (title, body, pageBreak) => {
        const table = {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#BFBFBF' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            dontBreakRows: true,
            keepWithHeaderRows: true,
            widths: ['100%'],
            body: [
              [
                { text: this.$t(`individualReport.leader_${title}`).toUpperCase(), margin: [0, 5, 0, 0], border: [true, true, true, true], fontSize: 10, bold: true, style: { color: '#fff' }, alignment: 'center' }
              ],
              ...body
            ]
          },
          margin: [0, pageBreak ? 15 : 0, 0, 10],
          border: [false, false, false, true],
          alignment: 'center',
          fontSize: 10
        }
        if (pageBreak) {
          table.pageBreak = 'before'
        }
        return table
      }
      let firstIdx = 0
      let secondIdx = 0
      for (let i = 0; i < this.openQuestions[0].length; i++) {
        strengths[firstIdx].push([getOpenRow(this.openQuestions[0][i])])
        // if (i === 25 || ((i - 25) % 35 === 0)) {
        //   firstIdx++
        //   strengths.push([])
        // }
        opportunities[secondIdx].push([getOpenRow(this.openQuestions[1][i])])
        tips[secondIdx].push([getOpenRow(this.openQuestions[2][i])])
        // if (i === 31 || ((i - 31) % 35 === 0)) {
        //   secondIdx++
        //   opportunities.push([])
        //   tips.push([])
        // }
      }
      const strengthsTable = strengths.map((item, idx) => getOpenTable('strengths', item, Boolean(idx)))
      const opportunitiesTable = opportunities.map((item, idx) => getOpenTable('opportunities', item, Boolean(idx)))
      const tipsTable = tips.map((item, idx) => getOpenTable('tips', item, Boolean(idx)))
      return [
        {
          table: {
            widths: [479],
            body: [
              [{ text: `6.10. ${this.$t('individualReport.open_questions_title')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 6, 0, 6] }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [-40, 0, 0, 20],
          pageBreak: 'before'
        },
        {
          text: this.$t('individualReport.open_questions[1]'),
          alignment: 'justify',
          margin: [0, 0, 16, 12],
          color: textColor,
          lineHeight: 1.5,
          fontSize: 10
        },
        {
          table: {
            widths: [479],
            body: [
              [{ text: [
                `${this.$t('individualReport.table')} 30. ${this.$t('individualReport.open_questions_title')}`
              ],
              bold: true,
              color: '#fff',
              margin: [20, 6, 0, 6] }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [35, 5, 100, 20]
        },
        ...strengthsTable,
        {
          table: {
            widths: [479],
            body: [
              [{ text: [
                `${this.$t('individualReport.table')} 31. ${this.$t('individualReport.open_questions_title')}`
              ],
              bold: true,
              color: '#fff',
              margin: [20, 6, 0, 6] }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [35, 0, 100, 20],
          pageBreak: 'before'
        },
        ...opportunitiesTable,
        {
          table: {
            widths: [479],
            body: [
              [{ text: [
                `${this.$t('individualReport.table')} 32. ${this.$t('individualReport.open_questions_title')}`
              ],
              bold: true,
              color: '#fff',
              margin: [20, 6, 0, 6] }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [35, 0, 100, 20],
          pageBreak: 'before'
        },
        ...tipsTable
      ]
    }
  }
}
