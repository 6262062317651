
const required = '<br/><br/><b>This field is required.</b>'

export default {
  icon: 'mdi-help-circle',
  engagement: {
    create: {
      name: {
        title: 'Poll name',
        text: `This field serves as a means of identification for the poll. ${required}`
      },
      displayName: {
        title: 'External name for receptors',
        // tslint:disable-next-line:max-line-length
        text: 'In case of being selected, this will be the name shown to the collaborators who are going to carry out the survey.'
      },
      deliveredAt: {
        title: 'Release Date',
        text: `Represents the date from which you can participate in the poll. ${required}`
      },
      validUntil: {
        title: 'poll valid until',
        text: `Represents the date until which you can participate in the poll. ${required}`
      },
      timezone: {
        title: 'Timezone',
        text: `Release dates, validity and reminders will be based on the chosen time zone. ${required}`
      },
      reminders: {
        title: 'Reminders',
        // tslint:disable-next-line: max-line-length
        text: 'Each reminder sends an email to all poll recipients on the specified date. In addition, the delivery date of the reminder must be included between the launch and validity dates of the poll.'
      },
      openQuestion: {
        title: 'Add additional open questions',
        // tslint: disable-next-line: max-line-length
        text: 'Add up to three extra open questions to the poll with a maximum of five options each.'
      },
      subject: {
        title: 'Message subject',
        text: 'Subject to be used in the email sent.'
      },
      body: {
        title: 'Message body',
        text: 'Content of the email sent.'
      },
      thankMessage: {
        title: 'Thank you message',
        // tslint: disable-next-line: max-line-length
        text: 'This message will be sent to the recipient\'s email that successfully completes the poll.'
      },
      remember_load_update_collaborators: 'Remember to upload or update collaborators before creating the assessment.'
    }
  },
  employee: {
    create: {
      identifyDocument: {
        title: 'Identification document',
        text: `Employee identification document. It is necessary to indicate the type of document.`,
        required
      },
      firstName: {
        title: 'Firstname',
        text: `Employee's firstnames.`,
        required
      },
      lastName: {
        title: 'Lastname',
        text: `Employee's lastnames.`,
        required
      },
      gender: {
        title: 'Gender',
        text: `Employee's gender.`,
        required
      },
      birthdate: {
        title: 'Birthdate',
        text: `Employee's birthdate.`,
        required
      },
      academicDegree: {
        title: 'Academic degree',
        text: `Employee's academic degree.`,
        required
      },
      country: {
        title: 'Country',
        text: `Employee's country.`,
        required
      },
      headquarter: {
        title: 'Headquarter',
        text: `Headquarters where the employee works.`,
        required
      },
      email: {
        title: 'Email',
        text: `Employee's email.`,
        required
      },
      phoneNumber: {
        title: 'Phone number',
        text: `Employee's phone number.`,
        required
      },
      admission: {
        title: 'Admission date',
        text: `Employee's admission date.`,
        required
      },
      jobType: {
        title: 'Job type',
        text: `Employee's job type.`,
        required
      },
      department: {
        title: 'Area or department',
        text: `Area or department where the employee belongs to.`,
        required
      },
      charge: {
        title: 'Charge',
        text: `Employee's charge in the organization.`,
        required
      }
    }
  },
  pulse: {
    create: {
      committal: {
        title: 'Add committal questions',
        // tslint:disable-next-line:max-line-length
        text: 'Allows you to add one to six committal questions to the poll. This action is mandatory if you do not include questions from a questionnaire in the poll.'
      },
      questionnaire: {
        title: 'Add questions from a questionnaire',
        // tslint:disable-next-line:max-line-length
        text: 'Allows you to add the questions of one or more dimensions belonging to a questionnaire. This action is mandatory in case of not including committal questions in the poll.'
      },
      open: {
        title: 'Add additional open questions',
        // tslint:disable-next-line:max-line-length
        text: 'Allows you to add up to three additional open questions to the poll with a maximum of five options each.'
      },
      selection: {
        title: 'Who do you want to send this poll to?',
        // tslint:disable-next-line:max-line-length
        text: `It allows to select the recipients of the poll based on three different criteria: the entire organization, filtered by demographic cuts or by random selection based on a percentage of all employees. ${required}`
      }
    }
  },
  questionnaire: {
    create: {
      name: {
        title: 'Name',
        text: `Name that will be assigned to the questionnaire. ${required}`
      },
      base: {
        title: 'Base questionnaire',
        text: `Questionnaire that will be copied in orer to generate the new questionnaire.`
      },
      language: {
        title: 'Languages',
        text: `Languages in which the questionnaire will be available.`
      },
      assignate: {
        title: 'Asignar cuestionario',
        text: 'Allows the questionnaire to be assigned to an specific business sector or organization in particular.'
      }
    }
  },
  enterprise: {
    create: {
      logo: {
        title: 'Logo',
        text: 'It must be an image with a size of 2MB.'
      },
      name: {
        title: 'Name',
        text: `Enterprise name. ${required}`
      },
      sector: {
        title: 'Sector',
        text: `Enterprise business sector. ${required}`
      },
      language: {
        title: 'Language',
        text: `Enterprise main language. ${required}`
      },
      country: {
        title: 'Country',
        text: `Country where the enterprise is located. ${required}`
      },
      headquarter: {
        title: 'Headquarter',
        text: `Enterprise headquarter. ${required}`
      },
      address: {
        title: 'Address',
        text: `Enterprise main address. ${required}`
      },
      groups: {
        title: 'Group',
        text: 'Group to which the enterprise belongs.'
      },
      size: {
        title: 'Enterprise size',
        text: `Enterprise size based on its incomings. ${required}`
      },
      employeesNumber: {
        title: 'Employees number',
        text: `Enterprise employees number. ${required}`
      },
      license: {
        title: 'License',
        text: `License to be asigned to the enterprise. ${required}`
      },
      engagements: {
        title: 'Engagement quantity',
        text: 'Engagement quantity available in the plan.'
      },
      pulses: {
        title: 'Pulses quantity',
        text: 'Pulsese quantity available in the plan.'
      },
      startAt: {
        title: 'Start date',
        text: `Start date for the enterprise plan. ${required}`
      },
      duration: {
        title: 'Duration',
        text: `License duration. ${required}`
      },
      identifyDocument: {
        title: 'Identification document',
        // tslint:disable-next-line: max-line-length
        text: `Contact employee's identification document. It is necessary to indicate the type of document. ${required}`
      },
      firstName: {
        title: 'Firstname',
        stext: `Contact employee's firstnames. ${required}`
      },
      lastName: {
        title: 'Lastname',
        text: `Contact employee's lastnames. ${required}`
      },
      email: {
        title: 'Email',
        text: `Contact employee's email. ${required}`
      },
      phoneNumber: {
        title: 'Phone number',
        text: `Contact employee's phone number.`
      },
      contactAddress: {
        title: 'Address',
        text: `Contact employee's address.`
      }
    },
    plan: {
      pulse_quantity: {
        title: 'Pulse to add',
        text: `Pulse that will be added to the company in the plan extension. ${required}`
      },
      engagement_quantity: {
        title: 'Engagement to add',
        text: `Engagement to be added to the company in the plan extension. ${required}`
      },
      new: {
        title: 'New plan',
        text: `Plan that will be asigned to the company. ${required}`
      },
      duration: {
        title: 'Duration',
        text: `New plan duration in months. ${required}`
      }
    },
    massive: {
      file_input: {
        title: 'File to upload',
        // tslint:disable-next-line:max-line-length
        text: `Upload an excel (.xls/.xlsx) or csv (.csv) file with your employees data. This process will update your whole employees data so you should provide the entire employees base. <br/><br/>Do not forget to use the downloadable template that you can get by clicking the "DOWNLOAD TEMPLATE" button. ${required}`
      }
    },
    group: {
      name: {
        title: 'Group name',
        text: `Name which will be used to identified the enteprises group. ${required}`
      },
      leader: {
        title: 'Leading enterprise',
        text: 'The leading enterprise (groups leader) can manage the enterprises plans that belong to its group.'
      }
    }
  }
}
