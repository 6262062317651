
export default {
  methods: {
    textToUpper (key) {
      const t = this.$t(key)
      return (t.text ? t.text : t).toUpperCase()
    },
    $generateResponsability () {
      let tables = []
      // 309DE0 EB493C 48BBA1
      const descriptions = [
        this.$t('organizationalReport.table1'),
        this.$t('organizationalReport.table2'),
        this.$t('organizationalReport.table3'),
        this.$t('organizationalReport.table4')
      ]
      for (let i = 0; i < 4; i++) {
        let rows = []
        let ranking = []
        let higherAbbr = ''
        let lowerAbbr = ''
        let length = 0
        for (let j = 0; j < 3; j++) {
          if (i === 0) {
            ranking = this.noAutoOthersRespRank
            ranking.sort((a, b) => a.score - b.score)
            higherAbbr = ranking[this.noAutoOthersRespRank.length - (j + 1)].abbreviation
            lowerAbbr = ranking[j].abbreviation
            length = this.noAutoOthersRespRank.length
          } else if (i === 1) {
            ranking = this.leadersRespRank
            ranking.sort((a, b) => a.score - b.score)
            higherAbbr = ranking[this.leadersRespRank.length - (j + 1)].abbreviation
            lowerAbbr = ranking[j].abbreviation
            length = this.leadersRespRank.length
          } else if (i === 2) {
            ranking = this.pairsRespRank
            ranking.sort((a, b) => a.score - b.score)
            higherAbbr = ranking[this.pairsRespRank.length - (j + 1)].abbreviation
            lowerAbbr = ranking[j].abbreviation
            length = this.pairsRespRank.length
          } else {
            ranking = this.dependentsRespRank
            ranking.sort((a, b) => a.score - b.score)
            higherAbbr = ranking[this.dependentsRespRank.length - (j + 1)].abbreviation
            lowerAbbr = ranking[j].abbreviation
            length = this.dependentsRespRank.length
          }
          rows.push([
            // Higher score
            { text: ranking[length - (j + 1)].score !== '--' ? ranking[length - (j + 1)].abbreviation : '--', margin: [0, 12, 0, 0], border: [true, true, true, true], bold: true, fontSize: 12, style: { color: higherAbbr === 'P' ? '#0D47A1' : higherAbbr === 'D' ? '#b4cf32' : higherAbbr === 'M' ? '#4ec7aa' : '#43ace2' } },
            { text: ranking[length - (j + 1)].score !== '--' ? ranking[length - (j + 1)].name : '--', margin: [0, 3, 0, 0], border: [true, true, true, true], style: { color: '#6d7a7a' } },
            { text: this.round(ranking[length - (j + 1)].score, 2), margin: [0, 12, 0, 0], alignment: 'center', border: [true, true, true, true], style: { color: '#6d7a7a' } },
            { text: this.round(ranking[length - (j + 1)].tenPercent, 2), margin: [0, 12, 0, 0], alignment: 'center', border: [true, true, true, true], style: { color: '#6d7a7a' } },
            { text: `${this.hasPrevious ? this.round(ranking[length - (j + 1)].trend, 2) : ''}`, margin: [0, 12, 0, 0], alignment: 'center', border: [true, true, true, true], style: { color: '#6d7a7a' } },
            {},
            // Lower score
            { text: ranking[j].score !== '--' ? ranking[j].abbreviation : '--', margin: [0, 12, 0, 0], border: [true, true, true, true], bold: true, fontSize: 12, style: { color: lowerAbbr === 'P' ? '#0D47A1' : lowerAbbr === 'D' ? '#b4cf32' : lowerAbbr === 'M' ? '#4ec7aa' : '#43ace2' } },
            { text: ranking[j].score !== '--' ? ranking[j].name : '--', margin: [0, 3, 0, 0], border: [true, true, true, true], style: { color: '#6d7a7a' } },
            { text: this.round(ranking[j].score, 2), margin: [0, 12, 0, 0], alignment: 'center', border: [true, true, true, true], style: { color: '#6d7a7a' } },
            { text: this.round(ranking[j].tenPercent, 2), margin: [0, 12, 0, 0], alignment: 'center', border: [true, true, true, true], style: { color: '#6d7a7a' } },
            { text: `${this.hasPrevious ? this.round(ranking[j].trend, 2) : ''}`, margin: [0, 12, 0, 0], alignment: 'center', border: [true, true, true, true], style: { color: '#6d7a7a' } }
          ])
        }
        let title = {}
        if (i === 0 || i === 2) {
          title = {
            table: {
              widths: [479],
              body: [
                [{ text: this.textToUpper('organizationalReport.index[0]'), bold: true, color: '#fff', margin: [50, 8, 0, 5] }]
              ]
            },
            layout: {
              fillColor: '#aaaaaa',
              defaultBorder: ''
            },
            alignment: 'left',
            margin: [-40, 40, 0, 0],
            pageBreak: i === 0 || i === 2 ? 'before' : ''
          }
        }
        tables.push(
          title,
          {
            layout: {
              defaultBorder: '',
              fillColor: '#aaaaaa'
            },
            table: {
              widths: [479],
              body: [
                [{ text: `${this.$t('organizationalReport.table')} ${i + 1}. ${descriptions[i]}`, margin: [30, 5, 0, 5], bold: true, color: '#fff' }]
              ]
            },
            margin: [35, 10, 20, 20]
          },
          {
            layout: {
              defaultBorder: '',
              fillColor: function (rowIndex, node, columnIndex) {
                return (rowIndex <= 1 && columnIndex !== 5) ? '#BFBFBF' : null
              },
              border: [false, false, false, true]
            },
            table: {
              headerRows: 1,
              body: [
                [
                  { text: this.$t('organizationalReport.higher_scores').toUpperCase(), colSpan: 5, margin: [0, 3, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                  {},
                  {},
                  {},
                  {},
                  {},
                  { text: this.$t('organizationalReport.lower_scores').toUpperCase(), colSpan: 5, margin: [0, 3, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                  {},
                  {},
                  {},
                  {}
                ],
                // Header
                [
                  { text: this.$t('organizationalReport.process'), margin: [-1, 8, -1, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                  { text: this.$t('organizationalReport.responsability'), margin: [5, 8, 5, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                  { text: this.$t('organizationalReport.score'), margin: [3, 8, 3, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                  { text: this.$t('organizationalReport.ten_percent'), margin: [1, 2, 1, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                  { text: this.$t('organizationalReport.trend'), margin: [-2, 8, -2, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                  {},
                  { text: this.$t('organizationalReport.process'), margin: [-1, 8, -1, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                  { text: this.$t('organizationalReport.responsability'), margin: [5, 8, 5, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                  { text: this.$t('organizationalReport.score'), margin: [3, 8, 3, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                  { text: this.$t('organizationalReport.ten_percent'), margin: [1, 2, 1, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                  { text: this.$t('organizationalReport.trend'), margin: [-2, 8, -2, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } }
                ],
                // Body
                ...rows
              ]
            },
            margin: [10, 0, 15, 10],
            border: [false, false, false, true],
            alignment: 'center',
            verticalAlignment: 'center',
            fontSize: 8
          }
        )
      }
      return tables
    }
  }
}
