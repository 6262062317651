
import PasswordValidator from 'password-validator'

export default {
  name: 'password',
  definition: {
    message: (name: string, params: any) => {
      // Esto se debe adaptar a la nueva versión de vee validate
      return 'Error message not set'
      /* switch (data.error[0]) {
        case 'uppercase':
          return `El campo ${name} debe tener al menos una letra en mayúscula.`
        case 'lowercase':
          return `El campo ${name} debe tener al menos una letra en minúscula.`
        case 'spaces':
          return `El campo ${name} no debe tener espacios en blanco.`
        case 'digits':
          return `El campo ${name} debe tener al menos un número.`
        case 'max':
          return `El campo ${name} no debe tener más de 100 carácteres.`
        default:
          return `El campo ${name} debe tener mínimo 8 carácteres.`
      } */
    },
    validate: (value: string) => {
      const schema = new PasswordValidator()
        .is().min(8)
        .is().max(100)
        .has().uppercase()
        .has().lowercase()
        .has().digits()
        .has().not().spaces()

      const errors = schema.validate(value, { list: true })
      if (!errors || (typeof errors === 'object' && !errors.length)) {
        return true
      }

      return {
        valid: false,
        data: {
          error: [(errors as string[]).shift()]
        }
      }
    }
  }
}
