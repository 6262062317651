
export default {
  methods: {
    $generateFoResults () {
      const strengths = []
      const opportunities = []
      let confirmedStrengths = this.confirmedStrengths
      let unknownStrengths = this.unknownStrengths
      let confirmedOpportunities = this.confirmedOpportunities
      let unknownOpportunities = this.unknownOpportunities
      confirmedStrengths.sort((a, b) => b.others - a.others)
      unknownStrengths.sort((a, b) => b.others - a.others)
      confirmedOpportunities.sort((a, b) => b.others - a.others)
      unknownOpportunities.sort((a, b) => b.others - a.others)
      const textColor = '#6d7a7a'
      for (let i = 0; i < 6; i++) {
        strengths.push([
          // Confirmed
          { text: i < confirmedStrengths.length ? confirmedStrengths[i].question : '.', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } },
          { text: i < confirmedStrengths.length ? (confirmedStrengths[i].others >= 1 ? this.round(confirmedStrengths[i].others, 2) : '--') : '.', alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } },
          { text: i < confirmedStrengths.length ? (confirmedStrengths[i].score >= 1 ? this.round(confirmedStrengths[i].score, 2) : '--') : '.', alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } },
          // Unknown
          { text: i < unknownStrengths.length ? unknownStrengths[i].question : '.', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } },
          { text: i < unknownStrengths.length ? (unknownStrengths[i].others >= 1 ? this.round(unknownStrengths[i].others, 2) : '--') : '.', alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } },
          { text: i < unknownStrengths.length ? (unknownStrengths[i].score >= 1 ? this.round(unknownStrengths[i].score, 2) : '--') : '.', alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } }
        ])
        opportunities.push([
          // Confirmed
          { text: i < confirmedOpportunities.length ? confirmedOpportunities[i].question : '.', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } },
          { text: i < confirmedOpportunities.length ? (confirmedOpportunities[i].others >= 1 ? this.round(confirmedOpportunities[i].others, 2) : '--') : '.', alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } },
          { text: i < confirmedOpportunities.length ? (confirmedOpportunities[i].score >= 1 ? this.round(confirmedOpportunities[i].score, 2) : '--') : '.', alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } },
          // Unknown
          { text: i < unknownOpportunities.length ? unknownOpportunities[i].question : '.', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } },
          { text: i < unknownOpportunities.length ? (unknownOpportunities[i].others >= 1 ? this.round(unknownOpportunities[i].others, 2) : '--') : '.', alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } },
          { text: i < unknownOpportunities.length ? (unknownOpportunities[i].score >= 1 ? this.round(unknownOpportunities[i].score, 2) : '--') : '.', alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } }
        ])
      }
      return [
        {
          table: {
            widths: [479],
            body: [
              [{ text: `${this.$t('individualReport.confirmed_fo_title')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 6, 0, 6] }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [-40, 0, 0, 5],
          pageBreak: 'before'
        },
        {
          text: `${this.$t('individualReport.confirmed_fo[1]')}`,
          alignment: 'justify',
          margin: [0, 0, 16, 10],
          color: textColor,
          lineHeight: 1
        },
        {
          text: `${this.$t('individualReport.confirmed_fo[2]')}`,
          alignment: 'justify',
          margin: [0, 0, 16, 10],
          color: textColor,
          lineHeight: 1
        },
        {
          text: `${this.$t('individualReport.confirmed_fo[3]')}`,
          alignment: 'justify',
          margin: [0, 0, 16, 10],
          color: textColor,
          lineHeight: 1
        },
        {
          table: {
            widths: [479],
            body: [
              [{ text: [
                `${this.$t('individualReport.table')} 24. ${this.$t('individualReport.table24')}`
              ],
              bold: true,
              color: '#fff',
              margin: [20, 5, 0, 5] }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [35, 5, 100, 10]
        },
        {
          layout: {
            defaultBorder: '',
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            widths: ['50%', '50%'],
            body: [
              [
                { text: [{ text: `${this.$t('individualReport.confirmed_strengths')}: `, bold: true }, `${this.$t('individualReport.confirmed_strengths_text')}`], color: textColor, margin: [0, 3, 0, 0], border: [true, true, true, true], fontSize: 9 },
                { text: [{ text: `${this.$t('individualReport.unknown_strengths')}: `, bold: true }, `${this.$t('individualReport.unknown_strengths_text')}`], color: textColor, border: [true, true, true, true], margin: [0, 3, 0, 0], fontSize: 9 }
              ],
              [
                { text: [{ text: `${this.$t('individualReport.confirmed_improvement_opportunities')}: `, bold: true }, `${this.$t('individualReport.confirmed_improvement_opportunities_text')}`], color: textColor, margin: [0, 3, 0, 0], border: [true, true, true, true], fontSize: 9 },
                { text: [{ text: `${this.$t('individualReport.unknown_improvement_opportunities')}: `, bold: true }, `${this.$t('individualReport.unknown_improvement_opportunities_text')}`], color: textColor, border: [true, true, true, true], margin: [0, 3, 0, 0], fontSize: 9 }
              ]
            ]
          },
          margin: [0, 0, 0, 10],
          border: [false, false, false, true],
          alignment: 'center',
          fontSize: 8
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex <= 1) ? '#BFBFBF' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            body: [
              [
                { text: this.$t('individualReport.confirmed_strengths').toUpperCase(), colSpan: 3, margin: [0, 3, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                {},
                {},
                { text: this.$t('individualReport.unknown_strengths').toUpperCase(), colSpan: 3, margin: [0, 3, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                {},
                {}
              ],
              // Header
              [
                { text: this.$t('individualReport.behavior'), margin: [0, 1, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.others'), margin: [-2, 1, -2, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.auto'), margin: [0, 1, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.behavior'), margin: [0, 1, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.others'), margin: [-2, 1, -2, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.auto'), margin: [0, 1, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } }
              ],
              // Body
              ...strengths
            ],
            widths: ['*', 17, 17, '*', 17, 17]
          },
          margin: [0, 0, 0, 10],
          border: [false, false, false, true],
          alignment: 'center',
          fontSize: 8
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex <= 1) ? '#BFBFBF' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            body: [
              [
                { text: this.$t('individualReport.confirmed_improvement_opportunities').toUpperCase(), colSpan: 3, margin: [0, 3, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                {},
                {},
                { text: this.$t('individualReport.unknown_improvement_opportunities').toUpperCase(), colSpan: 3, margin: [0, 3, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                {},
                {}
              ],
              // Header
              [
                { text: this.$t('individualReport.behavior'), margin: [0, 1, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.others'), margin: [-2, 1, -2, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.auto'), margin: [0, 1, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.behavior'), margin: [0, 1, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.others'), margin: [-2, 1, -2, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.auto'), margin: [0, 1, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } }
              ],
              // Body
              ...opportunities
            ],
            widths: ['*', 17, 17, '*', 17, 17]
          },
          margin: [0, 0, 0, 0],
          border: [false, false, false, true],
          alignment: 'center',
          fontSize: 8
        }
      ]
    }
  }
}
