export default {
  create: 'Create questionnaire',
  edit: 'Edit questionnaire',
  no_data: 'No questionnaires to show',
  autoEvaluation: 'Autoevaluation',
  generalEvaluation: '360°',
  as_person: 'As person',
  management: 'Management',
  mobilization: 'Mobilization',
  achievement: 'Achievement',
  tooltip_edit: 'You must press INTRO to edit',
  edit_questions: 'Edit questions',
  settings: 'Questionnaire settings',
  name: 'Nombre',
  last_edition: 'Ultima edición',
  status: 'Estado',
  actions: 'Acciones',
  customer: 'Customer',
  enterprise: 'Enterprise',
  sector: 'Sector',
  created_succesfully: 'Questionnaire created succesfully',
  error_on_creation: 'Error creating questionnaire',
  asignation_type: 'Assignation type',
  assign_to: 'Assign',
  edited_succesfully: 'Questionnaire edited succesfully',
  error_editing: 'Error editing questionnaire',
  responsability: 'Responsability',
  modal_enable: 'Do you want to enable the questionnaire?',
  modal_enable_msg: 'Once enabled, the questionnaire can again be selected for the creation of assessments, by the clients that have it',
  modal_disable: 'Do you want to disable the questionnaire?',
  modal_disable_msg: 'Once disabled, the customers who have this questionnaire will not be able to choose it to create assessments',
  question: 'Question'
}
