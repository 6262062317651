
import pdfUtil from '../utils_old/pdf'

export default {
  methods: {
    generateParagraph (translate, repeat, fontSize, italic) {
      let array = []
      for (let index = 0; index < repeat; index++) {
        array.push(
          {
            text: this.$t(`${translate + '[' + index + ']'}`),
            alignment: 'justify',
            margin: [0, 0, 16, 12],
            color: '#6d7a7a',
            lineHeight: 1.5,
            fontSize: fontSize,
            italics: italic
          }
        )
      }
      return array
    },
    $generateResponsabilityResults () {
      const asPerson = []
      const management = []
      const mobilization = []
      const achievement = []
      const legend = [
        this.$t('individualReport.no_answer'),
        this.$t('individualReport.data_range'),
        this.$t('individualReport.score'),
        this.$t('individualReport.ten_percent'),
        this.$t('individualReport.leaders_average'),
        this.$t('individualReport.higher_bold'),
        this.$t('individualReport.lower_sub')
      ]
      const evaluator = [
        this.$t('individualReport.me'),
        this.$t('individualReport.leaders'),
        this.$t('individualReport.pairs'),
        this.$t('individualReport.dependents'),
        this.$t('individualReport.others')
      ]
      const noZero = (n) => (n === 0 ? '--' : this.round(n, 2, '--'))
      const textColor = '#6d7a7a'
      // Obtenemos arreglo de puntajes por procesos para el higher negrita y lower subrayado
      let scoresFiltered = [this.autoResponsabilityFiltered, this.leadersResponsabilityFiltered, this.pairsResponsabilityFiltered, this.dependentsResponsabilityFiltered, this.noAutoScoresResponsabilityFiltered]
      let processScores = []
      for (let i = 0; i < 5; i++) {
        let responsabilityScores = []
        for (let j = 0; j < 5; j++) {
          let scores = []
          scoresFiltered.forEach((it) => {
            if (it[i] && it[i].responsability[j]) {
              const score = it[i].responsability[j].score
              if (!isNaN(score) && score > 0) {
                scores.push(score)
              }
            }
          })
          responsabilityScores.push(scores)
        }
        processScores.push(responsabilityScores)
      }

      for (let j = 0; j < 5; j++) {
        for (let i = 0; i < 5; i++) {
          let data, dataFiltered, previousDataFiltered
          switch (i) {
            case 0: // Auto (Yo)
              data = this.averageResponsability
              dataFiltered = this.autoResponsabilityFiltered
              previousDataFiltered = this.previousAutoResponsabilityFiltered
              break
            case 1:// Leaders
              data = this.leadersResponsability
              dataFiltered = this.leadersResponsabilityFiltered
              previousDataFiltered = this.previousLeadersResponsabilityFiltered
              break
            case 2:// Pairs
              data = this.pairsResponsability
              dataFiltered = this.pairsResponsabilityFiltered
              previousDataFiltered = this.previousPairsResponsabilityFiltered
              break
            case 3:// Dependants
              data = this.dependentsResponsability
              dataFiltered = this.dependentsResponsabilityFiltered
              previousDataFiltered = this.previousDependentsResponsabilityFiltered
              break
            default:// Others
              data = this.noAutoScoresResponsability
              dataFiltered = this.noAutoScoresResponsabilityFiltered
              previousDataFiltered = this.previousNoAutoScoresResponsabilityFiltered
              break
          }

          // Calcula simbolo a mostrar (+) o (-)
          let autoScore = noZero(this.autoResponsabilityFiltered[1].responsability[j].score)
          let othersScore = noZero(this.noAutoScoresResponsabilityFiltered[1].responsability[j].score)
          let symbol = this.getSymbol(autoScore, othersScore)

          // Calcula porcentaje de respuestas "No sabe" para los pares
          // let unknownPercent = i === 2 ? this.getUnknownPercent(dataFiltered[1].responsability[j]) : ''

          let isValid = !isNaN(dataFiltered[1].responsability[j].score) && dataFiltered[1].responsability[j].score >= 1
          let hasValidTrend = this.hasPrevious && isValid && previousDataFiltered[1].responsability[j].score > 0

          // # MANAGEMENT
          management.push([
            { text: dataFiltered[1].responsability[j].name + symbol, rowSpan: 5, color: textColor, margin: [0, 20, 0, 0], border: [true, true, true, true] },
            { text: evaluator[i], bold: true, color: textColor, margin: [-3, 0, -3, 0], border: [true, true, true, true] },
            // { text: evaluator[i] + unknownPercent, bold: true, color: textColor, margin: [-3, 0, -3, 0], border: [true, true, true, true] },
            isValid ? pdfUtil.generateXDCChartT3(dataFiltered[1].responsability, j, this.round(data[1].responsability[j].score, 2), data[1].responsability[j].tenPercent, 'responsability') : { text: '--', border: [true, true, true, true] },
            pdfUtil.higherLowerResponsability(processScores[1][j], isValid ? dataFiltered[1].responsability[j].score : '--', textColor, [0, 4, 0, 0], 'center', [true, true, true, true]),
            { text: data[1].responsability[j].tenPercent > 0 ? this.round(data[1].responsability[j].tenPercent, 2) : '--', bold: false, color: textColor, margin: [0, 4, 0, 0], border: [true, true, true, true], alignment: 'center' },
            { text: data[1].responsability[j].score > 0 ? this.round(data[1].responsability[j].score, 2) : '--', bold: false, color: textColor, margin: [0, 4, 0, 0], border: [true, true, true, true], alignment: 'center' },
            { text: `${hasValidTrend ? this.round(dataFiltered[1].responsability[j].score - previousDataFiltered[1].responsability[j].score, 2, '--') : '--'}`, bold: false, color: textColor, margin: [0, 4, 0, 0], border: [true, true, true, true], alignment: 'center' }
          ])

          // Calcula simbolo a mostrar (+) o (-)
          autoScore = noZero(this.autoResponsabilityFiltered[2].responsability[j].score)
          othersScore = noZero(this.noAutoScoresResponsabilityFiltered[2].responsability[j].score)
          symbol = this.getSymbol(autoScore, othersScore)

          // Calcula porcentaje de respuestas "No sabe" para los pares
          // unknownPercent = i === 2 ? this.getUnknownPercent(dataFiltered[2].responsability[j]) : ''

          isValid = !isNaN(dataFiltered[2].responsability[j].score) && dataFiltered[2].responsability[j].score >= 1
          hasValidTrend = this.hasPrevious && isValid && previousDataFiltered[2].responsability[j].score > 0

          // # MOBILIZATION
          mobilization.push([
            i === 0 ? { text: dataFiltered[2].responsability[j].name + symbol, rowSpan: 5, color: textColor, margin: [0, 20, 0, 0], border: [true, true, true, true] } : {},
            { text: evaluator[i], bold: true, color: textColor, margin: [-3, 0, -3, 0], border: [true, true, true, true] },
            // { text: evaluator[i] + unknownPercent, bold: true, color: textColor, margin: [-3, 0, -3, 0], border: [true, true, true, true] },
            isValid ? pdfUtil.generateXDCChartT3(dataFiltered[2].responsability, j, this.round(data[2].responsability[j].score, 2), data[2].responsability[j].tenPercent, 'responsability') : { text: '--', border: [true, true, true, true] },
            pdfUtil.higherLowerResponsability(processScores[2][j], isValid ? dataFiltered[2].responsability[j].score : '--', textColor, [0, 4, 0, 0], 'center', [true, true, true, true]),
            { text: data[2].responsability[j].tenPercent > 0 ? this.round(data[2].responsability[j].tenPercent, 2) : '--', bold: false, color: textColor, margin: [0, 4, 0, 0], border: [true, true, true, true], alignment: 'center' },
            { text: data[2].responsability[j].score > 0 ? this.round(data[2].responsability[j].score, 2) : '--', bold: false, color: textColor, margin: [0, 4, 0, 0], border: [true, true, true, true], alignment: 'center' },
            { text: `${hasValidTrend ? this.round(dataFiltered[2].responsability[j].score - previousDataFiltered[2].responsability[j].score, 2, '--') : '--'}`, bold: false, color: textColor, margin: [0, 4, 0, 0], border: [true, true, true, true], alignment: 'center' }
          ])

          // Calcula simbolo a mostrar (+) o (-)
          autoScore = noZero(this.autoResponsabilityFiltered[3].responsability[j].score)
          othersScore = noZero(this.noAutoScoresResponsabilityFiltered[3].responsability[j].score)
          symbol = this.getSymbol(autoScore, othersScore)

          // Calcula porcentaje de respuestas "No sabe" para los pares
          // unknownPercent = i === 2 ? this.getUnknownPercent(dataFiltered[3].responsability[j]) : ''

          isValid = !isNaN(dataFiltered[3].responsability[j].score) && dataFiltered[3].responsability[j].score >= 1
          hasValidTrend = this.hasPrevious && isValid && previousDataFiltered[3].responsability[j].score > 0

          // # ACHIVEMENT
          achievement.push([
            i === 0 ? { text: dataFiltered[3].responsability[j].name + symbol, rowSpan: 5, color: textColor, margin: [0, 20, 0, 0], border: [true, true, true, true] } : {},
            { text: evaluator[i], bold: true, color: textColor, margin: [-3, 0, -3, 0], border: [true, true, true, true] },
            // { text: evaluator[i] + unknownPercent, bold: true, color: textColor, margin: [-3, 0, -3, 0], border: [true, true, true, true] },
            isValid ? pdfUtil.generateXDCChartT3(dataFiltered[3].responsability, j, this.round(data[3].responsability[j].score, 2), data[3].responsability[j].tenPercent, 'responsability') : { text: '--', border: [true, true, true, true] },
            pdfUtil.higherLowerResponsability(processScores[3][j], isValid ? dataFiltered[3].responsability[j].score : '--', textColor, [0, 4, 0, 0], 'center', [true, true, true, true]),
            { text: data[3].responsability[j].tenPercent > 0 ? this.round(data[3].responsability[j].tenPercent, 2) : '--', bold: false, color: textColor, margin: [0, 4, 0, 0], border: [true, true, true, true], alignment: 'center' },
            { text: data[3].responsability[j].score > 0 ? this.round(data[3].responsability[j].score, 2) : '--', bold: false, color: textColor, margin: [0, 4, 0, 0], border: [true, true, true, true], alignment: 'center' },
            { text: `${hasValidTrend ? this.round(dataFiltered[3].responsability[j].score - previousDataFiltered[3].responsability[j].score, 2, '--') : '--'}`, bold: false, color: textColor, margin: [0, 4, 0, 0], border: [true, true, true, true], alignment: 'center' }
          ])
        }
      }
      return [
        {
          table: {
            widths: [479],
            body: [
              [{ text: `${this.$t('individualReport.responsability_results_title')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 6, 0, 6] }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [-40, 0, 0, 5],
          pageBreak: 'before'
        },
        // {
        //   text: `${this.$t('individualReport.responsability_results_text')}`,
        //   alignment: 'justify',
        //   margin: [0, 0, 22, 0],
        //   color: '#6d7a7a',
        //   lineHeight: 1.5
        // },
        {
          table: {
            widths: [479],
            body: [
              [{ text: [
                `${this.$t('individualReport.table')} 4. ${this.$t('individualReport.table4')}`,
                { text: this.$t('individualReport.direction').toUpperCase(), color: '#b4cf32' }
              ],
              bold: true,
              color: '#fff',
              margin: [20, 6, 0, 6] }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [35, 5, 100, 5]
        },
        pdfUtil.generateDmlLegendIndividual(legend),
        {
          layout: {
            defaultBorder: '',
            border: [false, false, false, true],
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#BFBFBF' : null
            }
          },
          table: {
            headerRows: 1,
            widths: ['16%', '14%', '38%', '8%', '8%', '8%', '8%'],
            body: [
              [
                { text: `${this.$t('individualReport.responsability')}`, bold: true, color: '#fff', margin: [0, 5, 0, 0], border: [true, true, true, true], fontSize: 9 },
                { text: `${this.$t('individualReport.evaluator')}`, bold: true, color: '#fff', border: [true, true, true, true], margin: [0, 5, 0, 0], fontSize: 9 },
                { layout: { defaultBorder: '', fillColor: '#BFBFBF' },
                  table: {
                    headerRows: 1,
                    body: [[
                      { text: '1\n|', margin: [10, 2, 15, 0] },
                      { text: '2\n|', margin: [0, 2, 15, 0] },
                      { text: '3\n|', margin: [0, 2, 15, 0] },
                      { text: '4\n|', margin: [0, 2, 15, 0] },
                      { text: '5\n|', margin: [0, 2, 15, 0] },
                      { text: '6\n|', margin: [0, 2, 15, 0] }
                    ]]
                  },
                  bold: true,
                  style: { color: '#fff' },
                  border: [true, true, true, true]
                },
                { text: this.$t('individualReport.score'), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 },
                { text: this.$t('individualReport.ten_percent'), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 },
                { text: this.$t('individualReport.leaders_average'), margin: [-3, 5, -3, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 },
                { text: this.$t('individualReport.trend'), margin: [-3, 5, -3, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 }
              ],
              ...management
            ]
          },
          margin: [-8, 0, 0, 10],
          border: [false, false, false, true],
          alignment: 'center',
          fontSize: 9
        },
        {
          table: {
            widths: [479],
            body: [
              [{ text: [
                `${this.$t('individualReport.table')} 5. ${this.$t('individualReport.table4')}`,
                { text: this.$t('individualReport.mobilization').toUpperCase(), color: '#4ec7aa' }
              ],
              bold: true,
              color: '#fff',
              margin: [20, 6, 0, 6] }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [35, 0, 100, 20],
          pageBreak: 'before'
        },
        pdfUtil.generateDmlLegendIndividual(legend),
        {
          layout: {
            defaultBorder: '',
            border: [false, false, false, true],
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#BFBFBF' : null
            }
          },
          table: {
            headerRows: 1,
            widths: ['16%', '14%', '38%', '8%', '8%', '8%', '8%'],
            body: [
              [
                { text: `${this.$t('individualReport.responsability')}`, bold: true, color: '#fff', margin: [0, 5, 0, 0], border: [true, true, true, true], fontSize: 9 },
                { text: `${this.$t('individualReport.evaluator')}`, bold: true, color: '#fff', border: [true, true, true, true], margin: [0, 5, 0, 0], fontSize: 9 },
                { layout: { defaultBorder: '', fillColor: '#BFBFBF' },
                  table: {
                    headerRows: 1,
                    body: [[
                      { text: '1\n|', margin: [10, 2, 15, 0] },
                      { text: '2\n|', margin: [0, 2, 15, 0] },
                      { text: '3\n|', margin: [0, 2, 15, 0] },
                      { text: '4\n|', margin: [0, 2, 15, 0] },
                      { text: '5\n|', margin: [0, 2, 15, 0] },
                      { text: '6\n|', margin: [0, 2, 15, 0] }
                    ]]
                  },
                  bold: true,
                  style: { color: '#fff' },
                  border: [true, true, true, true]
                },
                { text: this.$t('individualReport.score'), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 },
                { text: this.$t('individualReport.ten_percent'), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 },
                { text: this.$t('individualReport.leaders_average'), margin: [-3, 5, -3, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 },
                { text: this.$t('individualReport.trend'), margin: [-3, 5, -3, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 }
              ],
              ...mobilization
            ]
          },
          margin: [-8, 0, 0, 10],
          border: [false, false, false, true],
          alignment: 'center',
          fontSize: 9
        },
        {
          table: {
            widths: [479],
            body: [
              [{ text: [
                `${this.$t('individualReport.table')} 6. ${this.$t('individualReport.table4')}`,
                { text: this.$t('individualReport.achievement').toUpperCase(), color: '#43ace2' }
              ],
              bold: true,
              color: '#fff',
              margin: [20, 6, 0, 6] }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [35, 0, 100, 20],
          pageBreak: 'before'
        },
        pdfUtil.generateDmlLegendIndividual(legend),
        {
          layout: {
            defaultBorder: '',
            border: [false, false, false, true],
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#BFBFBF' : null
            }
          },
          table: {
            headerRows: 1,
            widths: ['16%', '14%', '38%', '8%', '8%', '8%', '8%'],
            body: [
              [
                { text: `${this.$t('individualReport.responsability')}`, bold: true, color: '#fff', margin: [0, 5, 0, 0], border: [true, true, true, true], fontSize: 9 },
                { text: `${this.$t('individualReport.evaluator')}`, bold: true, color: '#fff', border: [true, true, true, true], margin: [0, 5, 0, 0], fontSize: 9 },
                { layout: { defaultBorder: '', fillColor: '#BFBFBF' },
                  table: {
                    headerRows: 1,
                    body: [[
                      { text: '1\n|', margin: [10, 2, 15, 0] },
                      { text: '2\n|', margin: [0, 2, 15, 0] },
                      { text: '3\n|', margin: [0, 2, 15, 0] },
                      { text: '4\n|', margin: [0, 2, 15, 0] },
                      { text: '5\n|', margin: [0, 2, 15, 0] },
                      { text: '6\n|', margin: [0, 2, 15, 0] }
                    ]]
                  },
                  bold: true,
                  style: { color: '#fff' },
                  border: [true, true, true, true]
                },
                { text: this.$t('individualReport.score'), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 },
                { text: this.$t('individualReport.ten_percent'), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 },
                { text: this.$t('individualReport.leaders_average'), margin: [-3, 5, -3, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 },
                { text: this.$t('individualReport.trend'), margin: [-3, 5, -3, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 }
              ],
              ...achievement
            ]
          },
          margin: [-8, 0, 0, 10],
          border: [false, false, false, true],
          alignment: 'center',
          fontSize: 9
        }
      ]
    }
  }
}
