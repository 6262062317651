<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-row justify="space-between" class="px-8">
          <h4 class="display-1 left">{{ evaluation.name }}</h4>
          <v-chip
            class="mb-3 white--text right"
            color="primary"
            v-if="evaluation.displayName"
          >{{ evaluation.displayName }}</v-chip>
        </v-row>
      </v-col>
    </v-row>
    <v-card v-if="evaluation._id">
      <v-toolbar color="primary" flat class="white--text">
        <v-btn icon to="/evaluations" color="primary" large>
          <v-icon color="white">fa-chevron-left</v-icon>
        </v-btn>
        <v-toolbar-title>
          <h2 class="title">
            {{ $t('evaluations.evaluation') }}
          </h2>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-menu offset-y transition="slide-y-transition">
          <template v-slot:activator="{ on }">
            <v-btn
              color="primary"
              depressed
              v-on="on"
            >
            <v-icon class="mr-2">fa-cog</v-icon>
              {{ $t('evaluations.options') }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-if="evaluation.status === 'completed'"
              @click="$router.push(`/evaluations/reports/${evaluation._id}`)"
            >
              <v-icon class="mr-2" small>fa-file-pdf</v-icon>
              <v-list-item-title>{{ $t('input.download_reports') }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="$router.push(`/evaluations/${evaluation.slug}/edit`)"
              v-if="evaluation.status === 'in_progress' || evaluation.status === 'pending'"
            >
              <v-icon class="mr-2" small>fa-pen</v-icon>
              <v-list-item-title>{{ $t('evaluations.edit') }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="openConfirmationModal('reminders')"
              v-if="evaluation.status === 'in_progress'"
            >
              <v-icon class="mr-2" small>fa-share</v-icon>
              <v-list-item-title>{{ $t('evaluations.sending_reminders') }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="openConfirmationModal('close')"
              v-if="evaluation.status === 'in_progress'"
            >
              <v-icon class="mr-2" small>fa-times-circle</v-icon>
              <v-list-item-title>{{ $t('evaluations.close_evaluation') }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
      <v-row class="px-8 py-4 my-5">
        <v-col xs="12" sm="4" align="center">
          <v-progress-circular
            :rotate="-90"
            :size="150"
            :value="((evaluation.answers * 100) / totalEvaluations).toFixed(2)"
            :width="15"
            color="primary"
          >
            <p>
              <span class="display-2">
                {{ totalEvaluations }}
              </span> <br>
              <span class="title text-uppercase">
                {{ $t('evaluations.total') }}
              </span>
            </p>
          </v-progress-circular>
        </v-col>
        <v-col xs="12" sm="4" align="center">
          <v-row>
            <v-col cols="6" align="center">
              <h1 class="text-uppercase">{{ $t('evaluations.pending_evaluations') }}</h1>
              <h1 class="display-3" style="color: darkred">{{ totalEvaluations - evaluation.answers }}</h1>
              <h1>{{ (((totalEvaluations - evaluation.answers) * 100) / totalEvaluations).toFixed(2) }}% {{ $t('evaluations.of_polls') }}</h1>
            </v-col>
            <v-col cols="6" align="center">
              <h1 class="text-uppercase">{{ $t('evaluations.finished_evaluations') }}</h1>
              <h1 color="primary" class="display-3" style="color: #51c7af">{{ evaluation.answers }}</h1>
              <h1>{{ ((evaluation.answers * 100) / totalEvaluations).toFixed(2) }}% {{ $t('evaluations.of_polls') }}</h1>
            </v-col>
          </v-row>
        </v-col>
        <v-col xs="12" sm="4" align="center">
          <v-list dense>
            <v-list-item one-line>
              <v-list-item-content>
                <v-list-item-title class="text-uppercase">
                  <v-chip outlined label :color="getColor(evaluation.status)">
                    {{ $t(`evaluations.${evaluation.status}`) }}
                  </v-chip>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title class="text-uppercase">{{$t('evaluations.date_delivery')}}</v-list-item-title>
                <v-list-item-subtitle>{{ evaluation.deliveredAt | date }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title class="text-uppercase">{{$t('evaluations.poll_valid_until')}}</v-list-item-title>
                <v-list-item-subtitle>{{ evaluation.validUntil | date }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title class="text-uppercase">{{$t('evaluations.scheduled_reminders')}}</v-list-item-title>
                <template v-if="evaluation.reminders.length">
                  <v-list-item-subtitle v-for="(reminder, k) in evaluation.reminders" :key="k">{{ reminder.dateTime | date }}</v-list-item-subtitle>
                </template>
                <v-list-item-subtitle v-else>{{$t('evaluations.no_reminders')}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="px-8 py-4 mt-5" align="center" justify="center">
        <v-col cols="6" sm="3">
          <x-inputs-autocomplete
            :items="evaluateds"
            :input="filters.evaluated"
            @updateInput="($event) => filters.evaluated = $event"
            light multiple
            :label="$t('input.evaluated')"
            :on-change="runFilter"
            clearable
          />
        </v-col>
        <v-col cols="6" sm="3">
          <x-inputs-autocomplete
            :items="relations"
            :input="filters.relation"
            @updateInput="($event) => filters.relation = $event"
            light multiple
            :label="$t('input.relation')"
            clearable
            :on-change="runFilter"
          />
        </v-col>
        <v-col cols="6" sm="3">
          <x-inputs-autocomplete
            :items="evaluators"
            :input="filters.evaluators"
            @updateInput="($event) => filters.evaluators = $event"
            light multiple
            :label="$t('input.evaluators')"
            clearable
            :on-change="runFilter"
          />
        </v-col>
        <v-col cols="6" sm="3">
          <x-inputs-autocomplete
            :items="status"
            :input="filters.status"
            @updateInput="($event) => filters.status = $event"
            light
            :label="$t('input.status')"
            clearable
            :on-change="runFilter"
          />
        </v-col>
      </v-row>
      <v-row class="px-8">
        <v-col cols="12">
          <v-simple-table>
            <thead>
              <tr>
                <th class="text-left">{{$t('input.evaluated')}}</th>
                <th class="text-left">{{$t('input.relation')}}</th>
                <th class="text-left">{{$t('input.evaluators')}}</th>
                <th class="text-left">{{$t('input.status')}}</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(item, i) in evaluation.evaluated">
                <tr :key="`name${i}`">
                  <td :rowspan="getRowspan(item)">
                    {{ item.employee.employee.firstName }} {{ item.employee.employee.lastName }}
                  </td>
                  <td v-if="getRowspan(item) === 1" colspan="3">
                    {{ $t('no_data') }}
                  </td>
                </tr>
                <template v-if="showAuto(item)">
                  <tr :key="`${(i * 1 + randomKey())}self${item.employee.employee.id}`">
                    <td colspan="2" style="border-bottom: 1px solid rgba(0,0,0,.12);">{{ $t('evaluations.autoevaluation') }}</td>
                    <td style="border-bottom: 1px solid rgba(0,0,0,.12);">
                      {{ item.employee.completed ? $t('evaluations.completed') : $t('evaluations.pending') }}
                    </td>
                  </tr>
                </template>
                <template v-if="item.leadersEvaluators.length">
                  <tr
                    v-for="(leader, idxL) in item.leadersEvaluators"
                    :key="`${(i + idxL + 1 + randomKey())}leader${leader.employee.id + evaluation.evaluated.length}`"
                  >
                    <td style="border-bottom: 1px solid rgba(0,0,0,.12);">
                      {{$t('evaluations.leader')}}
                    </td>
                    <td style="border-bottom: 1px solid rgba(0,0,0,.12);">
                      {{ leader.employee.firstName }} {{ leader.employee.lastName }}
                    </td>
                    <td style="border-bottom: 1px solid rgba(0,0,0,.12);">
                      {{ leader.completed ? $t('evaluations.completed') : $t('evaluations.pending') }}
                    </td>
                  </tr>
                </template>
                <template v-if="item.pairsEvaluators.length">
                  <tr
                    v-for="(pairs, idxP) in item.pairsEvaluators"
                    :key="`${(i + idxP + 1 + randomKey())}pairs${pairs.employee.id + evaluation.evaluated.length}`"
                  >
                    <td style="border-bottom: 1px solid rgba(0,0,0,.12);">
                      {{$t('evaluations.pair')}}
                    </td>
                    <td style="border-bottom: 1px solid rgba(0,0,0,.12);">
                      {{ pairs.employee.firstName }} {{ pairs.employee.lastName }}
                    </td>
                    <td style="border-bottom: 1px solid rgba(0,0,0,.12);">
                      {{ pairs.completed ? $t('evaluations.completed') : $t('evaluations.pending') }}
                    </td>
                  </tr>
                </template>
                <template v-if="item.dependentsEvaluators.length">
                  <tr
                    v-for="(dependent, idxD) in item.dependentsEvaluators"
                    :key="`${(i + idxD + 1 + randomKey())}dependent${dependent.employee.id + evaluation.evaluated.length}`"
                  >
                    <td style="border-bottom: 1px solid rgba(0,0,0,.12);">
                      {{$t('evaluations.dependent')}}
                    </td>
                    <td style="border-bottom: 1px solid rgba(0,0,0,.12);">
                      {{ dependent.employee.firstName }} {{ dependent.employee.lastName }}
                    </td>
                    <td style="border-bottom: 1px solid rgba(0,0,0,.12);">
                      {{ dependent.completed ? $t('evaluations.completed') : $t('evaluations.pending') }}
                    </td>
                  </tr>
                </template>
              </template>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-card>
    <x-confirmation-modal
      :show.sync="showModal"
      :reversible="typeModal !== 'close'"
      :title="typeModal !== 'close' ? $t('evaluations.send_reminders') : $t('evaluations.close_evaluation')"
      :action="typeModal !== 'close' ? sendReminders : closeEvaluation"
      :btn-save="$t('input.confirm')"
      @close="showModal = false"
    >
      <template v-slot:question>{{ typeModal !== 'close' ? $t('evaluations.send_reminders_q') : $t('evaluations.close_evaluation_q') }}</template>
    </x-confirmation-modal>
  </v-container>
</template>

<script>

import Vue from 'vue'
import { mapState } from 'vuex'

import evaluationsService from '../../services/evaluations'

export default Vue.extend({
  data () {
    return {
      evaluation: {},
      totalEvaluations: 0,
      filters: {
        evaluated: [],
        relation: [],
        evaluators: [],
        status: ''
      },
      status: [
        { text: 'evaluations.pending', value: '0' },
        { text: 'evaluations.completed', value: '1' }
      ],
      relations: [
        { text: 'evaluations.autoevaluation', value: 'employee' },
        { text: 'evaluations.leader', value: 'leadersEvaluators' },
        { text: 'evaluations.pair', value: 'pairsEvaluators' },
        { text: 'evaluations.dependent', value: 'dependentsEvaluators' }
      ],
      evaluators: [],
      evaluateds: [],
      baseEvaluation: {},
      typeModal: '',
      showModal: false,
      keyRelations: ['leadersEvaluators', 'dependentsEvaluators', 'pairsEvaluators']
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    })
  },
  created () {
    this.status.forEach(status => { status.text = this.$t(status.text) })
    this.relations.forEach(relations => { relations.text = this.$t(relations.text) })
    this.getEvaluation()
  },
  methods: {
    getColor (status) {
      switch (status) {
        case 'pending':
          return 'gray'
        case 'completed':
          return 'green'
        case 'in_progress':
          return 'blue'
      }
    },
    openConfirmationModal (type) {
      this.showModal = true
      this.typeModal = type
    },
    sendReminders () {
      return evaluationsService.sendReminders(this.evaluation.slug)
        .then((res) => {
          this.$store.dispatch('alert/success', this.$t('evaluations.reminders_sent_succesfully'))
        }).catch((err) => {
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        })
    },
    closeEvaluation () {
      return evaluationsService.closeEvaluation(this.evaluation.slug)
        .then((res) => {
          this.$store.dispatch('alert/success', this.$t('evaluations.evaluation_closed_succesfully'))
          return this.getEvaluation()
        }).catch((err) => {
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        })
    },
    employeeMapper (employee) {
      return employee.text ? employee : {
        text: `${employee.firstName} ${employee.lastName}`,
        value: employee.id,
        ...employee
      }
    },
    mapEvaluated (evaluated) {
      this.keyRelations.forEach((key) => {
        evaluated[key].forEach((it) => {
          it.employee = this.employeeMapper(it.employee)
          if (it.completed) {
            this.evaluation.answers++
          }
          const existEvaluator = this.evaluators.find(e => e.value === it.employee.value)
          if (!existEvaluator) {
            this.evaluators.push({ value: it.employee.value, text: it.employee.text })
          }
        })
      })
      evaluated.employee.employee = this.employeeMapper(evaluated.employee.employee)
      this.evaluateds.push({ value: evaluated.employee.employee.value, text: evaluated.employee.employee.text })
    },
    getEvaluation () {
      this.$store.dispatch('loading/show')
      return evaluationsService.getOne(this.$route.params.slug)
        .then((res) => {
          this.baseEvaluation = res
          this.evaluation = JSON.parse(JSON.stringify(res))
          this.totalEvaluations = this.evaluation.answers = 0
          for (const evaluated of this.evaluation.evaluated) {
            this.mapEvaluated(evaluated)
            this.totalEvaluations += 1 + evaluated.leadersEvaluators.length + evaluated.dependentsEvaluators.length + evaluated.pairsEvaluators.length
            if (evaluated.employee.completed) {
              this.evaluation.answers++
            }
          }
          this.$store.dispatch('loading/hide')
        }).catch((err) => {
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
          this.$store.dispatch('loading/hide')
        })
    },
    runFilter () {
      const endEvaluateds = []
      for (const evaluated of this.baseEvaluation.evaluated) {
        if (this.filters.evaluated.length && this.filters.evaluated.indexOf(evaluated.employee.employee.id) === -1) {
          continue
        }
        const i = endEvaluateds.push({
          employee: evaluated.employee,
          leadersEvaluators: [],
          dependentsEvaluators: [],
          pairsEvaluators: [],
          permanent: true
        }) - 1

        for (const key of this.keyRelations) {
          if (this.filters.relation.length && this.filters.relation.indexOf(key) === -1) {
            continue
          }
          for (const evaluator of evaluated[key]) {
            let exclude = this.filters.evaluators.length && this.filters.evaluators.indexOf(evaluator.employee.id) === -1
            if (!exclude && this.filters.status) {
              exclude = this.filters.status === '1' && !evaluator.completed
              exclude = exclude || (this.filters.status === '0' && evaluator.completed)
            }
            if (!exclude) {
              endEvaluateds[i][key].push(evaluator)
            }
          }
        }
      }
      this.$set(this.evaluation, 'evaluated', endEvaluateds)
    },
    getRowspan (evaluated) {
      let rowspan = 1
      if (this.showAuto(evaluated)) {
        rowspan++
      }
      return rowspan + evaluated.leadersEvaluators.length + evaluated.dependentsEvaluators.length + evaluated.pairsEvaluators.length
    },
    showAuto (evaluated) {
      let show = (!this.filters.relation.length || this.filters.relation.indexOf('employee') !== -1) && !this.filters.evaluators.length
      if (show && this.filters.status) {
        show = this.filters.status === '1' && evaluated.employee.completed
        show = show || (this.filters.status === '0' && !evaluated.employee.completed)
      }
      return show
    },
    randomKey () {
      return Math.random()
    }
  }
})
</script>
