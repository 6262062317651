
import mapParameters from '../utils/map-parameters'
import Services from './base-services'

const service = new Services('open-questions')

interface IOptionsList {
  page?: number;
  itemsPerPage?: number;
  filter?: string;
  search?: string;
}

export default {
  getOne: (name: string) => {
    return service.get(`find-by-name/${name}`)
  },
  list: (options: IOptionsList) => {
    return service.get('list')
  },
  updateQuestion: (data: any) => {
    return service.put('edit-question', { data })
  }
}
