
export default {
  email: 'Email',
  password: 'Password',
  password_confirm: 'Confirm password',
  new_password: 'New password',
  new_password_confirm: 'New password confirm',
  logout: 'Logout',
  first_name: 'First name',
  last_name: 'Last name',
  identify_document: 'Identify document',
  gender: 'Gender',
  department: 'Department',
  employment: 'Employment',
  academic_degree: 'Academic degree',
  admission: 'Admission date',
  age_range: 'Age range',
  antiquity_range: 'Antiquity range',
  jobType: 'Job type',
  job_type: 'Job type',
  charge: 'Charge',
  country: 'Country',
  headquarter: 'Headquarter',
  phone_number: 'Phone number',
  accept: 'Accept',
  cancel: 'Cancel',
  confirm: 'Confirm',
  save: 'Save',
  next: 'Next',
  back: 'Go back',
  enable: 'Enable',
  disable: 'Disable',
  add: 'Add',
  trash: 'Delete',
  import: 'Import',
  file: 'File',
  save_changes: 'Save changes',
  error_fix: 'Fix errors',
  confirm_changes: 'Confirm changes',
  back_home: 'Back home',
  back_list: 'Back list',
  name: 'Name',
  sector: 'Sector',
  address: 'Address',
  enterprise: 'Enterprise',
  enterprises: 'Enterprises',
  enterprise_size: 'Enterprise Size',
  customer: 'Customer',
  language: 'Language',
  employees_number: 'Employees number',
  demographic_cuts: 'Demographic cuts',
  license: 'License',
  duration: 'Duration',
  start_date: 'Start date',
  edit: 'Edit',
  edit_questions: 'Edit questions',
  details: 'Details',
  view_details: 'View details',
  birthdate: 'Birthdate',
  confirm_save: 'Confirm and create',
  continue: 'Continue',
  start_poll: 'Start assessment',
  answer_poll: 'Answer assessment',
  finish_send: 'Finish and send',
  finish: 'Finish',
  back_to_dashboard: 'Back to dashboard',
  select_image: 'Select company logo',
  continue_filling: 'Continue filling',
  languages: 'Languages',
  begin: 'Begin',
  answer: 'Answer',
  resend: 'Resend',
  view_pdf: 'Go to report',
  extend_plan: 'Extend plan',
  enterprise_name: 'Enterprise name',
  pulses_qty_add: 'Pulse quantity to add:',
  engagements_qty_add: 'Engagement quantity to add:',
  admin_extension: 'Admin extension requests',
  reject: 'Reject',
  see_requests: 'View sent requests',
  identify_type: 'Identify document type',
  exist_identification: 'Entered identification number already exists.',
  identify_type_s: 'ID',
  newplan: 'New plan',
  admin_update: 'Adminupdate requests',
  update_plan: 'Update plan',
  no_permissions: 'You do not have permissions to perfom this action.',
  want_request_extend: 'If you want to request an improvement in your license click on extend plan button.',
  group: 'Group',
  engagementsQty: 'Engagement quantity',
  pulsesQty: 'Pulse quantity',
  view_extension_history: 'View requests history',
  plan_extend_info: 'Plan extend',
  demo_duration: '15 days',
  order: 'Orden',
  required_field: 'Required field.',
  valid_email: 'Enter a valid email.',
  close: 'Close',
  generic_link_btn: 'Link for Collaborators without Email',
  invitation_url: 'Invitation url',
  url_copied: 'Url copied',
  copy_invitation_url_title: 'Link for collaborators without email',
  // tslint:disable-next-line:max-line-length
  copy_invitation_url: 'You can copy this link and send it to the collaborators who are participating in the measurement and do not have an email or have not received the invitation email.',
  send_invitation_url: 'Send invitation url',
  modal_title: 'Edit employee data',
  preview: 'Preview',
  close_poll: 'Close poll',
  // tslint:disable-next-line:max-line-length
  master_references_delete_poll: 'After closing this poll no one of your employees will be able to make the poll and this action can not be changed. All the information for reports will be saved till this point.',
  select_video: 'Select video for the email',
  login_as_enterprise: 'Sign in as this enterprise',
  login_as_employee: 'Sign in as this employee',
  add_token: 'Add OCC Tokens',
  enterprise_profile: 'See enterprise porofile',
  contact_information: 'ID - Contact information',
  complete_name: 'Complete name',
  change: 'Change',
  actual_password: 'Actual password',
  got_it: 'Got it !',
  password_change: 'Password change',
  actual_email: 'Actual email',
  email_change: 'Email change',
  new_email: 'New email',
  view_profile: 'Profile',
  change_enterprise: 'Change enterprise',
  changing_enterprise: 'Enterprise change',
  fast_search: 'Fast search',
  sales_range: 'Sales range',
  manage_token: 'Manage OCC Tokens',
  select: 'Select',
  update: 'Update',
  see_more: 'See more',
  back_to_login: 'Go back to login',
  authorizations: 'Authorizations',
  manage_authorizations: 'Manage Authorizations',
  back_to_list: 'Go back to list',
  display_name: 'Display name',
  create: 'Create',
  evaluated: 'Evaluated',
  relation: 'Relation',
  evaluators: 'Evaluators',
  status: 'Status',
  apply: 'Apply',
  download: 'Download',
  upload_file: 'Upload file',
  warning: 'Warning',
  error: 'Error',
  see_warnings: 'See warnings',
  see_errors: 'See errors',
  no_reversible_msg: 'Remember this action is not reversible',
  start: 'Start',
  download_reports: 'Download Reports',
  the_reminders: 'The reminders',
  may_take_while: 'This action may take a few minutes'
}
