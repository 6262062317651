
export default {
  insert_document: 'Insert your document identification number',
  verify: 'Verify polls',
  polls_list: 'Available polls',
  pending_polls_header: [
    { text: 'Name', value: '', sortable: false, class: 'text-xs-left' },
    { text: 'Type', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'End at', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Actions', value: '', sortable: false, class: 'text-xs-center' }
  ],
  fill_poll: 'Fill poll',
  back_to_verify_polls: 'Volver a verificacion de documento'
}
