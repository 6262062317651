<template>
   <v-row justify="center">
    <template v-if="info.length">
      <template v-for="(activity, k) in info">
        <v-col align="center" xs="12" sm="" :key="k" v-if="activity.isEvaluated || activity.isEvaluator">
          <v-card>
            <v-toolbar dense class="mb-3">
              <v-toolbar-title class="text-capitalize center--text">{{activity.displayName || activity.name}}</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center" v-for="(header, k) in $t('dashboard.employee_activities_table_header')" :key="k">
                        {{ header }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-if="activity.isEvaluated">
                      <tr>
                        <td class="text-center">
                          {{ $t('dashboard.autoevaluation') }}
                        </td>
                        <td class="text-center">
                          <v-btn
                            class="text-capitalize"
                            rounded
                            outlined
                            color="primary"
                            :to="`evaluations/dml/${activity.asEvaluated.employee.token}`"
                            v-if="!activity.asEvaluated.employee.completed"
                          >
                            {{ $t('dashboard.start') }}
                          </v-btn>
                          <v-btn
                            v-else
                            disabled
                            rounded
                            outlined
                            class="text-capitalize"
                          >
                            {{ $t('dashboard.evaluation_finished') }}
                          </v-btn>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-center">{{ $t('dashboard.evaluators') }}</td>
                        <td class="text-center">
                          <v-btn class="text-capitalize" rounded outlined color="primary" @click="showEvaluators(activity.asEvaluated)">
                            {{ $t('dashboard.see_evaluated') }}
                          </v-btn>
                        </td>
                      </tr>
                    </template>
                    <template v-if="activity.isEvaluator">
                      <tr v-for="evaluator in activity.asEvaluator" :key="evaluator.employee.employee._id">
                        <td class="text-center">
                          {{ evaluator.evaluated }}  ({{$t(`dashboard.${evaluator.as}`)}})
                        </td>
                        <td class="text-center">
                          <v-btn
                            class="text-capitalize"
                            rounded
                            outlined
                            color="primary"
                            :to="`evaluations/dml/${evaluator.token}`"
                            v-if="!evaluator.completed"
                          >
                            {{ $t('dashboard.start') }}
                          </v-btn>
                          <v-btn
                            v-else
                            disabled
                            rounded
                            outlined
                            class="text-capitalize"
                          >
                            {{ $t('dashboard.evaluation_finished') }}
                          </v-btn>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" align="centered" class="py-10" :key="k" v-else>
            <h1 class="display-2 my-10 text-center" style="color: #90a4ae">
              {{ $t('dashboard.no_pending_activities') }}
            </h1>
        </v-col>
      </template>
    </template>
    <v-col cols="12" align="centered" class="py-10" v-else>
      <h1 class="display-2 my-10 text-center" style="color: #90a4ae">
        {{ $t('dashboard.no_pending_activities') }}
      </h1>
    </v-col>
    <v-dialog v-model="showDialog" v-if="showDialog" width="45em">
      <v-card>
        <v-toolbar dense class="mb-3">
          <v-toolbar-title class="text-capitalize center--text">{{ $t('dashboard.evaluators') }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-simple-table>
            <thead>
              <tr>
                <th class="text-left">{{$t('input.relation')}}</th>
                <th class="text-left">{{$t('input.evaluators')}}</th>
                <th class="text-left">{{$t('input.status')}}</th>
              </tr>
            </thead>
            <tbody>
              <template>
                <tr v-for="leader in evaluatedToDisplay.leadersEvaluators" :key="leader.employee._id">
                  <td>
                    {{$t('evaluations.leader')}}
                  </td>
                  <td>
                    {{ leader.employee.text}}
                  </td>
                  <td>
                    {{ leader.completed ? $t('evaluations.completed') : $t('evaluations.pending') }}
                  </td>
                </tr>
              </template>
              <template>
                <tr v-for="pair in evaluatedToDisplay.pairsEvaluators" :key="pair.employee._id">
                  <td>
                    {{$t('evaluations.pair')}}
                  </td>
                  <td>
                    {{ pair.employee.text }}
                  </td>
                  <td>
                    {{ pair.completed ? $t('evaluations.completed') : $t('evaluations.pending') }}
                  </td>
                </tr>
              </template>
              <template>
                <tr v-for="dependent in evaluatedToDisplay.dependentsEvaluators" :key="dependent.employee._id">
                  <td>
                    {{$t('evaluations.dependent')}}
                  </td>
                  <td>
                    {{ dependent.employee.text }}
                  </td>
                  <td>
                    {{ dependent.completed ? $t('evaluations.completed') : $t('evaluations.pending') }}
                  </td>
                </tr>
              </template>
            </tbody>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import Vue from 'vue'

export default Vue.extend({
  props: {
    info: Array
  },
  data () {
    return {
      showDialog: false,
      evaluatedToDisplay: null,
      i: 1
    }
  },
  methods: {
    showEvaluators (evaluators) {
      this.evaluatedToDisplay = evaluators
      this.showDialog = true
    }
  }
})
</script>

<style lang="css" scoped>
.text-center{
  text-align: center !important;
}
.info-data{
  font-size: 3em;
}
.minipercentage{
  font-size: 2em !important;
}
.padding-left-name{
  padding-left: .4em !important;
}
.padding-right-name{
  padding-right: .4em !important;
}
.text-success{
  color: #4caf50 !important
}
.text-error{
  color: #ff5252 !important
}
.no-polls{
  font-size: 1.8em;
  color: lightgray;
}
.padding-card{
  padding: 3em;
}
.pulse {
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 0 rgb(56, 153, 218);
  animation: pulse 2s infinite;
}
.no-decoration{
  text-decoration: none;
}
.survey-title{
  font-size: 1.2em;
  font-weight: bold;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgb(56, 153, 218);
  }
  70% {
      -webkit-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgb(56, 153, 218);
    box-shadow: 0 0 0 0 rgb(56, 153, 218);
  }
  70% {
      -moz-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
      box-shadow: 0 0 0 10px rgba(204,169,44, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
      box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
}
</style>
