
export default {
  methods: {
    $generateIntroduction () {
      return [
        {
          table: {
            widths: [479],
            body: [
              [{ text: `${this.$t('demographicReport.index_title')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 6, 0, 6] }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [-40, 50, 0, 20]
        },
        {
          columns: [
            {
              width: 'auto',
              stack: this.$t('demographicReport.index'),
              margin: [15, 0, 0, 0],
              color: '#6d7a7a',
              lineHeight: 2,
              fontSize: 13
            },
            { width: '*', text: '' },
            {
              width: '14%',
              text: `3\n7\n11\n12\n13\n17\n18\n19`,
              color: '#6d7a7a',
              lineHeight: 2,
              fontSize: 13
            }
          ]
        }
      ]
    }
  }
}
