
export default {
  email: 'Correo electrónico',
  password: 'Contraseña',
  password_confirm: 'Confirmar contraseña',
  new_password: 'Nueva contraseña',
  new_password_confirm: 'Confirmar nueva contraseña',
  logout: 'Cerrar sesión',
  first_name: 'Nombre',
  last_name: 'Apellido',
  identify_document: 'Documento de identidad',
  gender: 'Género',
  department: 'Departamento/Área',
  employment: 'Cargo',
  academic_degree: 'Nivel académico',
  admission: 'Fecha de ingreso',
  age_range: 'Rango de edad',
  antiquity_range: 'Rango de Antigüedad',
  job_type: 'Tipo de contratación',
  jobType: 'Tipo de contratación',
  charge: 'Cargo',
  country: 'País',
  headquarter: 'Sede',
  phone_number: 'Número de teléfono',
  accept: 'Aceptar',
  cancel: 'Cancelar',
  confirm: 'Confirmar',
  save: 'Guardar',
  next: 'Siguiente',
  back: 'Regresar',
  enable: 'Habilitar',
  disable: 'Inhabilitar',
  add: 'Agregar',
  trash: 'Borrar',
  refresh: 'Refrescar',
  import: 'Importar',
  file: 'Archivo',
  save_changes: 'Guardar cambios',
  error_fix: 'Corregir errores',
  confirm_changes: 'Confirmar cambios',
  back_home: 'Volver al inicio',
  back_list: 'Volver al listado',
  name: 'Nombre',
  sector: 'Sector',
  address: 'Dirección',
  enterprise: 'Empresa',
  enterprises: 'Empresas',
  enterprise_size: 'Tamaño de la empresa',
  language: 'Idioma',
  employees_number: 'Número de colaboradores',
  demographic_cuts: 'Cortes demográficos',
  license: 'Licencia',
  duration: 'Duración',
  start_date: 'Fecha de inicio',
  edit: 'Editar',
  edit_questions: 'Editar cuestionario',
  details: 'Detalles',
  view_details: 'Ver detalles',
  birthdate: 'Fecha de nacimiento',
  confirm_save: 'Confirmar y crear',
  continue: 'Continuar',
  start_poll: 'Comenzar valoración',
  answer_poll: 'Responder valoración',
  finish_send: 'Finalizar y enviar',
  finish: 'Finalizar',
  back_to_dashboard: 'Volver al tablero',
  select_image: 'Seleccionar logo de la compañía',
  continue_filling: 'Continuar llenando',
  languages: 'Idiomas',
  begin: 'Comenzar',
  answer: 'Respuesta',
  resend: 'Reenviar',
  view_pdf: 'Ir al reporte',
  extend_plan: 'Extender plan',
  enterprise_name: 'Nombre de la empresa',
  customer: 'Cliente',
  pulses_qty_add: 'Cantidad de Pulse a agregar:',
  engagements_qty_add: 'Cantidad de Engagement a agregar:',
  admin_extension: 'Administrar solicitudes de extensión',
  reject: 'Rechazar',
  see_requests: 'Ver solicitudes enviadas',
  identify_type: 'Tipo de documento de identidad',
  identify_type_s: 'DI',
  exist_identification: 'El número de identificación ingresado ya existe.',
  newplan: 'Nuevo plan',
  admin_update: 'Administrar solicitud de actualización',
  update_plan: 'Actualizar plan',
  no_permissions: 'No posee permisos para realizar esta acción',
  want_request_extend: 'Si desea solicitar una mejora en su licencia haga click en el boton de extender plan.',
  group: 'Grupo',
  engagementsQty: 'Cantidad de Engagement',
  pulsesQty: 'Cantidad de Pulse',
  view_extension_history: 'Ver histórico de solicitudes',
  plan_extend_info: 'Extensión de plan',
  demo_duration: '15 días',
  order: 'Orden',
  required_field: 'Campo obligatorio',
  valid_email: 'Ingrese un correo electrónico válido.',
  close: 'Cerrar',
  generic_link_btn: 'Enlace para Colaboradores sin Correo',
  invitation_url: 'Enlace de invitación',
  url_copied: 'Enlace copiado',
  copy_invitation_url_title: 'Enlace para colaboradores sin correo',
  // tslint:disable-next-line: max-line-length
  copy_invitation_url: 'Este enlace lo puedes copiar y enviar a los colaboradores que estén participando en la medición y no tengan correo electrónico o no les haya llegado el correo de invitación. Para poder responder la encuesta, los colaboradores deberán ingresar su documento de identidad con el cual se han cargado en la plataforma.',
  send_invitation_url: 'Enviar enlace de invitacion',
  modal_title: 'Editar datos de empleado',
  preview: 'Previsualización',
  close_poll: 'Cerrar valoración',
  poll_delete_title: 'Cerrar valoración',
  // tslint:disable-next-line:max-line-length
  master_references_delete_poll: 'Al cerrar la valoración ninguno de sus colaboradores podrá participar en la encuesta y esta acción no podrá ser cambiada de ninguna manera. Toda la información para reportes se guardará hasta este punto.',
  select_video: 'Seleccionar video para el correo',
  login_as_enterprise: 'Acceder como esta empresa',
  login_as_employee: 'Acceder como este empleado',
  add_token: 'Agregar OCC Tokens',
  enterprise_profile: 'Perfil de la empresa',
  contact_information: 'DI - Información de contacto',
  complete_name: 'Nombre completo',
  change: 'Cambiar',
  actual_password: 'Contraseña actual',
  got_it: '¡ Entendido !',
  password_change: 'Cambio de contraseña',
  actual_email: 'Dirección de correo actual',
  email_change: 'Cambio de correo eletrónico',
  new_email: 'Nuevo email',
  view_profile: 'Ver perfil',
  change_enterprise: 'Cambiar empresa',
  changing_enterprise: 'Cambio de empresa',
  fast_search: 'Búsqueda rápida',
  sales_range: 'Rango de ventas',
  manage_token: 'Gestionar OCC Tokens',
  select: 'Seleccionar',
  update: 'Actualizar',
  see_more: 'Ver más',
  back_to_login: 'Volver a inicio de sesión',
  authorizations: 'Autorizaciones',
  manage_authorizations: 'Gestionar Autorizaciones',
  back_to_list: 'Volver al listado',
  display_name: 'Nombre externo',
  create: 'Agregar',
  evaluated: 'Evaluado',
  relation: 'Relación',
  evaluators: 'Evaluadores',
  status: 'Estado',
  apply: 'Aplicar',
  download: 'Descargar',
  upload_file: 'Cargar archivo',
  warning: 'Advertencia',
  error: 'Error',
  see_warnings: 'Ver advertencias',
  see_errors: 'Ver errores',
  no_reversible_msg: 'Recuerde que esta acción es irreversible',
  start: 'Iniciar',
  update_model: 'Actualizar modelo',
  download_reports: 'Descargar Reportes',
  the_reminders: 'Los recordatorios',
  may_take_while: 'Esta acción puede tardar unos minutos'
}
