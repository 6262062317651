
export default {
  methods: {
    $generateIntroduction () {
      const stack2 = []
      for (let i = 0; i < 3; i++) {
        stack2.push([
          {
            columns: [
              {
                width: 'auto',
                text: this.$t(`individualReport.index2[${i}]`).name,
                margin: [28, 0, 0, 0],
                color: this.$t(`individualReport.index2[${i}]`).index === 1 ? '#b4cf32' : this.$t(`individualReport.index2[${i}]`).index === 2 ? '#4ec7aa' : '#43ace2',
                lineHeight: 2,
                fontSize: 13
              },
              { width: '*', text: '' },
              {
                width: '14%',
                text: this.$t(`individualReport.index2[${i}]`).index === 1 ? '5' : this.$t(`individualReport.index2[${i}]`).index === 2 ? '6' : '7',
                color: '#6d7a7a',
                lineHeight: 2,
                fontSize: 13
              }
            ]
          }
        ])
      }
      return [
        {
          table: {
            widths: [479],
            body: [
              [{ text: `${this.$t('individualReport.index_title')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 6, 0, 6] }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [-40, 0, 0, 20]
        },
        {
          columns: [
            {
              width: 'auto',
              stack: this.$t('individualReport.index1'),
              margin: [15, 0, 0, 0],
              color: '#6d7a7a',
              lineHeight: 2,
              fontSize: 13
            },
            { width: '*', text: '' },
            {
              width: '14%',
              text: '3\n4\n4',
              color: '#6d7a7a',
              lineHeight: 2,
              fontSize: 13
            }
          ]
        },
        ...stack2,
        {
          columns: [
            {
              width: 'auto',
              stack: this.$t('individualReport.index3'),
              margin: [15, 0, 0, 0],
              color: '#6d7a7a',
              lineHeight: 2,
              fontSize: 13
            },
            { width: '*', text: '' },
            {
              width: '14%',
              text: '8\n9\n12\n12\n13\n14\n16\n19\n36\n37\n39\n40\n41',
              color: '#6d7a7a',
              lineHeight: 2,
              fontSize: 13
            }
          ]
        }
      ]
    }
  }
}
